<template>
  <reports-dialog-layout v-model="state.enabled">
    <reports-dialog-form :filters="state.filters" :i18n-data="state.i18nData" ref="form" />
    <reports-dialog-form-submitter slot="footer" @click="submit" />
  </reports-dialog-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import ReportsDialogForm from './reports-dialog-form';
import ReportsDialogFormSubmitter from './reports-dialog-form-submitter';
import ReportsDialogLayout from './reports-dialog-layout';
import ReportsNotification from './reports-notification';

@Component({
  name: 'ReportsDialog',
  components: {
    ReportsDialogForm,
    ReportsDialogFormSubmitter,
    ReportsDialogLayout,
    ReportsNotification
  }
})
export default class ReportsDialog extends Vue {
  get state() {
    return this.$store.state.dialog.reports;
  }

  get reports() {
    return this.$store.state.reports;
  }

  async submit() {
    try {
      const { pictures, source, name, event_type, camera_in, camera_out, area } = await this.$refs.form.validate(),
        reportType = event_type ?? this.state.filters.type,
        type = reportType === 'kyc' && source === 'input' ? 'face-clusters' : reportType,
        item = { ...this.state.filters, type, name, pictures };

      if (camera_in && camera_out) {
        item.meta = { camera_in, camera_out };
      }

      if (area) {
        this.state.filters.filters.area = area;
      }

      await this.$store.dispatch(this.reports.Action.Create, item);
      this.notifyWhenReportCreated();
      this.state.enabled = false;
    } catch (e) {
      e && this.notifyWhenErrorThrown(e);
    }
  }

  notifyWhenReportCreated() {
    this.$notify({
      type: 'success',
      message: this.$createElement(ReportsNotification, {
        on: { click: (path) => this.$router.push(path), auxclick: (path) => window.open(path, '_blank') }
      })
    });
  }

  notifyWhenErrorThrown(e) {
    this.$notify({
      duration: 0,
      message: this.$createElement('message-box', { props: { e } })
    });
  }
}
</script>
