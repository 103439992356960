<template>
  <div class="reports-dialog-activator">
    <el-button size="small" class="reports-dialog-activator__button" v-text="$tf(['create', 'report'])" @click="openReportCreatingDialog" />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { evaluateReportFilter } from './reports-helpers';

@Component({
  props: {
    type: { type: String, required: true },
    filter: { type: Object, required: true },
    pictures: { type: Array, default: () => ['fullframe', 'thumbnail', 'link'] }
  }
})
export default class ReportsDialogActivator extends Vue {
  get state() {
    return this.$store.state.dialog.reports;
  }

  openReportCreatingDialog() {
    this.state.enabled = true;
    this.state.filters = {
      type: this.type,
      filters: evaluateReportFilter(this.type, this.filter),
      pictures: this.pictures
    };
  }
}
</script>

<style lang="stylus">
.reports-dialog-activator {
  padding-bottom: 2rem;

  &__button {
    width: 100%;
  }
}
</style>
