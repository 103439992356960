

































import { Component, Watch } from 'vue-property-decorator';
import BaseFormComponent from '../component.vue';
import { IUser } from '@/store/users/users/interfaces';

@Component
export default class BaseFormComponentUserPrimaryGroup extends BaseFormComponent {
  user: IUser | null = null;
  role = null;

  @Watch('form.item', { deep: true, immediate: true })
  userChangeHandler() {
    this.user = this.form.item;
  }

  @Watch('role')
  roleChangeHandler(v, p) {
    if (v) {
      this.role = null;
      this.user?.groups.push(v);
      this.setDefaultPrimaryRole();
    }
  }

  @Watch('form.item.primary_group')
  primaryGroupChangeHandler(currentGroup, prevGroup) {
    const currentGroupIndex = this.userGroups.indexOf(currentGroup);

    if (currentGroup && ~currentGroupIndex) this.userGroups.splice(currentGroupIndex, 1);
    if (currentGroup && prevGroup) this.userGroups.push(prevGroup);
  }

  get userGroups() {
    return this.form.getCurrentFieldValue('groups');
  }

  set userGroups(v) {
    this.form.setCurrentFieldValue('groups', v);
  }

  get userPrimaryGroup() {
    return this.form.item.primary_group;
  }

  get userRoles() {
    const itemIds = [].concat(this.userGroups, this.userPrimaryGroup).filter((v) => v >= 1);
    return itemIds
      .sort((a, b) => a - b)
      .map((v) => this.$store.state.groups.items.find((i) => i.id === v))
      .filter(Boolean);
  }

  get roles() {
    const itemIds = [].concat(this.userGroups, this.userPrimaryGroup).filter((v) => v >= 1);
    return this.$store.state.groups.items.filter((item: { id: never }) => !itemIds.includes(item.id));
  }

  get requiredArrayRule() {
    return this.getTranslatedRule({
      required: true,
      name: 'array',
      props: {
        i18nMessage: 'error.array.empty',
        minLength: 1
      }
    });
  }

  setDefaultPrimaryRole() {
    if (!this.userPrimaryGroup) {
      this.$nextTick(() => (this.form.item.primary_group = this.userGroups.sort((a, b) => a - b).shift()));
    }
  }

  deleteRoleHandler(v) {
    if (!this.user?.primary_group) return;

    if (this.user.primary_group === v.id) {
      this.user.primary_group = 0;
      this.setDefaultPrimaryRole();
    } else {
      let index = this.user.groups.findIndex((i) => i === v.id);
      this.user.groups.splice(index, 1);
    }
  }
}
