<template>
  <span v-if="!!model" v-text="model" />
</template>

<script>
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';

@Component()
export default class BaseFormComponentTextField extends BaseFormComponent {}
</script>
