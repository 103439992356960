export const BaseFormComponentName = {
  Age: 'age',
  Autocomplete: 'autocomplete',
  CameraGroups: 'camera-groups',
  Cameras: 'cameras',
  CheckboxGroup: 'checkbox-group',
  Confidence: 'confidence',
  Datetime: 'datetime',
  TextField: 'text-field',
  Input: 'input',
  Select: 'select',
  WatchLists: 'watch-lists',
  AreaTrigger: 'area-trigger',
  AreaCalendar: 'area-calendar',
  CameraRegions: 'camera-regions',
  UserPrimaryGroup: 'user-primary-group',
  UserFaces: 'user-faces',
  UserPasswords: 'user-passwords',
  UserActiveCheckbox: 'user-active-checkbox',
  DateField: 'date-field'
};

export const BaseFormComponents = {
  [BaseFormComponentName.Autocomplete]: require('./autocomplete').default,
  [BaseFormComponentName.CameraGroups]: require('./camera-groups').default,
  [BaseFormComponentName.Cameras]: require('./cameras').default,
  [BaseFormComponentName.CheckboxGroup]: require('./checkbox-group').default,
  [BaseFormComponentName.Confidence]: require('./confidence').default,
  [BaseFormComponentName.Datetime]: require('./datetime').default,
  [BaseFormComponentName.TextField]: require('./text-field').default,
  [BaseFormComponentName.Input]: require('./input').default,
  [BaseFormComponentName.Select]: require('./select').default,
  [BaseFormComponentName.WatchLists]: require('./watch-lists').default,
  [BaseFormComponentName.AreaTrigger]: require('./areas/area-trigger').default,
  [BaseFormComponentName.AreaCalendar]: require('./areas/area-calendar').default,
  [BaseFormComponentName.CameraRegions]: require('./regions/camera-regions').default,
  [BaseFormComponentName.UserPrimaryGroup]: require('./users/user-primary-group').default,
  [BaseFormComponentName.UserFaces]: require('./users/user-faces').default,
  [BaseFormComponentName.UserPasswords]: require('./users/user-passwords').default,
  [BaseFormComponentName.UserActiveCheckbox]: require('./users/user-active-checkbox').default,
  [BaseFormComponentName.DateField]: require('./date-field').default
};
