import { ICarCard, ICarCardFilter, IHumanCard, IHumanCardFilter } from '@/store/cards/cards/index';

export const EmptyHumanItem: IHumanCard = {
  id: 0,
  filled: '',
  name: '',
  comment: '',
  created_date: '',
  modified_date: '',
  active: true,
  watch_lists: [],
  meta: {},

  face_objects: 0,
  body_objects: 0,
  face_cluster: 0,
  body_cluster: 0,

  looks_like: null,
  looks_like_confidence: 0
};

export const EmptyHumansFilter: IHumanCardFilter = {
  limit: 10,
  id_in: '',
  filled: '',
  has_body_objects: '',
  has_face_objects: '',
  watch_lists: [],
  name_contains: '',
  page: undefined,
  ordering: '-id'
};

export const mockHumanItems: IHumanCard[] = [
  {
    ...EmptyHumanItem,
    id: 1,
    name: 'The One',
    active: true,
    watch_lists: [],
    meta: {},
    filled: true,
    face_objects: 3,
    body_objects: 12,
    face_cluster: 5,
    body_cluster: 24,
    created_date: new Date().toISOString(),
    modified_date: new Date().toISOString()
  },
  {
    ...EmptyHumanItem,
    id: 2,
    name: 'Мужик 2',
    active: true,
    watch_lists: [],
    meta: {},
    filled: false,
    face_objects: 0,
    body_objects: 0,
    face_cluster: 5,
    body_cluster: 24,
    created_date: new Date().toISOString(),
    modified_date: new Date().toISOString()
  }
];

export const EmptyCarItem: ICarCard = {
  id: 0,
  filled: '',
  name: '',
  comment: '',
  created_date: '',
  modified_date: '',
  active: true,
  watch_lists: [],
  license_plate_number: '',
  meta: {},

  car_objects: 0,
  car_cluster: 0,

  looks_like: null,
  looks_like_confidence: 0
};

export const EmptyCarsFilter: ICarCardFilter = {
  limit: 10,
  id_in: '',
  filled: '',
  has_car_objects: '',
  license_plate_number_contains: '',
  watch_lists: [],
  name_contains: '',
  page: undefined,
  ordering: '-id'
};
