



import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IFaceClusterEvent, IBodyClusterEvent, ICarClusterEvent } from '@/store/clusters';
import { getFilterString } from '@/definitions/base.items.state.module';

type ClusterEvent = IFaceClusterEvent | IBodyClusterEvent | ICarClusterEvent;

@Component({
  name: TableComponentNames.EpisodeLink
})
export default class EpisodeLink extends Vue {
  @Prop({ type: Object, required: true })
  item!: IFaceClusterEvent;

  @Prop({ type: String, required: true })
  objectsType!: string;

  get routerLink() {
    return {
      name: 'events',
      params: {
        objects: this.objectsType,
        filter: getFilterString({ episode: this.item.episode, limit: 10 }, {})
      }
    };
  }
}
