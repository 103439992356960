export const TableComponentNames = {
  Actions: 'table-generator-actions',
  ActiveComponent: 'table-generator-active-component',
  ActionTypeLabel: 'table-generator-action-type-label',
  AreaNameAndDescription: 'table-generator-area-name-and-description',
  AreaTriggerDescription: 'table-generator-area-trigger-description',
  AreaTriggerPolygon: 'table-generator-area-trigger-polygon',
  AreaRecordDescription: 'table-generator-area-record-description',
  AuthSessionButtons: 'table-generator-auth-session-buttons',
  CameraScreenshot: 'table-generator-camera-screenshot',
  CameraGroup: 'table-generator-camera-group',
  CameraState: 'table-generator-camera-state',
  CameraStatus: 'table-generator-camera-status',
  CardDescription: 'table-generator-card-description',
  CardObjectThumbnail: 'table-generator-card-object-thumbnail',
  CardClusterThumbnail: 'table-generator-card-cluster-thumbnail',
  CardClusterThumbnailItem: 'table-generator-card-cluster-thumbnail-item',
  CardStatistic: 'table-generator-card-statistic',
  CounterStatus: 'table-generator-counter-status',
  CounterChart: 'table-generator-counter-chart',
  CounterCheckbox: 'table-generator-counter-checkbox',
  CounterRecordImage: 'table-generator-counter-record-image',
  DateTime: 'table-generator-date-time',
  EventDossier: 'table-generator-event-dossier',
  EventFeatures: 'table-generator-event-features',
  EventTimeCamera: 'table-generator-event-time-camera',
  FilledComponent: 'table-generator-filled-component',
  NameWithComment: 'table-generator-name-with-comment',
  PolygonThumbnailObjectPreview: 'table-generator-polygon-thumbnail-object-preview',
  PolygonThumbnailPreview: 'table-generator-polygon-thumbnail-preview',
  ObjectTypeLabel: 'table-generator-object-type-label',
  ObjectItemLink: 'table-generator-object-item-link',
  ObjectThumbnail: 'table-generator-object-thumbnail',
  ClusterEventObjectThumbnail: 'cluster-event-object-thumbnail',
  ObjectThumbnailById: 'table-generator-object-thumbnail-by-id',
  UserProfileLink: 'table-generator-user-profile-link',
  ClusterLink: 'table-generator-cluster-link',
  EpisodeLink: 'table-generator-episode-link',
  FeaturesContent: 'table-generator-features-column'
};
