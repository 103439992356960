<template>
  <div>
    <el-dialog
      :title="$tf($store.state.dialog.image.title)"
      :visible.sync="$store.state.dialog.image.enabled"
      :modal="true"
      top="calc((100vh - 760px) / 2)"
      class="image--dialog el-dialog--adaptive"
    >
      <common-photo :key="$store.state.dialog.image.src"></common-photo>
      <div class="text-center" style="position: absolute; bottom: 0.5rem; width: 100%">
        <a :href="$store.state.dialog.image.src" target="_blank">{{ $store.state.dialog.image.src }}</a>
        <div v-if="$store.state.dialog.image.no_video">{{ $tf('video_player_video_not_found') }}</div>
      </div>
    </el-dialog>

    <!-- fixed blocks -->

    <el-dialog
      :title="$store.state.dialog.video_player.title || this.$tf('unknown_camera')"
      :visible.sync="$store.state.dialog.video_player.enabled"
      :modal="true"
      top="calc((100vh - 760px) / 2)"
      class="el-dialog--small"
    >
      <div v-if="$store.state.dialog.video_player.enabled" v-loading="!$store.state.dialog.video_player.visible">
        <div :style="{ opacity: $store.state.dialog.video_player.visible ? 1 : 0 }">
          <video-player
            :camera="$store.state.dialog.video_player.cameraId"
            :ts="$store.state.dialog.video_player.timeStart"
            @statusChanged="videoPlayerStatusHandler"
          ></video-player>
        </div>
      </div>
    </el-dialog>

    <!-- fixed blocks -->
    <el-dialog
      :title="$tf($store.state.dialog.screenshot.title)"
      :visible.sync="$store.state.dialog.screenshot.enabled"
      :modal="true"
      class="image--dialog el-dialog--wide"
    >
      <div class="text-center">
        <camera-screenshot :refresh="true" :width="1024" displayWidth="1024px" :screenshot="$store.state.dialog.screenshot.screenshot"></camera-screenshot>
      </div>
    </el-dialog>

    <!-- dialog: cryptopro -->
    <el-dialog
      :title="$tf('select,,1 | cryptopro | certificate') + ':'"
      :visible.sync="$store.state.dialog.cryptopro.enabled"
      :modal="true"
      class="image--dialog el-dialog--wide"
    >
      <div class="text-center">
        <div class="rows certificate-container" v-if="$store.state.cryptopro.items.length">
          <div
            v-for="(item, index) in $store.state.cryptopro.items"
            @click="
              () => {
                $store.state.cryptopro.selectedIndex = index;
              }
            "
            class="certificate-item"
            :class="$store.state.cryptopro.selectedIndex === index ? 'certificate-item--selected' : ''"
          >
            <div>{{ $tf('certificate') }}: {{ $filters.formatCertificate(item.name) }}</div>
            <div>{{ $tf('issuer') }}: {{ $filters.formatCertificate(item.issuer) }}</div>
            <div>{{ $tf('time_action') }}: {{ $filters.formatDate(item.validFrom) }} - {{ $filters.formatDate(item.validTo) }}</div>
            <div>{{ $tf('valid,,1') }}: {{ $tf(item.valid ? 'yes' : 'no') }}</div>
          </div>
        </div>
        <div v-else>
          {{ $tf('has | no | certificates,,1') }}
        </div>
      </div>
      <div slot="footer" class="text-center">
        <el-button type="primary" @click="$store.state.dialog.cryptopro.action()" :disabled="!$store.state.cryptopro.items.length">{{
          $tf('continue')
        }}</el-button>
      </div>
    </el-dialog>

    <!-- dialog: external search -->
    <el-dialog :modal="true" :title="$tf('external | search')" :visible.sync="$store.state.dialog.puppeteer_search.enabled">
      <puppeteer-sender
        v-if="$store.state.dialog.puppeteer_search.enabled"
        type="search"
        :externalItem="$store.state.dialog.puppeteer_search.item"
        :cardIDs="[$store.state.dialog.puppeteer_search.card]"
        :caseFaceId="$store.state.dialog.puppeteer_search.case_face"
        @sent="disablePuppeteerSearch"
      />
    </el-dialog>

    <reports-dialog v-if="$store.state.dialog.reports.enabled" />

    <detect-dialog v-if="$store.state.detect.dialog.enabled" />
    <deduplication-dialog v-if="$store.state.detect.deduplication_dialog.enabled" />

    <blocklist-records-dialog v-if="$store.state.dialog.blocklist_record.enabled"></blocklist-records-dialog>

    <!-- fixed blocks -->
    <license-agreement></license-agreement>

    <!-- fixed blocks -->
    <el-dialog :title="$tf('info')" :visible.sync="$store.state.dialog.info.enabled" :modal="true" class="image--dialog el-dialog--wide">
      <template v-if="$store.state.dialog.info.data && $store.state.dialog.info.data.formattedData">
        <div v-for="(value, key) in $store.state.dialog.info.data.formattedData">{{ $filters.firstCase(key) }}: {{ value }}</div>
        <br />
      </template>
      <pre class="text-xs">{{ $store.state.dialog.info.data && $store.state.dialog.info.data.item }}</pre>
    </el-dialog>

    <el-dialog :title="$store.state.dialog.custom.title" :visible.sync="$store.state.dialog.custom.enabled" :modal="true" class="image--dialog el-dialog--wide">
      <component
        v-if="$store.state.dialog.custom.enabled"
        :is="$store.state.dialog.custom.component"
        v-bind="$store.state.dialog.custom.props"
        @action="$store.state.dialog.custom.actionHandler($event)"
        @close="$store.state.dialog.custom.enabled = false"
      ></component>
    </el-dialog>
  </div>
</template>
<script>
import ReportsDialog from '@/components/reports/reports-dialog';
import PuppeteerSender from '@/components/puppeteer/sender/index';
import VideoPlayer from '@/components/video-player/video-player.vue';
import CameraScreenshot from '@/components/cameras/camera-screenshot.vue';
import CommonPhoto from '@/components/common/photo.vue';
import LicenseAgreement from '@/components/license-agreement/index.vue';
import DeduplicationDialog from '@/components/objects/detect/deduplication.dialog';
import DetectDialog from '@/components/objects/detect/detect.dialog';
import SessionMonitor from '@/components/users/session-monitor';

import Component from 'vue-class-component';
import Vue from 'vue';
import BlocklistRecordsDialog from '@/components/sessions/blocklist-records/form/dialog';

export const DialogActionType = {
  Reload: 'reload'
};

@Component({
  name: 'app-dialogs',
  components: {
    BlocklistRecordsDialog,
    ReportsDialog,
    PuppeteerSender,
    CameraScreenshot,
    CommonPhoto,
    LicenseAgreement,
    DeduplicationDialog,
    DetectDialog,
    SessionMonitor,
    VideoPlayer
  }
})
export default class AppDialogs extends Vue {
  disablePuppeteerSearch() {
    this.$store.state.dialog.puppeteer_search.enabled = false;
    this.$store.state.dialog.puppeteer_search.card = null;
    this.$store.state.dialog.puppeteer_search.item = null;
  }
  videoPlayerStatusHandler(status) {
    if (status === 'video_found') {
      const onVideoFound = this.$store.state.dialog.video_player.onVideoFound;
      if (onVideoFound instanceof Function) {
        onVideoFound();
      }
      this.$store.state.dialog.video_player.visible = true;
    }
    if (status === 'video_not_found') {
      const onVideoNotFound = this.$store.state.dialog.video_player.onVideoNotFound;
      if (onVideoNotFound instanceof Function) {
        onVideoNotFound();
      }
    }
  }
}
</script>

<style lang="stylus">
.el-dialog--adaptive .el-dialog {
  width: 80vw;
  min-height: 80vh;
}
</style>
