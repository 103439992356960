import Vue from 'vue';
import User from '@/components/menu/user';
import Extension from '@/components/menu/extension';
import CommonTab from '@/components/common/tabs';
import CommonList from '@/components/common/list';
import MetaField from '@/components/dossiers/metafield';
import ErrorMessage from '@/components/notification/error-message';
import Message from '@/components/notification/message';

Vue.component('user-menu', User);
Vue.component('children-menu', Extension);
Vue.component('common-tabs', CommonTab);
Vue.component('common-list', CommonList);
Vue.component('meta-field', MetaField);
Vue.component('error-message', ErrorMessage);
Vue.component('MessageBox', Message);
