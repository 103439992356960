




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { BodyClusterListModule, CarClusterListModule, FaceClusterListModule } from '../../store/clusters';
import { ObjectsType } from '@/store/objects/get.module';

@Component({
  name: 'cluster-filter',
  components: { FilterForm }
})
export default class ClusterFilter extends Vue {
  get state(): FaceClusterListModule | BodyClusterListModule | CarClusterListModule {
    const objects = this.$route.params.objects ?? ObjectsType.Faces;
    return this.$store.state[`clusters/${objects}`];
  }
}
