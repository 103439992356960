





import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

const CounterCheckboxEventType = 'counter_checkbox_update';
export { CounterCheckboxEventType };

@Component({
  name: TableComponentNames.CounterCheckbox
})
export default class TableGeneratorCounterCheckbox extends Vue {
  @Prop({ required: true, type: Object })
  item!: any;

  @Prop({ default: 'active' })
  prop!: string;

  changeHandler(value) {
    this.$emit('action', { type: CounterCheckboxEventType, item: this.item, prop: this.prop, value });
  }
}
