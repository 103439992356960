import createCommonObject, { IObjectItem } from '../create-common-object';

export default createCommonObject(
  function (camera, timeFrom, timeTo) {
    timeFrom -= 3600 * 24 * 5;
    timeTo += 3600 * 24 * 5;
    const isoTimeFrom = new Date(timeFrom * 1000).toISOString();
    const isoTimeTo = new Date(timeTo * 1000).toISOString();
    return {
      model: 'vms/timeline',
      filter: { camera: camera, time_from: isoTimeFrom, time_to: isoTimeTo }
    };
  },
  function (data) {
    const allChunks: IObjectItem[] = [];
    for (const item of data.timeline) {
      const id = `id_${item.time_from}`;
      const from = new Date(item.time_from).getTime() / 1000;
      const to = new Date(item.time_to).getTime() / 1000;
      const chunk: IObjectItem = {
        id,
        type: 0,
        timeFrom: from,
        timeTo: to,
        metadata: {}
      };
      allChunks.push(chunk);
    }
    return allChunks;
  }
);
