














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import datesAreOnSameDay from './utils/dates-are-on-same-day';

interface ITimelineChunk {
  from: Date;
  to: Date;
}

@Component({
  name: 'video-player-date-picker'
})
export default class VideoPlayerDatePicker extends Vue {
  @Prop({ type: Date })
  datePickerValue!: Date;

  @Prop({ type: Array, default: [] })
  timeline!: ITimelineChunk[];

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  isDateDisabled(date) {
    return !this.timeline.find((item) => datesAreOnSameDay(item.from, date) || datesAreOnSameDay(item.to, date) || (date > item.from && date < item.to));
  }

  play() {
    this.$emit('action', { type: 'pause', data: false });
  }

  pause() {
    this.$emit('action', { type: 'pause', data: true });
  }

  seek(delta) {
    this.$emit('action', { type: 'seekRelative', data: delta });
  }

  handleInputDate(v) {
    v.setHours(12);
    v.setMinutes(0);
    v.setSeconds(0);
    this.$emit('change', v.getTime() / 1000);
  }
}
