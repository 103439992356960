import './registerServiceWorker';
import './registerRouterHooks';

import _ from '@/apps/common/lodash';

import cryptoproPlugin from '@/apps/common/plugins/cryptopro';
import ExtendVueWithForm from '@/apps/common/plugins/extend.vue.form';
import axios from 'axios';

import Vue from 'vue';
import { AppRouter } from './router/router';

import { initLanguages } from './apps/common/i18n';
import { loadLanguages } from './apps/common/i18n/load';

import './components';
import App from './App';
import Loading from './Loading';

import router from './router';
import store from './store';

import { setupElementUi, setupElementUiI18n } from './main/element.components';
import { StylePlugin } from '@/main/styles';
import { ExtendProtoPlugin } from '@/main/extend.vue.proto';
import MicroservicesSupport from '@/apps/common/plugins/microservices.support';

let i18n = { instance: { t: () => '' } };

setupAxiosRequestXuuidHeader(axios);

Vue.config.productionTip = false;

Vue.use(AppRouter);

Vue.use(StylePlugin);
Vue.use(ExtendVueWithForm);
Vue.use(ExtendProtoPlugin);

setupElementUi(Vue);

loadConfig();

function loadConfig() {
  const timeout = window.app ? 5000 : 2500,
    reloadTimeout = 1000;

  axios({ url: '/config.json?r=' + Math.random(), timeout })
    .then((v) => {
      store.state.config = _.merge(_.cloneDeep(store.state.config), v.data);
      if (v.data && v.data.dicts) Object.assign(store.state.dicts.items, v.data.dicts);
      store.state.app.configured = true;
      if (store.state.config.plugins.cproauth === true) {
        Vue.use(cryptoproPlugin);
      }
      return setupLanguages();
    })
    .then(() => {
      renderApp();
      Vue.use(MicroservicesSupport, { store, router });
    })
    .catch((e) => {
      console.warn(`[config] load error ${e}`);
      if (!window.app) renderLoading();
      setTimeout(loadConfig, reloadTimeout);
    });
}

function renderLoading() {
  window.app = new Vue({
    el: '#root',
    i18n: i18n.instance,
    template: '<Loading/>',
    components: { Loading }
  }).$children[0];
}

async function setupLanguages() {
  let locales = null,
    languages = [];

  try {
    languages = await loadLanguages(store.state);
    locales = languages.reduce((m, v) => {
      m[v.name] = v.data;
      return m;
    }, {});
  } catch (e) {
    console.warn(`[languages] load error ${e}`);
  }

  const language = store.state.config.language || 'en-us';
  let locale = language.replace(/-\w+$/, '');
  if (!store.getters.languages.map((item) => item.name).includes(locale)) {
    locale = 'en';
  }

  i18n = initLanguages(locales);
  i18n.instance.locale = window.localStorage.locale || locale;
  Vue.prototype.$filters.tShortDuration = i18n.filters.tShortDuration;
  setupElementUiI18n(i18n);
  return null;
}

function renderApp() {
  window.app = new Vue({
    el: '#root',
    store,
    router,
    i18n: i18n.instance,
    template: '<App/>',
    components: { App }
  }).$children[0];
}

function setupAxiosRequestXuuidHeader(axiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    const xuuid = createXuuidHeader(store.state.app.uuid);
    return { ...config, headers: { ...config.headers, ...xuuid } };
  }, Promise.reject);
}

function createXuuidHeader(uuid) {
  return uuid ? { 'X-UUID': uuid } : {};
}

window.vue = window.Vue = Vue;
