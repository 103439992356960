<template>
  <div class="cluster-container"
       @mouseover="() => hover = true" @mouseout="() => hover = false"
       @click.stop.prevent="clusterClickHandler(item)">
    <div class="rows rows--center">
      <div class="cluster-image-container">
        <div v-if="bestEvent">
          <img
            :key="'cluster-' + i"
            :src="bestEvent.thumbnail"
            class="cluster-image-thumbnail"
            :class="{ 'cluster-image-thumbnail--highlight': animation.faceHighlight }"
            v-for="i in eventsFakeArray"
            :style="getStyle(i)"
          />
        </div>
      </div>
      <div class="cluster-text text-tetriary text-s text-center" :class="{ 'cluster-text--highlight': animation.faceHighlight }">
        {{ $tf('events') }} {{ item.events_count }},
        <span v-if="showContactsLink" @click.stop>
          <router-link :to="getContactsUrl(item)"><i class="fa fa-handshake-o"></i></router-link>
        </span>
        <br v-if="item.card" />
        <span v-if="item.card" @click.stop>
          <router-link :to="getCardUrl(item)">{{ cardName }}</router-link>
        </span>
        <br v-if="item.matched_lists" />
        <watch-lists-round-inline :ids="item.matched_lists" v-if="item.matched_lists" />
        <div class="cluster-container__checkbox-wrapper" :class="checkboxWrapperClasses" @click.stop v-if="selectable">
          <el-checkbox :value="selected" @input="selectedChangeHandler"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Color from 'color';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import WatchListsRoundInline from '../watch-lists/inline.round.items';
import { ObjectsType } from '@/store/objects/get.module';
import { CardType, objectsTypeToCardType } from '@/store/cards/cards';
import { carCardsModule, humanCardsModule } from '@/store';

const Item = {
  Height: 168,
  AcknowledgedHeight: 120
};

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: { WatchListsRoundInline },
  props: {
    item: Object,
    objects: String,
    selected: Boolean,
    anySelected: Boolean,
    selectable: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      hover: false,
      card: null,
      animation: {
        height: Item.AcknowledgedHeight,
        faceHighlight: false
      }
    };
  },
  computed: {
    checkboxWrapperClasses() {
      return {
        'cluster-container__checkbox-wrapper_visible': this.hover || this.anySelected
      };
    },
    cardType() {
      return objectsTypeToCardType(this.objects);
    },
    cardName() {
      return this.card ? this.$filters.shortString(this.card.name) : this.item.card;
    },
    bestEvent() {
      return this.item.best_event;
    },
    eventsFakeArray() {
      const count = Math.min(this.item.events_count || 1, 3);
      return new Array(count).fill(0).map((v, k) => k);
    },
    state() {
      return this.$store.state.clusters;
    },
    showContactsLink() {
      return this.$route.params.objects === 'faces';
    }
  },
  watch: {
    'item.events_count': function () {
      this.animation.faceHighlight = true;
      setTimeout(() => {
        this.animation.faceHighlight = false;
      }, 300);
    }
  },
  created() {},
  mounted() {
    this.getCard();
  },
  methods: {
    getCard() {
      this.card = null;
      if (this.item.card) {
        const module = this.cardType === CardType.Car ? carCardsModule : humanCardsModule;
        module.getById({ id: this.item.card }).then((v) => (this.card = v));
      }
    },
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();

      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getStyle(i) {
      const padding = 10,
        imageSize = 120 - padding * i,
        opacity = Math.max(1 - i * 0.3, 0.1),
        count = Math.min(this.item.events_count, 3);

      return {
        opacity,
        right: i * padding * 0.5 + 'px',
        height: imageSize + 'px',
        width: imageSize + 'px',
        top: i * padding * 1.5 + 'px',
        'z-index': count - i
      };
    },
    getClusterEventsUrl(v) {
      const filter = { cluster_in: v.id, limit: 10 };
      const cleanFilter = _.pickBy(filter, (v) => !!v);
      return `#/cluster-events/${this.objects}/filter/` + qs.stringify(cleanFilter, { sort: alphabeticalSort });
    },
    getCardUrl(v) {
      return `/cards/${this.cardType}s/` + v.card;
    },
    getContactsUrl(v) {
      return v.card ? `/contacts/filter/limit=10&card=${v.card}&ordering=-id` : `/contacts/filter/id_in=${v.id}&limit=10&ordering=-id`;
    },
    selectedChangeHandler(v) {
      this.$emit('select', !this.selected);
    },
    clusterClickHandler(v) {
      if (this.$store.state.app.key.ctrl) {
        if (!this.selectable) {
          window.open(this.getClusterEventsUrl(v), '_blank');
        }
      } else {
        this.$router.push({ path: this.getClusterEventsUrl(v).slice(1) });
      }
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    }
  }
};
</script>
<style lang="stylus">

.cluster-container {
  width: 200px;
  cursor: pointer;
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  transition: background 0.3s, height 0.5s;


  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &__selected {
    background: rgba(255, 255, 255, 0.3);
  }

  &__selected:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  .cluster-text--live {
    display: inline-block;
    background-color: red;
    color: white;
    margin-left: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
  }

  .cluster-text--large {
    display: block;
    color: rgba(255, 255, 255, 0.1);
    font-weight: 900;
    font-size: 30px;
    line-height: 0.9
    letter-spacing: -1px;
    border-radius: 4px;
  }

  .watch-lists-container {
    margin-top: 1rem;
  }

  .card-list-item {
    display: inline-block;
    margin-right: 0.5rem;
    background-color: gray;
    color: white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 4px;
  }

  .cluster-image-container {
    position: relative;
    height: 120px;
    width: 120px;
  }

  .cluster-image-thumbnail {
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    border-radius: 4px;
    transition: border 0.5s;
  }

  .cluster-text {
    margin-top: 1rem;
    transition: color 0.5s;
  }

  .cluster-link {
    color: rgba(255, 255, 255, 0.85);
  }

  .cluster-link:hover {
    color: rgba(255, 255, 255, 0.95);
  }


  .cluster-text--highlight {
    color: rgba(255, 255, 255, 0.9) !important;
  }

  .cluster-image-thumbnail--highlight:first-child {
    border: 1px solid rgba(255, 255, 255, 0.9);
    transition: border 0.2s;
  }

  .cluster-link--large {
    font-size: 1.2rem;
  }
}

.cluster-container {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__checkbox-wrapper {
    padding: 10px;
    opacity: 0;

    .el-checkbox {
      margin-left: -8px;
    }
  }

  &__checkbox-wrapper_visible {
    opacity: 1;
  }
}
</style>
