




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'video-player-thumb'
})
export default class VideoPlayerThumb extends Vue {
  @Prop({ required: true })
  src!: any;

  get styles() {
    return {
      width: '100%',
      height: '100%',
      backgroundImage: 'url(' + this.src + ')',
      backgroundSize: 'cover'
    };
  }
}
