<template>
  <div class="counter-item" v-loading="loading">
    <chart-area
      v-if="items.length"
      :colors="[
        ['#0000ff', '#00ccff'],
        ['#ff0000', '#ffff00'],
        ['#00ff9d', '#0a9d00']
      ]"
      :values="items"
      :grid="grid"
      :dispose-before-update="this.disposeBeforeUpdate"
      :click-handler="this.clickHandler"
      type="time"
      style="width: 100%; height: 100%"
    ></chart-area>
  </div>
</template>

<script>
import ChartArea from './chart.area';
import { groupCounterRecordsByCreatedDate } from './common';

export default {
  name: 'counter-chart-item',
  components: { ChartArea },
  props: {
    item: {
      type: Object,
      required: true
    },
    live: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Object,
      default: () => ({ limit: 20 })
    },
    grid: {
      type: Boolean,
      default: false
    },
    disposeBeforeUpdate: {
      type: Boolean,
      default: false
    },
    clickHandler: {
      type: Function
    }
  },
  data() {
    return {
      liveUpdateInterval: null,
      loading: false,
      items: []
    };
  },
  watch: {
    filter() {
      this.load();
    },
    item() {
      this.load();
    }
  },
  computed: {
    state() {
      return this.$store.state.counter_records;
    }
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    clearTimeout(this.liveUpdateInterval);
  },
  methods: {
    load() {
      const filter = Object.assign({ counter: this.item.id }, this.filter);
      this.loading = true;
      this.$store.dispatch(this.state.Action.Get, { filter }).then((v) => {
        this.items = groupCounterRecordsByCreatedDate(v.results);
        this.loading = false;

        Object.assign(this.item.last, this.items[this.items.length - 1] ?? {});

        clearInterval(this.liveUpdateInterval);
        if (this.live) this.liveUpdateInterval = setTimeout(() => this.load(), 10000);
      });
    }
  }
};
</script>

<style>
.counter-item {
  height: 100%;
  width: 100%;
}
</style>
