<template>
  <div class="page-layout">
    <div class="page-layout__left">
      <div class="page-layout__header cols content-header">
        <div>
          <header-back-button v-if="showBackButton" />
          <slot name="header-before-name" />
        </div>

        <div class="page-layout__header-texts">
          <h2 class="page-layout__header-name">
            <slot name="header-name" />
          </h2>
          <h3 class="page-layout__header-detail">
            <slot name="header-detail" />
          </h3>
        </div>

        <div class="page-layout__header-actions">
          <slot name="header-actions" />
        </div>

        <div class="page-layout__header-actions_after">
          <slot name="header-actions-after" />
        </div>

        <div class="page-layout__header-actions_end">
          <slot name="header-actions-end" />
        </div>
      </div>
      <div class="page-layout__content-header">
        <div class="page-layout__content-header__before-actions">
          <slot name="header-content-before-actions" />
        </div>

        <div class="page-layout__content-header__actions">
          <slot name="header-content-actions" />
        </div>

        <div class="page-layout__content-header__navigation">
          <slot name="header-content-navigation" />
        </div>
      </div>
      <div class="page-layout__content">
        <slot name="content" />
      </div>
      <div class="page-layout__content-footer">
        <div class="page-layout__content-footer__actions">
          <slot name="footer-content-actions" />
        </div>

        <div class="page-layout__content-footer__navigation">
          <slot name="footer-content-navigation" />
        </div>
      </div>

      <div>&nbsp;</div>
    </div>
    <div class="page-layout__right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import HeaderBackButton from '@/components/common/header.back.button';

export default {
  name: 'PageLayout',

  components: {
    HeaderBackButton
  },

  props: {
    showBackButton: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="stylus">
.page-layout {
  // display: flex; //@todo right block resize issue

  div {
    // border: 1px solid rgba(255, 0, 0, 0.2); // @debug
  }

  &__right:not(:empty) {
    margin-top: 6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 16rem;
  }

  &__left {
    flex: 1 1;
  }

  &__right {
    flex: 0 0;
  }


  &__header {
    height: 6.25em;
    display: flex;
    align-items: center;

    &-texts {
      margin-bottom: 1rem;
    }

    h2 {
      margin-top: 0;
      line-height: 1;
    }

    h3:not(:empty) {
      margin-top: 0.5rem !important;
      line-height: 1;
      font-size: 1.1rem;
    }
  }

  &__header-detail {
    opacity: 0.5;
  }

  &__header-actions {
    margin-left: 2rem;
  }

  &__header-actions_after {
    margin-left: 2rem;
  }

  &__header-actions_end {
    margin-left: auto;
  }

  &__content-header {
    margin-bottom: 1em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    &__before-actions {
      flex: 1 0 auto;
    }

    &__actions {
      flex: 0 0 auto;
    }

    &__navigation {
      padding-left: 1em;
    }
  }

  &__content-footer {
    margin-top: 1rem;
    margin-bottom: 6.25rem;
    display: flex;

    &__navigation {
      margin: 0 0 2rem auto;
      padding-left: 1rem;
      flex: 0 0 auto;
    }
  }
}
</style>
