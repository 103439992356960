















import BaseForm from '@/components/common/base-form/index.vue';
import PageLayout from '@/components/page/layout.vue';
import { blocklistRecordsModule } from '@/store/index';
import { createBlocklistRecordModule, IBlockListRecord } from '@/store/sessions/blocklist-records';
import Vue from 'vue';
import Component from 'vue-class-component';
import formSchema from './schema';

@Component({
  name: 'blocklist-record-form',
  components: { PageLayout, BaseForm }
})
export default class BlocklistRecordForm extends Vue {
  private readonly module = createBlocklistRecordModule(this.$store);

  get multiple(): boolean {
    return Array.isArray(this.state?.item?.uuid);
  }

  get state() {
    return this.module.state;
  }

  get isEdit() {
    return !!this.state?.item?.uuid;
  }

  get schemaFields() {
    const fields = formSchema.fields.map((item) => {
      if (item.prop === 'uuid') {
        Object.assign(item.component, { disabled: this.isEdit });
      }
      return item;
    });
    return this.multiple ? fields.slice(1, fields.length) : fields;
  }

  created() {
    this.load();
  }

  load() {
    const { item } = this.$store.state.dialog.blocklist_record;
    if (item) {
      this.state.setItemsState(item as any as IBlockListRecord);
    } else {
      this.state.setEmptyItemsState();
    }
  }

  beforeDestroy() {
    this.module.unreg();
  }

  async saveHandler() {
    let isFormValid = false;
    try {
      isFormValid = await (this.$refs.baseForm as BaseForm)?.validate();
    } catch (e) {
      if (e !== false) throw e;
    }

    const { item } = this.state;
    if (isFormValid) {
      let result;
      try {
        if (item?.id) {
          result = await this.state.update({ ...item });
        } else {
          if (this.multiple) {
            result = [];
            const uuids = this.state.item?.uuid as any as string[];
            for (let uuid of uuids) {
              result.push(await blocklistRecordsModule.create({ ...this.state.item, uuid }));
            }
          } else {
            result = await this.state.create();
          }
          this.$router.push(blocklistRecordsModule.getListRoute());
        }
        this.$notify({ type: 'success', message: this.$tf('action | success') });
        this.$emit('saved', result);
      } catch (e) {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      } finally {
      }
    } else {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: this.$tf('error.form.validation') } }) });
    }
  }
}
