const Episodes = {
  Get: 'get_episodes',
  Update: 'update_episodes'
};

const FacesEvents = {
  DisconnectWebSocket: 'disconnectWebSocket',
  ConnectToWebSocket: 'connectToWebSocket',
  Satisfy: 'satisfy',
  Get: 'get_events_faces',
  Update: 'update_events_faces',
  Acknowledge: 'acknowledge_events_faces'
};

const BodiesEvents = {
  Get: 'get_events_bodies',
  Update: 'update_events_bodies',
  Acknowledge: 'acknowledge_events_bodies'
};

const CarsEvents = {
  Get: 'get_events_cars',
  Update: 'update_events_cars',
  Acknowledge: 'acknowledge_events_cars'
};

const Puppeteer = {
  DisconnectWebSocket: 'disconnectPuppeteerWebSocket',
  ConnectToWebSocket: 'connectToPuppeteerWebSocket',
  SetAllAcknowledged: 'setPuppeteerAllAcknowledged'
};

const Dossiers = {
  Get: 'get_dossiers',
  GetAll: 'getAllDossiers',
  Create: 'create_dossiers',
  Update: 'update_dossiers',
  Delete: 'delete_dossiers'
};

const Persons = {
  Get: 'get_persons',
  Create: 'create_persons',
  Update: 'update_persons',
  Delete: 'delete_persons'
};

const PersonEvents = {
  Get: 'get_person-events',
  Create: 'create_person-events',
  Update: 'update_person-events',
  Delete: 'delete_person-events'
};

const Cameras = {
  GetAll: 'getAllCameras',
  Get: 'get_cameras',
  GetDefaultParameters: 'getDefaultParameters',
  Create: 'create_cameras',
  Update: 'update_cameras',
  Delete: 'delete_cameras'
};

const CameraGroups = {
  Get: 'get_camera-groups',
  Create: 'create_camera-groups',
  Update: 'update_camera-groups',
  Delete: 'delete_camera-groups'
};

const Groups = {
  Get: 'get_groups',
  Create: 'create_groups',
  Update: 'update_groups',
  Delete: 'delete_groups'
};

const Watchlists = {
  Get: 'get_watch-lists',
  Create: 'create_watch-lists',
  Update: 'update_watch-lists',
  Delete: 'delete_watch-lists',
  DeleteAllCardsByWatchlist: 'deleteAllCardsByWatchlist',
  DeleteAllCards: 'deleteAllCards'
};

const FacesObjects = {
  Get: 'get_objects_faces',
  Create: 'create_objects_faces',
  Update: 'update_objects_faces',
  Delete: 'delete_objects_faces'
};

const CarsObjects = {
  Get: 'get_objects_cars',
  Create: 'create_objects_cars',
  Update: 'update_objects_cars',
  Delete: 'delete_objects_cars'
};

const BodiesObjects = {
  Get: 'get_objects_bodies',
  Create: 'create_objects_bodies',
  Update: 'update_objects_bodies',
  Delete: 'delete_objects_bodies'
};

const DossierAttachments = {
  Get: 'get_dossier-attachments',
  Create: 'create_dossier-attachments',
  Update: 'update_dossier-attachments',
  Delete: 'delete_dossier-attachments'
};

const Users = {
  GetGroups: 'getGroups',
  Get: 'get_users',
  Create: 'create_users',
  Update: 'update_users',
  Delete: 'delete_users',
  TryLogin: 'try-login',
  Login: 'login',
  Logout: 'logout',
  RenewToken: 'renew_token',
  LoadLoggedInUser: 'load_logged_in_user',
  LoginByVideo: 'login_by_video'
};

const UserFace = {
  Get: 'get_user-face',
  Create: 'create_user-face',
  Update: 'update_user-face',
  Delete: 'delete_user-face'
};

const BatchUpload = {
  Get: 'get_batch-upload',
  Create: 'create_batch-upload',
  Update: 'update_batch-upload',
  Delete: 'delete_batch-upload'
};

const BatchUploadEntry = {
  Get: 'get_batch-upload-entry'
};

const Genetec = {
  GetConfig: 'get_genetec_config',
  CreateConfig: 'create_genetec_config',
  CheckConfig: 'check_genetec_config',
  GetCameras: 'get_genetec_cameras',
  ImportCameras: 'import_genetec_cameras'
};

const Hooks = {
  Get: 'get_hooks',
  Create: 'create_hooks',
  Update: 'update_hooks',
  Put: 'put_hooks',
  Delete: 'delete_hooks'
};

const Videos = {
  Get: 'get_videos',
  Create: 'create_videos',
  Update: 'update_videos',
  Put: 'put_videos',
  Delete: 'delete_videos',
  DeleteFile: 'deleteVideoFile',
  GetActive: 'getActiveVideo',
  Upload: 'uploadVideo',
  Process: 'processVideo',
  Stop: 'stopVideo'
};

const Permissions = {
  Get: 'get_permissions',
  FetchPluginPermissions: 'fetch_plugin_permissions'
};

const Dicts = {
  Get: 'get_dicts'
};

const Counters = {
  Get: 'get_counters',
  Create: 'create_counters',
  Update: 'update_counters',
  Put: 'put_counters',
  Delete: 'delete_counters',
  StartCalibration: 'startCalibration',
  StopCalibration: 'stopCalibration'
};

const CountersProximityCalibration = {
  Create: 'create_counters_proximity_calibration',
  Delete: 'delete_counters_proximity_calibration'
};

const CounterRecords = {
  Get: 'get_counter-records',
  Create: 'create_counter-records',
  Update: 'update_counter-records',
  Put: 'put_counter-records',
  Delete: 'delete_counter-records'
};

const Kyc = {
  Get: 'get_kyc'
};

const PuppeteerDailyEvents = {
  Get: 'get_puppeteer_daily_events'
};

const PuppeteerRemoteMonitoring = {
  Get: 'get_puppeteer_remote-monitoring',
  Create: 'create_puppeteer_remote-monitoring',
  Update: 'update_puppeteer_remote-monitoring',
  Put: 'put_puppeteer_remote-monitoring',
  Delete: 'delete_puppeteer_remote-monitoring'
};

const PuppeteerRemoteMonitoringEvents = {
  Get: 'get_puppeteer_remote-monitoring_events',
  Acknowledge: 'puppeeteerEventAcknowledge',
  AcknowledgeAll: 'puppeeteerEventsAcknowledgeAll'
};

const PuppeteerSearch = {
  Get: 'get_puppeteer_search',
  Create: 'create_puppeteer_search',
  Update: 'update_puppeteer_search',
  Put: 'put_puppeteer_search',
  Delete: 'delete_puppeteer_search'
};

const Cases = {
  Get: 'get_cases',
  Create: 'create_cases',
  Update: 'update_cases',
  Put: 'put_cases',
  Delete: 'delete_cases'
};

const CaseFaces = {
  Get: 'get_case-faces',
  Create: 'create_case-faces',
  Update: 'update_case-faces',
  Put: 'put_case-faces',
  Delete: 'delete_case-faces'
};

const PuppeteerSearchEvents = {
  Get: 'get_puppeteer_search_events'
};

const Reports = {
  Get: 'get_reports',
  Restart: 'restartReports',
  Create: 'create_reports',
  Delete: 'delete_reports'
};

const AuditLogs = {
  Get: 'get_audit-logs'
};

export default {
  BatchUpload,
  BatchUploadEntry,
  Users,
  UserFace,
  Episodes,
  FacesEvents,
  CarsEvents,
  BodiesEvents,
  Dicts,
  Dossiers,
  Watchlists,
  FacesObjects,
  DossierAttachments,
  Cameras,
  CameraGroups,
  Groups,
  Hooks,
  Genetec,
  Permissions,
  Persons,
  PersonEvents,
  Counters,
  CounterRecords,
  Videos,
  Kyc,
  Puppeteer,
  PuppeteerDailyEvents,
  PuppeteerRemoteMonitoring,
  PuppeteerRemoteMonitoringEvents,
  PuppeteerSearch,
  PuppeteerSearchEvents,
  Cases,
  CaseFaces,
  Reports,
  AuditLogs,
  CarsObjects,
  BodiesObjects,
  CountersProximityCalibration
};
