<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <confidence-select v-model="model" :objects="formattedObjects" />
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import ConfidenceSelect from '../../common/confidence.select';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({
  props: {
    objects: { type: [Function], default: (v) => v }
  },
  components: { ConfidenceSelect }
})
export default class FilterFormComponentConfidence extends FilterFormClearableComponent {
  get formattedObjects() {
    return this.objects();
  }
}
</script>
