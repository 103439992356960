import _ from '@/apps/common/lodash';
import { ObjectsType } from '../../objects/get.module';
import { computeFilterFieldsSchema, FieldName } from './fields';

const commonFeatures = [
  [FieldName.MatchedLists],
  [FieldName.NoMatch],
  [FieldName.Acknowledged],
  [FieldName.CameraGroups],
  [FieldName.Cameras],
  [FieldName.CreatedDateGte],
  [FieldName.CreatedDateLte],
  [FieldName.VideoArchive],
  [FieldName.Episode],
  [FieldName.Id],
  [FieldName.Page]
];

export const commonFields = [
  FieldName.MatchedLists,
  FieldName.NoMatch,
  FieldName.Acknowledged,
  FieldName.CameraGroups,
  FieldName.Cameras,
  FieldName.CreatedDateGte,
  FieldName.CreatedDateLte,
  FieldName.VideoArchive
];

const features = Object.freeze({
  [ObjectsType.Cars]: 'config.objects.cars.features',
  [ObjectsType.Faces]: 'config.objects.faces.features',
  [ObjectsType.Bodies]: 'config.objects.bodies.features'
});

const weights = Object.freeze({
  [FieldName.BodiesHeadwear]: 1,
  [FieldName.BodiesUpperClothes]: 2,
  [FieldName.BodiesDetailedUpperClothes]: 3,
  [FieldName.BodiesTopColor]: 4,
  [FieldName.BodiesLowerClothes]: 5,
  [FieldName.BodiesBottomColor]: 6
});

export function createFilterSchemaGetter(objectsType) {
  return function (_state, _getters, rootState) {
    const module = computeStoreModuleName(objectsType);
    const action = computeStoreActionName(objectsType);
    const features = getConfiguredObjectsFeatures(objectsType, rootState);
    const fields = computeObjectsFeaturesFilterFields(objectsType, features);
    return { module, action, fields };
  };
}

function computeStoreActionName(objectsType) {
  return `get_events_${objectsType}`;
}

function computeStoreModuleName(objectsType) {
  return `${objectsType}_events`;
}

function getConfiguredObjectsFeatures(objectsType, rootState) {
  return _.get(rootState, features[objectsType], {});
}

function computeObjectsFeaturesFilterFields(objectsType, features) {
  const commonFeatures = getObjectsFeaturesFilterCommonFields(objectsType);
  const allFeatures = commonFeatures.concat(Object.entries(features).sort(compareObjectsFeaturesAsc));
  return computeFilterFieldsSchema(allFeatures).sort(compareFilterFieldsByWeight);
}

export function computeClustersFields(objectsType, commonFeatures, state) {
  let features = getConfiguredObjectsFeatures(objectsType, state);
  if (objectsType === ObjectsType.Cars) {
    const excludedCarClusterFields = [FieldName.CarsLicensePlateCountry, FieldName.CarsLicensePlateNumber, FieldName.CarsLicensePlateRegion];
    features = Object.keys(features)
      .filter((featureItem) => !excludedCarClusterFields.includes(featureItem))
      .reduce((acc, featureItem) => ({ ...acc, [featureItem]: features[featureItem] }), {});
  }
  const allFeatures = commonFeatures.concat(Object.entries(features).sort(compareObjectsFeaturesAsc));
  return computeFilterFieldsSchema(allFeatures).sort(compareFilterFieldsByWeight);
}

export function computeClusterEventFields(objectsType, commonFeatures, state) {
  let features = getConfiguredObjectsFeatures(objectsType, state);
  if (objectsType === ObjectsType.Cars) {
    features = Object.keys(features).reduce((acc, featureItem) => ({ ...acc, [featureItem]: features[featureItem] }), {});
  }
  const allFeatures = commonFeatures.concat(Object.entries(features).sort(compareObjectsFeaturesAsc));
  return computeFilterFieldsSchema(allFeatures).sort(compareFilterFieldsByWeight);
}

function getObjectsFeaturesFilterCommonFields(objectsType) {
  switch (objectsType) {
    case ObjectsType.Faces:
      return [[FieldName.MatchedHumanCard], ...commonFeatures, [FieldName.BsType]];
    case ObjectsType.Bodies:
      return [[FieldName.MatchedHumanCard], ...commonFeatures];
    case ObjectsType.Cars:
      return [[FieldName.MatchedCarCard], ...commonFeatures];
  }
}

function compareObjectsFeaturesAsc([fieldNameA], [fieldNameB]) {
  return fieldNameA.localeCompare(fieldNameB);
}

function compareFilterFieldsByWeight(fieldA, fieldB) {
  return computeFilterFieldWeight(fieldA.name) - computeFilterFieldWeight(fieldB.name);
}

function computeFilterFieldWeight(fieldName) {
  return weights[fieldName] ?? 0;
}
