<template>
  <div class="content">
    <h2>Languages</h2>
    <div>
      <h3>Export PO file</h3>
      <el-button :key="lang" type="primary" @click="() => savePOText(lang)" v-for="lang in langs">{{ lang.toUpperCase() }}</el-button>
      <br />
      <el-button type="primary" @click="() => savePotText()">POT</el-button>
    </div>

    <h3>Select language to compare</h3>
    <el-form>
      <el-form-item :label="$tf('language')">
        <el-select v-model="language" size="mini">
          <el-option :key="item.name" :value="item.name" :label="item.label" v-for="item in $store.getters.languages"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template v-for="(item, key) in items">
      <h2>{{ items[key].title }}</h2>

      <el-button type="primary" @click="saveCsv(items[key])">CSV</el-button>
      <el-table stripe :data="items[key].data">
        <el-table-column prop="keys" align="left" label="Ключ" min-width="180"> </el-table-column>

        <el-table-column prop="english_translate" align="left" label="Английский перевод" min-width="180"> </el-table-column>

        <el-table-column prop="russian_translate" align="left" label="Русский перевод" min-width="180"> </el-table-column>

        <el-table-column prop="selected_translate" align="left" label="Выбранный перевод" min-width="180"> </el-table-column>
      </el-table>

      <el-dialog title="Statistic" :visible.sync="displayStats">
        <div v-if="stats">
          <h3>String keys / object keys: {{ stats.stringKeys + ' / ' + stats.objectKeys }}</h3>

          <h3>Translated: {{ stats.translated }}</h3>

          <h3>Empty: {{ stats.emptyItems.length }}</h3>
          <div>{{ stats.emptyItems }}</div>

          <h3>Equal: {{ stats.equalItems.length }}</h3>
          <div>{{ stats.equalItems }}</div>

          <h3>Dublicates {{ statsDublicateItems.length }}</h3>
          <div>{{ statsDublicateItems }}</div>
        </div>
        <div v-else>Statistic error.</div>
      </el-dialog>
    </template>

    <template>
      <h2>Message Format Example</h2>
      <div>
        From "{{ $tt('mf_example') }}" to <br /><br />
        0 => {{ $tmf('mf_example', { count: 0 }) }} <br />
        1 => {{ $tmf('mf_example', { count: 1 }) }} <br />
        2 => {{ $tmf('mf_example', { count: 2 }) }} <br />
        5 => {{ $tmf('mf_example', { count: 5 }) }} <br />
      </div>
    </template>
  </div>
</template>

<script>
import { convertJSObjectToPOText } from '../../apps/common/po';

function Translate(en = ' - ', ru = ' - ', selected = ' - ') {
  this.english_translate = en;
  this.russian_translate = ru;
  this.selected_translate = selected;
}

function getLangTokens(def = {}, current = {}, stats) {
  const keys = [...new Set([...Object.keys(def), ...Object.keys(current)])];
  const r = keys.reduce(reduceHandler, {});

  function reduceHandler(m, v) {
    const value = def[v] || current[v],
      currentValue = current[v] || '',
      valueIsObject = value && typeof value === 'object';

    if (valueIsObject) {
      stats.objectKeys++;
    } else {
      const currentValueLC = currentValue.toLowerCase().trim();
      stats.stringKeys++;
      if (currentValue) stats.dublicateKeys[currentValueLC] = stats.dublicateKeys[currentValueLC] > -1 ? stats.dublicateKeys[currentValueLC] + 1 : 0;
      if (!currentValue) {
        stats.emptyItems.push(`${v}`);
      } else if (def[v] === currentValue) {
        stats.equalItems.push(currentValue);
      } else stats.translated++;
    }

    m[v] = valueIsObject ? getLangTokens(def[v], current[v], stats) : (current && current[v]) || '';
    return m;
  }
  return r;
}

export default {
  name: 'translates',

  data() {
    return {
      language: 'ru',
      displayStats: false,
      stats: null
    };
  },

  computed: {
    statsDublicateItems() {
      const dublicateKeys = this.stats && this.stats.dublicateKeys,
        result = [];
      for (let key in dublicateKeys) {
        if (dublicateKeys[key] > 0) result.push(key);
      }
      return result;
    },
    items() {
      const messages = this.$i18n.messages,
        { en, ru } = messages,
        current = messages[this.language];
      return {
        commonItems: this.getItems('Common file', en.common, ru.common, current.common),
        dateItems: this.getItems('Date file', en.date, ru.date, current.date),
        errorItems: this.getItems('Error file', en.error, ru.error, current.error),
        parameterItems: this.getItems('Parameter file', en.parameter, ru.parameter, current.parameter)
      };
    },
    langs() {
      return Object.keys(this.$i18n.messages);
    }
  },

  methods: {
    getItems(title, en, ru, selected) {
      const langKeys = [...Object.keys(ru), ...Object.keys(en), ...Object.keys(selected)];
      const arrLength = Array.from(new Set(langKeys));
      const consoleTable = [];
      for (let i = 0; i < arrLength.length; i++) {
        if (!en[arrLength[i]] || !ru[arrLength[i]] || !selected[arrLength[i]]) {
          consoleTable.push({
            keys: arrLength[i],
            ...new Translate(en[arrLength[i]], ru[arrLength[i]], selected[arrLength[i]])
          });
        }
      }
      return {
        title,
        data: consoleTable
      };
    },
    saveCsv(item) {
      let csvContent = 'data:text/csv;charset=utf-8,keys,english translate,russian translate,spanish translate\n';
      item.data.forEach((row) => {
        const { keys, english_translate, russian_translate, spanish_translate } = row;
        csvContent += `${keys},${english_translate},${russian_translate},${spanish_translate}\n`;
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${item.title}_translate.csv`);
      console.log(link);
      document.body.appendChild(link);
      link.click();
    },
    getCleanMessages(v) {
      const r = { ...v };
      delete r.el;
      return r;
    },
    getCleanLangObject(lang) {
      const defaultObject = this.getCleanMessages(this.$i18n.messages.en),
        fromObject = this.getCleanMessages(this.$i18n.messages[lang]),
        stats = { stringKeys: 0, objectKeys: 0, emptyItems: [], equalItems: [], dublicateKeys: {}, translated: 0 },
        result = getLangTokens(defaultObject, fromObject, stats);
      this.stats = stats;
      return result;
    },
    savePOText(lang) {
      const text = convertJSObjectToPOText(this.getCleanLangObject(lang), this.getCleanMessages(this.$i18n.messages.en), { Language: lang }),
        content = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
      const link = document.createElement('a');
      link.setAttribute('href', content);
      link.setAttribute('download', `${lang}_i18n_ffsec.po`);
      document.body.appendChild(link);
      link.click();
      this.displayStats = true;
    },
    savePotText() {
      const lang = 'en',
        text = convertJSObjectToPOText(this.getCleanLangObject({}), this.getCleanMessages(this.$i18n.messages.en), {
          Language: lang
        }),
        content = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);

      const link = document.createElement('a');
      link.setAttribute('href', content);
      link.setAttribute('download', 'i18n_ffsec.pot');
      document.body.appendChild(link);
      link.click();
      this.displayStats = true;
    }
  }
};
</script>
