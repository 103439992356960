



























import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';
import { CardType } from '@/store/cards/cards';
import ObjectsInline from '@/components/objects/inline.vue';
import store from '@/store';

@Component({
  name: TableComponentNames.CardObjectThumbnail,
  components: { ObjectsInline }
})
export default class TableGeneratorCardObjectThumbnail extends Vue {
  @Prop({ required: true })
  readonly item!: any;

  @Prop({ type: String, required: true })
  readonly cardType!: CardType;

  getObjectEnabled(object) {
    const objectsConfigSection = store.state.config.objects || {};
    return objectsConfigSection?.[object].enabled;
  }

  get bodyEnabled() {
    return Boolean(this.item.body_objects) && this.getObjectEnabled('bodies');
  }

  get isHumanCard(): boolean {
    return this.cardType === CardType.Human;
  }
}
