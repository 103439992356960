import store from '../index';
import _ from '@/apps/common/lodash';
import { Action, Module, RegisterOptions } from 'vuex-class-modules';
import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { CarsFilterSchema, HumansFilterSchema } from '@/store/episodes/filter.schema';
import { EmptyCarItem, EmptyCarsFilter, EmptyHumanItem, EmptyHumansFilter } from '@/store/episodes/items';
import filters from '@/apps/common/filters.js';

export type IHumanEpisode = components['schemas']['HumanEpisode'];
export type IHumanEpisodeFilter = operations['episodes_human_list']['parameters']['query'];
export type ICarEpisode = components['schemas']['CarEpisode'];
export type ICarEpisodeFilter = operations['episodes_car_list']['parameters']['query'];

export enum EpisodeType {
  Human = 'human',
  Car = 'car'
}

export const EpisodeTypeList: EpisodeType[] = [EpisodeType.Human, EpisodeType.Car];

@Module({ generateMutationSetters: true })
export class HumanEpisodesModule extends BaseItemsStateModule<IHumanEpisode, IHumanEpisodeFilter> {
  name = 'episodes/humans';
  routeName = 'humansEpisodes';
  aclModelName = 'humanepisode';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyHumanItem, EmptyHumansFilter, HumansFilterSchema);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }

  @Action
  satisfy(payload) {
    return satisfyFilter({ state: this, rootState: store.state }, payload);
  }
}

@Module({ generateMutationSetters: true })
export class CarEpisodesModule extends BaseItemsStateModule<ICarEpisode, ICarEpisodeFilter> {
  name = 'episodes/cars';
  routeName = 'carsEpisodes';
  aclModelName = 'carepisode';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyCarItem, EmptyCarsFilter, CarsFilterSchema);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }

  @Action
  satisfy(payload): boolean {
    return satisfyFilter({ state: this, rootState: store.state }, payload);
  }
}

function satisfyFilter({ state, rootState }, { episode }): boolean {
  let filter = state.filter.current,
    cameras = rootState.cameras.items,
    satisfy = true,
    event = episode.last_body_event || episode.last_face_event;

  if (satisfy && filter.id_in) {
    satisfy = filter.id_in === episode.id;
  }

  if (satisfy && filter.matched_card) {
    satisfy = filter.matched_card === (event.matched_card || '').toString();
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = _.intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.no_face_match) {
    satisfy = filter.no_face_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.no_body_match) {
    satisfy = filter.no_body_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.no_car_match) {
    satisfy = filter.no_car_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.camera_groups.length) {
    let groups = cameras.filter((v) => episode.cameras.find(v) > -1).map((v) => v.id),
      group = filter.camera_groups.find((v) => groups.find((i) => v === i));
    satisfy = !!group;
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v) => !!episode.cameras.find((i) => v === i));
  }

  if (satisfy && filter.created_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.gender?.length) {
    let r = _.get(event, 'features.gender.name'),
      item = filter.gender.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.temperature_gte) {
    satisfy = event.temperature >= filter.temperature_gte;
  }

  if (satisfy && filter.temperature_lte) {
    satisfy = event.temperature <= filter.temperature_lte;
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }

  if (satisfy && filter.emotions?.length) {
    let r = _.get(event, 'features.emotions.name'),
      item = filter.emotions.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.glasses?.length) {
    let r = _.get(event, 'features.glasses.name'),
      item = filter.glasses.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.beard?.length) {
    let r = _.get(event, 'features.beard.name'),
      item = filter.beard.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.liveness?.length) {
    let r = _.get(event, 'features.liveness.name'),
      item = filter.liveness.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.race?.length) {
    let r = _.get(event, 'features.race.name'),
      item = filter.race.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.medmask?.length) {
    let r = _.get(event, 'features.medmask.name'),
      item = filter.medmask.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.events_count_gte) {
    satisfy = episode.events_count >= filter.events_count_gte;
  }

  if (satisfy && filter.video_archive?.length) {
    satisfy = !!filter.video_archive.find((v) => v === episode.video_archive);
  }

  return satisfy;
}
