





import { Component, Prop, Vue } from 'vue-property-decorator';
import { CardType } from '@/store/cards/cards';

@Component({
  name: 'card-metafield-list-item'
})
export default class CardMetaFieldListItem extends Vue {
  @Prop({ required: true })
  item!: any;

  @Prop({ required: true })
  cardType!: CardType;

  get metaFields() {
    let metaData = this.cardType === CardType.Human ? this.$store.state.config.human_card : this.$store.state.config.car_card;
    return (metaData?.items || []).filter((v) => v.display.indexOf('list') > -1);
  }

  get computedMetaFields() {
    const result = this.metaFields
      .map((v) => {
        const label = v.label || v.name;
        const value = this.getMetaValue(v);
        return { label, value };
      })
      .filter((v) => !!v.value);
    return result;
  }

  getMetaValue(metaField) {
    let r = '',
      metaValue = this.item.meta && this.item.meta[metaField.name];

    switch (metaField.type) {
      case 'list':
        if (this.hasValue(metaValue)) {
          r = metaField.multiple
            ? metaValue.map((v) => this.getDictItemLabel(v, metaField.items, metaField.custom_id)).join(', ')
            : this.getDictItemLabel(metaValue, metaField.items, metaField.custom_id);
        } else {
          r = this.$tf('not_set');
        }
        break;
      case 'datetime':
        r = metaValue ? this.$filters.formatDateTime(new Date(metaValue)) : this.$tf('not_set');
        break;
      case 'date':
        r = metaValue ? this.$filters.formatDate(new Date(metaValue)) : this.$tf('not_set');
        break;
      case 'boolean':
        r = metaValue === true ? this.$tf('enabled') : metaValue === false ? this.$tf('disabled') : this.$tf('not_set');
        break;
      default:
        r = Array.isArray(metaValue) ? metaValue.join(', ') : metaValue; // type = string, valuelist
        break;
    }
    return r;
  }

  getDictItemLabel(id, dictName, field = 'id') {
    const dict = this.$store.state.dicts.items[dictName],
      item = dict && dict.find((v) => v[field] === id);
    return item ? item.label || item.name : dict ? this.$tf('item | not_found') : this.$tf('dictionary | not_found');
  }

  hasValue(v) {
    return v !== null && v !== undefined;
  }
}
