// import qs from 'qs'
import _ from '@/apps/common/lodash';
import axios from 'axios';
import Vue from 'vue';
import getCrud from '../get.crud';
import schema from './filter.json';

const defaultObjectDetectorSettings = {
  filter_min_quality: 0.45,
  filter_min_size: 1,
  filter_max_size: 8192,
  roi: '',
  fullframe_crop_rot: false,
  fullframe_use_png: false,
  jpeg_quality: 95,
  overall_only: false,
  realtime_post_first_immediately: false,
  realtime_post_interval: 1,
  realtime_post_every_interval: false,
  track_interpolate_bboxes: true,
  track_miss_interval: 1,
  track_overlap_threshold: 0.25,
  track_max_duration_frames: 0,
  track_send_history: false,
  post_best_track_frame: true,
  post_best_track_normalize: true,
  post_first_track_frame: false,
  post_last_track_frame: false
};

const emptyFilter = {
    ordering: 'id',
    name: '',
    created_date_lte: null,
    created_date_gte: null,
    limit: 1e4,
    page: ''
  },
  emptyItem = {
    id: null,
    name: '',
    camera_group: -1,
    camera: null,
    url: '',
    source_file: '',
    active: false,
    progress: 0,
    save_to: '',
    health_status: {
      status: 'UNKNOWN'
    },
    stream_settings: {
      play_speed: -1,
      imotion_threshold: 0,
      router_timeout_ms: 15000,
      router_verify_ssl: true,
      ffmpeg_params: [],
      ffmpeg_format: '',
      start_stream_timestamp: 0,
      rot: '',
      stream_data_filter: '',
      video_transform: '',
      use_stream_timestamp: false,
      disable_drops: false,
      detectors: {
        face: _.cloneDeep(defaultObjectDetectorSettings),
        car: _.cloneDeep(defaultObjectDetectorSettings),
        body: _.cloneDeep(defaultObjectDetectorSettings)
      }
    }
  },
  UpdatableFields = ['name', 'camera_group', 'camera', 'stream_settings', 'id', 'url', 'save_to'],
  MultiUpdatableFields = ['camera_group', 'camera', 'stream_settings', 'id'],
  MultiCopyStreamSettingsFields = ['rot', 'start_stream_timestamp', 'use_stream_timestamp'],
  MultiCopyDetectorsFields = ['roi'],
  Name = 'videos',
  Action = require('../action.names').default.Videos;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      fields: UpdatableFields,
      multi_fields: MultiUpdatableFields,
      multi_copy_stream_settings_fields: MultiCopyStreamSettingsFields,
      multi_copy_detectors_fields: MultiCopyDetectorsFields,
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter),
      schema
    },
    processing: {
      progress: {},
      health_status: {},
      state: {}
    },
    uploading: {},
    Action
  },
  actions: Object.assign(
    {
      processVideo,
      stopVideo,
      getActiveVideo,
      setProcessingProgress,
      setProcessingVideoStatus,
      deleteVideoFile,
      uploadVideo
    },
    getCrud(Name)
  )
};

function processVideo({ state, dispatch }, payload) {
  state.items.forEach((item) => {
    if (item.id === payload.id) Vue.set(item, 'progress', 0);
  });
  return dispatch('requestApi', { model: Name, subaction: 'process', id: payload.id, method: 'post' });
}

function deleteVideoFile({ state, dispatch }, payload) {
  return dispatch('requestApi', { model: Name, subaction: 'file', id: payload.id, method: 'delete' });
}

function stopVideo({ state, dispatch }, payload) {
  return dispatch('requestApi', { model: Name, subaction: 'stop', id: payload.id, method: 'post' });
}

function getActiveVideo({ dispatch }) {
  return dispatch('requestApi', { model: Name, action: 'active' });
}

function setProcessingProgress({ state }, payload) {
  if (!payload) return;
  const id = payload.video_archive_id;
  state.processing.progress = { ...state.processing.progress, [id]: payload.progress };
  state.processing.health_status = { ...state.processing.health_status, [id]: payload.health_status };

  const item = state.items.filter((v) => v.id === id)[0];
  if (item) {
    item.progress = payload.progress;
    item.health_status = payload.health_status;
  }
}

function setProcessingVideoStatus({ state }, payload) {
  const id = payload.id;
  state.processing.state = { ...state.processing.state, [id]: payload };

  const item = state.items.filter((v) => v.id === id)[0];
  if (item) {
    Object.assign(item, payload);
  }
}

async function uploadVideo({ state, rootState }, { file, item }) {
  const { id } = item,
    cancelTokenSource = axios.CancelToken.source(),
    uploadingItem = {
      item,
      cancel(message) {
        cancelTokenSource && cancelTokenSource.cancel(message);
      }
    },
    headers = {
      Authorization: 'Token ' + encodeURIComponent(rootState.app.token)
    },
    request = {
      url: `${rootState.config.server.url}videos/${id}/upload/source_file/`,
      method: 'PUT',
      headers,
      cancelToken: cancelTokenSource.token,
      data: file,
      onUploadProgress: (e) => {
        item.progress = e.loaded / e.total;
      }
    };
  let result;
  Vue.set(state.uploading, item.id, uploadingItem);

  try {
    item.progress = 0.001;
    result = await axios(request);
  } catch (e) {
    result = Promise.reject(e);
  } finally {
    Vue.set(state.uploading, item.id, null);
  }

  return result;
}
