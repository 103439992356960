export const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/components/users/page-login'),
    meta: {
      i18n: ['common.login,,1']
    }
  },
  {
    path: '/launcher',
    name: 'launcher',
    component: () => import('@/components/launcher/launcher'),
    meta: {
      i18n: ['common.launcher']
    }
  },
  {
    path: '/users/create/',
    name: 'userCreate',
    component: () => import('@/components/users/user-page/form/index'),
    meta: {
      i18n: ['common.create', 'common.user,,1']
    }
  },
  {
    path: '/users/:id/',
    name: 'userId',
    component: () => import('@/components/users/user-page/form/index'),
    meta: {
      i18n: ['common.edit', 'common.user,,1']
    }
  },
  {
    path: '/users/filter/:filter?',
    name: 'users',
    component: () => import('@/components/users/index'),
    meta: {
      i18n: ['common.users']
    }
  },
  {
    path: '/search/:filter?',
    name: 'search',
    component: () => import('@/components/search/objects/index'),
    meta: {
      i18n: ['common.search']
    }
  },
  {
    path: '/events/:objects/filter/:filter',
    name: 'events',
    component: () => import('@/components/events/index'),
    meta: {
      i18n: ['common.events']
    }
  },
  {
    path: '/events/:objects/:id/',
    name: 'event',
    component: () => import('@/components/events/form'),
    meta: {
      i18n: ['common.view', 'common.event,,1']
    }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('@/components/events/index'),
    meta: {
      i18n: ['common.events']
    }
  },
  {
    path: '/episodes/:objects/filter/:filter',
    name: 'episodes',
    component: () => import('@/components/episodes/index'),
    meta: {
      i18n: ['common.episodes']
    }
  },
  {
    path: '/clusters/:objects/filter/:filter',
    name: 'clusters',
    component: () => import('@/components/clusters/index'),
    meta: {
      i18n: ['clusters']
    }
  },
  {
    path: '/cluster-events/:objects/filter/:filter',
    name: 'clusterEvents',
    component: () => import('@/components/cluster-events/index'),
    meta: {
      i18n: ['cluster_events']
    }
  },
  {
    path: '/counter-records/filter/:filter',
    name: 'counter-records',
    component: () => import('@/components/counter-records/index'),
    meta: {
      i18n: 'counter | records,,1'
    }
  },
  {
    path: '/counters/create/',
    name: 'counter-create',
    component: () => import('@/components/counters/form'),
    meta: {
      i18n: 'create_counter'
    }
  },
  {
    path: '/counters/:id',
    name: 'counters',
    component: () => import('@/components/counters/form'),
    meta: {
      i18n: 'edit_counter'
    }
  },
  {
    path: '/counters/filter/:filter',
    name: 'counter-items',
    component: () => import('@/components/counters/index'),
    meta: {
      i18n: ['common.counters']
    }
  },
  /* Cameras */
  {
    path: '/cameras/create/',
    name: 'camerasCreate',
    component: () => import('@/components/cameras/form/index'),
    meta: {
      i18n: ['common.create', 'common.camera,,1']
    }
  },
  {
    path: '/cameras/filter/:filter',
    name: 'cameras',
    component: () => import('@/components/cameras/index'),
    meta: {
      i18n: ['common.cameras']
    }
  },
  {
    path: '/cameras/:id/:tab?',
    name: 'camerasEdit',
    component: () => import('@/components/cameras/form/index'),
    meta: {
      i18n: ['common.edit', 'common.camera,,1']
    }
  },
  /* Camera Groups */
  {
    path: '/camera-groups/create/',
    name: 'cameraGroupsCreate',
    component: () => import('@/components/camera-groups/form'),
    meta: {
      i18n: ['common.create', 'common.camera_group,,1']
    }
  },
  {
    path: '/camera-groups/:id/',
    name: 'cameraGroupsEdit',
    component: () => import('@/components/camera-groups/form'),
    meta: {
      i18n: ['common.edit', 'common.camera_group,,1']
    }
  },
  {
    path: '/camera-groups/',
    name: 'cameraGroups',
    component: () => import('@/components/camera-groups/index'),
    meta: {
      i18n: ['common.camera_groups']
    }
  },

  /* Watch lists */
  {
    path: '/watch-lists/create/',
    name: 'watchlistCreate',
    component: () => import('@/components/watch-lists/form'),
    meta: {
      i18n: ['common.create', 'common.watch_lists,,1']
    }
  },
  {
    path: '/watch-lists/:id/',
    name: 'watchlistEdit',
    component: () => import('@/components/watch-lists/form'),
    meta: {
      i18n: ['common.edit', 'common.watch_lists,,1']
    }
  },
  {
    path: '/watch-lists/',
    name: 'watchlists',
    component: () => import('@/components/watch-lists/index'),
    meta: {
      i18n: ['common.watch_lists']
    }
  },
  {
    path: '/settings/',
    name: 'settings',
    component: () => import('@/components/settings/index'),
    meta: {
      i18n: ['common.general,,3', 'common.preferences']
    }
  },
  {
    path: '/appearance/',
    name: 'appearance',
    component: () => import('@/components/settings/appearance'),
    meta: {
      i18n: ['common.appearance']
    }
  },
  {
    path: '/verify/',
    name: 'verify',
    component: () => import('@/components/verify/index'),
    meta: {
      i18n: ['common.verify']
    }
  },
  {
    path: '/genetec/',
    name: 'genetec',
    component: () => import('@/components/genetec/index'),
    meta: {
      i18n: ['common.genetec']
    }
  },
  {
    path: '/dashboard/',
    name: 'dashboard',
    component: () => import('@/components/dashboard/index'),
    meta: {
      i18n: ['dashboard']
    }
  },
  {
    path: '/groups/create/',
    name: 'groupsCreate',
    component: () => import('@/components/groups/form'),
    meta: {
      i18n: ['common.create', 'common.group,,1']
    }
  },
  {
    path: '/groups/:id/',
    name: 'groupsEdit',
    component: () => import('@/components/groups/form'),
    meta: {
      i18n: ['common.edit', 'common.group,,1']
    }
  },
  {
    path: '/groups/',
    name: 'groups',
    component: () => import('@/components/groups/index'),
    meta: {
      i18n: ['roles']
    }
  },
  {
    path: '/video-wall/',
    name: 'video-wall',
    component: () => import('@/components/video-wall/index'),
    meta: {
      i18n: ['common.video_wall']
    }
  },
  {
    path: '/license/',
    name: 'license',
    component: () => import('@/components/license/index'),
    meta: {
      i18n: ['common.license']
    }
  },
  /* Hooks */
  {
    path: '/hooks/create/',
    name: 'hooksCreate',
    component: () => import('@/components/hooks/form'),
    meta: {
      i18n: ['common.create', 'common.webhook']
    }
  },
  {
    path: '/hooks/:id/',
    name: 'hooksEdit',
    component: () => import('@/components/hooks/form'),
    meta: {
      i18n: ['common.edit', 'common.webhook']
    }
  },
  {
    path: '/hooks/',
    name: 'hooks',
    component: () => import('@/components/hooks/index'),
    meta: {
      i18n: ['common.webhooks']
    }
  },
  /* Videos */
  {
    path: '/videos/multi-edit/:options?',
    name: 'videosMultiEdit',
    component: () => import('@/components/videos/form/index'),
    meta: {
      i18n: ['common.create', 'common.video']
    }
  },
  {
    path: '/videos/create/:params?',
    name: 'videosCreate',
    component: () => import('@/components/videos/form/index'),
    meta: {
      i18n: ['common.create', 'common.video']
    }
  },
  {
    path: '/videos/:id/',
    name: 'videosEdit',
    component: () => import('@/components/videos/form/index'),
    meta: {
      i18n: ['common.edit', 'common.video']
    }
  },
  {
    path: '/videos/filter/:filter',
    name: 'videos',
    component: () => import('@/components/videos/index'),
    meta: {
      i18n: ['common.videos']
    }
  },
  {
    path: '/puppeteer/search/filter/:filter',
    name: 'puppeteer-search',
    component: () => import('@/components/puppeteer/search/index'),
    meta: {
      i18n: ['common.search']
    }
  },
  {
    path: '/kyc/filter/:filter',
    name: 'kyc',
    component: () => import('@/components/kyc/index'),
    meta: {
      i18n: ['know_your_customer']
    }
  },
  {
    path: '/puppeteer/search/events/filter/:filter',
    name: 'search-events',
    component: () => import('@/components/puppeteer/search-events/index'),
    meta: {
      i18n: ['common.search']
    }
  },
  {
    path: '/puppeteer/daily/events/filter/:filter',
    name: 'daily-events',
    component: () => import('@/components/puppeteer/daily-events/index'),
    meta: {
      i18n: ['common.events']
    }
  },
  {
    path: '/puppeteer/remote-monitoring/events/filter/:filter',
    name: 'remote-monitoring-events',
    component: () => import('@/components/puppeteer/remote-monitoring-events/index'),
    meta: {
      i18n: ['common.events']
    }
  },
/*  {
    path: '/puppeteer/remote-monitoring/events/:id/',
    name: 'remote-monitoring-event',
    component: () => import('@/components/puppeteer/remote-monitoring-events/form'),
    meta: {
      i18n: ['common.view', 'common.event,,1']
    }
  },*/
  {
    path: '/puppeteer/remote-monitoring/filter/:filter/',
    name: 'remote-monitoring',
    component: () => import('@/components/puppeteer/remote-monitoring/index'),
    meta: {
      i18n: ['common.view', 'common.event,,1']
    }
  },
  {
    path: '/contacts/filter/:filter',
    name: 'relations',
    component: () => import('@/components/contacts/index'),
    meta: {
      i18n: 'relations'
    }
  },
  {
    path: '/cases/filter/:filter',
    name: 'cases',
    component: () => import('@/components/cases/index'),
    meta: {
      i18n: 'cases'
    }
  },
  {
    path: '/cases/create/',
    name: 'casesCreate',
    component: () => import('@/components/cases/form'),
    meta: {
      i18n: 'create | cases'
    }
  },
  {
    path: '/cases/:id/:tab/filter/:filter',
    name: 'caseFilter',
    component: () => import('@/components/cases/form'),
    meta: {
      i18n: 'view | case | faces'
    }
  },
  {
    path: '/cases/:id/:tab?',
    name: 'case',
    component: () => import('@/components/cases/form'),
    meta: {
      i18n: 'view | case'
    }
  },
  /* Reports */
  {
    path: '/reports/',
    name: 'reports',
    component: () => import('@/components/reports/index'),
    meta: {
      i18n: ['common.reports']
    }
  },
  {
    path: '/audit-logs/filter/:filter',
    name: 'audit_logs',
    component: () => import('@/components/audit-logs/audit-logs-page'),
    meta: {
      i18n: ['audit_logs']
    }
  },
  /* Areas */
  {
    path: '/areas/filter/:filter',
    name: 'areas',
    component: () => import('@/components/areas/areas/index'),
    meta: {
      i18n: ['common.areas']
    }
  },
  {
    path: '/areas/create',
    name: 'areasCreate',
    component: () => import('@/components/areas/areas/form/index'),
    meta: {
      i18n: ['create_area']
    }
  },
  {
    path: '/areas/:id',
    name: 'areasEdit',
    component: () => import('@/components/areas/areas/form/index'),
    meta: {
      i18n: ['edit_area']
    }
  },
  {
    path: '/area-triggers/filter/:filter',
    name: 'areaTriggers',
    component: () => import('@/components/areas/triggers/index'),
    meta: {
      i18n: ['common.area_trigger_activators']
    }
  },
  {
    path: '/area-records/filter/:filter',
    name: 'areaRecords',
    component: () => import('@/components/areas/records/index'),
    meta: {
      i18n: ['common.area_records']
    }
  },
  {
    path: '/area-records/filter/:filter',
    name: 'areaRecords',
    component: () => import('@/components/areas/records/index'),
    meta: {
      i18n: ['common.area_records']
    }
  },
  {
    path: '/auth-sessions/filter/:filter',
    name: 'authSessions',
    component: () => import('@/components/sessions/sessions/index'),
    meta: {
      i18n: ['sessions']
    }
  },
  {
    path: '/blocklist-records/filter/:filter',
    name: 'blocklistRecords',
    component: () => import('@/components/sessions/blocklist-records/index'),
    meta: {
      i18n: ['blocklist_records']
    }
  },
  {
    path: '/batch-upload/filter/:filter',
    name: 'batchUpload',
    component: () => import('@/components/cards/batch-uploader/batch-upload'),
    meta: {
      i18n: ['common.batch', 'common.upload', 'common.log']
    },
    props: true
  },
  {
    path: '/batch-upload-entry/filter/:filter',
    name: 'batchUploadEntry',
    component: () => import('@/components/cards/batch-uploader/batch-upload-entry'),
    meta: {
      i18n: ['common.batch', 'common.upload', 'common.log']
    },
    props: (route) => ({ cardType: route.query.cardType })
  },
  {
    path: '/cards/batch/:cardType?',
    name: 'cardsBatch',
    component: () => import('@/components/cards/batch-uploader/batch-uploader'),
    meta: {
      i18n: 'batch_cards_upload'
    }
  },
  {
    path: '/cards/humans/create/',
    name: 'humansCardsCreate',
    component: () => import('@/components/cards/form'),
    meta: {
      i18n: 'create_card'
    }
  },
  {
    path: '/cards/humans/filter/:filter',
    name: 'humansCards',
    component: () => import('@/components/cards/index'),
    meta: {
      i18n: 'human_cards_list'
    }
  },
  {
    path: '/cards/humans/:id/',
    name: 'humansCardsEdit',
    component: () => import('@/components/cards/form/index'),
    meta: {
      i18n: 'edit_card'
    }
  },
  {
    path: '/cards/cars/create/',
    name: 'carsCardsCreate',
    component: () => import('@/components/cards/form'),
    meta: {
      i18n: 'create_card',
      type: 'car'
    }
  },
  {
    path: '/cards/cars/filter/:filter',
    name: 'carsCards',
    component: () => import('@/components/cards/index'),
    meta: {
      type: 'car',
      i18n: 'car_cards_list'
    }
  },
  {
    path: '/cards/cars/:id/',
    name: 'carsCardsEdit',
    component: () => import('@/components/cards/form/index'),
    meta: {
      i18n: 'edit_card',
      type: 'car'
    }
  }
];
