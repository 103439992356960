// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    watch_lists: [],
    ordering: 'id'
  },
  emptyItem = {
    name: '',
    comment: '',
    color: 'cccccc',
    permissions: {},
    camera_groups: [],
    notify: true,
    acknowledge: true,
    ignore_events: false,
    active: true,
    face_threshold: null,
    car_threshold: null,
    body_threshold: null
  },
  Name = 'watch-lists',
  SyncName = 'sync/' + Name,
  DeleteAllCardsName = Name + '/purge_all',
  Action = require('../action.names').default.Watchlists;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    syncing: false,
    loading: false,
    Action
  },
  actions: Object.assign({ deleteAllCards, addSync, deleteSync, deleteAllCardsByWatchlist }, getCrud(Name)),
  getters: {
    watchlistsInDossier(state) {
      return state.items.map((v) => ({ ...v, disabled: v.id === -1 || v.remote_url }));
    },
    watchlistsInFilter(state) {
      return state.items.filter((v) => v.id !== -1);
    }
  }
};

function deleteAllCardsByWatchlist({ state, rootState, dispatch }, { id }) {
  return dispatch('requestApi', { model: Name, method: 'POST', id: id + '/purge' }).then((v) => {
    return v;
  });
}

function addSync({ state, dispatch }, v) {
  state.syncing = true;
  return dispatch('requestApi', { model: SyncName, method: 'post', data: v })
    .then((v) => {
      state.syncing = false;
      return v;
    })
    .catch((e) => {
      state.syncing = false;
      return Promise.reject(e);
    });
}

function deleteSync({ state, dispatch }, v) {
  state.syncing = true;
  return dispatch('requestApi', { model: SyncName, method: 'delete', id: v.id })
    .then((v) => {
      state.syncing = false;
      return v;
    })
    .catch((e) => {
      state.syncing = false;
      return Promise.reject(e);
    });
}

function deleteAllCards({ state, rootState, dispatch }) {
  state.loading = true;

  return dispatch('requestApi', { model: DeleteAllCardsName, method: 'post' })
    .then((v) => {
      state.loading = false;
      return v;
    })
    .catch((e) => {
      state.loading = false;
      return Promise.reject(e);
    });
}
