import {
  carEpisodesModule,
  carVideoEpisodesModule,
  carWallEpisodesModule,
  humanEpisodesModule,
  humanVideoEpisodesModule,
  humanWallEpisodesModule
} from '../index';

const ObjectsType = {
  Faces: 'faces',
  Cars: 'cars',
  Bodies: 'bodies'
};

function wsMessageHandler({ state, dispatch }, e) {
  let message = JSON.parse(e.data);

  if (!message) return;

  if (message.type === 'ping') {
    try {
      window.ws.send(JSON.stringify({ type: 'pong', data: { ...message.data, pong_date: new Date().toISOString() } }));
    } catch (e) {
      console.log('Cant sent pong', e);
    }
    return;
  }

  if (message.type === 'license_alert') {
    dispatch('addNotification', { type: 'error', message: message.data?.message });
    return;
  }

  if (message.type === 'model_event') {
    dispatch('modelEvent', message.data);
    return;
  }

  if (message.type === 'video_archive_progress') {
    dispatch('setProcessingProgress', message.data);
  }

  if (message.type === 'video_archive_vm_status') {
    dispatch('setProcessingVideoStatus', message.data);
  }

  if (message.type === 'camera_vm_status') {
    dispatch('modifyCamera', message.data);
  }

  if (message.type === 'events_acknowledged') {
    state.faces_events.commandItems.push(message);
    return;
  }

  if (message.type === 'event_created') {
    const { car, face, body } = message?.data;
    state.ws_temp_data.last_event = car || face || body;
    if (car && isObjectEventsReceivingEnabled(ObjectsType.Cars)) {
      insertEvent(car, ObjectsType.Cars);
    } else if (face && isObjectEventsReceivingEnabled(ObjectsType.Faces)) {
      insertEvent(face, ObjectsType.Faces);
    } else if (body && isObjectEventsReceivingEnabled(ObjectsType.Bodies)) {
      insertEvent(body, ObjectsType.Bodies);
    } else {
      console.warn('[ws]:event:not_supported ', message);
    }
  }

  if (message.type === 'event_updated' && message.data) {
    const { car, face, body } = message.data,
      updatedItem = face || body || car,
      items = [state.faces_events.items, state.bodies_events.items, state.cars_events.items],
      storedItem = items.reduce((m, i) => m || i.find((v) => v.id === updatedItem.id), null);
    state.ws_temp_data.last_event = car || face || body;
    if (storedItem) {
      Object.assign(storedItem, updatedItem);
    }
  }

  if (message.type === 'episode_updated' && message.data) {
    const updatedItem = message.data && message.data.episode,
      storedItem = state.episodes.items.find((v) => v.id === updatedItem.id);
    if (storedItem) {
      Object.assign(storedItem, updatedItem);
    }
  }

  if (message.type === 'person_created' && state.persons.playing) {
    const { person, person_event } = message.data;

    if (person_event && !person.best_event) person.best_event = person_event;

    insertPerson(person);
  }

  if (message.type === 'person_updated' && message.data) {
    const { person, person_event } = message.data,
      storedItem = state.persons.items.find((v) => v.id === person.id);

    if (person_event && !person.best_event) person.best_event = person_event;

    if (storedItem) {
      Object.assign(storedItem, person);
    } else {
      console.warn('updated person not in the list: ', person);
    }
  }

  if (message.type === 'unacknowledged') {
    state.faces_events.unacknowledged = message.data.all;
    state.faces_events.notify = message.data.notify;
  }

  if (message.type === 'episode_open' || message.type === 'episode_event' || message.type === 'episode_close') {
    const { episode, episode_type } = message.data;
    episode.source = 'ws';
    upsertEpisode(episode, episode_type);
  }

  function insertPerson(person) {
    dispatch('satisfyPerson', person).then((v) => {
      if (v) {
        let sameItem = state.persons.items.find((i) => i.id === person.id);
        if (!sameItem) {
          state.persons.items = [].concat(person, state.persons.items).slice(0, state.persons.filter.current.limit);
        }
      } else {
        console.warn('Event is not satisfied to filters ', state.persons.filter.current, person);
      }
    });
  }

  function insertEvent(event, objectsType = 'faces') {
    const currentState = state[`${objectsType}_events`],
      limit = currentState.filter.current.limit;

    dispatch(`satisfy_${objectsType}`, event).then((v) => {
      if (v) {
        let sameItem = currentState.items.find((i) => i.id === event.id);
        if (!sameItem && !currentState.filter.current.page) {
          currentState.items = [].concat(event, currentState.items).slice(0, limit);
        }
      } else {
        console.warn('Event is not satisfied to filters ', state.faces_events.filter.current, event);
      }
    });
  }

  function upsertEpisode(item, type) {
    const humanModules = [humanEpisodesModule, humanVideoEpisodesModule, humanWallEpisodesModule];
    const carModules = [carEpisodesModule, carVideoEpisodesModule, carWallEpisodesModule];
    const episodeModules = type === 'human' ? humanModules : carModules;
    const lastEvent = item.last_event;

    if (lastEvent && state.faces_events.filter.current.episode) {
      insertEvent(lastEvent, ObjectsType.Faces); // FFSER-4255
    }
    if (lastEvent && state.cars_events.filter.current.episode) {
      insertEvent(lastEvent, ObjectsType.Cars);
    }
    if (lastEvent && state.bodies_events.filter.current.episode) {
      insertEvent(lastEvent, ObjectsType.Bodies);
    }

    for (const module of episodeModules) {
      const id = item.id;
      const existEpisode = module.items.find((v) => v.id === id);

      if (existEpisode) {
        const open = existEpisode.open === false ? false : item.open; // FFSER-4241 right fix
        Object.assign(existEpisode, item, { open });
      } else {
        module.satisfy({ episode: item }).then((success) => {
          if (!success || !item.open || module.filter.current.page) return;
          if (module.items.length >= module.filter.current.limit) {
            module.items = module.items.slice(0, -1);
          }
          module.items = [item, ...module.items];
        });
      }
    }
  }

  function isObjectEventsReceivingEnabled(objectsType) {
    const moduleState = state[`${objectsType}_events`];
    return moduleState?.playing ?? false;
  }
}

export default wsMessageHandler;
