/**
 * Coerce value as non-nullable.
 * @param value Possibly nullable value.
 * @returns Coerced non-nullable value.
 * @throws When value is null or undefined.
 */
export function coerceAsNonNullable<TValue>(value: TValue | null | undefined): TValue {
  return value != null ? value : throwError('Value equals null or undefined.');
}

export function throwError(message: string): never {
  throw new Error(message);
}
