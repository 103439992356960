interface IObjectItem {
  id: string;
  type: number;
  timeFrom: number;
  timeTo: number;
  metadata?: any;
}

interface IObjectModule {
  limit: number;
  isLoadedAll: boolean;
  getOptions: (camera: number, timeFrom: number, timeTo: number, limit: number) => any;
  saveItems: (data) => void;
  findItems: (timeFrom: number, timeTo: number) => IObjectItem[];
}

type GetOptions = (camera: number, timeFrom: number, timeTo: number, limit: number) => any;
type GetItems = (data: any, object?: any) => IObjectItem[];

export { IObjectItem, IObjectModule, GetOptions, GetItems };

export default function createCommonObject(getOptions: GetOptions, getItems: GetItems) {
  return () => {
    const state: { items: IObjectItem[] } = {
      items: []
    };
    return {
      limit: 100,
      isLoadedAll: true,
      getOptions,
      saveItems: function (data) {
        const newItems = getItems(data, this);
        newItems.forEach((newItem) => {
          const index = state.items.findIndex((item) => item.id === newItem.id);
          if (index !== -1) {
            state.items[index] = newItem;
          } else {
            state.items.push(newItem);
          }
        });
      },
      findItems: function (timeFrom: number, timeTo: number): IObjectItem[] {
        return state.items.filter((item: IObjectItem): boolean => {
          return item.timeFrom < timeTo && item.timeTo > timeFrom;
        });
      }
    };
  };
}
