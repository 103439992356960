import _ from '@/apps/common/lodash';

export const ReportType = Object.freeze({
  AuditLogs: 'audit-logs',
  BodyEvents: 'body-events',
  Cameras: 'cameras',
  CarEvents: 'car-events',
  Counters: 'counters',
  HumanEpisodes: 'human-episodes',
  CarEpisodes: 'car-episodes',
  FaceEvents: 'face-events',
  Kyc: 'kyc',
  Persons: 'persons',
  Areas: 'areas',
  AreaTriggers: 'area-activations',
  AreaRecords: 'area-records',
  HumanCards: 'human-cards',
  CarCards: 'car-cards',
  FaceClusters: 'face-clusters',
  BodyClusters: 'body-clusters',
  CarClusters: 'car-clusters',
  FaceClusterEvents: 'face-clusters',
  BodyClusterEvents: 'body-clusters',
  CarClusterEvents: 'car-clusters'
});

export const ReportTypeI18n = Object.freeze({
  'area-activations': 'area_trigger_activators',
  'area-records': 'areas_records',
  'human-cards': 'human_cards__by',
  'car-cards': 'car_cards__by'
});

const ForbiddenReportFilterField = Object.freeze({
  Page: 'page',
  Type: 'type',
  Limit: 'limit'
});

const evaluateCommonReportFilter = compose(
  pickOnlyTruthyReportFilterFields,
  excludeForbiddenReportFilterFields([ForbiddenReportFilterField.Page, ForbiddenReportFilterField.Type, ForbiddenReportFilterField.Limit])
);

const evaluateEventReportFilter = compose(pickOnlyTruthyReportFilterFields, excludeForbiddenReportFilterFields([ForbiddenReportFilterField.Type]));

const evaluators = Object.freeze({
  [ReportType.AuditLogs]: evaluateCommonReportFilter,
  [ReportType.BodyEvents]: evaluateEventReportFilter,
  [ReportType.Cameras]: evaluateCommonReportFilter,
  [ReportType.CarEvents]: evaluateEventReportFilter,
  [ReportType.Counters]: evaluateCommonReportFilter,
  [ReportType.Dossiers]: compose(
    pickOnlyTruthyReportFilterFields,
    excludeForbiddenReportFilterFields([ForbiddenReportFilterField.Page, ForbiddenReportFilterField.Type]),
    excludeDossierReportLimitAttributeWhenItCreatesFromDossierPage
  ),
  [ReportType.HumanEpisodes]: evaluateCommonReportFilter,
  [ReportType.CarEpisodes]: evaluateCommonReportFilter,
  [ReportType.FaceEvents]: evaluateEventReportFilter,
  [ReportType.Kyc]: compose(evaluateCommonReportFilter, appendTimezoneToFilter),
  [ReportType.Persons]: compose(evaluateCommonReportFilter, appendPersonInProperty),
  [ReportType.Areas]: evaluateCommonReportFilter,
  [ReportType.AreaTriggers]: evaluateCommonReportFilter,
  [ReportType.AreaRecords]: evaluateCommonReportFilter,
  [ReportType.HumanCards]: evaluateCommonReportFilter,
  [ReportType.CarCards]: evaluateCommonReportFilter,
  [ReportType.FaceClusters]: compose(
    evaluateCommonReportFilter,
    replaceIdInToClusterIn,
    replaceFirstEventDateGteInToCreatedDateGte,
    replaceFirstEventDateLteInToCreatedDateLte
  ),
  [ReportType.BodyClusters]: compose(
    evaluateCommonReportFilter,
    replaceIdInToClusterIn,
    replaceFirstEventDateGteInToCreatedDateGte,
    replaceFirstEventDateLteInToCreatedDateLte
  ),
  [ReportType.CarClusters]: compose(
    evaluateCommonReportFilter,
    replaceIdInToClusterIn,
    replaceFirstEventDateGteInToCreatedDateGte,
    replaceFirstEventDateLteInToCreatedDateLte
  )
});

export function evaluateReportFilter(reportType, filter) {
  return evaluators[reportType]?.(filter);
}

function excludeDossierReportLimitAttributeWhenItCreatesFromDossierPage(filter) {
  const LooksLikeFilterPropertyName = 'looks_like';
  return _.has(filter, LooksLikeFilterPropertyName) ? filter : _.omit(filter, ForbiddenReportFilterField.Limit);
}

function pickOnlyTruthyReportFilterFields(filter) {
  return _.transform(filter, transformReportFilterField, {});
}

function transformReportFilterField(filter, value, key) {
  if (isReportFilterFieldSituable(value, key)) {
    filter[key] = computeReportFilterFieldValue(value);
  }
  return filter;
}

function isReportFilterFieldSituable(value) {
  return !!(Array.isArray(value) ? value.length : value);
}

function computeReportFilterFieldValue(value) {
  return Array.isArray(value) && value.length === 1 ? value[0] : value;
}

function appendTimezoneToFilter(filter) {
  return { ...filter, timezone: computeTimezoneString() };
}

function appendPersonInProperty(filter) {
  if (filter.id) {
    return { person_in: filter.id, ...filter };
  }
  return filter;
}

function replaceIdInToClusterIn(filter) {
  if (filter.id_in) {
    const { id_in, ...clusterFilter } = filter;
    clusterFilter.cluster_in = id_in;
    return clusterFilter;
  }
  return filter;
}

function replaceFirstEventDateGteInToCreatedDateGte(filter) {
  if (filter.first_event_date_gte) {
    const { first_event_date_gte, ...clusterFilter } = filter;
    clusterFilter.created_date_gte = first_event_date_gte;
    return clusterFilter;
  }
  return filter;
}

function replaceFirstEventDateLteInToCreatedDateLte(filter) {
  if (filter.first_event_date_lte) {
    const { first_event_date_lte, ...clusterFilter } = filter;
    clusterFilter.created_date_lte = first_event_date_lte;
    return clusterFilter;
  }
  return filter;
}

function computeTimezoneString() {
  const tzo = -new Date().getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return dif + pad(tzo / 60) + ':' + pad(tzo % 60);
}

function excludeForbiddenReportFilterFields(forbiddenFields) {
  return (filter) => _.pickBy(filter, (_value, key) => !forbiddenFields.includes(key));
}

function compose(...transforms) {
  return (filter) => transforms.reduce((result, transform) => transform(result), filter);
}

export function applyReportsChanges(reports, changes) {
  const changesMap = createReportsChangesMap(changes);

  for (let index = 0; index < reports.length; ) {
    const report = reports[index];
    const { id } = report;
    if (id in changesMap) {
      Object.assign(report, changesMap[id]);
      delete changesMap[id];
      index++;
    } else {
      reports.splice(index, 1);
    }
  }

  for (const id in changesMap) {
    if (Object.prototype.hasOwnProperty.call(changesMap, id)) {
      reports.push(changesMap[id]);
    }
  }
}

function createReportsChangesMap(changes) {
  return changes.reduce((changesMap, change) => {
    changesMap[change.id] = change;
    return changesMap;
  }, {});
}
