import { throwError } from '@/apps/common/lib';
import _ from '@/apps/common/lodash';
import { BaseItemStateModule } from '@/definitions/base.item.state.module';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { components, operations } from '@/definitions/openapi';
import store from '@/store';
import { EmptyHumanItem } from '@/store/cards/cards/items';
import { getClusterEventsFilterSchema, getClustersFilterSchema } from '@/store/clusters/filter.schema';
import { ObjectsType } from '@/store/objects/get.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { configureDynamicStoreModuleFactory } from '../common';
import {
  EmptyBodyClusterFilter,
  EmptyBodyClusterItem,
  EmptyCarClusterFilter,
  EmptyCarClusterItem,
  EmptyClusterEventFilter,
  EmptyClusterEventItem,
  EmptyFaceClusterFilter,
  EmptyFaceClusterItem
} from './items';

export type MergeTypes<T, U> = Omit<T, keyof U> & U;

export type UpdatedFaceClusterFilter = {
  gender: [];
  age_gte: number | null | undefined;
  age_lte: number | null | undefined;
  emotions: [];
  glasses: [];
  beard: [];
  liveness: [];
  race: [];
  medmask: [];
};

export type UpdatedBodyClusterFilter = {
  top_color: [];
  bottom_color: [];
  detailed_upper_clothes: [];
  headwear: [];
  lower_clothes: [];
  upper_clothes: [];
};

export type UpdatedCarsClusterFilter = {
  color: [];
  body: [];
  make: [];
  model: [];
  special_vehicle_type: [];
};

export type IFaceCluster = components['schemas']['FaceCluster'];
export type IFaceClusterFilter = operations['clusters_faces_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class FaceClusterListModule extends BaseItemsStateModule<IFaceCluster, IFaceClusterFilter> {
  name = 'clusters/faces';
  routeName = 'clusters';
  aclModelName = 'facecluster';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyFaceClusterItem, EmptyFaceClusterFilter, getClustersFilterSchema(ObjectsType.Faces, store.state));
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }

  async merge(ids: number[]): Promise<any> {
    const firstId = ids.splice(0, 1);
    return this.requestImplementation({ model: `${this.name}/${firstId}/merge`, method: 'POST', filter: { clusters: ids } });
  }
}

@Module({ generateMutationSetters: true })
export class FaceClusterItemModule extends BaseItemStateModule<IFaceCluster> {
  name = 'clusters/faces';
  routeName = 'clusters';
  aclModelName = 'facecluster';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyHumanItem);
    this.setEmptyItemsState();
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

export type IBodyCluster = components['schemas']['BodyCluster'];
export type IBodyClusterFilter = operations['clusters_bodies_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class BodyClusterListModule extends BaseItemsStateModule<IBodyCluster, IBodyClusterFilter> {
  name = 'clusters/bodies';
  routeName = 'clusters';
  aclModelName = 'bodycluster';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyBodyClusterItem, EmptyBodyClusterFilter, getClustersFilterSchema(ObjectsType.Bodies, store.state));
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }

  async merge(ids: number[]): Promise<any> {
    const firstId = ids.splice(0, 1);
    return this.requestImplementation({ model: `${this.name}/${firstId}/merge`, method: 'POST', filter: { clusters: ids } });
  }
}

export type ICarCluster = components['schemas']['CarCluster'];
export type ICarClusterFilter = operations['clusters_cars_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class CarClusterListModule extends BaseItemsStateModule<ICarCluster, ICarClusterFilter> {
  name = 'clusters/cars';
  routeName = 'clusters';
  aclModelName = 'carcluster';

  constructor(options: RegisterOptions) {
    super(options);
    store.subscribeAction({
      after: (action) => {
        if (action.type === 'loadFeaturesData') {
          this.init(EmptyCarClusterItem, EmptyCarClusterFilter, getClustersFilterSchema(ObjectsType.Cars, store.state));
        }
      }
    });
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }

  async merge(ids: number[]): Promise<any> {
    const firstId = ids.splice(0, 1);
    return this.requestImplementation({ model: `${this.name}/${firstId}/merge`, method: 'POST', filter: { clusters: ids } });
  }
}

export type IFaceClusterEvent = components['schemas']['FaceClusterEvent'];
export type IFaceClusterEventFilter = operations['cluster_events_faces_list']['parameters']['query'];

export type IBodyClusterEvent = components['schemas']['BodyClusterEvent'];
export type IBodyClusterEventFilter = operations['cluster_events_bodies_list']['parameters']['query'];

export type ICarClusterEvent = components['schemas']['CarClusterEvent'];
export type ICarClusterEventFilter = operations['cluster_events_cars_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class ClusterEventsFaceListModule extends BaseItemsStateModule<IFaceClusterEvent, IFaceClusterEventFilter> {
  name = 'cluster-events/faces';
  routeName = 'cluster-events';
  aclModelName = '';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyClusterEventItem, EmptyClusterEventFilter(ObjectsType.Faces), getClusterEventsFilterSchema(ObjectsType.Faces, store.state));
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class ClusterEventsBodyListModule extends BaseItemsStateModule<IBodyClusterEvent, IBodyClusterEventFilter> {
  name = 'cluster-events/bodies';
  routeName = 'cluster-events';
  aclModelName = '';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyClusterEventItem, EmptyClusterEventFilter(ObjectsType.Bodies), getClusterEventsFilterSchema(ObjectsType.Bodies, store.state));
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class ClusterEventsCarListModule extends BaseItemsStateModule<ICarClusterEvent, ICarClusterEventFilter> {
  name = 'cluster-events/cars';
  routeName = 'cluster-events';
  aclModelName = '';

  constructor(options: RegisterOptions) {
    super(options);
    store.subscribeAction({
      after: (action) => {
        if (action.type === 'loadFeaturesData') {
          this.init(EmptyClusterEventItem, EmptyClusterEventFilter(ObjectsType.Cars), getClusterEventsFilterSchema(ObjectsType.Cars, store.state));
        }
      }
    });
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

export const createClusterEventsFaceListModule = configureDynamicStoreModuleFactory('cluster-events-face-list', (store, name) => {
  return new ClusterEventsFaceListModule({ store, name });
});

export const createClusterEventsBodyListModule = configureDynamicStoreModuleFactory('cluster-events-body-list', (store, name) => {
  return new ClusterEventsBodyListModule({ store, name });
});

export const createClusterEventsCarListModule = configureDynamicStoreModuleFactory('cluster-events-car-list', (store, name) => {
  return new ClusterEventsCarListModule({ store, name });
});

export const createCarClusterListModule = configureDynamicStoreModuleFactory('car-cluster-list', (store, name) => {
  return new CarClusterListModule({ store, name });
});

export const createFaceClusterListModule = configureDynamicStoreModuleFactory('face-cluster-list', (store, name) => {
  return new FaceClusterListModule({ store, name });
});

export const createBodyClusterListModule = configureDynamicStoreModuleFactory('body-cluster-list', (store, name) => {
  return new BodyClusterListModule({ store, name });
});

export function selectClusterListStoreModuleFactory(objectsType: ObjectsType) {
  switch (objectsType) {
    case ObjectsType.Cars:
      return createCarClusterListModule;
    case ObjectsType.Bodies:
      return createBodyClusterListModule;
    default:
      return createFaceClusterListModule;
  }
}

export function selectClusterEventsListStoreModuleFactory(objectsType: ObjectsType) {
  switch (objectsType) {
    case ObjectsType.Faces:
      return createClusterEventsFaceListModule;
    case ObjectsType.Bodies:
      return createClusterEventsBodyListModule;
    case ObjectsType.Cars:
      return createClusterEventsCarListModule;
  }
  throwError(`Unsupported object type: "${objectsType}".`);
}
