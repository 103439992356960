import store from '@/store';
import _ from '@/apps/common/lodash';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IFaceClusterEvent, IFaceClusterEventFilter } from '@/store/clusters';
import { ApiUrls } from '@/services/constants';

abstract class ListResponseData<T> {
  results: T[] | null = null;
  next_page: string | null = null;
}

type ApiIdType = string | number;

type ListPromiseResponse<T> = Promise<ListResponseData<T>>;
type ItemPromiseResponse<T> = Promise<T>;
type DeletePromiseResponse = Promise<boolean>;

export abstract class DataService<T, F> {
  abstract getList(filters: F): ListPromiseResponse<T>;
  abstract get(id: ApiIdType): ItemPromiseResponse<T>;
  abstract getListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any>;
  abstract getItemListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any>;
  abstract create(data: Partial<T>): ItemPromiseResponse<T>;
  abstract update(id: ApiIdType, data: Partial<T>): ItemPromiseResponse<T>;
  abstract delete(id: ApiIdType): DeletePromiseResponse;
}

class DefaultHTTPService<T, F> extends DataService<T, F> {
  private axios: AxiosInstance;
  private _url: string;

  constructor(axios: AxiosInstance, url: string) {
    super();
    this.axios = axios;
    this._url = url;
  }

  get url() {
    return this._url;
  }

  getList(filters: Partial<F> | undefined): ListPromiseResponse<T> {
    console.log('Data service get list', filters);
    return this.axios({ url: this.url, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  get(id: ApiIdType): ItemPromiseResponse<T> {
    return this.axios({ url: `${this.url}/${id}/`, method: 'get' }).then(this.adaptResult);
  }

  getListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any> {
    return this.axios({ url: `${this.url}/${action}/`, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  getItemListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any> {
    return this.axios({ url: `${this.url}/${id}/${action}/`, method: 'get', params: this.cleanObject(filters) }).then(this.adaptResult);
  }

  create(data: Partial<T>): ItemPromiseResponse<T> {
    return this.axios({ url: this.url, method: 'post', data }).then(this.adaptResult);
  }

  update(id: ApiIdType, data: Partial<T>): ItemPromiseResponse<T> {
    return this.axios({ url: this.url, method: 'patch', data }).then(this.adaptResult);
  }

  delete(id: ApiIdType): DeletePromiseResponse {
    return this.axios({ url: `${this.url}/${id}/`, method: 'delete' }).then(this.adaptResult);
  }

  adaptResult(v: AxiosResponse<any>): any {
    return v.data;
  }

  cleanObject(i: any) {
    return _.pickBy(_.cloneDeep(i), (v) => !(v === '' || v === null));
  }
}

export class DataServiceFactory {
  private _services: { [P: string]: DataService<any, any> } = {};

  getService<T = any, F = any>(url: string): DataService<T, F> {
    let result = this._services[url] || new DefaultHTTPService<T, F>(this.getAxiosInstance(), url);
    this._services[url] = result;
    return result as DataService<T, F>;
  }

  protected getAxiosInstance(): AxiosInstance {
    const instance = axios.create();
    instance.interceptors.request.use(function (config) {
      return {
        ...config,
        baseURL: store.state.config.server.url,
        headers: {
          'accept-language': store.state.app.acceptLanguage,
          authorization: 'Token ' + encodeURIComponent(store.state.app.token),
          'x-uuid': store.state.app.uuid,
          ...config.headers
        }
      };
    });
    return instance;
  }
}

export class DataServiceRepository {
  constructor(private _dataServiceRepository: DataServiceFactory) {}

  getBodyClusterEvents(): DataService<IFaceClusterEvent, IFaceClusterEventFilter> {
    return this._dataServiceRepository.getService(ApiUrls.BodyClusterEvents);
  }
}

export const dataServiceFactory = new DataServiceFactory();
export const dataServiceRepository = new DataServiceRepository(dataServiceFactory);
export const getService = dataServiceFactory.getService.bind(dataServiceFactory);
