import _ from '@/apps/common/lodash';

const Identity = Object.freeze({
  Int: 'int',
  Boolean: 'boolean',
  IntOrNull: 'int_or_null',
  Indefinite: 'indefinite'
});

const identifiers = Object.freeze({
  [Identity.Boolean]: (v) => (v ? Boolean(v) : ''),
  [Identity.Int]: identifyFieldValueAsInt,
  [Identity.IntOrNull]: identifyFieldValueAsNullableInt,
  [Identity.Indefinite]: returnIndefiniteFieldValue
});

export function identifyFilterFieldValue(fieldValue, { multiple, identity }) {
  const identify = getFilterFieldValueIdentifier(identity);
  if (multiple) {
    return fieldValue ? (_.isArray(fieldValue) ? fieldValue : [fieldValue]).map(identify) : [];
  }
  return identify(fieldValue);
}

function getFilterFieldValueIdentifier(fieldIdentity) {
  return _.get(identifiers, fieldIdentity, returnIndefiniteFieldValue);
}

function identifyFieldValueAsInt(fieldValue) {
  return Number(fieldValue);
}

function identifyFieldValueAsNullableInt(fieldValue) {
  return fieldValue ? identifyFieldValueAsInt(fieldValue) : null;
}

function returnIndefiniteFieldValue(fieldValue) {
  return fieldValue;
}
