export default {
  /* Camera group */
  deduplicate_desc: 'Фиксировать только уникальные события среди камер группы, исключив дубликаты.',
  deduplicate_delay_desc: 'Интервал в секундах, с которым события проверяются на уникальность.',
  /* CPU */
  roi_desc: 'Включает отправку на сервер объектов, обнаруженных только внутри интересующей области.',
  rot_desc:
    'Включает детектирование и отслеживание объектов только внутри заданной прямоугольной области. Используйте данную опцию, чтобы уменьшить нагрузку на сервер.',
  min_score: 'Минимальное качество объекта (min_score)',
  min_score_desc:
    'Минимальное качество изображения объекта при выборе лучшего. Определяется эмпирически: отрицательные значения вблизи 0 = наиболее качественные прямые изображения лиц анфас, -1 = хорошее качество, -2 = удовлетворительное качество, отрицательные значения -5 и меньше = перевернутые объекта и объекта, повернутые под большими углами, распознавание может быть неэффективным.',
  min_d_score: 'Максимальное отклонение объекта (min_d_score)',
  min_d_score_desc:
    'Максимальное отклонение объекта от положения анфас при выборе лучшего. Определяется эмпирически: -3.5 = слишком большие углы поворота, распознавание лиц может быть неэффективным,  -2.5 = удовлетворительное отклонение, -0.05 = близко к положению анфас, 0 = анфас.',
  filter_min_size: 'Минимальный размер объекта (filter_min_size)',
  filter_min_size_desc: 'Минимальный размер объекта в пикселях для отправки на сервер.',
  filter_max_size: 'Максимальный размер объекта (filter_max_size)',
  filter_max_size_desc: 'Максимальный размер объекта в пикселях для отправки на сервер.',
  realtime: 'Режим реального времени (realtime)',
  realtime_desc:
    'Режим реального времени. Выбирать лучший кадр с объектом в каждом интервале времени realtime_dly. Если realtime_post_perm: true, отправка лучшего кадра происходит по завершению каждого интервала realtime_dly; если false, лучший кадр отправляется, только если его качество улучшилось по сравнению с предыдущим отправленным кадром.',
  realtime_post_first_immediately: 'Отправлять первый объект сразу (realtime_post_first_immediately)',
  realtime_post_first_immediately_desc:
    'Если true, отправлять первый объект из трека сразу, как только фильтры по качеству/размеру/региону интереса пройдены, не дожидаясь окончания первого realtime_post_interval  в режиме реального времени. Если false, отправлять первый объект после окончания первого realtime_post_interval.',
  realtime_post_perm: 'Отправлять лучший кадр (realtime_post_perm)',
  realtime_post_perm_desc:
    'Если true, отправлять лучший кадр в каждом интервале времени realtime_dly в режиме реального времени. Если false, отправлять лучший кадр, только если его качество улучшилось по сравнению с предыдущим отправленным кадром.',
  realtime_dly: 'Временной интервал (realtime_dly)',
  realtime_dly_desc: 'Временной интервал в миллисекундах, в течение которого в режиме реального времени выбирается лучший кадр с объектом.',
  overall: 'Буферный режим (overall)',
  overall_only: 'Буферный режим (overall_only)',
  overall_desc: 'Буферный режим. Отправлять для объекта один кадр наилучшего качества.',
  ffmpeg_params: 'Опции ffmpeg (ffmpeg_params)',
  ffmpeg_params_desc: 'Опции ffmpeg для видеопотока. Задаются массивом строк ключ=значение, например, "rtsp_transport=tcp allowed_media_types=video"',
  fd_frame_height: 'Размер кадра (fd_frame_height)',
  fd_frame_height_desc:
    'Размер кадра для детектора объектов в пикселях. Отрицательные значения соответствуют исходному размеру. Оптимальные значения для уменьшения нагрузки: 640-720',
  npersons: 'Максимальное количество объектов (npersons)',
  npersons_desc: 'Максимальное количество объектов, одновременно отслеживаемых детектором объектов. Влияет на производительность.',
  tracker_threads: 'Количество тредов отслеживания (tracker_threads)',
  tracker_threads_desc:
    'Количество тредов отслеживания для детектора объектов. Должно быть меньше или равно значению параметра npersons. Оптимально, когда они равны. Если количество тредов отслеживания меньше, чем максимальное количество отслеживаемых объектов, потребление ресурсов уменьшается, однако также уменьшается и скорость отслеживания.',
  jpeg_quality: 'Качество сжатия (jpeg_quality)',
  jpeg_quality_desc: 'Качество сжатия полного кадра для отправки.',
  draw_track: 'Рисовать след (draw_track)',
  draw_track_desc: 'Рисовать в bbox след от движения объекта.',
  api_timeout: 'Время ожидания (api_timeout)',
  api_timeout_desc: 'Время ожидания в миллисекундах ответа на API-запрос',
  md_threshold: 'Минимальная интенсивность движения (md_threshold)',
  md_threshold_desc:
    'Минимальная интенсивность движения, которая будет регистрироваться детектором движения. Определяется эмпирически: 0 = детектор выключен, 0.002 = значение по умолчанию, 0.05 = минимальная интенсивность слишком высока, чтобы зарегистрировать движение.',
  md_scale: 'Коэффициент масштабирования кадра (md_scale)',
  md_scale_desc:
    'Размер кадра для детектора движения относительно исходного размера от 0 до 1. Кадр должен быть уменьшен при больших разрешениях камеры, отображении объектов крупным планом, а также при чрезмерной загрузке процессора — для снижения потребления системных ресурсов.',
  /* GPU */
  fullframe_crop_rot: 'Обрезать полный кадр по ROT (fullframe_crop_rot)',
  fullframe_crop_rot_desc:
    'Если true, то обрезать полный кадр по размеру ROT-области перед отправкой на распознавание. Размер полного кадра будет равен размеру ROT-области.',
  track_overlap_threshold: "Степень перекрытия bbox'ов в треке (track_overlap_threshold)",
  track_overlap_threshold_desc:
    "Процент перекрытия bbox'ов между двумя последовательными кадрами, чтобы эти" +
    " bbox'ы считались одним треком.\n" +
    'Значение данного параметра стоит уменьшить при низкой частоте кадров, иначе треки будут слишком часто разрываться, что приведёт к большому количеству overall-кадров и большой нагрузке на findface-extraction-api.\n' +
    'Значение данного параметра стоит увеличить, если происходит ошибка, когда внутри одного трека появляются разные объекты (треки разных объектов переходят от одного к другому).\n' +
    'Диапазон значений: от 0 до 1.',
  track_miss_interval: 'Время закрытия трека (track_miss_interval)',
  track_miss_interval_desc: 'Система завершит трек, если в нем не было задетектировано новых изображений объекта в течение заданного времени (в секундах).',
  track_send_history: 'Отправлять историю трека (track_send_history)',
  track_send_history_desc: "Отправка массива координат bbox'ов вместе с событием. Может быть применимо для внешних интеграций, чтобы отобразить путь объекта.\n" +
    "Работа при track_max_duration_frames = 0 невозможна - необходимо установить этот параметр в ненулевое значение, иначе будет получен статус misconfigured.",
  post_first_track_frame: 'Отправлять дополнительно первый кадр трека при его завершении (post_first_track_frame)',
  post_first_track_frame_desc:
    'При завершении трека в дополнение к overall-кадру трека будет дополнительно отправлен первый кадр трека. Может быть применимо для внешних интеграций.',
  post_last_track_frame: 'Отправлять дополнительно последний кадр трека при его завершении (post_last_track_frame)',
  post_last_track_frame_desc:
    'При завершении трека в дополнение к overall-кадру трека будет дополнительно отправлен последний кадр трека. Может быть применимо для внешних интеграций.',
  filter_min_quality: 'Минимальное качество изображения объекта (filter_min_quality)',
  filter_min_quality_faces_desc:
    'Минимальное качество изображения лица для детекции.\n' +
    'Допустимый диапазон от 0 до 1.\n' +
    'Базовое рекомендуемое значение 0.45 - соответствует удовлетворительному качеству изображения лица.\n' +
    'Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям лиц, полностью находящихся в кадре.\n' +
    'Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые лица или лица, повернутые под большими углами. При таких значениях распознавание может быть неэффективным.',
  filter_min_quality_bodies_desc:
    'Минимальное качество изображения силуэта для детекции.\n' +
    'Допустимый диапазон от 0 до 1.\n' +
    'Базовое рекомендуемое значение 0.6 - соответствует удовлетворительному качеству изображения силуэта.\n' +
    'Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям силуэтов, полностью находящихся в кадре.\n' +
    'Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые силуэты или силуэты, повернутые под большими углами. При таких значениях распознавание может быть неэффективным.',
  filter_min_quality_cars_desc:
    'Минимальное качество изображения автомобиля для детекции.\n' +
    'Допустимый диапазон от 0 до 1.\n' +
    'Базовое рекомендуемое значение 0.73 - соответствует удовлетворительному качеству изображения автомобиля.\n' +
    'Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям автомобилей, полностью находящихся в кадре.\n' +
    'Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые автомобили или автомобили, повернутые под большими углами. При таких значениях распознавание может быть неэффективным.',
  router_timeout_ms: 'Время ожидания ответа на запрос (router_timeout_ms)',
  router_timeout_ms_desc: 'Время ожидания в миллисекундах ответа на отправленный запрос с лицом.',
  start_stream_timestamp: 'Прибавлять к временным меткам (start_stream_timestamp)',
  start_stream_timestamp_desc: 'Прибавлять указанное количество секунд к временным меткам из потока.',
  use_stream_timestamp: 'Получать временные метки из потока',
  use_stream_timestamp_desc:
    'Если true - отправлять на сервер временные метки, полученные из потока. Если false - отправлять текущие дату и время. По умолчанию передается текущие дата и время.',
  use_stream_timestamp__simple: 'Передавать на сервер временные метки из видеопотока (use_stream_timestamp)',
  use_stream_timestamp_desc__simple: 'Если не выбрано, то по умолчанию будут передаваться текущие дата и время.',
  select_start_stream_timestamp: 'Указать временную метку для загружаемого видео',
  select_start_stream_timestamp_desc:
    'Чтобы самостоятельно указать временную метку для загружаемого видео, включите выше передачу на сервер временных меток из видеопотока.',
  watch_list_camera_groups_desc: 'Если список групп камер пустой, то список досье работает по всем камерам.',
  play_speed: 'Ограничение скорости проигрывания (play_speed)',
  play_speed_desc:
    'Если меньше нуля, то скорость не ограничивается, в остальных случаях поток читается со скоростью play_speed. Не применимо для потоков с камер видеонаблюдения.',
  ffmpeg_format: 'Формат FFMPEG (ffmpeg_format)',
  ffmpeg_format_desc: 'Передать FFMPEG формат(mxg, flv, и т. д.), если он не может быть автоматически определен.',
  router_verify_ssl: 'Проверять SSL-сертификат (router_verify_ssl)',
  router_verify_ssl_desc:
    'Если true, проверять SSL-сертификат сервера при отправке на него объектов через https. Если false, может быть принят самоподписанный сертификат.',
  imotion_threshold: 'Минимальная интенсивность движения (imotion_threshold)',
  imotion_threshold_desc: 'Минимальная интенсивность движения, которая будет регистрироваться детектором движения.',
  realtime_post_interval: 'Временной интервал (realtime_post_interval)',
  realtime_post_interval_desc:
    ' Временной интервал в секундах (целое или рациональное число), в течение которого в режиме реального времени выбирается лучший кадр с объектом.',
  realtime_post_every_interval: 'Отправлять лучший кадр (realtime_post_every_interval)',
  realtime_post_every_interval_desc:
    'Если true, отправлять лучший кадр в каждом временном интервале realtime_post_interval в режиме реального времени. Если false, отправлять лучший кадр, только если его качество улучшилось по сравнению с предыдущим отправленным кадром.',
  detect_bodies_desc:
    'Чтобы использовать счётчик по силуэтам необходимо включить детектирование силуэтов. Для этого ознакомьтесь с документацией (раздел "Включение детектирования силуэтов"), или обратитесь к администратору вашей установки FindFace Multi.',
  detect_cars_desc:
    'Чтобы использовать счётчик по автомобилям необходимо включить детектирование автомобилей. Для этого ознакомьтесь с документацией (раздел “Распознавание автомобилей и их атрибутов”), или обратитесь к администратору вашей установки FindFace Multi.',
  time_should_be_in_utc: 'Время должно указываться в формате UTC',
  collect_track_data_seconds_desc: 'Минимальное время - 15, максимальное - 300.',
  detect_proximity_recommendations_desc: 'Рекомендации для успешной калибровки камер:',
  detect_proximity_recommendations_desc_1: 'Человек на камере должен быть виден в полный рост.',
  detect_proximity_recommendations_desc_2: 'Человек должен пройти по зоне, фиксируемой камерой, по которой в дальнейшем будут рассчитываться расстояния.',
  detect_proximity_recommendations_desc_3: 'Для достижения наилучшего качества человек должен ходить по ровной поверхности.',
  distance_measurement_desc:
    'После включения функции "Определение дистанции" необходимо нажать кнопку "Сохранить". После сохранения счетчика нужно произвести настройку счетчика: ввести время калибровки и нажать кнопку "Откалибровать счетчик".',
  disable_drops: 'Обработка всех подходящих кадров без пропусков (disable_drops)',
  disable_drops_desc:
    'По умолчанию (false), если findface-video-worker не обладает достаточными ресурсами для обработки всех кадров с объектами, он отбрасывает некоторые из них. Если данная опция активна (true), findface-video-worker помещает лишние кадры в очередь, чтобы обработать их впоследствии. При обработке файлов как камер необходимо указать значение true.',
  single_pass: 'Обработать поток один раз до конца или до первой ошибки (single_pass)',
  single_pass_desc: 'Если true, то не перезапускать обработку потока в случае ошибки.',
  track_max_duration_frames: 'Максимальная длительность трека в кадрах (track_max_duration_frames)',
  track_max_duration_frames_desc:
    'Ограничение максимального количества кадров в истории трека. \n' +
    "Если значение этого параметра больше нуля и количество кадров в треке превышает его, то трек будет принудительно разорван. Однако из-за особенностей videoworker'а (интерполяция bbox'ов) эта проверка является не строгой - к примеру, при track_max_duration_frames = 5 трек может длиться и 6, и 7 кадров. \n" +
    'Данная настройка нужна для разрыва "вечных" треков объектов - например, лицо на рекламном плакате, который попал в поле зрения камеры. \n' +
    'Для работы опции track_send_history необходимо установить параметр track_max_duration_frames в ненулевое значение.',
};
