import { IFilter } from '@/definitions/filter';
import { FilterFormComponentName } from '@/components/filter-form/components';
import { shortString } from '@/apps/common/filters.js';
import ActionNames from '@/store/action.names.js';

const CountOptions = [
  {
    value: 1,
    label: 1
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 5,
    label: 5
  },
  {
    value: 10,
    label: 10
  }
];

export const schema: IFilter = {
  module: 'counter_records',
  action: ActionNames.CounterRecords.Get,
  fields: [
    {
      name: 'counter',
      control: {
        i18nLabel: ['common.counter'],
        component: [
          FilterFormComponentName.Select,
          {
            itemsPath: '$store.state.counters.items',
            computeItem: (v) => ({
              ...v,
              value: v.id,
              label: shortString(v.name),
              tooltip: v.name
            }),
            filterable: true,
            i18nPlaceholder: 'common.not_selected',
            clearFieldsOnChange: ['page', 'id']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    },
    {
      name: 'cameras',
      control: {
        i18nLabel: ['common.cameras'],
        component: [
          FilterFormComponentName.Select,
          {
            itemsPath: '$store.state.cameras.items',
            computeItem: (v) => ({
              ...v,
              value: v.id,
              label: shortString(v.name),
              tooltip: v.name
            }),
            multiple: true,
            multipleLimit: 10,
            alphabeticalOrder: true,
            i18nPlaceholder: 'common.not_selected',
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        multiple: true,
        identity: 'int'
      }
    },
    {
      name: 'created_date_gte',
      control: {
        i18nLabel: ['common.start'],
        component: [
          FilterFormComponentName.Datetime,
          {
            type: 'datetime',
            clearFieldsOnChange: ['page']
          }
        ]
      }
    },
    {
      name: 'created_date_lte',
      control: {
        i18nLabel: ['common.end'],
        component: [
          FilterFormComponentName.Datetime,
          {
            type: 'datetime',
            clearFieldsOnChange: ['page']
          }
        ]
      }
    },
    {
      name: 'faces_count_gte',
      control: {
        i18nLabel: ['count | faces,,1'],
        component: [
          FilterFormComponentName.Select,
          {
            items: CountOptions,
            i18nPlaceholder: 'common.from',
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    },
    {
      name: 'silhouettes_count_gte',
      control: {
        i18nLabel: ['count | silhouettes,,1'],
        component: [
          FilterFormComponentName.Select,
          {
            items: CountOptions,
            i18nPlaceholder: 'common.from',
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    },
    {
      name: 'cars_count_gte',
      control: {
        i18nLabel: ['count | cars,,1'],
        component: [
          FilterFormComponentName.Select,
          {
            items: CountOptions,
            i18nPlaceholder: 'common.from',
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    },
    {
      name: 'id_in',
      control: {
        i18nLabel: ['counter_record_ids'],
        component: [
          FilterFormComponentName.InputIDs,
          {
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        multiple: true,
        identity: 'int'
      }
    },
    {
      name: ['proximity_min_gte', 'proximity_min_lte'],
      control: {
        i18nLabel: ['min_distance'],
        component: [
          FilterFormComponentName.SelectDoubled,
          {
            clearable: true,
            filterable: true,
            itemsPath: '$store.getters.getDistanceFilterOptions',
            i18nPlaceholder: ['common.from', 'common.till'],
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    },
    {
      name: ['proximity_max_gte', 'proximity_max_lte'],
      control: {
        i18nLabel: ['max_distance'],
        component: [
          FilterFormComponentName.SelectDoubled,
          {
            clearable: true,
            filterable: true,
            itemsPath: '$store.getters.getDistanceFilterOptions',
            i18nPlaceholder: ['common.from', 'common.till'],
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    },
    {
      name: ['proximity_avg_gte', 'proximity_avg_lte'],
      control: {
        i18nLabel: ['avg_distance'],
        component: [
          FilterFormComponentName.SelectDoubled,
          {
            clearable: true,
            filterable: true,
            itemsPath: '$store.getters.getDistanceFilterOptions',
            i18nPlaceholder: ['common.from', 'common.till'],
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        identity: 'int'
      }
    }
  ]
};
