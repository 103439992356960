













import { Prop, Component, Watch } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import FailedScreenshot from '@/components/common/failedScreenshot.vue';
import { IAreaTrigger } from '@/store/areas/triggers';
import { IAreaRecord, IAreaRecordFilter } from '@/store/areas/records';
import { areaRecordsModule } from '@/store/index';
import { mixins } from 'vue-class-component';
import PolygonView from '@/components/polygon-draw/view.vue';
import { ObjectPreview } from '@/components/objects/preview';

@Component({
  name: TableComponentNames.AreaTriggerPolygon,
  components: { FailedScreenshot, PolygonView }
})
export default class TableGeneratorAreaTriggerPolygon extends mixins(ObjectPreview) {
  @Prop({ required: true })
  item!: IAreaTrigger;

  record: IAreaRecord | null = null;

  @Watch('item')
  itemHandler(v, p) {
    if (v?.id !== p?.id) this.load();
  }

  public dimensions = { width: 0, height: 0 };

  get points() {
    /** @todo Remove this hack as soon as possible. */
    return (this.record as any)?.roi || [];
  }

  async loadDimensions() {
    this.dimensions = (await this.$store.dispatch('getCameraDimensions', this.record?.camera)) ?? { width: 0, height: 0 };
  }

  mounted() {
    this.load();
  }

  async load() {
    const filter: IAreaRecordFilter = { area_trigger: [this.item.id], ordering: '-id', limit: 1 };
    this.record = null;
    try {
      const items = await areaRecordsModule.getByFilter(filter);
      this.record = items[0];
      await this.loadDimensions();
    } catch (e) {
      console.warn('[area-record] get error', e);
    }
  }
}
