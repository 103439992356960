import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    watch_lists: []
  },
  emptyItem = {
    id: null,
    name: ''
  },
  Name = 'groups',
  Action = require('../action.names').default.Groups;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({}, getCrud(Name)),
  getters: {
    getGroupsListForSelect(state) {
      return state.items.map((v) => ({ value: v.id, label: v.name }));
    }
  }
};
