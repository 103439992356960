



import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IAuditLog } from '@/store/audit-logs/types';

@Component({
  name: TableComponentNames.ActionTypeLabel
})
export default class TableGeneratorActionTypeLabel extends Vue {
  @Prop({ required: true })
  item!: IAuditLog;

  get label() {
    let actionType = this.item.action_type;
    if (actionType) {
      return this.$tf(this.$store.state.audit_logs.ActionTypesI18n[actionType] ?? actionType);
    }
    return this.$tf('unknown,');
  }
}
