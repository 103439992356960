import _ from '@/apps/common/lodash';
import { BaseItemStateModule } from '@/definitions/base.item.state.module';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { components, operations } from '@/definitions/openapi';
import { CarsFilterSchema, HumansFilterSchema } from '@/store/cards/cards/filter.schema';
import { configureDynamicStoreModuleFactory } from '@/store/common';
import { Action, Module, RegisterOptions, VuexModule } from 'vuex-class-modules';
import store, { carCardsModule, humanCardsModule } from '@/store';
import { EmptyCarItem, EmptyCarsFilter, EmptyHumanItem, EmptyHumansFilter } from './items';
import { ObjectsType, ObjectsTypeItems } from '@/store/objects/get.module';

export type IHumanCard = components['schemas']['HumanCard'];
export type IHumanCardFilter = operations['cards_human_list']['parameters']['query'];
export type ICarCard = components['schemas']['CarCard'];
export type ICarCardFilter = operations['cards_cars_list']['parameters']['query'];
export type CommonCardType = ICarCard | IHumanCard | null;

interface ICreatedCardParams {
  cardType: CardType;
  data: IBaseCard;
}

export interface IFileItem {
  fileName: string;
  file: File;
  sourcePhoto: null | string;
  getSourcePhoto: () => string;
  status: string;
  error?: {
    desc: string;
  };
}

export interface IBatchUploadItem {
  created_date: Date;
  fail_count: number;
  id: number;
  name: string;
  secret_key: string;
  success_count: number;
}

export interface IBaseCard {
  name: string;
  comment: string;
  watch_lists: number[];
  active: boolean;
  upload_list: number;
}

export enum CardType {
  None = '',
  Human = 'human',
  Car = 'car'
}

export const CardTypesToObjects = {
  human: ['faces', 'bodies'],
  car: ['cars']
};

export const CardTypeList: CardType[] = [CardType.Human, CardType.Car];

export const getEnabledCardTypes = (config) => {
  const configObjects = config?.objects;
  return CardTypeList.map((type) => {
    const intersectionArr = CardTypesToObjects[type].filter((i) => configObjects[i]?.enabled && Object.keys(configObjects).includes(i));
    if (intersectionArr.length) {
      return type;
    }
  }).filter(Boolean);
};

@Module({ generateMutationSetters: true })
export class HumanCardsModule extends BaseItemsStateModule<IHumanCard, IHumanCardFilter> {
  name = 'cards/humans';
  routeName = 'humansCards';
  aclModelName = 'humancard';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyHumanItem, EmptyHumansFilter, HumansFilterSchema);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class HumanCardItemModule extends BaseItemStateModule<IHumanCard> {
  name = 'cards/humans';
  routeName = 'humansCards';
  aclModelName = 'humancard';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyHumanItem);
    this.setEmptyItemsState();
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class CarCardsModule extends BaseItemsStateModule<ICarCard, ICarCardFilter> {
  name = 'cards/cars';
  routeName = 'carsCards';
  aclModelName = 'carcard';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyCarItem, EmptyCarsFilter, CarsFilterSchema);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class CarCardItemModule extends BaseItemStateModule<ICarCard> {
  name = 'cards/cars';
  routeName = 'carsCards';
  aclModelName = 'carcard';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyCarItem);
    this.setEmptyItemsState();
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class CardBatchModule extends VuexModule {
  name = 'cards/batch';

  constructor(options: RegisterOptions) {
    super(options);
  }

  objectTabs(type: CardType) {
    return this.enabledObjectsByType(type).map((item) => {
      return { i18n: item, name: item };
    });
  }

  enabledObjectsByType(type: CardType) {
    const objectsConfigSection = store.state.config.objects || {};
    const result = ObjectsTypeItems.filter((v) => objectsConfigSection[v]?.enabled && CardTypesToObjects[type].includes(v));
    return result.length ? result : ['faces'];
  }

  @Action
  async createCard({ cardType, data }: ICreatedCardParams): Promise<CommonCardType | undefined> {
    if (cardType === CardType.Human) {
      return humanCardsModule.create(data);
    } else if (cardType === CardType.Car) {
      return carCardsModule.create(data);
    }
  }
}

export const createCarCardItemModule = configureDynamicStoreModuleFactory('car-card-item', (store, name) => {
  return new CarCardItemModule({ store, name });
});

export const createHumanCardItemModule = configureDynamicStoreModuleFactory('human-card-item', (store, name) => {
  return new HumanCardItemModule({ store, name });
});

export function objectsTypeToCardType(objectsType: string): CardType {
  let result = CardType.None;
  switch (objectsType) {
    case ObjectsType.Faces:
    case ObjectsType.Bodies:
      result = CardType.Human;
      break;
    case ObjectsType.Cars:
      result = CardType.Car;
      break;
  }
  return result;
}
