<template>
  <ul class="item-features">
    <li class="item-features__content" v-if="header">{{ $tf('attributes') + ':' }}</li>
    <li :key="name" v-for="{ name, value } in formattedFeatures" class="item-features__content">{{ name }}: {{ value }}</li>
  </ul>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component, Vue } from 'vue-property-decorator';

const IgnoredAttributesToTranslate = ['license_plate_number', 'make', 'model'];

const FeatureWeights = Object.freeze({
  headwear: 1,
  upper_clothes: 2,
  detailed_upper_clothes: 3,
  top_color: 4,
  lower_clothes: 5,
  bottom_color: 6
});

@Component({
  props: {
    features: { type: Object, required: true },
    objectsType: { type: String, default: 'faces' },
    header: { type: Boolean, default: false },
    showConfidence: { type: Boolean, default: true }
  }
})
export default class Features extends Vue {
  formatFeatureObjectValue = createFeatureObjectValueFormatter(
    [
      (_name, value) => value.name === 'unknown' && this.$tf('unknown,,3').toLowerCase(),
      (name, value) => IgnoredAttributesToTranslate.includes(name) && value.name,
      (name, value) => this.$t(this.computeFeatureValueI18nKey(name, value.name))
    ],
    this.showConfidence
  );
  get formattedFeatures() {
    return Object.entries(this.features)
      .map((arr) => fixFeatureName(arr))
      .filter(isDisplayableFeature)
      .sort(compareFeatureNamesAsc)
      .sort(compareFeatureNamesByWeight)
      .map(this.formatFeature);
  }

  formatFeature([name, value]) {
    return {
      name: this.$tf(this.computeFeatureNameI18nKey(name)),
      value: this.getFeatureValue(name, value)
    };
  }

  getFeatureValue(name, value) {
    if (_.isNumber(value.name)) {
      return formatFeatureNumberValue(name, value.name);
    } else if (_.isObject(value)) {
      return this.formatFeatureObjectValue(name, value);
    } else {
      return formatFeatureStringValue(name, value);
    }
  }

  computeFeatureValueI18nKey(name, valueName) {
    return `${this.computeFeatureNameI18nKey(name)}__${valueName}`;
  }

  computeFeatureNameI18nKey(name) {
    return `features.${this.objectsType}_${name}`;
  }
}

function createFeatureObjectValueFormatter(valueNameFormatters, showConfidence) {
  return function (name, payload) {
    const formattedValueName = valueNameFormatters.reduce((result, formatter) => {
      return result || formatter(name, payload);
    }, false);
    const formattedValueConfidence = payload.confidence.toFixed(2);
    return showConfidence ? `${formattedValueName}, ${formattedValueConfidence}` : `${formattedValueName}`;
  };
}

function isDisplayableFeature([, value]) {
  if (_.isObject(value)) {
    return Boolean(Number.isFinite(value.confidence) && value.confidence > 0 && value.name);
  }
  return Boolean(value);
}

function compareFeatureNamesAsc([nameA], [nameB]) {
  return nameA.localeCompare(nameB);
}

function formatFeatureNumberValue(_name, value) {
  return Math.round(value).toString();
}

function formatFeatureStringValue(_name, value) {
  return value.replace('/', ' ');
}

function compareFeatureNamesByWeight([nameA], [nameB]) {
  return compareFeatureNameWeight(nameA) - compareFeatureNameWeight(nameB);
}

function compareFeatureNameWeight(featureName) {
  return FeatureWeights[featureName] ?? 0;
}

function fixFeatureName([name, value]) {
  const oldFeature = name.indexOf('f_') === 0;
  if (oldFeature) {
    const nameContainsClass = name.indexOf(':') > 0;
    const newName = name.replace('f_', '').replace('_class', '').split(':')[0];
    const newValue = nameContainsClass ? { name: name.split(':')[1], confidence: value } : value;
    return [newName, newValue];
  } else {
    return [name, value];
  }
}
</script>

<style lang="stylus">
.item-features {
  margin: 0;
  list-style: none;
  font-size: 0.8125rem;
  word-wrap: break-word;

  &__content:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
</style>
