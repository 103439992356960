



import Component from 'vue-class-component';
import Vue from 'vue';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';
import { IBodyClusterEvent, ICarClusterEvent, IFaceClusterEvent } from '../../../../store/clusters';

type ClusterEvent = IFaceClusterEvent | IBodyClusterEvent | ICarClusterEvent;

@Component({
  name: TableComponentNames.ClusterEventObjectThumbnail,
  components: { ObjectThumbnail }
})
export default class ClusterEventObjectThumbnail extends Vue {
  @Prop({ required: true })
  item!: ClusterEvent;

  previewHandler(src: string) {
    this.$store.dispatch('showImage', { src });
  }
}
