





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import datesAreOnSameDay from './utils/dates-are-on-same-day';
import VideoPlayerControls from './video-player-controls.vue';
import VideoPlayerRender from './video-player-render.vue';
import VideoPlayerTimeline from './video-player-timeline.vue';

interface ITimelineChunk {
  from: Date;
  to: Date;
}

@Component({
  name: 'video-player',
  components: {
    VideoPlayerRender,
    VideoPlayerControls,
    VideoPlayerTimeline
  },
  props: {
    camera: {
      type: Number,
      required: true
    },
    ts: {
      type: Number,
      required: true
    },
    model: {
      type: String,
      default: 'cameras'
    }
  }
})
export default class VideoPlayer extends Vue {
  @Ref('timeline')
  private readonly playerTimeline!: VideoPlayerTimeline;
  @Ref('render')
  private readonly playerRenderer!: VideoPlayerRender;
  camera!: number;
  ts!: number;
  model!: string;

  timeStart = 0;
  wsUrl = '';
  liveModeTimer = 0;
  isLiveMode = false;
  timeline: ITimelineChunk[] = [];
  datePickerValue = new Date();
  isPaused = false;
  isCameraRemoved = false;
  fallback = '';
  helper = '';

  get isGDPREnabled() {
    return this.$store.state.config.available_video_wall_features?.gdpr;
  }

  get showBboxObjects() {
    const objects = this.$store.state.config.available_video_wall_features;
    return objects && Object.keys(objects).filter((i) => objects[i]);
  }

  playRealtime() {
    this.wsUrl = this.$store.getters.wsStreamUrlTemplate.replace('{id}', this.camera).replace('{model}', this.model);
  }

  async playArchive() {
    try {
      const archive = await this.$store.dispatch('requestApi', {
        model: 'vms/archive',
        filter: {
          camera: this.camera,
          time_from: new Date(this.timeStart * 1000),
          quality: 50
        }
      });
      this.wsUrl = archive.url;
    } catch (e) {
      console.error('video-player: playArchive error', e);
    }
  }

  async getAllTimelineData() {
    try {
      const data = await this.$store.dispatch('requestApi', {
        model: 'vms/timeline',
        filter: {
          camera: this.camera,
          time_from: new Date(0).toISOString(),
          time_to: new Date().toISOString()
        }
      });
      this.timeline = data.timeline.map((item) => ({
        from: new Date(item.time_from),
        to: new Date(item.time_to)
      }));
    } catch (e) {
      if (e.includes('does_not_exist')) {
        this.isCameraRemoved = true;
        return;
      }
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
    }
  }

  updateMode() {
    if (this.liveModeTimer) {
      clearInterval(this.liveModeTimer);
    }
    if (this.isLiveMode) {
      this.playRealtime();
      this.videoSeeked();
      this.liveModeTimer = setInterval(() => {
        this.videoPositionChange(new Date().getTime() / 1000);
      }, 200);
    } else if (!this.fallback) {
      this.playArchive();
    }
  }

  checkTimeStartVideo(timeStart) {
    if (timeStart) {
      const ts = new Date(timeStart * 1000);
      const timeStartHasVideo = this.timeline.reduce((prev: boolean, value: ITimelineChunk) => {
        return prev || (ts < value.to && ts > value.from);
      }, false);
      if (timeStartHasVideo) {
        this.fallback = '';
        this.$emit('statusChanged', 'video_found');
      } else {
        this.setTimelinePosition(ts.getTime() / 1000);
        this.fallback = this.$tf('video_player_video_not_found');
        this.$emit('statusChanged', 'video_not_found');
      }
    }
  }

  async created() {
    this.timeStart = this.ts;
    if (!this.timeStart) {
      this.isLiveMode = true;
    } else {
      this.isLiveMode = false;
    }
    await this.getAllTimelineData();
    this.checkTimeStartVideo(this.timeStart);
    this.updateMode();
  }

  beforeDestroy() {
    if (this.liveModeTimer) {
      clearInterval(this.liveModeTimer);
    }
  }

  setTimelinePosition(time) {
    if (this.playerTimeline) {
      this.playerTimeline.setPosition(time);
      this.playerTimeline.autoOffset(time);
    }
  }

  videoPositionChange(time) {
    this.setTimelinePosition(time);
    const newDate = new Date(time * 1000);
    if (!datesAreOnSameDay(this.datePickerValue, newDate)) {
      this.datePickerValue = newDate;
    }
  }

  videoSeeked() {
    if (this.playerTimeline) {
      this.playerTimeline.autoOffsetForceNext();
    }
  }

  timelinePositionChange(time) {
    if (time < new Date().getTime() / 1000) {
      if (this.isLiveMode || !this.wsUrl || this.fallback) {
        this.fallback = '';
        this.isLiveMode = false;
        this.timeStart = time;
        this.checkTimeStartVideo(this.timeStart);
        this.updateMode();
      } else {
        this.checkTimeStartVideo(time);
        if (this.fallback) {
          this.wsUrl = ''; // stop video
        } else {
          this.setTimelinePosition(time);
          this.playerRenderer.seek(time);
        }
      }
    } else {
      if (!this.isLiveMode) {
        this.isLiveMode = true;
        this.timeStart = 0;
        this.updateMode();
      }
    }
  }

  controlAction(action) {
    if (action.type === 'pause') {
      this.playerRenderer.pause(action.data);
    }
    if (action.type === 'seekRelative') {
      const realTimestamp = this.isLiveMode ? new Date().getTime() / 1000 : this.playerRenderer.realTimestamp;
      this.timelinePositionChange(realTimestamp + action.data);
    }
    if (action.type === 'seekAbsolute') {
      this.timelinePositionChange(action.data);
    }
  }

  toggleExportMode(isEnabled) {
    if (!this.isLiveMode) {
      this.playerRenderer.pause(isEnabled);
    }
  }
}
