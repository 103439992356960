import Vue from 'vue';
import { AppRouter } from './router';
import store from '../store/index';
import { createUserLoginNavigationGuard } from './user-login-navigation-guard';

let router = new AppRouter({ routes: [].concat(require('./guide').routes, require('./pages').routes) });

router.historyItems = [];

router.backTo = ({ path }) => {
  let strict = path.indexOf('filter') === -1,
    routeName = strict ? path : path.split('/').slice(0, -1).join('/'),
    checkFunction = (v) => v && (strict ? v.path === path : v.path.indexOf(routeName) > -1),
    index = router.historyItems.findIndex(checkFunction);

  if (index > -1) {
    let backIndex = index * -1;
    router.go(backIndex);
  } else {
    router.push({ path });
  }
};

function searchItemInRoot(to) {
  // this
  return (
    store.getters.menu.find((v) => Vue.prototype.$isActiveItem(to.path, v)) ||
    store.getters.menu_settings.find((v) => Vue.prototype.$isActiveItem(to.path, v))
  );
}

function searchItemInChildren(to) {
  let searchHandler = (v) => {
      return v && v.children && v.children.find((c) => Vue.prototype.$isActiveItem(to.path, c));
    },
    parent = store.getters.menu.find(searchHandler) || store.getters.menu_settings.find(searchHandler),
    child = parent && parent.children.find((v) => Vue.prototype.$isActiveItem(to.path, v));
  return [parent, child];
}

function updateRouteWsSubscriptions(to) {
  if (to.name === 'events') {
    store.dispatch('updateWsSubscriptions', { events: true, episodes: false, persons: false });
  } else if (to.name === 'episodes' || to.name === 'videosEdit') {
    store.dispatch('updateWsSubscriptions', { events: false, episodes: true, persons: false });
  } else if (to.name === 'video-wall') {
    store.dispatch('updateWsSubscriptions', { events: true, episodes: true, persons: false });
  } else if (to.name === 'persons') {
    store.dispatch('updateWsSubscriptions', { events: false, episodes: false, persons: true });
  } else {
    store.dispatch('updateWsSubscriptions', { events: false, episodes: false, persons: false });
  }
}

router.beforeEach(createUserLoginNavigationGuard(() => !!localStorage.access));

router.beforeEach((to, from, next) => {
  router.historyItems.splice(10);
  router.historyItems.unshift(to);

  let parent = searchItemInRoot(to),
    child = null;

  if (!parent) {
    const result = searchItemInChildren(to);
    parent = result[0];
    child = result[1];
  }

  store.state.app.route = to;
  store.state.app.menu.parent = parent;
  store.state.app.menu.child = child;
  document.title = to?.meta?.i18n ? router.app.$tf(to.meta.i18n) : to.name;

  const shouldUpdateWsSubscription = to.name !== from.name;
  if (shouldUpdateWsSubscription) updateRouteWsSubscriptions(to);
  next();
});

router.beforeEach((to, from, next) => {
  if (doesUserRequestEventsRouteFromMenu(to)) {
    next(computeNextEventsPageLocation(from));
  } else {
    next();
  }
});

function doesUserRequestEventsRouteFromMenu(to) {
  return to.fullPath === '/events';
}

function computeNextEventsPageLocation(from) {
  const objects = computeNextObjectsType(from);
  const filter = computeNextStringifiedFilter(from);
  return { name: 'events', replace: true, params: { objects, filter } };
}

function computeNextObjectsType(from) {
  return doesUserRequestEventsRouteFromEventsPage(from) ? from.params.objects : store.getters.defaultObjects;
}

function computeNextStringifiedFilter(from) {
  return doesUserRequestEventsRouteFromEventsPage(from) ? 'limit=20&no_match=False' : 'limit=20';
}

function doesUserRequestEventsRouteFromEventsPage(from) {
  return from.name === 'events';
}

export default router;
