<template>
  <div class="kyc-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('common.start')" prop="created_date_gte">
        <el-date-picker name="created-date-gte" v-model="filter.created_date_gte" type="date" align="right"> </el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.end')" prop="created_date_lte">
        <el-date-picker name="created-date-lte" v-model="created_date_lte" type="date" align="right"> </el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.precision')">
        <el-select name="precision" v-model="filter.precision">
          <el-option
            v-for="item in $store.state.kyc.precision"
            :key="'precision-' + item"
            :label="$tt.apply(null, state.DateIntervalToI18n[item])"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('common.return_interval')">
        <el-select name="return_interval" v-model="filter.return_interval">
          <el-option v-for="item in returnInterval" :key="'return_interval-' + item" :label="$tt.apply(null, state.DateIntervalToI18n[item])" :value="item">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('common.returns_number')">
        <el-select name="returns_number" v-model="filter.returns_number">
          <el-option v-for="item in $store.state.kyc.returns_number" :key="'returns_number-' + item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('common.cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('common.watch_lists')">
        <el-select name="matched-lists" v-model="filter.watch_lists" multiple :placeholder="$tf('common.not_selected')" :multiple-limit="10">
          <el-option
            v-for="listItem in $store.state.watch_lists.items"
            :key="'watchlist-' + listItem.id"
            :value="listItem.id"
            :label="$filters.shortString(listItem.name)"
          >
            <watchlist-option :item="listItem"></watchlist-option>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('watch_lists')" v-if="hasFilter('watch_lists')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('age')">
        <div class="age-block">
          <el-select name="age-from" v-model="filter.age_gte" :clearable="true" :filterable="true" :placeholder="$tf('common.from')">
            <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
          <el-select name="age-to" v-model="filter.age_lte" :clearable="true" :filterable="true" :placeholder="$tf('common.to,,1')">
            <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
              <span>{{ item }}</span>
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item :label="$tf('gender')">
        <el-select name="precision" v-model="filter.gender">
          <el-option v-for="item in $store.state.kyc.gender" :key="'precision-' + item" :label="$tf(item || 'all')" :value="item"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import { setDateEndOfDayTime, setFilterTodayAndTwoWeeksAgoDates } from '@/store/kyc/kyc.helpers.js';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { createModelFilterMixin } from '../common/model-filter-mixin-factory';
import WatchlistOption from '../watch-lists/option.vue';

const parseInt10 = (v) => parseInt(v, 10);
const mixin = createModelFilterMixin({
  routeName: 'kyc',
  stateProp: 'kyc',
  storageProp: 'kyc.filter.current',
  fieldSchema: {
    chart_types: { multiple: true },
    cameras: { multiple: true, identity: parseInt10 },
    watch_lists: { multiple: true, identity: parseInt10 }
  },
  fieldPredicate: Boolean
});

@Component({
  name: 'kyc-filter',
  mixins: [mixin],
  components: { WatchlistOption }
})
export default class extends Vue {
  get returnInterval() {
    const maxIndex = this.state.precision_to_return_interval[this.filter.precision] || 1;
    return this.state.return_interval.slice(0, maxIndex);
  }

  get state() {
    return this.$store.state.kyc;
  }

  get filter() {
    return this.state.filter.current;
  }

  get created_date_lte() {
    return this.filter.created_date_lte;
  }

  set created_date_lte(created_date_lte) {
    this.filter.created_date_lte = setDateEndOfDayTime(created_date_lte);
  }

  get hasFilters() {
    const difference = _.differenceOf(this.$store.state.kyc.filter.current, this.$store.state.kyc.filter.empty);
    return !!Object.keys(difference).length;
  }

  beforeCreate() {
    setFilterTodayAndTwoWeeksAgoDates(this.$store.state.kyc.filter.current);
  }

  created() {
    this.loadItems = _.debounce(this.loadItems, 1000);
    this.state.prev_page = this.filter.page ? [''] : [];
  }

  @Watch('returnInterval')
  handleReturnIntervalChange(v) {
    if (v.indexOf(this.filter.return_interval) === -1) {
      this.filter.return_interval = v[v.length - 1];
    }
  }

  loadItems() {
    this.$store.dispatch('get_kyc_items');
  }

  clearFilter(v) {
    this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
  }

  filterCameras(v) {
    const cameras = v ? this.$store.state.cameras.items.filter((i) => i.name.indexOf(v) > -1) : this.$store.state.cameras.items;
    this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
  }

  clearFilters() {
    this.state.filter.current = _.cloneDeep(this.state.filter.empty);
  }

  hasFilter(v) {
    return !_.isEqual(this.filter[v], this.state.filter.empty[v]);
  }
}
</script>

<style lang="stylus">
.kyc-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }

  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;

    .el-checkbox {
      margin: 0;
      flex: 0 0 50%;
    }
  }

  .age-block {
    display: flex;
    justify-content: space-between;

    .el-select {
      flex: 0 0 45%;
    }
  }
}
</style>
