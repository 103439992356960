import _ from '@/apps/common/lodash';
import getCrud from '../../get.crud';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    card: null,
    limit: 100,
    ordering: null,
    created_date_gte: null,
    created_date_lte: null,
    threshold: 0.75
  }),
  emptyItem = Object.seal({
    id: null,
    created_by: 0,
    created_date: null,
    modified_date: null,
    reason: null,
    last_search_date: null,
    last_results_count: 0,
    search_params: {
      limit: 100,
      threshold: 0.75,
      created_date_gte: null,
      created_date_lte: null
    },
    is_deleted: false,
    save_results: false
  }),
  Name = 'puppeteer/search',
  Action = require('../../action.names').default.PuppeteerSearch;

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    prev_page: [],
    limits: [10, 20, 50, 100, 200, 500],
    items: [],
    item: {
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: { ...getCrud(Name), refreshSearch }
};

function refreshSearch({ dispatch, rootState: { Action } }, { id }) {
  return dispatch(Action.RequestApi, { model: Name, method: 'POST', subaction: 'update_search', id });
}
