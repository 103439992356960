<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-input :value="inputValue" @input="handleInputValue" />
  </filter-form-clear-button>
</template>

<script>
import { Component, Watch } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';
import { Debounce } from '@/apps/common/decorators/lodash';

@Component({
  props: { number: { type: Boolean, default: false } }
})
export default class FilterFormComponentInputIDs extends FilterFormClearableComponent {
  inputValue = '';

  handleInputValue(value) {
    this.inputValue = value;
    this.parseIDs(value);
  }

  @Debounce(1000)
  parseIDs(value) {
    const ids = [...new Set(value.split(/[\s,;]+/).filter((item) => item.trim().match(/^\d+$/)))];
    this.setModelValue(ids);
  }

  setModelValue(value) {
    this.clearLinkedFields((this.model = value));
  }

  @Watch('model')
  watchModel(newValue) {
    this.inputValue = newValue.join(', ');
  }
}
</script>
