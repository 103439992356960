import { BaseItemsState, ItemStorage, StateFilter } from '@/definitions/base.state';
import { DataService } from '@/services/data.services';
import { AclPrefix, AutoUpdateIntervalInMs, getFilterString, getPageFromNextPage, IIntervalData } from '@/definitions/base.items.state.module';
import { AnyFieldOfTypeWithId } from '@/definitions/base.actions';
import { RawLocation } from 'vue-router';
import _ from '@/apps/common/lodash';

export class ListViewModel<T, F> implements BaseItemsState<T, F> {
  name = 'base';
  routeName = 'baseRouteName';
  aclModelName: string | undefined;
  version = 2;
  syncFiltersToRoute = false;

  playing = false;
  loading = false;
  appending = false;
  loaded = false;
  loadError: any | null = null;
  items: T[] = [];
  selectedItems: T[] = [];
  itemHandler?: (...args: any[]) => any;
  protected _dataService: DataService<T, F> | undefined;

  autoUpdate: IIntervalData = {
    enabled: false,
    intervalInMs: AutoUpdateIntervalInMs,
    intervalIndex: 0
  };

  item: ItemStorage<T> = {
    current: {} as T,
    empty: {} as T
  };

  page = '';
  next_page: string | null = null;
  prev_page: string[] = [];
  limits: number[] = [10, 20, 50, 100, 200, 500];

  filter: StateFilter<F> = {
    current: {} as F,
    empty: {} as F,
    schema: null
  };

  constructor() {}

  set dataService(v: DataService<T, F>) {
    this._dataService = v;
  }

  get dataService(): DataService<T, F> {
    if (!this._dataService) {
      throw new Error('Data Service should be initialized');
    } else {
      return this._dataService;
    }
  }

  get hasAcl(): boolean {
    return !!this.aclModelName;
  }

  get aclViewPermissionName(): string {
    return `${AclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${AclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${AclPrefix}.edit_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${AclPrefix}.delete_${this.aclModelName}`;
  }

  init(emptyItem: T, emptyFilter: F, filterSchema?: any) {
    this.filter.empty = _.cloneDeep(emptyFilter);
    this.filter.current = _.cloneDeep(emptyFilter);
    this.filter.schema = filterSchema;
    this.item.empty = _.cloneDeep(emptyItem);
  }

  resetBeforeGet(): void {
    this.selectedItems = [];
  }

  async get(resetState = true): Promise<boolean> {
    if (this.loading || this.appending) return false;
    if (resetState) this.items = [];
    this.setLoading(true);

    try {
      const responseData = await this.dataService.getList(this.filter.current);
      this.items = responseData.results || [];
      this.next_page = responseData.next_page;
    } catch (e) {
      this.loadError = e;
    }

    this.setLoading(false);
    this.setAutoUpdate(this.autoUpdate.enabled);
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  async dispatch(action: string, payload: any): Promise<any> {
    throw new Error('[list.view.model] dispatch must be override by store dispatch or mock dispatch');
  }

  setLoading(value: boolean) {
    this.loading = value;

    if (this.loading) {
      this.loadError = null;
    } else {
      this.loaded = true;
    }
  }

  setAutoUpdate(value: boolean, intervalInMs?: number) {
    clearInterval(this.autoUpdate.intervalIndex);
    this.autoUpdate.enabled = value;
    this.autoUpdate.intervalInMs = intervalInMs || this.autoUpdate.intervalInMs;
    if (value) {
      this.autoUpdate.intervalIndex = setInterval(() => this.get(false), this.autoUpdate.intervalInMs);
    }
  }

  async append(): Promise<boolean> {
    const nextPage = this.next_page && getPageFromNextPage(this.next_page);
    if (!nextPage) return false;
    if (this.loading || this.appending) return false;

    this.appending = true;
    this.loadError = null;

    try {
      const result = await this.dataService.getList({ ...this.filter.current, page: nextPage });
      this.items = result.results || [];
    } catch (e) {
      this.loadError = e;
    }

    this.appending = false;
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  async create(data: Partial<T>): Promise<T> {
    const result = await this.dataService.create(data);
    return result;
  }

  async update(data: AnyFieldOfTypeWithId<T>): Promise<T> {
    const id: any = data.id;
    const result = await this.dataService.update(id, data);
    return result;
  }

  async delete(id: string | number): Promise<boolean> {
    const result = await this.dataService.delete(id);
    return true;
  }

  getNewItemRoute() {
    return {
      name: `${this.routeName}Create`
    };
  }

  getItemRoute(item: T): RawLocation {
    const id = (item as AnyFieldOfTypeWithId<T>).id;
    const params = {
      id: id,
      item: item
    };
    return {
      name: `${this.routeName}Edit`,
      params: params as any
    };
  }

  getListRoute(filter): RawLocation {
    return {
      name: `${this.routeName}`,
      params: {
        filter: getFilterString(filter, this.filter.empty)
      }
    };
  }

  dispose() {
    this.setAutoUpdate(false);
    this._dataService = undefined;
  }

  getModuleActions() {
    return {
      get: ({ rootState, state, dispatch }, payload) => this.get(payload),
      create: ({ rootState, state, dispatch }, payload) => this.create(payload),
      update: ({ rootState, state, dispatch }, payload) => this.update(payload),
      delete: ({ rootState, state, dispatch }, payload) => this.delete(payload)
    };
  }
}
