<template></template>

<script>
import { Component } from 'vue-property-decorator';
import FilterFormClearButton from './filter-form-clear-button';
import FilterFormComponent from './filter-form-component';

@Component({
  components: { FilterFormClearButton }
})
export default class FilterFormClearableComponent extends FilterFormComponent {
  get isFieldClear() {
    return this.form.isFieldClear(this.fieldName);
  }

  clearField(field) {
    const fieldsForClear = field || this.fieldName;
    this.form.clearFields([fieldsForClear]);
  }
}
</script>
