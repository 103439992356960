<template>
  <filter-form ref="filterForm" v-bind="schema" :key="objectsType" />
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import FilterForm from '../filter-form/filter-form';

const temperatures = [35.0, 36.0, 36.6, 37.0, 37.5, 38.0, 38.5, 39.0, 40.0, 41.0];

@Component({ components: { FilterForm } })
export default class EventsFilter extends Vue {
  Temperatures = temperatures;

  get objectsType() {
    return this.$route.params.objects ?? 'faces';
  }

  get schema() {
    return this.$store.getters[`${this.objectsType}_filter_schema`];
  }

  @Watch('$store.state.faces_events.commandItems')
  handleCommandItemsChange(v, p) {
    const hasItems = v?.length;
    if (!hasItems) return;
    this.$notify({ type: 'success', message: this.$tf('events | were | acknowledged,,1'), position: 'top-right' });
    this.$refs?.filterForm?.syncStateItems();
  }
}
</script>
