<template>
  <div class="content">
    <events-demo-page class="demo-events-page"></events-demo-page>
    <events-demo-page class="demo-events-page"></events-demo-page>
    <events-demo-page class="demo-events-page"></events-demo-page>
  </div>
</template>

<script>
import EventsDemoPage from '@/guide/components/events.demo.page.vue';

export default {
  name: 'demo-events',
  components: { EventsDemoPage },
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/stylus">
.demo-events-page {
  position: relative;
  min-height: 20rem;
  margin: 2rem;
  border: 2px solid red;
  max-height: 640px;
  overflow: scroll;
}
</style>
