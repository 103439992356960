import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import store from '../index';
import { CardType } from '@/store/cards/cards';
import { IRelation } from '@/store/relations/relations';

export interface IRelationLink {
  id: number;
  card: number | null;
  relation: number | null | IRelation;
  name: string;
}

export interface IRelationLinkFilter {
  card?: number;
  relation?: number;
  name?: string;
  name_contains?: string;
  id_in?: number[];
  limit?: number;
}

export const EmptyItem: IRelationLink = {
  id: 0,
  card: 0,
  relation: { id: 0, name: '' },
  name: ''
};

export const EmptyFilter: IRelationLinkFilter = {
  limit: 10
};

@Module({ generateMutationSetters: true })
export class RelationLinksModule extends BaseItemsStateModule<IRelationLink, IRelationLinkFilter> {
  destroy: () => void;

  constructor(options: RegisterOptions) {
    super(options);
    this.setCardType(CardType.Human);
    this.init(EmptyItem, EmptyFilter);
    // TODO: move to BaseItemsStateModule
    this.destroy = () => {
      options.store.unregisterModule(options.name);
    };
  }

  setCardType(cardType: CardType) {
    switch (cardType) {
      case CardType.Human:
        this.name = 'relation-links/human';
        this.routeName = 'relation_links_human';
        break;
      case CardType.Car:
        this.name = 'relation-links/car';
        this.routeName = 'relation_links_car';
        break;
    }
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}
