




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { ClusterEventsBodyListModule, ClusterEventsCarListModule, ClusterEventsFaceListModule } from '@/store/clusters';

@Component({
  name: 'cluster-events-filter',
  components: { FilterForm }
})
export default class ClusterEventsFilter extends Vue {
  get state(): ClusterEventsFaceListModule | ClusterEventsBodyListModule | ClusterEventsCarListModule {
    const objects = this.$route.params.objects;
    return this.$store.state[`cluster-events/${objects}`];
  }
}
