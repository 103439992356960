

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import VideoPlayerButton from '@/components/video-player/video-player-button.vue';

@Component({
  name: 'video-player-controls',
  components: { VideoPlayerButton }
})
export default class VideoPlayerControls extends Vue {
  @Prop({ type: String, default: '' })
  helper?: string;

  @Prop({ type: Boolean, required: true })
  paused!: boolean;

  @Prop({ type: Boolean, required: true })
  liveMode!: boolean;

  play() {
    if (this.liveMode) return;
    this.$emit('action', { type: 'pause', data: false });
  }

  pause() {
    if (this.liveMode) return;
    this.$emit('action', { type: 'pause', data: true });
  }

  seek(delta) {
    if (this.liveMode && delta > 0) return;
    this.$emit('action', { type: 'seekRelative', data: delta });
  }

  goToLiveMode() {
    this.$emit('action', { type: 'seekAbsolute', data: new Date().getTime() / 1000 + 100 });
  }
}
