





import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.ActiveComponent,
  props: {
    item: {
      type: Object,
      required: true
    },
    changeHandler: {
      type: Function,
      required: true
    }
  }
})
export default class TableGeneratorActiveComponent extends Vue {
  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: Function, required: true })
  changeHandler!: any;

  loading = false;

  async changeActiveHandler(v: boolean) {
    if (this.loading) return;
    this.loading = true;
    try {
      const result = await this.changeHandler(this.item, v);
      if (result) this.item.active = v;
      else console.warn('[change:active] request error');
    } catch (e) {
      console.warn('[change:active] error ', e);
    }
    this.loading = false;
  }
}
