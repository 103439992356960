








import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { HumanCardsModule } from '@/store/cards/cards';
import MetaField from '@/components/cards/form/metafield.vue';

@Component({
  name: 'human-cards-filter',
  components: { FilterForm, MetaField }
})
export default class HumanCardsFilter extends Vue {
  filters = [];

  get state(): HumanCardsModule {
    return this.$store.state['cards/humans'] as HumanCardsModule;
  }

  get metaItems() {
    return this.$store.state.config.human_card.items;
  }

  get metaFieldsToReset() {
    return this.filters.map((filter) => filter.field);
  }

  getFiltersWithType() {
    const filters = this.$store.state.config.human_card?.filters;
    if (filters instanceof Array) {
      return filters.map((filter) => Object.assign(filter, { type: this.getFilterTypeByName(filter.name) }));
    }
    return [];
  }

  getFilterTypeByName(name: string) {
    return this.metaItems.find((item) => item.name === name).type;
  }

  created() {
    this.filters = this.getFiltersWithType();
    this.filters.forEach((filter) => Vue.set(this.state.filter.current, filter.name, this.state.filter.current[filter.field] ?? ''));
  }
}
