import _ from '@/apps/common/lodash';
import Vue from 'vue';
import { ObjectsType, ObjectsTypeSingleForm } from '../objects/get.module';

const Name = 'detect';

const AttributeName = {
  Car: 'car',
  Body: 'body',
  Face: 'face'
};

const ObjectsToAttributeName = {
  faces: 'face',
  bodies: 'body',
  cars: 'car'
};

const FeaturesByAttributeName = {
  [AttributeName.Face]: {},
  [AttributeName.Body]: {},
  [AttributeName.Car]: {
    description: true,
    special_vehicle_type: true
  }
};

const thumbnail = { w: 160, h: 160 };

const DetectType = {
  Manual: 'manual',
  Auto: 'auto'
};

const EmptyState = {
  objects: null,
  items: [],
  image: {
    url: null
  },
  orientation: 0,
  selectedIndex: 0,
  loading: false
};

export default {
  state: {
    Name,
    deduplication_dialog: {
      resolveHandler: null,
      rejectHandler: null,
      enabled: false
    },
    dialog: {
      resolvePromise: null,
      rejectPromise: null,
      enabled: false
    },
    objects: null,
    items: [],
    current: {
      detect: null,
      duplicate: null
    },
    image: {
      url: null
    },
    orientation: 0,
    selectedIndex: 0,
    loading: false
  },
  actions: {
    getDetectObjectsState,
    detectObjects,
    manualDetect,
    searchSameObjects,
    choose_deduplication
  }
};

async function manualDetect({ state }) {
  const dialogPromise = new Promise((r, j) => {
    state.dialog.resolveHandler = r;
    state.dialog.rejectHandler = j;
  });
  state.dialog.enabled = true;
  return await dialogPromise;
}

async function choose_deduplication({ state }, { detect, duplicate }) {
  const dialogPromise = new Promise((r, j) => {
    state.deduplication_dialog.resolveHandler = r;
    state.deduplication_dialog.rejectHandler = j;
  });
  state.current.detect = detect;
  state.current.duplicate = duplicate;
  state.deduplication_dialog.enabled = true;
  const result = await dialogPromise;
  state.deduplication_dialog.enabled = false;
  return result;
}

function clear(state) {
  state.dialog.resolveHandler = null;
  state.dialog.rejectHandler = null;

  state.selectedIndex = 0;
  state.items = [];
  state.loading = false;
}

function getDetectAttributes(rootState, attributeName, features) {
  const result = { [attributeName]: features ?? FeaturesByAttributeName[attributeName] };
  if (!features && Object.values(AttributeName).includes(attributeName)) {
    const availableFeatures = rootState.config.available_detect_features?.[attributeName] || [];
    for (const featureName in result[attributeName]) {
      result[attributeName][featureName] = availableFeatures.includes(featureName);
    }
  }

  return result;
}

async function detectObjects({ state, rootState, getters, dispatch }, { file, detectType = DetectType.Manual, objects = ObjectsType.Faces }) {
  const formData = new FormData(),
    filename = (file && file.filename) || undefined,
    attributeName = ObjectsToAttributeName[objects] || ObjectsToAttributeName.faces;

  formData.append('photo', file, filename);
  formData.append('attributes', JSON.stringify(getDetectAttributes(rootState, attributeName)));

  try {
    clear(state);
    state.objects = objects;
    state.loading = true;
    const results = await dispatch('requestApi', { model: 'detect', method: 'post', data: formData });
    const items = results.objects[attributeName];
    state.items = excludeLowQualityItems(items);
    state.unsupportedItems = includeLowQualityItems(items);
    state.orientation = results.orientation;
    state.image.url = URL.createObjectURL(file);

    if (state.items.length > 1) {
      if (detectType === DetectType.Manual) state.selectedIndex = await dispatch('manualDetect');
    } else if (state.items.length === 0 && state.unsupportedItems.length > 0) {
      return Promise.reject(new Error(Vue.prototype.$tf('has_no_good_quality_objects')));
    } else if (state.items.length === 0) {
      return Promise.reject(new Error(Vue.prototype.$tf('has_no_objects_detected')));
    } else {
      state.selectedIndex = 0;
    }
  } catch (e) {
    return Promise.reject(e);
  } finally {
    state.dialog.enabled = false;
    state.loading = false;
  }

  const item = state.items[state.selectedIndex],
    thumbnail = item
      ? await createThumbnailFromDetect(item, state.orientation, getters.isBrowserOrientationOld, {
          url: state.image.url
        })
      : null,
    result = {
      id: item?.id,
      thumbnail
    };

  return result;
}

async function getDetectObjectsState({ rootState, dispatch }, { file, objects = ObjectsType.Faces, features }) {
  const formData = new FormData(),
    filename = (file && file.filename) || undefined,
    attributeName = ObjectsToAttributeName[objects] || ObjectsToAttributeName.faces,
    state = _.cloneDeep(EmptyState);

  formData.append('photo', file, filename);
  formData.append('attributes', JSON.stringify(getDetectAttributes(rootState, attributeName, features)));

  try {
    state.objects = objects;
    state.loading = true;
    const results = await dispatch('requestApi', { model: 'detect', method: 'post', data: formData });
    const items = results.objects[attributeName];

    state.items = excludeLowQualityItems(items);
    state.unsupportedItems = includeLowQualityItems(items);
    state.orientation = results.orientation;
    state.image.url = URL.createObjectURL(file);
    state.selectedIndex = 0;

    if (state.items.length === 0 && state.unsupportedItems.length > 0) {
      return Promise.reject(new Error(Vue.prototype.$tf('has_no_good_quality_objects')));
    }
    if (state.items.length === 0) {
      return Promise.reject(new Error(Vue.prototype.$tf('has_no_objects_detected')));
    }
  } catch (e) {
    return Promise.reject(e);
  } finally {
    state.loading = false;
  }

  return state;
}

async function searchSameObjects({ state, getters, rootState, dispatch }, { objects, id, excludedCardId }) {
  const objectType = ObjectsTypeSingleForm[objects];
  const cardType = objectType === 'car' ? 'cars' : 'humans';
  const threshold = getters.objectConfidenceThresholds[`${objectType}_confidence_threshold`];

  state.loading = true;
  return dispatch('requestApi', {
    model: `cards/${cardType}`,
    method: 'get',
    filter: { looks_like: `detection:${id}`, threshold, limit: 100, ordering: '-looks_like_confidence' }
  })
    .then((v) => {
      const results = v.results?.filter((v) => v.id !== excludedCardId) || [];
      const matchedCard = results[0];
      const getObjectByIdPromise =
        matchedCard && dispatch('requestApi', { model: `objects/${objects}`, id: matchedCard.looks_like.matched_object }).then((v) => [v]);
      return getObjectByIdPromise || [];
    })
    .catch((e) => {
      return Promise.reject(e);
    })
    .finally(() => {
      state.loading = false;
    });
}

function createThumbnailFromDetect(item, orientation, isBrowserOrientationOld, { file, url }) {
  let image = new Image(),
    canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    resolveHandler,
    promise = new Promise((r) => {
      resolveHandler = r;
    });

  canvas.width = thumbnail.w;
  canvas.height = thumbnail.h;
  image.src = url ? url : URL.createObjectURL(file);

  image.onload = () => {
    const bbox = enlargeBBox(item.bbox, image.width, image.height, 0.25);
    const bboxWidth = bbox.right - bbox.left;
    const bboxHeight = bbox.bottom - bbox.top;
    let sx, sy, sWidth, sHeight;
    if (orientation === 8 && isBrowserOrientationOld) {
      sx = image.width - bbox.bottom;
      sy = bbox.left;
      sWidth = bboxHeight;
      sHeight = bboxWidth;
    } else if (orientation === 6 && isBrowserOrientationOld) {
      sx = bbox.top;
      sy = image.height - bbox.right;
      sWidth = bboxHeight;
      sHeight = bboxWidth;
    } else if (orientation === 3 && isBrowserOrientationOld) {
      sx = image.width - bbox.right;
      sy = image.height - bbox.bottom;
      sWidth = bboxWidth;
      sHeight = bboxHeight;
    } else {
      sx = bbox.left;
      sy = bbox.top;
      sWidth = bboxWidth;
      sHeight = bboxHeight;
    }
    const scaledBbox = scaleBboxForThumbnail({ w: bboxWidth, h: bboxHeight }, thumbnail);
    ctx.drawImage(image, sx, sy, sWidth, sHeight, scaledBbox.offsetX, scaledBbox.offsetY, scaledBbox.w, scaledBbox.h);
    canvas.toBlob((blob) => {
      resolveHandler(URL.createObjectURL(blob));
    });
    image.onload = null;
  };

  return promise;
}

function scaleBboxForThumbnail(bbox, thumbnail) {
  const factor = computeBboxScalingFactor(bbox, thumbnail);
  const scaledBboxW = bbox.w * factor;
  const scaledBboxH = bbox.h * factor;
  const offsetX = computeScaledBboxDimensionOffset(scaledBboxW, thumbnail.w);
  const offsetY = computeScaledBboxDimensionOffset(scaledBboxH, thumbnail.h);
  return { w: scaledBboxW, h: scaledBboxH, offsetX, offsetY };
}

function computeBboxScalingFactor(bbox, thumbnail) {
  const factorW = thumbnail.w / bbox.w;
  const factorH = thumbnail.h / bbox.h;
  return Math.min(factorW, factorH);
}

function computeScaledBboxDimensionOffset(scaledBboxDimension, thumbnailDimension) {
  return Math.round((thumbnailDimension - scaledBboxDimension) / 2);
}

function enlargeBBox(bbox, imageWidth, imageHeight, factor) {
  const diffW = (bbox.right - bbox.left) * factor;
  const diffH = (bbox.bottom - bbox.top) * factor;
  return {
    top: Math.max(bbox.top - diffH, 0),
    left: Math.max(bbox.left - diffW, 0),
    right: Math.min(bbox.right + diffW, imageWidth),
    bottom: Math.min(bbox.bottom + diffH, imageHeight)
  };
}

function excludeLowQualityItems(v) {
  return (v || []).filter((i) => !i.low_quality);
}

function includeLowQualityItems(v) {
  return (v || []).filter((i) => i.low_quality);
}
