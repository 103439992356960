


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { SilhouettesBboxType } from '@/store/counter-records/types';

@Component
export default class CounterDistanceLines extends Vue {
  @Prop({ default: () => ({}) }) box!: SilhouettesBboxType;
  @Prop(String) lineColor!: string;

  get hasDistances() {
    return !!this.box.distances.length;
  }
}
