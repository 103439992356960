import _ from '@/apps/common/lodash';

export default {
  state: {
    menu: require('../menu.json'),
    menu_settings: require('../menu.settings.json'),
    user_menu: ['launcher', 'events', 'episodes']
  },
  getters: {
    menu: function (state, getters, rootState) {
      const microservicesMainMenu = rootState.microservices?.main_menu ?? [];
      return [...state.menu, ...microservicesMainMenu];
    },
    menu_settings: function (state, getters, rootState) {
      const settings = _.cloneDeep(state.menu_settings);
      const microservicesSettingsMenu = rootState.microservices?.settings_menu ?? [];
      const toolsItemIndex = settings[0].children.findIndex((v) => v.name === 'tools');
      settings[0].children.splice(toolsItemIndex, 0, ...microservicesSettingsMenu);
      return settings;
    }
  },
  actions: {
    toggleItemLauncher({ state, dispatch }, name) {
      const menuItemIndex = state.user_menu.indexOf(name);
      if (menuItemIndex !== -1) {
        state.user_menu.splice(menuItemIndex, 1);
      } else {
        state.user_menu.push(name);
      }
      dispatch('saveUserInfo', { keyName: 'launcher.user_menu' });
    },
    setAllLauncher({ state, dispatch }, items) {
      state.user_menu = items;
      dispatch('saveUserInfo', { keyName: 'launcher.user_menu' });
    },
    setDefaultLauncher({ state, dispatch }) {
      state.user_menu = ['launcher', 'events', 'episodes'];
      dispatch('saveUserInfo', { keyName: 'launcher.user_menu' });
    }
  }
};
