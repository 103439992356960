


import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getNumberValidator, getArrayValidator, getStringValidator } from '@/apps/common/validator';
import { IFormFieldValidator } from '@/definitions/base.form.schema';

@Component({
  name: 'base-form-component'
})
export default class BaseFormComponent extends Vue {
  @Prop({ type: Object, required: true })
  form!: any;

  @Prop({ type: String, required: true })
  fieldName!: string;

  @Prop({ type: String, default: '' })
  i18nPlaceholder?: string;

  @Prop({ type: Array, default: () => [] })
  clearFieldsOnChange?: string[];

  getTranslatedRule(validator: IFormFieldValidator) {
    return [validator].map((v) => {
      const rule = { required: v.required, trigger: v.trigger, validator: null, tmessage: null, message: null };

      switch (v.name) {
        case 'number':
          rule.validator = getNumberValidator({ ...v.props, required: v.required });
          rule.tmessage = this.$tf(v.props?.i18nMessage);
          break;
        case 'array':
          rule.validator = getArrayValidator({ ...v.props, required: v.required });
          rule.tmessage = this.$tf(v.props?.i18nMessage);
          break;
        case 'string':
          rule.validator = getStringValidator({ ...v.props });
          rule.tmessage = this.$tf(v.props?.i18nMessage);
          break;
        default:
          rule.message = this.$tf(v.props?.i18nMessage);
          break;
      }

      return rule;
    });
  }

  get model() {
    return this.form.getCurrentFieldValue(this.fieldName);
  }

  set model(fieldValue) {
    this.form.setCurrentFieldValue(this.fieldName, fieldValue);
  }

  get placeholder() {
    return this.i18nPlaceholder && this.$tf(this.i18nPlaceholder);
  }

  clearLinkedFields() {
    this.form.clearFields(this.clearFieldsOnChange);
  }
}
