import _ from '@/apps/common/lodash';
import getCrud from '../../get.crud';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    card: null,
    limit: 100,
    ordering: null,
    created_date_gte: null,
    created_date_lte: null
  }),
  emptyItem = Object.seal({
    id: null,
    created_by: 0,
    created_date: null,
    modified_date: null,
    reason: null,
    card: 0
  }),
  Name = 'puppeteer/remote-monitoring',
  Action = require('../../action.names').default.PuppeteerRemoteMonitoring;

async function removeFromMonitoring({ state, dispatch }, { cardIDs, vueContext }) {
  await vueContext.$confirm(vueContext.$tf('remove_from_monitoring') + '?', vueContext.$tfo('warning'), {
    confirmButtonText: vueContext.$tfo('ok'),
    cancelButtonText: vueContext.$tf('cancel'),
    type: 'warning'
  });
  const allMonitorings = await dispatch(state.Action.Get, { filter: { limit: 1e6 } });

  const promises = cardIDs.map((cardId) => {
    const item = allMonitorings.results.find((el) => el.card === cardId && !el.is_deleted);
    return item ? dispatch(state.Action.Delete, { id: item.id }) : Promise.resolve(-1);
  });

  try {
    await Promise.all(promises);
    vueContext.$notify({ type: 'success', message: vueContext.$tf(['common.action', 'common.success']) });
  } catch (e) {
    vueContext.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
  }
}

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    prev_page: [],
    limits: [10, 20, 50, 100, 200, 500],
    items: [],
    item: {
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: { removeFromMonitoring, ...getCrud(Name) }
};
