import { getObjectModule, ObjectsType } from './get.module';
import axios from 'axios';

const module = getObjectModule(ObjectsType.Faces);
module.actions = { ...module.actions, uploadObject };
export default module;

function uploadObject({ rootState, state, dispatch }, payload) {
  const objects = payload.objects || ObjectsType.Faces;
  let options = {
      url: rootState.config.server.url + `objects/${objects}/`,
      method: 'POST',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(rootState.app.token),
        'Accept-Language': rootState.app.acceptLanguage
      },
      timeout: 60000,
      data: null
    },
    formData = new FormData();

  formData.append('card', payload.card);
  payload.create_from && formData.append('create_from', payload.create_from);
  payload.source_photo && formData.append('create_from', payload.source_photo);
  payload.mf_selector && formData.append('mf_selector', payload.mf_selector);
  payload.upload_list && formData.append('upload_list', payload.upload_list);
  options.data = formData;

  return axios(options)
    .then((v) => {
      return Promise.resolve(v);
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });
}
