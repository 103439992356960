








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'card-relations'
})
export default class CardRelations extends Vue {
  @Prop({ required: true })
  item!: any;

  get title() {
    return this.$tf('connections') + ': ' + this.item.links_to_relations?.length;
  }

  get description() {
    const s = this.item.links_to_relations.map((v) => v.name).join(', ');
    return s.length > 60 ? s.substr(0, 60) + '...' : s;
  }
}
