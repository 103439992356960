





import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IFaceClusterEvent, IBodyClusterEvent, ICarClusterEvent } from '@/store/clusters';
import { getFilterString } from '@/definitions/base.items.state.module';
import Features from '@/components/common/features.vue';

type ClusterEvent = IFaceClusterEvent | IBodyClusterEvent | ICarClusterEvent;

@Component({
  name: TableComponentNames.FeaturesContent,
  components: { Features }
})
export default class FeaturesColumn extends Vue {
  @Prop({ required: true })
  item!: IFaceClusterEvent;

  @Prop({ required: true })
  objectsType!: string;
}
