<template>
  <div class="level-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('use | last,,2 | event')">
        <el-checkbox v-model="filter.use_last_event" @change="clearOnChange('use_last_event')" :key="'use_last_event'" />
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('use_last_event')"
          v-if="hasFilter('use_last_event')"
        />
      </el-form-item>

      <el-form-item :label="$tf('relations | threshold') + ', s'">
        <el-select
          name="contact_threshold_seconds"
          v-model="filter.contact_threshold_seconds"
          :allow-create="true"
          @change="clearOnChange('contact_threshold_seconds')"
          placeholder=""
        >
          <el-option :value="1" label="1"></el-option>
          <el-option :value="3" label="3"></el-option>
          <el-option :value="5" label="5"></el-option>
          <el-option :value="10" label="10"></el-option>
          <el-option :value="20" label="20"></el-option>
          <el-option :value="30" label="30"></el-option>
          <el-option :value="60" label="60"></el-option>
          <el-option :value="120" label="120"></el-option>
        </el-select>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('contact_threshold_seconds')"
          v-if="hasFilter('contact_threshold_seconds')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.matches,, 1')">
        <el-select name="no-match" v-model="filter.has_matches" @change="clearOnChange('has_matches')" placeholder="">
          <el-option value="" :label="$tf('common.all')"></el-option>
          <el-option value="True" :label="$tfo(['common.only_with_matches'])"></el-option>
          <el-option value="False" :label="$tfo(['common.only_without_matches'])"></el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('has_matches')" v-if="hasFilter('has_matches')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('common.watch_lists')">
        <el-select
          name="matched-lists"
          v-model="filter.matched_lists"
          multiple
          @change="clearOnChange('matched_lists')"
          :placeholder="$tf('common.not_selected')"
          :multiple-limit="10"
        >
          <el-option
            v-for="listItem in $store.state.watch_lists.items"
            :key="'watchlist-' + listItem.id"
            :value="listItem.id"
            :label="$filters.shortString(listItem.name)"
          >
            <watchlist-option :item="listItem"></watchlist-option>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('matched_lists')" v-if="hasFilter('matched_lists')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.cameras')">
        <el-select
          name="cameras"
          v-model="filter.cameras"
          @change="clearOnChange('cameras')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
          filterable
          remote
          :remote-method="filterCameras"
        >
          <el-option v-for="item in $store.state.cameras.items" :key="'cameras-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="hasFilter('cameras')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.camera_groups')">
        <el-select
          name="camera-groups"
          v-model="filter.camera_groups"
          @change="clearOnChange('camera_groups')"
          multiple
          :multiple-limit="10"
          :placeholder="$tf('common.not_selected')"
        >
          <el-option v-for="item in $store.state.camera_groups.items" :key="'cameragroup-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ item.name | shortString }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
        <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter('camera_groups')">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.start')">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('common.end')">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <!-- special filters-->
      <div class="extra-filters" v-if="features">
        <el-form-item :key="index" v-for="(name, index) in sortedFeatureNames" :label="$tf(name)">
          <el-checkbox-group v-model="filter[name]" v-if="name !== 'age'">
            <el-checkbox v-for="(item, idx) in features[name]" :label="item" @change="clearOnChange(name)" :key="'extra-filter-' + idx">{{
              $tf(item)
            }}</el-checkbox>
          </el-checkbox-group>
          <div class="age-block" v-else>
            <el-select
              name="age-from"
              v-model="filter.age_gte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_gte')"
              :placeholder="$tf('common.from')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
            <el-select
              name="age-to"
              v-model="filter.age_lte"
              :clearable="true"
              :filterable="true"
              @change="clearOnChange('age_lte')"
              :placeholder="$tf('common.to,,1')"
            >
              <el-option v-for="item in Array.from({ length: 100 }, (v, k) => k + 1)" :key="'age-' + item" :label="item" :value="item">
                <span>{{ item }}</span>
              </el-option>
            </el-select>
            <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('camera_groups')" v-if="hasFilter(name)">
            </el-button>
          </div>
        </el-form-item>
      </div>
      <!-- end of special filters-->

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import WatchlistOption from '../watch-lists/option.vue';

export default {
  components: { WatchlistOption },
  name: 'contacts-level-filter',
  props: {
    state: {
      type: Object
    },
    index: {
      type: Number
    }
  },
  data: function () {
    return {
      hasFilters: true,
      matchedCardText: ''
    };
  },
  computed: {
    filter() {
      return this.state.filter.current;
    },
    sortedFeatureNames() {
      return Object.keys(this.$store.state.config.objects.faces.features || {}).sort((a, b) => (a < b ? -1 : 1));
    },
    features: function () {
      return this.$store.state.config.objects.faces.features;
    }
  },
  watch: {
    filter: {
      deep: true,
      handler: function (v, p) {
        if (v === p && !v.page) this.loadItems();
      }
    }
  },
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
  },
  methods: {
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10);

      return {
        contact_threshold_seconds: { multiple: false, identity: parseInt10 },
        limit: { multiple: false, identity: parseInt10 },
        cameras: { multiple: true, identity: parseInt10 },
        camera_groups: { multiple: true, identity: parseInt10 },
        gender: { multiple: true },
        emotions: { multiple: true },
        glasses: { multiple: true },
        beard: { multiple: true },
        liveness: { multiple: true },
        race: { multiple: true }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        default:
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    filterCameras(v) {
      let cameras = v
        ? this.$store.state.cameras.items.filter((i) => {
            return i.name.indexOf(v) > -1;
          })
        : this.$store.state.cameras.items;
      this.cameras = cameras.sort((a, b) => (a.name || '').localeCompare(b.name));
    },
    loadItems() {
      this.$store.dispatch('getFacesContacts', { level: this.index, id: this.state.current.id }).catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
      this.matchedCardText = '';
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">
.level-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }

  .extra-filters {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;

      .el-checkbox {
        margin: 0;
        flex: 0 0 50%;
      }
    }

    .age-block {
      display: flex;
      justify-content: space-between;

      .el-select {
        flex: 0 0 45%
      }
    }
  }
}
</style>
