import { IFormSchema } from '@/definitions/base.form.schema';
import { BaseFormComponentName } from '@/components/common/base-form/components';

const schema: IFormSchema = {
  fields: [
    {
      prop: 'uuid',
      i18nLabel: 'uuid',
      component: {
        name: 'input',
        disabled: false
      },
      validators: [
        {
          required: true,
          props: {
            i18nMessage: 'error.required.field'
          }
        }
      ]
    },
    {
      prop: 'reason',
      i18nLabel: 'reason',
      component: {
        name: 'input'
      },
      validators: [
        {
          required: true,
          props: {
            i18nMessage: 'error.required.field'
          }
        }
      ]
    },
    {
      prop: 'expire_date',
      i18nLabel: 'expire',
      component: {
        name: BaseFormComponentName.Datetime
      }
    }
    /*
    {
      prop: 'calendar',
      component: {
        name: 'area-calendar'
      }
    }*/
  ]
};

export default schema;
