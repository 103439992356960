import { FilterFormComponentName } from '@/components/filter-form/components';
import { FieldName } from '@/store/events/filters/fields';
import qs from 'qs';

export const schema = {
  module: 'search_objects',
  action: 'searchObjectsFake',
  fields: [
    {
      name: FieldName.Threshold,
      control: {
        i18nLabel: 'threshold',
        component: [
          FilterFormComponentName.Confidence,
          {
            isFieldClear: false,
            objects: getObjectsType
          }
        ]
      }
    },
    {
      name: FieldName.CameraGroups,
      control: {
        i18nLabel: 'camera_groups',
        component: [
          FilterFormComponentName.CameraGroups,
          {
            itemsPath: '$store.state.camera_groups.items',
            i18nPlaceholder: 'not_selected'
          }
        ]
      },
      meta: { multiple: true, identity: 'int' }
    },
    {
      name: FieldName.Cameras,
      control: {
        i18nLabel: 'cameras',
        component: [
          FilterFormComponentName.Cameras,
          {
            itemsPath: '$store.state.cameras.items',
            i18nPlaceholder: 'not_selected',
            cameraGroupsFieldName: 'camera_groups'
          }
        ]
      },
      meta: { multiple: true, identity: 'int' }
    },
    {
      name: FieldName.CreatedDateGte,
      control: {
        i18nLabel: 'start',
        component: [
          FilterFormComponentName.Datetime,
          {
            type: 'datetime'
          }
        ]
      }
    },
    {
      name: FieldName.CreatedDateLte,
      control: {
        i18nLabel: 'end',
        component: [
          FilterFormComponentName.Datetime,
          {
            type: 'datetime'
          }
        ]
      }
    },
    {
      name: FieldName.Ordering,
      control: {
        i18nLabel: 'ordering',
        component: [
          FilterFormComponentName.Select,
          {
            items: [
              { value: '-id', i18nLabel: 'date' },
              { value: '-looks_like_confidence', i18nLabel: 'confidence' }
            ]
          }
        ]
      }
    },
    {
      name: FieldName.Limit,
      control: {
        i18nLabel: 'number_of_results',
        component: [
          FilterFormComponentName.Select,
          {
            items: [10, 20, 50, 100, 200, 500]
          }
        ]
      }
    }
  ]
};

function getObjectsType() {
  const filter = this.$route.params.filter;
  const queryFilter = qs.parse(filter);
  return queryFilter.objectsType;
}
