import { IObjectItem, IObjectModule } from './objects/create-common-object';
import Cars from './objects/events/cars';
import Faces from './objects/events/faces';
import Chunks from './objects/vms/chunks';

type DataLoader = (options: any) => any;
type DataRender = () => void;
type StatusHandler = (status: string) => void;
type ErrorHandler = (e: any) => void;

export default class VideoPlayerTimelineData {
  private isLoading = false;

  private debounceTimer = 0;
  private autoUpdateTimer = 0;
  private camera = 0;
  private lastTimeFrom = 0;
  private lastTimeTo = 0;
  private lastAutoUpdateTimeout = 0;

  private dataLoader!: DataLoader;
  private dataRender!: DataRender;
  private statusHandler!: StatusHandler;
  private errorHandler!: ErrorHandler;

  private objects: IObjectModule[] = [Chunks()];

  setCamera(camera) {
    this.camera = camera;
  }

  setDataLoader(dataLoader: DataLoader, statusHandler: StatusHandler, errorHandler: ErrorHandler) {
    this.dataLoader = dataLoader;
    this.statusHandler = statusHandler;
    this.errorHandler = errorHandler;
  }

  setDataRender(dataRender: DataRender) {
    this.dataRender = dataRender;
  }

  setObjects(config: any) {
    this.objects = [Chunks()];
    if (config?.events?.cars?.enabled) {
      const cars = Cars();
      cars.limit = config?.events?.cars?.limit || cars.limit;
      this.objects.push(cars);
    }
    if (config?.events?.faces?.enabled) {
      const faces = Faces();
      faces.limit = config?.events?.faces?.limit || faces.limit;
      this.objects.push(faces);
    }
  }

  setAutoUpdate(timeout: number) {
    if (this.autoUpdateTimer) {
      clearInterval(this.autoUpdateTimer);
    }
    if (timeout) {
      this.lastAutoUpdateTimeout = timeout;
      this.autoUpdateTimer = setInterval(() => {
        this.updateDebounced(this.lastTimeFrom, this.lastTimeTo);
      }, timeout);
    }
  }

  async update(timeFrom: number, timeTo: number) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.statusHandler('loading');
    try {
      for (let i = 0; i < this.objects.length; i++) {
        const options = this.objects[i].getOptions(this.camera, timeFrom, timeTo, this.objects[i].limit);
        const data = await this.dataLoader(options);
        this.objects[i].saveItems(data);
      }
      this.statusHandler('');
    } catch (e) {
      this.statusHandler('error');
      this.errorHandler(e);
    }
    this.isLoading = false;
    this.dataRender && this.dataRender();
  }

  updateDebounced(timeFrom: number, timeTo: number) {
    clearTimeout(this.debounceTimer);
    this.statusHandler('wait');
    this.debounceTimer = setTimeout(() => {
      this.update(timeFrom, timeTo);
      this.setAutoUpdate(this.lastAutoUpdateTimeout);
    }, 3000);
  }

  updateDataByTimeRange(timeFrom: number, timeTo: number) {
    if (timeFrom === this.lastTimeFrom || timeTo === this.lastTimeTo) {
      return;
    }
    this.lastTimeFrom = timeFrom;
    this.lastTimeTo = timeTo;
    this.updateDebounced(timeFrom, timeTo);
  }

  findItems(timeFrom: number, timeTo: number) {
    let objectItemsAll: IObjectItem[] = [];
    let isLoadedAll = true;
    for (let i = 0; i < this.objects.length; i++) {
      const objectItems = this.objects[i].findItems(timeFrom, timeTo);
      objectItemsAll = objectItemsAll.concat(objectItems);
      isLoadedAll = isLoadedAll && this.objects[i].isLoadedAll;
    }
    return { objectItemsAll, isLoadedAll };
  }

  clear() {
    clearTimeout(this.debounceTimer);
    clearInterval(this.autoUpdateTimer);
  }
}
