<template>
  <page-layout>
    <span slot="header-name">{{ $tf('events') }}</span>

    <span slot="header-detail">{{ $tf('match,,1') }}: {{ eventsParams.matched }}, {{ $tf('common.total') }}: {{ eventsParams.total }}</span>

    <common-tabs slot="header-actions" v-model="type" :items="eventsTabs" :converter="$tfo" @change="changeTab" />

    <div slot="header-content-actions">
      <i class="el-icon-loading" v-if="state.playing && $store.state.server.connected"></i>
      <el-button name="play-btn" size="small" type="primary" v-if="!state.playing" @click="togglePlaying" :disabled="!!state.filter.current.page"
        ><i class="fa fa-play"></i
      ></el-button>
      <el-button name="pause-btn" size="small" :plain="true" v-else @click="togglePlaying"><i class="fa fa-pause"></i></el-button>
      <el-button name="set-all-acknowledged-btn" class="mar-left--0-5" size="small" :plain="true" @click="setAllAcknowledged">
        <i class="fa fa-check"></i>
        <span class="mar-left--0-5">{{ $tfo('acknowledge_v | all') }}</span>
      </el-button>
      <el-select
        name="limit"
        class="filter--page-limit mar-left--1"
        v-model="state.filter.current.limit"
        :disabled="state.loading"
        v-if="state.limits"
        size="small"
      >
        <el-option :key="item" :value="item" :label="item" v-for="item in state.limits"></el-option>
      </el-select>
    </div>

    <table-navigation slot="header-content-navigation" :state="state"></table-navigation>

    <div slot="content" v-if="$store.state.app.debug">
      {{ state.filter.current }}
    </div>

    <el-table
      slot="content"
      name="events-table"
      class="events-table"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      row-key="id"
      @row-click="rowClick"
      v-loading="state.loading"
    >
      <el-table-column prop="id" :label="$tf('common.id')" width="80" class-name="events-table__id-cell">
        <template slot-scope="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.id" placement="right-end">
            <span>{{ row.id }}</span>
          </el-tooltip>
          <copy-to-clipboard-button style="margin-top: 10px" :text="row.id" icon />
        </template>
      </el-table-column>

      <el-table-column prop="" :label="$tf('common.detected')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail :url="row.thumbnail" :frame="row.fullframe" @preview="showEventFullframe(row)"></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="" :label="$tf('common.dossier')" min-width="376px">
        <div class="dossier" slot-scope="{ row }">
          <object-thumbnail
            style="width: 176px"
            :id="row.matched_object"
            :objects="objectsType"
            :requiredId="!!row.matched_dossier"
            @preview="(v) => showSourceImage(v && v.src)"
          ></object-thumbnail>
          <div>
            <card-item :id="row.matched_dossier" :eventId="row.id" :confidence="row.confidence" :objects="objectsType" v-if="row.matched_dossier"></card-item>
            <div v-else>
              <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ $tf(['common.no', 'common.matches,, 0']) }}</router-link>
            </div>
            <watch-lists-inline links :objects-type="objectsType" :filter="state.filter.current" :ids="row.matched_lists" />
            <div class="event-reaction-content">
              <event-reaction :item="row" :objects="objectsType" :always-display-cancel="false" />
            </div>
          </div>
        </div>
      </el-table-column>

      <el-table-column width="192px" :label="$tf('common.attributes')">
        <template slot-scope="{ row }">
          <features :features="row.features" :objects-type="objectsType" />
          <div v-if="$store.getters.features.temperature">{{ $tf('temperature') }}: {{ row.temperature ? row.temperature : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="role" width="160px" :label="`${$tf('common.exact_time')}, ${$tf('common.camera')}`">
        <template slot-scope="{ row }">
          {{ row.created_date | isoStringToDate | formatDate }}
          <br />
          {{ row.created_date | isoStringToDate | formatTime }}
          <br /><br />

          <el-tooltip
            class="tooltip-item"
            effect="dark"
            :content="getCameraGroup(row).name + ' (' + getCamera(row).name + ')'"
            placement="top"
            :open-delay="800"
          >
            <div>
              <span class="link" @click.stop="addArrayFilter('camera_groups', getCamera(row).group)">{{ getCameraGroup(row).name | shortString }}</span>
              (<span class="link" @click.stop="addArrayFilter('cameras', getCamera(row).id)">{{ $filters.shortString(getCamera(row).name, 10) }}</span
              >)
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!--
      demo generator
      <table-generator slot="table" :state="state" :objects-type="objectsType"></table-generator>
    !-->

    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>

    <filter-form slot="right" :local_state="state" :fields="schema.fields" :module="this.stateName"></filter-form>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import qs from 'qs';
import Vue from 'vue';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import TableNavigation from '@/components/tables/navigation.vue';
import WatchListsInline from '@/components/watch-lists/inline.vue';
import ObjectsInline from '@/components/objects/inline.vue';
import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import CardItem from '@/components/events/card-item.vue';
import Features from '@/components/common/features.vue';
import EventReaction from '@/components/events/reaction.vue';
import PageLayout from '@/components/page/layout';
import TableGenerator from '@/components/tables/generator';
import CopyToClipboardButton from '@/components/common/copyToClipboardButton';
import { getEventBBox, ImageType } from '@/store/actions/showImageByType';
import { ObjectsType } from '../../store/objects/get.module';
import { getListViewModel } from '../../definitions/view-models';
import FilterForm from '../../components/filter-form/filter-form';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}
export default {
  components: {
    FilterForm,
    CopyToClipboardButton,
    TableGenerator,
    PageLayout,
    Features,
    EventReaction,
    CardItem,
    ObjectThumbnail,
    WatchListsInline,
    ObjectsInline,
    TableNavigation
  },
  name: 'events-demo-page',
  computed: {
    state() {
      const state = this.$store.state[this.stateName];
      const options = {
        url: `/events/${this.objectsType}`,
        routeName: ObjectsTypeSingleForm[this.objectsType] + 'Events',
        emptyItem: state.emptyItem,
        emptyFilter: state.filter.empty
      };
      return this.getModule(options);
    },
    schema() {
      const schema = this.$store.getters[this.objectsType + '_filter_schema'];
      return schema;
    },
    objectsType() {
      return this.type ?? 'faces';
    },
    stateName() {
      return `${this.objectsType}_events`;
    },
    statisticName() {
      return ObjectsTypeSingleForm[this.objectsType] + '_events';
    },
    eventsParams() {
      return this.$store.state.stats[this.statisticName];
    },
    eventsTabs() {
      return this.$store.getters.enabledObjects
        .filter((objectsType) => !this.$hasNoPermission(computeObjectEventsViewPermissionStringByObjectsType(objectsType)))
        .map((item) => ({ i18n: item, name: item }));
    }
  },
  data() {
    return {
      type: ObjectsType.Faces,
      modules: {}
    };
  },
  mounted() {
    this.$cache.enable(true);
    this.$store.dispatch(this.$store.state.camera_groups.Action.Get);
    this.$store.dispatch(this.$store.state.cameras.Action.GetAll);
    this.$store.dispatch(this.$store.state.watch_lists.Action.Get);
    this.loadStats();
  },
  beforeDestroy() {
    clearInterval(this.statsInterval);
    this.$cache.enable(false);
    Object.keys(this.modules).forEach((v) => this.modules[v].dispose());
  },
  watch: {
    stateName() {
      this.loadStats();
    }
  },
  methods: {
    getModule(options) {
      const module = this.modules[this.stateName] ?? getListViewModel(options);
      this.modules[this.stateName] = module;
      return module;
    },
    loadStats() {
      clearInterval(this.statsInterval);
      this.$store.dispatch('getStats', this.statisticName);
      this.statsInterval = setInterval(() => this.$store.dispatch('getStats', this.statisticName), 10000);
    },
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      if (this.$store.state.app.key.alt) {
        this.$store.dispatch('showInfo', { item, formattedData: this.getFormattedData(item) });
        return;
      }

      this.$router.push({ path: this.getItemLink(item) });
    },
    showEventImage(item) {
      this.$store.dispatch('showImageByType', { type: ImageType.Event, objectsType: this.objectsType, data: item });
    },
    showSourceImage(source) {
      this.$store.dispatch('showImageByType', { type: ImageType.Source, objectsType: this.objectsType, data: source });
    },
    showEventVideo(cameraId, timestamp) {
      let ts = new Date(timestamp).getTime() / 1000;
      const tsOffset = 3;
      const timeStart = ts - tsOffset;
      this.$store.dispatch('showVideoPlayer', { cameraId, timeStart });
    },
    showEventFullframe(row) {
      if (this.$store.getters.isVmsPlayer) {
        this.showEventVideo(row.camera, row.detector_params.track.face.best.timestamp);
      } else {
        this.showEventImage(row);
      }
    },
    getCamera(item) {
      return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tf('camera | not_found,,1') };
    },
    getCameraGroup(item) {
      return this.$store.state.camera_groups.items.find((v) => v.id === item.camera_group) || { name: '' };
    },
    togglePlaying() {
      this.state.playing = !this.state.playing;

      if (this.state.playing) {
        this.state.filter.current.created_date_lte = null;
      } else {
        this.state.filter.current.created_date_lte = new Date().toISOString();
      }
    },
    getItemLink(item) {
      return `/events/${this.objectsType}/${encodeURIComponent(item.id)}/`;
    },
    getFilterPath(filter) {
      return `#/events/${this.objectsType}/filter/` + qs.stringify(Object.assign(filter, { limit: 20 }), { sort: alphabeticalSort });
    },
    addArrayFilter(name, id) {
      if (this.state.filter.current[name].indexOf(id) === -1) this.state.filter.current[name].push(id);
    },
    setAllAcknowledged() {
      this.$confirm(this.$tf(['common.set_all_acknowledged_confirm']), this.$tf(['common.warning']), {
        confirmButtonText: this.$tf(['common.ok']),
        cancelButtonText: this.$tf(['common.cancel']),
        type: 'warning'
      })
        .then(() => {
          this.doMakeEventsAcknowledgedRequest()
            .then(() => this.$store.dispatch(this.state.Action.Get))
            .then(() => {
              this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
            })
            .catch((e) => {
              this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
            });
        })
        .catch(() => {
          console.warn('[acknowledge] was cancelled');
        });
    },
    toggleAcknowledged(item) {
      let instance = _.pick(item, ['acknowledged', 'id']);
      instance.acknowledged = !item.acknowledged;
      Vue.set(item, 'loading', true);
      this.$store
        .dispatch(this.state.Action.Update, instance)
        .then(() => {
          item.acknowledged = instance.acknowledged;
          Vue.set(item, 'cancelAcknowledged', true);
          item.loading = false;
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
        })
        .catch((e) => {
          item.loading = false;
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    changeTab(name) {
      this.type = name;
      // this.$router.push({ name: 'events', params: { objects: name, filter: 'limit=20' } });
    },
    doMakeEventsAcknowledgedRequest() {
      return this.$store.dispatch('requestApi', {
        model: `events/${this.objectsType}/acknowledge`,
        method: 'POST'
      });
    },
    getFormattedData(event) {
      const bbox = getEventBBox(event);
      return {
        width: bbox.w,
        height: bbox.h,
        size: Math.round(Math.sqrt(bbox.w * bbox.h)),
        quality: event.quality
      };
    }
  }
};

function computeObjectEventsViewPermissionStringByObjectsType(objectsType) {
  return `ffsecurity.view_${ObjectsTypeSingleForm[objectsType]}event`;
}
</script>

<style lang="stylus">
.event-reaction-content {
  margin-top: 1rem;
}

.filter--page-limit {
  display: inline-block;
  width: 5rem;
}

.tag-loading {
  cursor: none !important;
  opacity: 0.2;
}

.dossier {
  display: flex;
}

.events-table {
  &__id-cell > .cell {
    width: 5rem;
    direction: rtl;
  }
}
</style>
