export default class AutoOffsetTimer {
  isAutoOffset = true; // включена ли авто прокрутка timeOffset в данный момент
  private timer = 0;

  stopForTimeout(timeout = 25000) {
    clearTimeout(this.timer);
    this.isAutoOffset = false;
    this.timer = setTimeout(() => {
      this.isAutoOffset = true;
    }, timeout);
  }
}
