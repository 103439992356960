export const ApiUrls = {
  HumanCards: '/cards/humans',
  CarCards: '/cards/cars',
  FaceEvents: '/events/faces',
  BodyEvents: '/events/bodies',
  CarEvents: '/events/cars',
  FaceClusterEvents: '/cluster-events/faces',
  BodyClusterEvents: '/cluster-events/bodies',
  CarsClusterEvents: '/cluster-events/cars'
};
