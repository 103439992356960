import _ from '@/apps/common/lodash';
import qs from 'qs';
import { firstCase } from '../../apps/common/filters';

export function getModule(objectsType) {
  const emptyFilter = Object.seal({
      id: '',
      page: '',
      limit: 10,
      contact_threshold_seconds: 3,
      use_last_event: true,
      open: null,
      created_date_gte: null,
      created_date_lte: null,
      matched_lists: [],
      acknowledged: '',
      has_matches: '',
      cameras: [],
      camera_groups: [],
      matched_dossier: null,
      events_count_gte: null,
      gender: [],
      age_gte: null,
      age_lte: null,
      emotions: [],
      glasses: [],
      beard: [],
      liveness: [],
      race: [],
      medmask: []
    }),
    emptyItem = Object.seal({
      id: null,
      person_id: null,
      best_event: null,
      matched_lists: [],
      matched_person_events: [],
      matched_dossier: null,
      last_event_date: null,
      events_count: 0,
      matched_event_id: null
    }),
    emptyLevel = Object.seal({
      loading: false,
      appending: false,
      next_page: '',
      filter: {
        empty: _.cloneDeep(emptyFilter),
        current: _.cloneDeep(emptyFilter)
      },
      items: [],
      current: null
    });

  const state = {
    loading: false,
    item: {
      empty: emptyItem
    },
    level: {
      empty: emptyLevel
    },
    levels: [_.cloneDeep(emptyLevel), _.cloneDeep(emptyLevel), _.cloneDeep(emptyLevel), _.cloneDeep(emptyLevel)]
  };

  function getContacts({ state, rootState, dispatch }, payload) {
    const model = 'clusters/' + objectsType + '/[id]/contacts'.replace('[id]', payload.id),
      append = !!payload.append,
      level = payload.level || 0,
      currentLevel = state.levels[level];

    state.levels.forEach((v, k) => {
      const levelToClear = append ? level + 1 : level;
      if (k > levelToClear) {
        Object.assign(v, _.cloneDeep(state.level.empty));
      }
    });

    if (!currentLevel) return;

    if (append) {
      if (!currentLevel.next_page) return Promise.resolve(null);
      const nextPageFilter = qs.parse(currentLevel.next_page.split('?')[1] || '', { arrayLimit: 100 });
      currentLevel.appending = true;
      currentLevel.filter.current.page = nextPageFilter.page;
      currentLevel.next_page = '';
    } else {
      currentLevel.items = [];
      currentLevel.filter.current.page = '';
      currentLevel.loading = true;
    }

    return dispatch('requestApi', { model: model, method: 'get', filter: currentLevel.filter.current })
      .then((v) => {
        currentLevel.loading = false;
        currentLevel.appending = false;
        currentLevel.next_page = v.next_page;
        if (append) {
          currentLevel.items = currentLevel.items.concat(v.results);
        } else {
          currentLevel.items = v.results;
        }
        return v;
      })
      .catch((e) => {
        debugger;
        currentLevel.loading = false;
        currentLevel.appending = false;
        currentLevel.next_page = '';
        currentLevel.items = [];
        return Promise.reject(e);
      });
  }

  return {
    state,
    actions: { [`get${firstCase(objectsType)}Contacts`]: getContacts }
  };
}
