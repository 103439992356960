<template>
  <div class="object-image__container" :class="(selected && 'object-image__selected') || ''" :style="{ opacity: local ? 0.5 : 1 }">
    <img
      ref="img"
      :src="thumbnailSrc"
      class="object-image__thumbnail image-fallback"
      :class="[orientationClass, item && item.active === false ? 'object-image__inactive' : '']"
      v-if="url || item.id"
      @click.stop="(e) => !editable && $emit('preview', { src: fullImageSrc })"
      @error="handleThumbnailLoadError"
    />
    <div class="object-image__overlay abs-full cols cols--center" v-if="editable">
      <i class="object-image__icon-button flex-cell--static el-icon-view" @click="$emit('preview')"></i>
      <i class="object-image__icon-button flex-cell--static el-icon-delete" @click="$emit('delete')"></i>
    </div>

    <i class="object-image__icon-selected el-icon-check" v-if="selected"></i>

    <div class="object-image__overlay abs-full" v-if="selectable" @click="$emit('select')">
      <i class="object-image__icon-selectable el-icon-check" :class="selected && 'object-image__icon-selected'"></i>
    </div>

    <div class="object-image__error abs-full cols cols--center" v-if="error">
      <i class="object-image__icon flex-cell--static el-icon-close"></i>
    </div>

    <div class="object-image__id" v-if="showId && (item.id || id)">
      {{ item.id || id }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'object-thumbnail',
  props: {
    objects: {
      type: String,
      default: 'faces'
    },
    target: {
      type: String,
      default: 'dossier'
    },
    orientation: Number,
    frame: String,
    url: String,
    editable: Boolean,
    local: Boolean,
    id: [Number, String],
    requiredId: Boolean,
    box: Object,
    showId: Boolean,
    externalItem: Object,
    selectable: Boolean,
    selected: Boolean,
    caseFaceId: Number
  },
  data: function () {
    return {
      updateTimeout: 0,
      tempUrl: '',
      item: { id: null, source_photo: '', failed: false }
    };
  },
  computed: {
    thumbnailSrc() {
      return this.tempUrl || this.url || this.item.thumbnail || this.item.source_photo;
    },
    fullImageSrc() {
      return this.frame || this.url || this.item.source_photo;
    },
    orientationClass() {
      return this.$store.getters.isBrowserOrientationOld ? 'orientation' + (this.orientation || 1) : '';
    },
    error() {
      return this.requiredId && !(this.url || !this.item.failed);
    },
    isDossier() {
      return this.target === 'dossier';
    },
    model() {
      return this.target + '-face' + (this.isDossier ? 's' : '');
    },
    batchActionName() {
      const objectName = this.$filters.firstCase(this.objects);
      return `getBatchObject${objectName}`;
    }
  },
  watch: {
    id: function (v, p) {
      this.loadHandler();
    },
    caseFaceId: function (v, p) {
      this.loadCaseFace(v);
    },
    requiredId: function (v, p) {
      this.loadHandler();
    },
    externalItem: function (v, p) {
      this.item = v;
    },
    url: function (v, p) {
      this.tempUrl = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=';
      setTimeout(() => (this.tempUrl = ''), 0);
    }
  },
  mounted() {
    if (this.externalItem) {
      this.item = this.externalItem;
    } else if (this.caseFaceId) {
      this.loadCaseFace(this.caseFaceId);
    } else {
      this.loadHandler();
    }
  },
  methods: {
    handleThumbnailLoadError(e) {
      this.$emit('thumbnailError');
    },
    loadCaseFace(id) {
      this.item = { id: null, source_photo: '' };
      this.error = false;

      return this.$store
        .dispatch('get_case-faces', { id })
        .then((v) => {
          this.item = v;
        })
        .catch((e) => {
          this.error = true;
        });
    },
    loadHandler() {
      let name = this.model + '/' + this.id,
        promiseName = 'promise/' + name,
        cachedItem = this.$cache.get(name),
        promise = this.$cache.get(promiseName);

      if (!this.id) return;

      if (cachedItem) {
        this.item = this.$cache.get(name);
      } else {
        promise = promise || this.$store.dispatch(this.batchActionName, { id_in: this.id });
        promise
          .then((v) => {
            if (this.id !== v.id) return;
            this.item = v;
            this.$cache.set(name, v);
          })
          .catch(() => {
            this.item = { id: null, source_photo: '', failed: true };
          });
        !this.$cache.get(promiseName) && this.$cache.set(promiseName, promise);
      }
    }
  }
};
</script>

<style lang="stylus">
.object-image__icon-selected {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  color: #50bfff;
  background-color: #fff;
  border-radius: 50%;
  font-size: 1.2rem;
  padding: 0.4rem;
}

.object-image__icon-selectable {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  color: #50bfff;
  border-radius: 50%;
  font-size: 1.2rem;
  padding: 0.4rem;
}

.object-image__inactive {
  opacity: 0.2;
}

.object-image__id {
  position: absolute;
  line-height: 1.6rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
  right: 0;
  top: 0;
  color: #fff;
  background-color: #000;
  z-index: 201;
}

.object-image__container {
  position: relative;
  display: inline-flex;
  padding: 0;
  width: 160px;
  height: 160px;
}

.object-image__container {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.abs-full {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.object-image__icon {
  color: #ccc;
  font-size: 2rem;
  padding: 0.5rem;
}

.object-image__icon-button {
  cursor: pointer;
  color: #ccc;
  font-size: 2rem;
  padding: 0.5rem;
}

.object-image__icon-button:hover {
  color: #fff;
}

.object-image__icon-button {
  color: #fff;
}

.object-image__error {
  border: 1px solid #ccc;
  opacity: 1;
}

.object-image__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  opacity: 0;
}

.object-image__overlay:hover {
  transition: opacity 0.2s;
  opacity: 1;
}

.object-image__thumbnail {
  cursor: pointer;
//  border: 1px solid #333;
//  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 0;
  width: 160px;
  height: 160px;
  object-fit: contain;
}
</style>
