






import { IFilter } from '@/definitions/filter';
import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';

@Component({
  name: 'counters-filter',
  components: { FilterForm }
})
export default class CountersFilter extends Vue {
  get schema(): IFilter {
    return this.$store.state.counters.filter.schema;
  }
}
