import { IFilter, IFilterField } from '@/definitions/filter';
import { computeClusterEventFields, computeClustersFields } from '@/store/events/filters';
import { FieldName } from '@/store/events/filters/fields.js';
import { ObjectsType } from '@/store/objects/get.module';

export function getClustersFilterSchema(objects: string, state) {
  const cardFieldName = objects === ObjectsType.Cars ? FieldName.MatchedCarCardAsCard : FieldName.MatchedHumanCardAsCard;
  const fieldNames = [
    cardFieldName,
    FieldName.HasCard,
    FieldName.MatchedLists,
    FieldName.Cameras,
    FieldName.CameraGroups,
    FieldName.FirstEventDateGte,
    FieldName.FirstEventDateLte,
    FieldName.IdInAsId
  ].map((v) => [v]);

  const fields = (computeClustersFields(objects, fieldNames, state) as IFilterField[]).map((field) => {
    return field.name === FieldName.IdInAsId ? patchClusterFilterIdInAsIdField(field) : field;
  });

  const schema: IFilter = {
    module: `clusters/${objects}`,
    action: `clusters/${objects}/get`,
    fields
  };

  return schema;
}

export function patchClusterFilterIdInAsIdField(field: IFilterField): IFilterField {
  const { name, control, meta } = field;
  return { name, control: { ...control, i18nLabel: 'cluster_id' }, meta };
}

export function getClusterEventsFilterSchema(objects: string, state) {
  const fieldNames = [FieldName.Cameras, FieldName.CameraGroups, FieldName.CreatedDateGte, FieldName.CreatedDateLte, FieldName.ClusterIdIn].map((v) => [v]);

  const schema: IFilter = {
    module: `cluster-events/${objects}`,
    action: `cluster-events/${objects}/get`,
    fields: computeClusterEventFields(objects, fieldNames, state)
  };

  return schema;
}
