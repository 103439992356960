enum ExportMoveType {
  NoMove = 0,
  LeftBorder = 1,
  RightBorder = 2,
  FullArea = 3
}

export default class VideoPlayerTimelineExport {
  enabled = false;
  exportTimeStart = 0;
  exportTimeEnd = 20;
  private exportMoveType = ExportMoveType.NoMove;
  private mouseTime = 0;
  private thresholdTime = 1;
  private oldExportTime = 0;

  checkLimits() {
    if ((this.exportTimeStart + 10) > this.exportTimeEnd) {
      if (this.exportMoveType === ExportMoveType.LeftBorder) {
        this.exportTimeStart = this.exportTimeEnd - 10;
      } else {
        this.exportTimeEnd = this.exportTimeStart + 10;
      }
    }
  }

  setMouseTime(mouseTime) {
    this.mouseTime = mouseTime;
  }

  setThresholdTime(thresholdTime) {
    this.thresholdTime = thresholdTime;
  }

  isMouseInsideArea() {
    return this.mouseTime > this.exportTimeStart && this.mouseTime < this.exportTimeEnd;
  }

  isMouseOverLeftBorder() {
    return Math.abs(this.mouseTime - this.exportTimeStart) < this.thresholdTime;
  }

  isMouseOverRightBorder() {
    return Math.abs(this.mouseTime - this.exportTimeEnd) < this.thresholdTime;
  }

  mousedown() {
    if (this.isMouseInsideArea()) {
      this.exportMoveType = ExportMoveType.FullArea;
      this.oldExportTime = this.exportTimeStart;
    }

    if (this.isMouseOverLeftBorder()) {
      this.exportMoveType = ExportMoveType.LeftBorder;
      this.oldExportTime = this.exportTimeStart;
    }
    if (this.isMouseOverRightBorder()) {
      this.exportMoveType = ExportMoveType.RightBorder;
      this.oldExportTime = this.exportTimeEnd;
    }
  }

  mousemove(deltaTime) {
    if (this.exportMoveType === ExportMoveType.LeftBorder) {
      this.exportTimeStart = this.oldExportTime + deltaTime;
      return true;
    }
    if (this.exportMoveType === ExportMoveType.RightBorder) {
      this.exportTimeEnd = this.oldExportTime + deltaTime;
      return true;
    }
    if (this.exportMoveType === ExportMoveType.FullArea) {
      const len = this.exportTimeEnd - this.exportTimeStart;
      this.exportTimeStart = this.oldExportTime + deltaTime;
      this.exportTimeEnd = this.exportTimeStart + len;
      return true;
    }
    return false;
  }

  mouseup() {
    this.exportMoveType = ExportMoveType.NoMove;
  }

  toggleExportMode(areaStart, areaEnd) {
    this.enabled = !this.enabled;
    if (this.enabled) {
      this.exportTimeStart = (areaStart * 2 + areaEnd) / 3;
      this.exportTimeEnd = (areaStart + areaEnd * 2) / 3;
    }
  }

  get cursor() {
    let cursor = '';
    if (this.isMouseInsideArea()) {
      cursor = 'move';
    }
    if (this.isMouseOverLeftBorder()) {
      cursor = 'w-resize';
    }
    if (this.isMouseOverRightBorder()) {
      cursor = 'e-resize';
    }
    return cursor;
  }
}
