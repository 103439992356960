import { schema } from './filter.schema.ts';
import _ from '@/apps/common/lodash';
import { CardType, objectsTypeToCardType } from '@/store/cards/cards';

export const SearchTargetType = {
  Cards: 'cards',
  Events: 'events',
  Clusters: 'clusters',
  Social: 'social'
};

const emptyFilter = Object.seal({
  looks_like: '' /* String or array, examples: 'event:100', 'detection:3901' */,
  threshold: 0.75,
  limit: 100,
  ordering: '-id',
  camera_groups: [],
  cameras: [],
  card: '',
  created_date_gte: null,
  created_date_lte: null,
  page: '',
  objectsType: 'faces',
  sourceType: 'event',
  targetType: 'events',
  sourceCardId: ''
});

export default {
  state: {
    sourceType: 'event',
    loading: false,
    loaded: false,
    items: [],
    page: '',
    limits: [10, 20, 50, 100, 200, 500],
    next_page: null,
    prev_page: [],
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter),
      schema
    },
    ordering_options: [
      { value: '-id', i18nLabel: 'date' },
      { value: '-looks_like_confidence', i18nLabel: 'confidence' }
    ]
  },
  actions: {
    detectSearchObjects,
    searchObjects,
    searchObjectsFake
  }
};

function getSearchModel(state) {
  const filter = state.filter.current;
  let r = '';
  if (filter.targetType === SearchTargetType.Events) {
    r = `events/${filter.objectsType}`;
  } else if (filter.targetType === SearchTargetType.Cards) {
    const cardType = objectsTypeToCardType(filter.objectsType);
    r = `${SearchTargetType.Cards}/`;
    r += cardType === CardType.Human ? 'humans' : 'cars';
  } else if (filter.targetType === SearchTargetType.Clusters) {
    r = `clusters/${filter.objectsType}`;
  } else {
    r = SearchTargetType.Social;
  }
  return r;
}

function searchObjectsFake({ state, dispatch }) {}

function searchObjects({ state, dispatch }) {
  let model = getSearchModel(state);
  state.loading = true;
  state.loaded = false;

  const clearedCurrentFilter = clearLocalParams(state.filter.current);

  return dispatch('requestApi', { model, method: 'get', filter: clearedCurrentFilter })
    .then((v) => {
      state.next_page = v.next_page;
      state.items = v.results;
      return v;
    })
    .catch((e) => {
      state.items = [];
      state.next_page = '';
      return Promise.reject(e);
    })
    .finally(() => {
      state.loading = false;
      state.loaded = true;
    });
}

function detectSearchObjects({ state, rootState, dispatch }, event) {
  const formData = new FormData(),
    file = event.file,
    filename = (file && file.filename) || undefined;
  formData.append('photo', file, filename);
  return dispatch('requestApi', { model: 'detect', method: 'post', data: formData });
}

const LocalFilterParams = ['sourceCardId', 'targetType', 'sourceType', 'objectsType', 'confidence'];
function clearLocalParams(filter) {
  return Object.keys(filter)
    .filter((paramName) => !LocalFilterParams.includes(paramName))
    .reduce((clearFilter, apiParamName) => {
      clearFilter[apiParamName] = filter[apiParamName];
      return clearFilter;
    }, {});
}
