<template>
  <el-form-item prop="active" :label="$tf(`common.active`)" class="el-form-item--small">
    <el-checkbox v-model="model" :label="$tf('active')" :disabled="isCurrentUser">{{ $tf('active') }}</el-checkbox>
  </el-form-item>
</template>

<script>
import { Component } from 'vue-property-decorator';
import BaseFormComponent from '../component.vue';

@Component
export default class BaseFormComponentUserActiveCheckbox extends BaseFormComponent {
  get isCurrentUser() {
    return this.userId === +this.$route.params.id || 'me' === this.$route.params.id;
  }

  get userId() {
    return this.$store.state.users.current.id;
  }
}
</script>
