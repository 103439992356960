import { EventEmitter2 } from 'eventemitter2';
import { GlobalBusName, IAddStoreModule, IMenu, IServiceData, IUISevice, MicroserviceEventType } from '../../../definitions/microservices'; // @dont-touch

const MicroservicesSupport: any = {};

MicroservicesSupport.install = function install(Vue, options) {
  const { store, router } = options;
  const bus = new EventEmitter2();
  const dev = true;

  if (dev) console.log('Install MicroservicesSupport', options);

  if (MicroservicesSupport.installed) return;

  window[GlobalBusName] = bus;
  MicroservicesSupport.installed = true;

  bus.on(MicroserviceEventType.AddService, addService);

  bus.on(MicroserviceEventType.AddMainMenu, addMainMenu);
  bus.on(MicroserviceEventType.AddSettingsMenu, addSettingsMenu);
  bus.on(MicroserviceEventType.AddRoute, addRoute);
  bus.on(MicroserviceEventType.AddStoreModule, addStoreModule);
  bus.on(MicroserviceEventType.RemoveStoreModule, removeStoreModule);

  loadPlugins();

  function addService(payload: IServiceData) {
    if (dev) console.log('add service', payload);
    const { modules, routes, main_menu, settings_menu } = payload;
    if (main_menu) addMainMenu(main_menu);
    if (settings_menu) addSettingsMenu(settings_menu);
    if (routes) routes.forEach(addRoute);
    if (modules) modules.forEach(addStoreModule);
  }

  function addRoute(route) {
    if (dev) console.log('Add route ', route);
    router.addRoute(route);
  }

  function addStoreModule(payload: IAddStoreModule) {
    if (dev) console.log('Add store ', payload);
    store.registerModule(payload.name, payload.module);
  }

  function removeStoreModule(payload: IAddStoreModule) {
    store.unregisterModule(payload.name);
  }

  function addMainMenu(item: IMenu) {
    if (!item) return;
    const menuItems = store.state.microservices.main_menu;
    menuItems.push(item);
  }

  function addSettingsMenu(item: IMenu) {
    if (!item) return;
    const menuItems = store.state.microservices.settings_menu;
    menuItems.push(item);
  }

  function loadPlugins() {
    const services: IUISevice[] = store.state.config?.ui_services || [];
    if (dev) console.log('Load services ', services);
    services.forEach(({ js, css, name }: IUISevice) => {
      if (dev) console.log('Load plugin ', name, js, css);
      if (js) loadJs(js);
      if (css) loadCss(css);
    });
  }

  function loadJs(url: string) {
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);
  }

  function loadCss(url: string) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    document.head.appendChild(link);
  }
};

export default MicroservicesSupport;
