import { IAreaRecord, IAreaRecordFilter } from '@/store/areas/records';

export const EmptyItem: IAreaRecord = {
  id: 0,
  created_date: '',
  area: 0,
  area_trigger_activation: 0,
  body_count: 0,
  camera: 0,
  fullframe: '',
  thumbnail: '',
  camera_group: null,
  body_bboxes: [],
  error: false,
  error_msg: ''
};

export const EmptyFilter: IAreaRecordFilter = {
  area: [],
  area_trigger: [],
  body_count_gte: undefined,
  body_count_lte: undefined,
  created_date_gt: '',
  created_date_gte: '',
  created_date_lt: '',
  created_date_lte: '',
  limit: 10,
  ordering: '',
  page: undefined
};

export const mockItems = [
  {
    ...EmptyItem,
    id: 1,
    area: 0,
    area_trigger_activation: 0,
    camera: 0,
    created_date: new Date().toISOString(),
    body_count: 10,
    fullframe: 'https://picsum.photos/1000/1000',
    thumbnail: 'https://picsum.photos/160/160',
    camera_group: 0,
    error: false,
    error_msg: 'error'
  },
  {
    ...EmptyItem,
    id: 2,
    area: 0,
    area_trigger_activation: 1,
    camera: 0,
    created_date: new Date().toISOString(),
    body_count: 20,
    fullframe: 'http://example.com',
    thumbnail: 'http://example.com',
    camera_group: 0,
    error: false,
    error_msg: 'error'
  },
  {
    ...EmptyItem,
    id: 3,
    area: 0,
    area_trigger_activation: 2,
    camera: 0,
    created_date: new Date().toISOString(),
    body_count: 22,
    fullframe: 'http://example.com',
    thumbnail: 'http://example.com',
    camera_group: 0,
    error: false,
    error_msg: 'error'
  },
  {
    ...EmptyItem,
    id: 4,
    area: 0,
    area_trigger_activation: 2,
    camera: 0,
    created_date: new Date().toISOString(),
    body_count: 14,
    fullframe: 'http://example.com',
    thumbnail: 'http://example.com',
    camera_group: 0,
    error: false,
    error_msg: 'error'
  }
];
