// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    watch_lists: [],
    ordering: 'id'
  },
  emptyItem = {
    id: null,
    name: '',
    comment: '',
    labels: {},
    deduplicate: false,
    deduplicateDelay: 15,
    active: true,
    permissions: {},
    face_threshold: null,
    car_threshold: null,
    body_threshold: null
  },
  Name = 'camera-groups',
  Action = require('../action.names').default.CameraGroups;
const VideoArchiveCameraGroupId = -1;

function getCameraGroupNameById(state, getters) {
  const itemsMap = state.items.reduce((m, v) => {
    m[v.id] = v;
    return m;
  }, {});
  return (id) => itemsMap[id]?.name ?? '';
}

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action,
    VideoArchiveCameraGroupId
  },
  getters: {
    getCameraGroupNameById,
    cameraGroupsWithoutSpecial(state) {
      return state.items.filter(({ id }) => id !== VideoArchiveCameraGroupId);
    }
  },
  actions: Object.assign({}, getCrud(Name))
};
