import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import store from '../index';

export interface IRelation {
  id: number;
  name: string;
}

export interface IRelationFilter {
  name?: string;
  name_contains?: string;
  id_in?: number[];
  limit?: number;
}

export const EmptyItem: IRelation = {
  id: 0,
  name: ''
};

export const EmptyFilter: IRelationFilter = {
  limit: 10
};

@Module({ generateMutationSetters: true })
export class RelationsModule extends BaseItemsStateModule<IRelation, IRelationFilter> {
  name = 'relations';
  routeName = 'relations';
  destroy: () => void;

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyItem, EmptyFilter);
    // TODO: move to BaseItemsStateModule
    this.destroy = () => {
      options.store.unregisterModule(options.name);
    };
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}
