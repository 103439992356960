<template>
  <div class="stream-player" :class="{ 'stream-player_fullscreen': fullscreen }">
    <video v-show="this.wsUrl" :key="'video-player'" ref="video" autoplay muted @timeupdate="timeupdate"></video>
    <div class="stream-player__bbox-container" ref="bbox-container" :style="containerStyles">
      <template v-for="bbox in bboxArrEl">
        <div :key="'debug-' + (bbox && bbox.trackId)" v-if="$store.state.app.debug" class="stream-player__bbox-info" :style="bbox.style">
          Track ID: <br />{{ bbox && bbox.trackId }}
        </div>
        <div :key="'bbox' + bbox.trackId" :style="bbox.style" class="stream-player__bbox-item" />
        <div :key="'meta' + bbox.trackId" v-if="bbox.dossier || hasEventFeatures(bbox.event)" :style="bbox.metaInfoStyle" class="player-meta-box">
          <template v-if="bbox.dossier">
            <div class="player-meta-box__name">{{ bbox.dossier.name }}</div>
            <dossier-lists-round-inline :ids="bbox.event.matched_lists" class="player-meta-box__lists" />
          </template>
          <features v-if="hasEventFeatures(bbox.event)" :objects-type="bbox.objectsType" :features="bbox.event.features" :show-confidence="false" />
        </div>
      </template>
    </div>

    <div v-if="seeking" class="fallback">
      <div class="el-loading-spinner">
        <svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
        </svg>
      </div>
    </div>

    <div v-if="fallback" class="fallback">
      {{ fallback }}
    </div>
    <template v-else>
      <div v-if="stateText === 'ended' || stateText === 'closed'" class="fallback">
        {{ $tf('video_not_available') }}
      </div>
      <div v-if="cameraRemoved" class="fallback">
        {{ $tf('no_video_camera_removed') }}
      </div>
    </template>

    <div class="info" v-if="$store.state.app.debug">
      <b>{{ stateText }}</b> | {{ statText }}
    </div>

    <div class="fullscreen-button" @click.stop="toggleFullscreen">
      <div class="fullscreen-button--content">
        <i class="fa center--transform" :class="fullscreen ? 'fa-compress' : 'fa-arrows-alt'"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Features from '@/components/common/features.vue';
import DossierListsRoundInline from '@/components/watch-lists/inline.round.items.vue';
import { Watch } from 'vue-property-decorator';

const DefaultWidth = 640,
  DefaultHeight = 360;

const BBOXColors = {
  faces: 'green',
  cars: 'red',
  bodies: 'blue',
  match: '#32D74B',
  noMatch: '#FF375F'
};

// Use ts enum in the future
const SeekDirection = {
  Forward: 'forward',
  Backward: 'backward'
};

@Component({
  name: 'video-player-render',
  components: {
    DossierListsRoundInline,
    Features
  },
  props: {
    wsUrl: {
      type: String,
      required: true
    },
    reconnectOnClose: {
      type: Boolean,
      default: false
    },
    showBboxObjects: {
      type: Array,
      default() {
        return ['faces', 'bodies', 'cars'];
      }
    },
    gdpr: {
      type: Boolean,
      default: false
    },
    cameraRemoved: {
      type: Boolean,
      default: false
    },
    fallback: {
      type: String,
      default: ''
    }
  }
})
export default class VideoPlayerRender extends Vue {
  realTimestamp = 0;
  count = 0;
  canAppendToMediaSource = false;
  statText = '';
  stateText = 'loading';
  fullscreen = false;
  updateInterval = 0;
  openTimeout = 0;
  streamProps = {
    width: DefaultWidth,
    height: DefaultHeight,
    fps: 30
  };
  bboxArrEl = [];
  trackIdToEventMap = {};
  scaleFactor = 1;
  containerWidth = '640px';
  containerHeight = '480px';
  seekOnConnected = 0;
  playing = false;
  seeking = false;

  get objectsNames() {
    return this.$store.getters.enabledObjects;
  }

  get containerStyles() {
    return {
      width: this.containerWidth,
      height: this.containerHeight
    };
  }

  @Watch('wsUrl')
  watch_wsUrl() {
    this.playing && this.stop();
    this.$nextTick(() => this.play());
  }

  @Watch('$store.state.ws_temp_data.last_event')
  watch_last_event(v, p) {
    const trackId = v?.detector_params?.track?.id;
    if (trackId && this.trackIdToEventMap[trackId] !== undefined) this.trackIdToEventMap[trackId] = v;
  }

  created() {
    this.playing = false;
    this.$websocket = null;
    this.$mediaSource = new MediaSource();
    this.$sourceBuffer = null;
    this.$canvasCtx = null;
    this.$video = null;
    this.$buffers = {
      video: [],
      timesync: [],
      json: []
    };
  }

  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
    if (this.wsUrl) {
      this.play();
    }
  }

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
    this.stop();
  }

  hasMatchedLists(event) {
    const lists = event?.matched_lists?.filter((v) => v !== -1);
    return (lists?.length || 0) > 0;
  }

  keyupHandler(e) {
    if (this.fullscreen && e.keyCode === 27) {
      this.toggleFullscreen();
    }
  }

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    this.$store.state.video_wall.draggable = !this.fullscreen;
    this.resizeContainer();
  }

  play() {
    this.clear();
    if (!this.wsUrl) {
      return;
    }
    this.initWebSocket();
    this.initMediaSource();
    this.playing = true;
    this.$emit('pausedChange', false);
  }

  pause(isPaused) {
    if (this.$websocket) {
      if (isPaused) {
        this.$websocket.send('{"type":"pause", "data": true}');
        this.$refs.video.pause();
        this.playing = false;
      } else {
        this.$websocket.send('{"type":"pause", "data": false}');
        this.$refs.video.play();
        this.playing = true;
      }
    } else if (!isPaused) {
      this.play();
    }
    this.$emit('pausedChange', isPaused);
  }

  getVideoTimeByRealTime(realTime, seekDirection, lastPlayedRealTime) {
    for (let i = 0; i < this.$buffers.timesync.length; i++) {
      const bufferRealTime = this.$buffers.timesync[i].real_timestamp;
      if (
        (seekDirection === SeekDirection.Forward && bufferRealTime >= realTime) ||
        (seekDirection === SeekDirection.Backward && bufferRealTime >= realTime && bufferRealTime < lastPlayedRealTime)
      ) {
        return this.$buffers.timesync[i].timestamp;
      }
    }
    return -1;
  }

  seek(targetRealTimestamp) {
    if (this.stateText === 'closed' && !this.seekOnConnected) {
      this.play();
      this.seekOnConnected = targetRealTimestamp;
    } else {
      if (!this.seeking) {
        if (!this.playing) {
          this.pause(false);
        }

        if (this.seekTimer != undefined) {
          return;
        }

        this.$refs.video.pause();
        this.seeking = true; // ui - placeholder
        const seekDirection = targetRealTimestamp > this.realTimestamp ? SeekDirection.Forward : SeekDirection.Backward;
        const lastPlayedRealTime = this.realTimestamp;

        this.seekTimer = setInterval(() => {
          if (this.stateText === 'ended' || this.stateText === 'closed') {
            this.seeking = false;
            clearInterval(this.seekTimer);
            this.seekTimer = undefined;
          }

          let targetVideoTs = this.getVideoTimeByRealTime(targetRealTimestamp, seekDirection, lastPlayedRealTime);
          if (targetVideoTs == -1) {
            return;
          }
          const sk = this.$refs.video.seekable;
          if (sk.length > 0) {
            const tsEnd = sk.end(sk.length - 1);
            if (targetVideoTs <= tsEnd) {
              this.$refs.video.currentTime = targetVideoTs;
              this.$refs.video.play();
              this.seeking = false;
              clearInterval(this.seekTimer);
              this.seekTimer = undefined;
              this.$emit('seeked');
            }
          }
        }, 100);

        this.$websocket.send(`{"type":"seek", "data": ${targetRealTimestamp}}`);
      }
    }
  }

  stop() {
    this.playing = false;
    this.$websocket?.close();
  }

  clear() {
    if (this.$websocket) {
      this.$websocket = this.$websocket.onclose = this.$websocket.onopen = this.$websocket.onerror = null;
    }
    this.$mediaSource = new MediaSource();
    this.$buffers = {
      video: [],
      timesync: [],
      json: []
    };
    clearTimeout(this.openTimeout);
    clearInterval(this.updateInterval);
  }

  initWebSocket() {
    clearTimeout(this.openTimeout);
    const websocket = new WebSocket(this.wsUrl);
    websocket.binaryType = 'arraybuffer';
    websocket.onopen = () => {
      clearTimeout(this.openTimeout);
      this.stateText = 'connected';
      if (this.seekOnConnected) {
        this.seek(this.seekOnConnected);
        this.seekOnConnected = 0;
      }
    };
    websocket.onclose = (e) => {
      this.clear();
      this.stateText = 'closed';
      if (this.realTimestamp) {
        this.seekOnConnected = this.realTimestamp;
        this.realTimestamp = 0;
      }
      this.$emit('pausedChange', true);
      if (this.playing && this.reconnectOnClose) {
        this.openTimeout = setTimeout(this.play, 1000);
      }
    };
    websocket.onerror = (e) => {
      this.stateText = 'error';
    };
    websocket.onmessage = (e) => this.messageHandler(e);
    this.$websocket = websocket;
  }

  initMediaSource() {
    this.$video = this.$refs.video;
    this.$video.src = URL.createObjectURL(this.$mediaSource);
    this.$mediaSource.addEventListener('sourceopen', () => {
      URL.revokeObjectURL(this.$video.src);
      this.$sourceBuffer = this.$mediaSource.addSourceBuffer('video/mp4;codecs="avc1.4D4001"');
      this.$sourceBuffer.mode = 'segments';
      this.$sourceBuffer.addEventListener('updateend', () => {
        if (this.$buffers.video.length) {
          this.$sourceBuffer.appendBuffer(this.$buffers.video.shift());
        } else {
          this.canAppendToMediaSource = true;
        }
      });
      this.$sourceBuffer.addEventListener('error', (e) => {
        console.log('sourceBuffer:', e);
      });
      this.canAppendToMediaSource = true;
    });
  }

  computeObjectBboxesFromBufferData(v) {
    const showObjects = this.showBboxObjects.filter((item) => this.objectsNames.includes(item));
    return showObjects.reduce((m, i) => {
      if (v[i] instanceof Array) {
        v[i].forEach((r) => (r['objectsType'] = i));
      }
      return !v[i] ? m : m.concat(v[i]);
    }, []);
  }

  updateRealTimestamp(videoTime) {
    let i = 0;
    for (i = 0; i < this.$buffers.timesync.length; i++) {
      let v = this.$buffers.timesync[i];
      if (v.timestamp >= videoTime) {
        this.realTimestamp = v.real_timestamp;
        this.$emit('positionChange', this.realTimestamp);
        break;
      }
    }
    this.$buffers.timesync = this.$buffers.timesync.slice(i);
  }

  updateOverlays(videoTime) {
    let jsonData = [],
      i = 0,
      c = videoTime;

    for (i = 0; i < this.$buffers.json.length; i++) {
      let v = this.$buffers.json[i];
      if (v.ts >= c) {
        jsonData = this.computeObjectBboxesFromBufferData(v);
        break;
      }
    }

    this.$buffers.json = this.$buffers.json.slice(i);
    this.statText =
      'Time ' +
      Math.round(c) +
      ' / b.data ' +
      Math.round(this.$buffers.json.length / 10) * 10 +
      ' / b.video ' +
      Math.round(this.$buffers.video.length / 10) * 10;
    this.resizeContainer();
    this.showBBoxes(jsonData);
  }

  timeupdate() {
    const videoTime = this.$video.currentTime;
    this.updateRealTimestamp(videoTime);
    this.updateOverlays(videoTime);
  }

  resizeContainer() {
    if (!this.$refs.video) {
      return;
    }
    const scaleFactorH = this.$refs.video.offsetWidth / this.streamProps.width;
    const scaleFactorV = this.$refs.video.offsetHeight / this.streamProps.height;
    if (scaleFactorH * this.streamProps.height > this.$refs.video.offsetHeight) {
      this.scaleFactor = scaleFactorV;
      this.containerWidth = this.streamProps.width * scaleFactorV + 'px';
      this.containerHeight = '100%';
    } else {
      this.scaleFactor = scaleFactorH;
      this.containerWidth = '100%';
      this.containerHeight = this.streamProps.height * scaleFactorH + 'px';
    }
  }

  showBBoxes(jsonData) {
    this.bboxArrEl = [];
    for (let i = 0; i < jsonData.length; i++) {
      const { bbox, track_id: trackId, objectsType } = jsonData[i];
      if (trackId && !this.trackIdToEventMap[trackId]) this.trackIdToEventMap[trackId] = null;

      const isMatched = this.trackIdToEventMap[trackId]?.matched;
      const bboxElParams = {
        style: this.getBBoxParams(bbox, isMatched, objectsType),
        metaInfoStyle: this.getBBoxInfoParams(bbox),
        objectsType,
        trackId,
        dossier: this.getDossier(trackId),
        event: this.getEvent(trackId)
      };
      this.bboxArrEl.push(bboxElParams);
    }
  }

  getBBoxRect(bbox) {
    const { scaleFactor } = this;
    return {
      x: bbox[0] * scaleFactor,
      y: bbox[1] * scaleFactor,
      width: (bbox[2] - bbox[0]) * scaleFactor,
      height: (bbox[3] - bbox[1]) * scaleFactor
    };
  }

  getBBoxParams(bbox, isMatched, objectsType) {
    const bboxRect = this.getBBoxRect(bbox);
    const bboxBorderColor = isMatched ? BBOXColors.match : BBOXColors.noMatch;
    const isMustBlurred = objectsType === 'faces' && !isMatched && this.gdpr;
    const gdprBBoxStyle = isMustBlurred && {
      'backdrop-filter': 'blur(8px)',
      'border-radius': '1000px',
      'border-width': 0
    };
    return {
      left: `${bboxRect.x}px`,
      top: `${bboxRect.y}px`,
      width: `${bboxRect.width}px`,
      height: `${bboxRect.height}px`,
      'border-color': `${bboxBorderColor}`,
      ...gdprBBoxStyle
    };
  }

  getBBoxInfoParams(bbox) {
    const { x, y, width } = this.getBBoxRect(bbox);
    const leftCoord = x + width / 2;
    return {
      left: `${leftCoord}px`,
      top: `${y}px`
    };
  }

  getDossier(trackId) {
    const dossierId = this.trackIdToEventMap[trackId]?.matched_dossier;
    return dossierId && this.$store.state.dossiers.items.find((item) => item.id === dossierId);
  }

  getEvent(trackId) {
    return this.trackIdToEventMap[trackId];
  }

  messageHandler(event) {
    const isString = typeof event.data === 'string';

    if (isString) {
      let data = JSON.parse(event.data);
      const type = data.type;

      if (type === 'timesync') {
        this.$buffers.timesync.push(data.data);
      } else if (type === 'begin') {
        if (data.data && data.data.width) {
          this.streamProps = data.data;
        } else {
          console.warn('[stream-player] has no stream data information (width, height): ', data);
        }
        this.stateText = 'started';
      } else if (type === 'end') {
        this.stateText = 'ended';
        this.stop();
      } else if (type === 'frame') {
        this.$buffers.json.push(data.data);
      } else {
        console.warn('[stream-player] Error, unknown type: ', data);
      }
      return;
    }

    let data = new Uint8Array(event.data);
    if (this.canAppendToMediaSource === true) {
      const buffered = this.$sourceBuffer.buffered;
      try {
        this.$sourceBuffer.appendBuffer(data);
      } catch (e) {
        console.log(e, buffered);
      }
      this.canAppendToMediaSource = false;
    } else {
      this.$buffers.video.push(data);
    }
  }

  hasEventFeatures(event) {
    return Object.keys(event?.features || {}).length > 0;
  }
}
</script>

<style lang="stylus">
.stream-player {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__bbox-container {
    width: 100%
    position: relative;
    transform: translate(0,0);
  }
  &__bbox-item {
    transition: all 0.3s;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    border-radius: 1000px;
  }

  &__bbox-info {
    transition: all 0.3s;
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 10px;
    font-size: 0.75rem;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &_fullscreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: initial;
    height: initial;
    position: fixed;
  }

  &:hover {
    .fullscreen-button {
      opacity: 1;
    }
  }

  video, canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  video {
    position: absolute;

    &:focus {
      outline-style: unset;
    }
  }

  canvas {
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }

  .fallback {
    pointer-events: none;
    position: absolute;
    color: rgba(255, 255, 255, 0.7);
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .fullscreen-button {
    cursor: pointer !important;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    opacity: 0;
    transition: opacity 0.2s;

    &--content {
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      background-color: rgba(0, 0, 0, 0.5);

      i {
        font-size: 2rem;
      }
    }
  }
  &_blur {
    backdrop-filter: blur(8px);
    border-radius: 1000px;
    border-width: 0;
  }
}

.player-meta-box {
  position: absolute;
  width: 120px;
  min-height: 40px;
  background: #FFFFFF;
  color: black;
  transform: translate(-50%, -100%);
  margin-top: -24px;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
    0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 20px;
  padding: 12px 12px 24px 12px;
  font-size: .75rem;
  line-height: .75rem;
  z-index: 1000;
  transition: all 0.3s;

  &__name {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  &__lists {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  &__features {
    display: flex;
    justify-content: space-between;
  }
}
</style>
