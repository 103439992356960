<template>
  <div class="event-reaction" v-loading="item.loading" element-loading-spinner="el-icon-loading" element-loading-custom-class="row-loader">
    <div v-if="item.acknowledged">
      <span class="reaction-text">{{ item.acknowledged_reaction ? getReactionText(item.acknowledged_reaction) : $tf('acknowledged') }}</span>
      <el-button
        name="unacknowledge-btn"
        size="small"
        @click.stop="toggleAcknowledged(item)"
        v-if="cancelable && (alwaysDisplayCancel || sessionDisplayCancel)"
        >{{ $tf('cancel_v') }}</el-button
      >
    </div>
    <div v-else-if="reactions">
      <el-button
        :name="'reaction-' + reaction.code + '-btn'"
        @click.stop="reactionHandler(reaction)"
        v-for="(reaction, idx) in reactions"
        :key="'reaction' + idx"
      >
        {{ reaction.text }}
      </el-button>
    </div>
    <div v-else>
      <el-button name="acknowledge-btn" type="danger" size="small" @click.stop="toggleAcknowledged(item)">{{ $tf('not_taked') }}</el-button>
    </div>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import Vue from 'vue';

function objectsTypeToCardTypes(objectsType) {
  switch (objectsType) {
    case 'faces':
    case 'bodies':
      return 'humans';
    case 'cars':
      return 'cars';
  }
}

export default {
  name: 'event-reaction',
  props: {
    model: {
      type: String
    },
    item: {
      type: Object
    },
    alwaysDisplayCancel: {
      type: Boolean,
      default: true
    },
    cancelable: {
      type: Boolean,
      default: true
    },
    objects: {
      type: String,
      default: 'faces'
    }
  },
  data: function () {
    return {
      sessionDisplayCancel: false
    };
  },
  computed: {
    modelStateName() {
      const cardType = objectsTypeToCardTypes(this.objects);
      return this.model ? (this.model === 'episodes' ? `${this.model}/${cardType}` : this.model) : null;
    },
    stateName() {
      return this.modelStateName || `${this.objects}_events`;
    },
    state() {
      return this.$store.state[this.stateName];
    },
    reactions() {
      return (this.$store.state.config.event && this.$store.state.config.event.reactions) || null;
    }
  },
  mounted() {},
  methods: {
    getReactionText(code) {
      let reaction = (this.reactions || []).filter((v) => v.code === code)[0] || { result_text: code };
      return reaction.result_text || code;
    },
    getActionName() {
      return this.state.Action?.Acknowledge || `${this.modelStateName}/update`;
    },
    reactionHandler(v) {
      let item = this.item,
        instance = { id: this.item.id },
        action = this.getActionName();
      instance.acknowledged_reaction = v.code;
      Vue.set(item, 'loading', true);
      Vue.set(item, 'acknowledged_reaction', v.code);
      this.$store
        .dispatch(action, instance)
        .then((v) => {
          this.sessionDisplayCancel = true;
          item.acknowledged = true;
          this.$notify({ type: 'success', message: this.$tf(['action', 'success']) });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        })
        .finally(() => {
          item.loading = false;
        });
    },
    toggleAcknowledged() {
      let item = this.item,
        instance = _.pick(item, ['acknowledged', 'id']),
        action = this.getActionName();
      instance.acknowledged = !item.acknowledged;
      Vue.set(item, 'loading', true);
      this.$store
        .dispatch(action, instance)
        .then((v) => {
          Object.assign(item, _.pick(v, ['acknowledged', 'acknowledged_by', 'acknowledged_date', 'acknowledged_reaction']));
          if (instance.acknowledged) item.acknowledged = true; // fix puppeteer
          this.sessionDisplayCancel = item.acknowledged;
          this.$notify({ type: 'success', message: this.$tf(['action', 'success']) });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        })
        .finally(() => {
          item.loading = false;
        });
    }
  }
};
</script>

<style lang="stylus">
.event-reaction {

  .reaction-text {
    margin-right: 1rem;
    font-weight: bold;
  }

  .row-loader .el-loading-spinner {
    margin-top: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
