




















import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';
import CommonStatus from '@/components/common/common-status.vue';
import { CalibrationStatuses, CalibrationStatusesI18n } from '@/components/counters/counters.constants';

@Component({
  name: TableComponentNames.CounterStatus,
  components: { CommonStatus }
})
export default class TableGeneratorCounterStatus extends Vue {
  @Prop({ required: true, type: Object })
  item!: any;

  getDistanceEnabled(item) {
    return item.detect_proximity;
  }

  getCalibrationStatus(item) {
    return CalibrationStatuses[item.proximity_calibration_status];
  }

  getTranslatedCalibrationStatus(item) {
    const status = CalibrationStatusesI18n[this.getCalibrationStatus(item)];
    return this.$tf(status);
  }
}
