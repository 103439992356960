import { DataService, dataServiceFactory, DataServiceFactory } from '@/services/data.services';
import { AclPrefix, getDelay, getFilterString } from '@/definitions/base.items.state.module';
import { AnyFieldOfTypeWithId } from '@/definitions/base.actions';
import _ from '@/apps/common/lodash';
import { BaseItemState } from '@/definitions/base.state';

export class ItemViewModel<T> implements BaseItemState<T> {
  name = 'base';
  routeName = 'base';
  aclModelName: string | undefined;
  version = 2;

  loading = false;
  loaded = false;
  loadError: any | null = null;

  emptyItem?: T;
  originalItem?: T;
  item?: T;

  excludedChangeKeys: string[] = [];

  private _dataService: DataService<T, any> | undefined;

  constructor() {}

  init(emptyItem: T) {
    this.emptyItem = _.cloneDeep(emptyItem);
    this.setEmptyItemsState();
  }

  async dispatch(action: string, payload: any): Promise<any> {
    throw new Error('[list.view.model] dispatch must be override by store dispatch or mock dispatch');
  }

  set dataService(v: DataService<T, any>) {
    this._dataService = v;
  }

  get dataService(): DataService<T, any> {
    if (!this._dataService) {
      throw new Error('Data Service should be initialized');
    } else {
      return this._dataService;
    }
  }

  get aclViewPermissionName(): string {
    return `${AclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${AclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${AclPrefix}.edit_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${AclPrefix}.delete_${this.aclModelName}`;
  }

  get changes(): Partial<T> {
    const diffObject = _.differenceOf(this.item, this.originalItem),
      hasExcludedKeys = this.excludedChangeKeys?.length;
    hasExcludedKeys && this.excludedChangeKeys.forEach((v) => delete diffObject[v]);
    return diffObject;
  }

  get hasChanges(): boolean {
    return Object.keys(this.changes).length > 0;
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    throw new Error('it must be overrided');
  }

  setEmptyItemsState(): void {
    this.item = _.cloneDeep(this.emptyItem);
    this.originalItem = _.cloneDeep(this.emptyItem);
  }

  async get(id: string | number): Promise<boolean> {
    this.loading = true;
    this.loadError = null;

    try {
      const item = await this.dataService.get(id);
      this.setItemsState(item);
    } catch (e) {
      this.setItemsState(null, e);
    }

    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  setItemsState(item: T | null, error?: any) {
    if (item) {
      this.originalItem = item;
      this.item = _.cloneDeep(item);
      this.loaded = true;
    } else {
      this.loaded = false;
    }
    this.loadError = error;
    this.loading = false;
  }

  async create(): Promise<T | null> {
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      const createdItem: AnyFieldOfTypeWithId<T> = _.cloneDeep(this.item);
      delete createdItem.id;
      await getDelay(200);
      result = await this.dataService.create(createdItem);
      this.setItemsState(result);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  async update(data: Partial<T>): Promise<T | null> {
    const id = (this.item as any)?.id;
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      if (!id) throw new Error('Can not update, because no item ID is defined');
      await getDelay(200);
      result = await this.dataService.update(id, data);
      this.setItemsState(result);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  async delete(id: string | number): Promise<boolean> {
    return this.dataService.delete(id);
  }

  getItemRoute(): any {
    const id = (this.item as any)?.id;

    return {
      name: `${this.routeName}Edit`,
      params: {
        id: id,
        item: this.item
      }
    };
  }

  dispose() {
    this._dataService = undefined;
  }
}
