import { ReportType } from '@/components/reports/reports-helpers.js';
import { ObjectsType, ObjectsTypeSingleForm } from './store/objects/get.module.js';

const filters = Object.freeze([
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/puppeteer/search/events/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/puppeteer/search-events/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/cameras/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/cameras/filter'),
        report: {
          type: ReportType.Cameras,
          filter: vm.$store.state.local_cameras.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/events\/.*\/filter/);
    },
    getDescriptor: (vm) => {
      const objectType = vm.$route.params.objects;
      const type = mapObjectTypeToReportType(objectType);
      const filter = mapObjectTypeToReportFilterState(objectType, vm.$store);
      return {
        component: require('@/components/events/filter'),
        report: { type, filter }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/search/');
    },
    getDescriptor: (vm) => {
      const filter = vm.$store.state.search_objects.filter.current;
      let type = areEventsSelectedAsSearchTarget(filter.targetType) ? mapObjectTypeToReportType(filter.objectsType) : mapTargetTypeToReportType(filter);
      type = areClustersSelectedAsSearchTarget(filter.targetType) ? mapObjectTypeToClusterReportType(filter.objectsType) : type;
      return {
        component: require('@/components/search/objects/filter'),
        report: { type, filter }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/episodes\/.*\/filter/);
    },
    getDescriptor: (vm) => {
      const objectType = vm.$route.params.objects;
      const type = getReportTypeByEpisodeObjectType(objectType);
      const filter = mapObjectTypeToEpisodeReportFilterState(objectType, vm.$store);
      return {
        component: require('@/components/episodes/filter'),
        report: { type, filter }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/clusters\/.*\/filter\//);
    },
    getDescriptor: (vm) => {
      const objectType = vm.$route.params.objects;
      const type = mapObjectTypeToClusterReportType(objectType);
      const filter = mapObjectTypeToClusterReportFilterState(objectType, vm.$store);
      return {
        component: require('@/components/clusters/filter'),
        report: {
          type: type,
          filter: filter
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/cluster-events\/.*\/filter\//);
    },
    getDescriptor: (vm) => {
      const objectType = vm.$route.params.objects ?? ObjectsType.Faces;
      const type = `${ObjectsTypeSingleForm[objectType]}-clusters`;
      const filter = vm.$store.state[`cluster-events/${objectType}`].filter.current;
      return {
        component: require('@/components/cluster-events/filter'),
        report: {
          type: type,
          filter: filter
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/person-events/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/person-events/filter'),
        report: {
          type: ReportType.Persons,
          filter: vm.$store.state.person_events.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/counters/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/counters/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/counter-records/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/counter-records/filter'),
        report: {
          type: ReportType.Counters,
          filter: vm.$store.state.counter_records.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/puppeteer/remote-monitoring/events/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/puppeteer/remote-monitoring-events/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/kyc/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/kyc/filter'),
        report: {
          type: ReportType.Kyc,
          filter: vm.$store.state.kyc.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return vm.$route.path.includes('/contacts/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/contacts/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/cases/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/cases/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.match(/\/cases\/[\d+]\/faces\/filter.*/gi);
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/cases/case.faces.filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/videos/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/videos/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/audit-logs/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/audit-logs/filter'),
        report: {
          type: ReportType.AuditLogs,
          filter: vm.$store.state.audit_logs.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/areas/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/areas/areas/filter'),
        report: {
          type: ReportType.Areas,
          filter: vm.$store.state.areas.filter.current,
          pictures: ['link']
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/area-triggers/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/areas/triggers/filter'),
        report: {
          type: ReportType.AreaTriggers,
          filter: vm.$store.state.area_triggers.filter.current,
          pictures: ['link']
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/area-records/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/areas/records/filter'),
        report: {
          type: ReportType.AreaRecords,
          filter: vm.$store.state.area_records.filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/users/filter/');
    },
    getDescriptor: (_vm) => {
      return {
        component: require('@/components/users/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/auth-sessions/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/sessions/sessions/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/blocklist-records/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/sessions/blocklist-records/filter')
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/cards/humans/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/cards/human.filter'),
        report: {
          type: ReportType.HumanCards,
          filter: vm.$store.state['cards/humans'].filter.current
        }
      };
    }
  },
  {
    isActive: (vm) => {
      return isUserLoggedIn(vm.$store) && vm.$route.path.includes('/cards/cars/filter/');
    },
    getDescriptor: (vm) => {
      return {
        component: require('@/components/cards/car.filter'),
        report: {
          type: ReportType.CarCards,
          filter: vm.$store.state['cards/cars'].filter.current
        }
      };
    }
  }
]);

function areEventsSelectedAsSearchTarget(targetType) {
  return targetType === 'events';
}

function areClustersSelectedAsSearchTarget(targetType) {
  return targetType === 'clusters';
}

export function getActiveFilterDescriptor(vm) {
  return filters.find((filter) => filter.isActive(vm))?.getDescriptor(vm);
}

function isUserLoggedIn(store) {
  return store.state.users.current.id;
}

function getReportTypeByEpisodeObjectType(objectType) {
  return objectType === 'cars' ? ReportType.CarEpisodes : ReportType.HumanEpisodes;
}

function mapObjectTypeToReportType(objectType) {
  switch (objectType) {
    case ObjectsType.Cars:
      return ReportType.CarEvents;
    case ObjectsType.Bodies:
      return ReportType.BodyEvents;
  }
  return ReportType.FaceEvents;
}

function mapTargetTypeToReportType({ targetType, objectsType }) {
  if (targetType !== 'cards') return ReportType.HumanCards;

  switch (objectsType) {
    case ObjectsType.Cars:
      return ReportType.CarCards;
    case ObjectsType.Bodies:
    case ObjectsType.Faces:
      return ReportType.HumanCards;
  }
  return ReportType.HumanCards;
}

function mapObjectTypeToReportFilterState(objectType, store) {
  switch (objectType) {
    case ObjectsType.Cars:
      return store.state.cars_events.filter.current;
    case ObjectsType.Bodies:
      return store.state.bodies_events.filter.current;
  }
  return store.state.faces_events.filter.current;
}

function mapObjectTypeToClusterReportType(objectType) {
  switch (objectType) {
    case ObjectsType.Cars:
      return ReportType.CarClusters;
    case ObjectsType.Bodies:
      return ReportType.BodyClusters;
  }
  return ReportType.FaceClusters;
}

function mapObjectTypeToClusterReportFilterState(objectType, store) {
  return store.state[`clusters/${objectType}`].filter.current;
}

function mapObjectTypeToEpisodeReportFilterState(objectType, store) {
  return store.state[`episodes/${objectType}`].filter.current;
}
