







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'video-player-button'
})
export default class VideoPlayerButton extends Vue {
  @Prop({ type: String, default: '' })
  icon?: string;

  @Prop({ type: Boolean, default: false })
  red?: boolean;

  @Prop({ type: Boolean, default: false })
  blue?: boolean;

  get buttonClasses() {
    const result = {};
    if (this.red) {
      result['bg-red'] = true;
    }
    if (this.blue) {
      result['bg-blue'] = true;
    }
    return result;
  }

  get spanClasses() {
    const result = {};
    if (this.icon) {
      result['video-player-button__icon'] = true;
      result[this.icon] = true;
    }
    return result;
  }
}
