import { ICarEpisode, ICarEpisodeFilter, IHumanEpisode, IHumanEpisodeFilter } from '@/store/episodes/index';

export const EmptyHumansFilter: IHumanEpisodeFilter = {
  id: '',
  id_in: '', //@cause it uses in simple number input field
  page: 0,
  limit: 10,
  open: null,
  matched_lists: [],
  created_date_gte: '',
  created_date_lte: '',
  acknowledged: '',
  no_face_match: '',
  no_body_match: '',
  cameras: [],
  camera_groups: [],
  matched_card: null,
  events_count_gte: null,
  temperature_gte: null,
  temperature_lte: null,
  video_archive: null,
  gender: [],
  age_gte: '',
  age_lte: '',
  emotions: [],
  glasses: [],
  beard: [],
  liveness: [],
  race: [],
  medmask: [],
  top_color: [],
  bottom_color: [],
  detailed_upper_clothes: [],
  headwear: [],
  lower_clothes: [],
  upper_clothes: []
};

export const EmptyCarsFilter: ICarEpisodeFilter = {
  id: '',
  id_in: '', //@cause it uses in simple number input field
  page: 0,
  limit: 10,
  open: null,
  matched_lists: [],
  no_car_match: '',
  acknowledged: '',
  camera_groups: [],
  cameras: [],
  events_count_gte: null,
  created_date_gte: '',
  created_date_lte: '',
  video_archive: null,
  body: [],
  color: [],
  license_plate_country: [],
  license_plate_number: '',
  license_plate_region: [],
  special_vehicle_type: [],
  make: [],
  model: []
};

export const EmptyHumanItem: IHumanEpisode = {
  id: null,
  open: false,
  event_id: null,
  last_event_date: null,
  events_count: 0,
  matched_event_id: null,
  face_features: [],
  body_features: []
};

export const EmptyCarItem: ICarEpisode = {
  id: null,
  open: false,
  event_id: null,
  last_event_date: null,
  events_count: 0,
  matched_event_id: null,
  car_features: []
};
