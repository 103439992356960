import store, { RequiredMenuItems } from '@/store';
import hasNoPermission from '@/apps/common/hasNoPermission';
import _ from '@/apps/common/lodash';

function hasPermission(item) {
  return item?.meta?.permissions ? !hasNoPermission(item.meta.permissions, item.meta['permissions-rule']) : true;
}

function checkOnlyDev(item) {
  let developmentItem = item?.meta?.dev,
    developmentMode = process.env.NODE_ENV === 'development' || store.state.config?.development;
  return developmentItem ? developmentMode : true;
}

function hasPlugins(item) {
  let itemPlugins = item?.meta?.plugins,
    configPlugins = store.state.config.plugins || {};
  return itemPlugins
    ? Object.keys(itemPlugins).reduce((m, v) => {
        return m ? configPlugins[v] === itemPlugins[v] : false;
      }, true)
    : true;
}

function hasServices(item) {
  let services = item?.meta?.services,
    configPlugins = store.state.config.services?.ffsecurity || {};
  return services
    ? services.reduce((m, v) => {
        return m ? !!configPlugins[v] : false;
      }, true)
    : true;
}

function notDisabled(item) {
  const menuConfig = store.state.config.menu;
  if (!item.name) {
    return true;
  }
  if (RequiredMenuItems.includes(item.name) || _.isEmpty(menuConfig)) {
    return true;
  }
  return menuConfig.disabled_items.indexOf(item.name) === -1;
}

function canShowMenuItem(item) {
  return notDisabled(item) && hasPermission(item) && hasPlugins(item) && hasServices(item) && checkOnlyDev(item);
}

export { hasPermission, checkOnlyDev, hasPlugins, hasServices, canShowMenuItem };
