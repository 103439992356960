import { FilterFormComponentName } from '@/components/filter-form/components';

export const FieldName = {
  Acknowledged: 'acknowledged',
  BodiesBottomColor: 'bottom_color',
  BodiesTopColor: 'top_color',
  BodiesDetailedUpperClothes: 'detailed_upper_clothes',
  BodiesHeadwear: 'headwear',
  BodiesLowerClothes: 'lower_clothes',
  BodiesUpperClothes: 'upper_clothes',
  BsType: 'bs_type',
  CameraGroups: 'camera_groups',
  Cameras: 'cameras',
  CarsBody: 'body',
  CarsColor: 'color',
  CarsLicensePlateCountry: 'license_plate_country',
  CarsLicensePlateNumber: 'license_plate_number',
  CarsLicensePlateRegion: 'license_plate_region',
  CarsSpecialVehicleType: 'special_vehicle_type',
  CarsMake: 'make',
  CarsModel: 'model',
  CreatedDateGte: 'created_date_gte',
  CreatedDateLte: 'created_date_lte',
  Episode: 'episode',
  FacesAge: 'age',
  FacesBeard: 'beard',
  FacesEmotions: 'emotions',
  FacesGender: 'gender',
  FacesGlasses: 'glasses',
  FacesLiveness: 'liveness',
  FacesMedmask: 'medmask',
  Id: 'id',
  ClusterIdIn: 'cluster_in',
  HasCard: 'has_card',
  Limit: 'limit',
  MatchedCard: 'matched_card',
  MatchedHumanCard: 'matched_human_card',
  MatchedCarCard: 'matched_car_card',
  MatchedLists: 'matched_lists',
  NoMatch: 'no_match',
  Page: 'page',
  Race: 'race',
  VideoArchive: 'video_archive',
  BodyCountGte: 'body_count_gte',
  BodyCountLte: 'body_count_lte',
  MaxBodyCountGte: 'max_body_count_gte',
  MaxBodyCountLte: 'max_body_count_lte',
  IdInAsId: 'id_in',
  MatchedHumanCardAsCard: 'matched_human_card_as_card',
  MatchedCarCardAsCard: 'matched_car_card_as_card',
  Confidence: 'confidence',
  Threshold: 'threshold',
  Ordering: 'ordering',
  FirstEventDateGte: 'first_event_date_gte',
  FirstEventDateLte: 'first_event_date_lte'
};

const DefaultClearableFields = [FieldName.Page, FieldName.Id];

const schemas = {
  [FieldName.Acknowledged]: computeAcknowledgedFieldSchema,
  [FieldName.FacesAge]: computeFacesAgeFieldSchema,
  [FieldName.FacesBeard]: computeFacesBeardFieldSchema,
  [FieldName.BodiesTopColor]: computeBodiesTopColorFieldSchema,
  [FieldName.BodiesBottomColor]: computeBodiesBottomColorFieldSchema,
  [FieldName.BodiesDetailedUpperClothes]: computeBodiesDetailedUpperClothes,
  [FieldName.BodiesHeadwear]: computeBodiesHeadwear,
  [FieldName.BodiesLowerClothes]: computeBodiesLowerClothes,
  [FieldName.BodiesUpperClothes]: computeBodiesUpperClothes,
  [FieldName.CameraGroups]: computeCameraGroupsFieldSchema,
  [FieldName.Cameras]: computeCamerasFieldSchema,
  [FieldName.CreatedDateGte]: computeCreatedDateGteFieldSchema,
  [FieldName.CreatedDateLte]: computeCreatedDateLteFieldSchema,
  [FieldName.FacesEmotions]: computeFacesEmotionsFieldSchema,
  [FieldName.Episode]: computeEpisodeFieldSchema,
  [FieldName.FacesGender]: computeFacesGenderFieldSchema,
  [FieldName.FacesGlasses]: computeFacesGlassesFieldSchema,
  [FieldName.Id]: computeIdFieldSchema,
  [FieldName.IdInAsId]: computeIdInAsIdFieldSchema,
  [FieldName.CarsBody]: computeCarsBodyFieldSchema,
  [FieldName.CarsMake]: computeCarsMakeFieldSchema,
  [FieldName.CarsColor]: computeCarsColorFieldSchema,
  [FieldName.CarsModel]: computeCarsModelFieldSchema,
  [FieldName.CarsLicensePlateCountry]: computeCarsLicensePlateCountryFieldSchema,
  [FieldName.CarsLicensePlateNumber]: computeCarsLicensePlateNumberFieldSchema,
  [FieldName.CarsLicensePlateRegion]: computeCarsLicensePlateRegionFieldSchema,
  [FieldName.CarsSpecialVehicleType]: computeCarsSpecialVehicleTypeFieldSchema,
  [FieldName.Limit]: computeLimitFieldSchema,
  [FieldName.FacesLiveness]: computeFacesLivenessFieldSchema,
  [FieldName.MatchedLists]: computeMatchedListsFieldSchema,
  [FieldName.FacesMedmask]: computeFacesMedmaskFieldSchema,
  [FieldName.NoMatch]: computeNoMatchFieldSchema,
  [FieldName.Page]: computePageFieldSchema,
  [FieldName.Race]: computeRaceFieldSchema,
  [FieldName.VideoArchive]: computeVideoArchiveFieldSchema,
  [FieldName.BsType]: computeBsTypeFieldSchema,
  [FieldName.ClusterIdIn]: computeClusterIdFieldSchema,
  [FieldName.HasCard]: computeHasCardFieldSchema,
  [FieldName.MatchedHumanCard]: computeMatchedHumanCardFieldSchema,
  [FieldName.MatchedCarCard]: computeMatchedCarCardFieldSchema,
  [FieldName.MatchedHumanCardAsCard]: computeMatchedHumanCardAsCardFieldSchema,
  [FieldName.MatchedCarCardAsCard]: computeMatchedCarCardAsCardFieldSchema,
  [FieldName.BsType]: computeBsTypeFieldSchema,
  [FieldName.Confidence]: computeConfidenceFieldSchema,
  [FieldName.Ordering]: computeOrderingFieldSchema,
  [FieldName.FirstEventDateGte]: computeFirstEventDateGteFieldSchema,
  [FieldName.FirstEventDateLte]: computeFirstEventDateLteFieldSchema
};

export function computeFilterFieldsSchema(fields) {
  return fields.map(computeFieldSchema).flat();
}

function computeFieldSchema([fieldName, fieldProps]) {
  if (fieldName in schemas) {
    return schemas[fieldName](fieldProps);
  }
  throw new Error(`Unable to compute schema for the unknown filter field: ${fieldName}`);
}

function computeCheckboxGroupFieldSchema(name, i18nLabel, items) {
  return {
    name,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.CheckboxGroup,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeFieldItems(items) {
  const isArray = Array.isArray(items);
  if (!isArray) console.warn(`[feature.field.items] has incorrect value: ${items}`);
  return isArray ? items.map((item) => ({ value: item, label: item })) : [];
}
function computeNumberFieldI18nItems(items) {
  return items.map((item) => ({ value: item, i18nLabel: item }));
}

function computeFieldI18nItems(items, i18nLabel) {
  return items.map((item) => ({ value: item, i18nLabel: `${i18nLabel}__${item}` }));
}

function computeAcknowledgedFieldSchema() {
  return {
    name: FieldName.Acknowledged,
    control: {
      i18nLabel: 'acknowledged',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'False', i18nLabel: 'only_unacknowledged' },
            { value: 'True', i18nLabel: 'only_acknowledged' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeFacesAgeFieldSchema() {
  const i18nLabel = 'features.faces_age';
  return [
    {
      name: 'age_gte',
      control: {
        align: 'left',
        i18nLabel,
        component: [
          FilterFormComponentName.Age,
          {
            maxAge: 100,
            i18nPlaceholder: 'from'
          }
        ]
      }
    },
    {
      name: 'age_lte',
      control: {
        align: 'right',
        component: [
          FilterFormComponentName.Age,
          {
            maxAge: 100,
            i18nPlaceholder: 'to,,1'
          }
        ]
      }
    }
  ];
}

function computeFacesBeardFieldSchema(items) {
  return computeCheckboxGroupFieldSchema(FieldName.FacesBeard, 'features.faces_beard', items);
}

function computeCarsBodyFieldSchema(items) {
  const i18nLabel = 'features.cars_body';
  return {
    name: FieldName.CarsBody,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          multiple: true,
          filterable: true,
          items: computeFieldI18nItems(items, i18nLabel),
          alphabeticalOrder: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeCarsMakeFieldSchema(items) {
  const i18nLabel = 'features.cars_make';
  return {
    name: FieldName.CarsMake,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          multiple: true,
          filterable: true,
          items: computeFieldItems(items),
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeBodiesTopColorFieldSchema(items) {
  const i18nLabel = 'features.bodies_top_color';
  return {
    name: FieldName.BodiesTopColor,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          multiple: true,
          colorIcons: true,
          alphabeticalOrder: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeBodiesBottomColorFieldSchema(items) {
  const i18nLabel = 'features.bodies_bottom_color';
  return {
    name: FieldName.BodiesBottomColor,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          multiple: true,
          colorIcons: true,
          alphabeticalOrder: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeBodiesDetailedUpperClothes(items) {
  const i18nLabel = 'features.bodies_detailed_upper_clothes';
  return {
    name: FieldName.BodiesDetailedUpperClothes,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          multiple: true,
          colorIcons: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeBodiesHeadwear(items) {
  const i18nLabel = 'features.bodies_headwear';
  return {
    name: FieldName.BodiesHeadwear,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          multiple: true,
          colorIcons: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeBodiesLowerClothes(items) {
  const i18nLabel = 'features.bodies_lower_clothes';
  return {
    name: FieldName.BodiesLowerClothes,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          multiple: true,
          colorIcons: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeBodiesUpperClothes(items) {
  const i18nLabel = 'features.bodies_upper_clothes';
  return {
    name: FieldName.BodiesUpperClothes,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          multiple: true,
          colorIcons: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeCameraGroupsFieldSchema() {
  return {
    name: FieldName.CameraGroups,
    control: {
      i18nLabel: 'camera_groups',
      component: [
        FilterFormComponentName.CameraGroups,
        {
          itemsPath: '$store.state.camera_groups.items',
          i18nPlaceholder: 'not_selected',
          clearFieldsOnChange: [...DefaultClearableFields, FieldName.Cameras],
          multipleLimit: 0
        }
      ]
    },
    meta: { multiple: true, identity: 'int' }
  };
}

function computeCamerasFieldSchema() {
  return {
    name: FieldName.Cameras,
    control: {
      i18nLabel: 'cameras',
      component: [
        FilterFormComponentName.Cameras,
        {
          itemsPath: '$store.state.cameras.items',
          i18nPlaceholder: 'not_selected',
          cameraGroupsFieldName: 'camera_groups',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true, identity: 'int' }
  };
}

function computeCarsColorFieldSchema(items) {
  const i18nLabel = 'features.cars_color';
  return {
    name: FieldName.CarsColor,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          multiple: true,
          filterable: true,
          items: computeFieldI18nItems(items, i18nLabel),
          colorIcons: true,
          alphabeticalOrder: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeCreatedDateGteFieldSchema() {
  return {
    name: FieldName.CreatedDateGte,
    control: {
      i18nLabel: 'start',
      component: [
        FilterFormComponentName.Datetime,
        {
          type: 'datetime',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeCreatedDateLteFieldSchema() {
  return {
    name: FieldName.CreatedDateLte,
    control: {
      i18nLabel: 'end',
      component: [
        FilterFormComponentName.Datetime,
        {
          type: 'datetime',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeFacesEmotionsFieldSchema(items) {
  return computeCheckboxGroupFieldSchema(FieldName.FacesEmotions, 'features.faces_emotions', items);
}

function computeEpisodeFieldSchema() {
  return {
    name: FieldName.Episode,
    control: {
      i18nLabel: 'episode_id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeFirstEventDateGteFieldSchema() {
  return {
    name: FieldName.FirstEventDateGte,
    control: {
      i18nLabel: 'start',
      component: [
        FilterFormComponentName.Datetime,
        {
          type: 'datetime',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeFirstEventDateLteFieldSchema() {
  return {
    name: FieldName.FirstEventDateLte,
    control: {
      i18nLabel: 'end',
      component: [
        FilterFormComponentName.Datetime,
        {
          type: 'datetime',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeFacesGenderFieldSchema(items) {
  return computeCheckboxGroupFieldSchema(FieldName.FacesGender, 'features.faces_gender', items);
}

function computeFacesGlassesFieldSchema(items) {
  return computeCheckboxGroupFieldSchema(FieldName.FacesGlasses, 'features.faces_glasses', items);
}

function computeClusterIdFieldSchema() {
  return {
    name: FieldName.ClusterIdIn,
    control: {
      i18nLabel: 'cluster_id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true
        }
      ]
    }
  };
}

function computeHasCardFieldSchema() {
  return {
    name: FieldName.HasCard,
    control: {
      i18nLabel: 'matches,, 1',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'False', i18nLabel: 'only_without_matches' },
            { value: 'True', i18nLabel: 'only_with_matches' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeIdFieldSchema() {
  return {
    name: FieldName.Id,
    control: {
      i18nLabel: 'event_id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true,
          clearFieldsOnChange: Object.values(FieldName).filter((v) => v !== FieldName.Id)
        }
      ]
    }
  };
}

export function computeIdInAsIdFieldSchema() {
  return {
    name: FieldName.IdInAsId,
    control: {
      i18nLabel: 'id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true,
          clearFieldsOnChange: Object.values(FieldName).filter((v) => v !== FieldName.IdInAsId)
        }
      ]
    }
  };
}

function computeCarsLicensePlateCountryFieldSchema(items) {
  const i18nLabel = 'features.cars_license_plate_country';
  return {
    name: FieldName.CarsLicensePlateCountry,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          multiple: true,
          filterable: true,
          items: computeFieldI18nItems(items, i18nLabel),
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeCarsLicensePlateNumberFieldSchema() {
  const i18nLabel = 'features.cars_license_plate_number';
  return {
    name: FieldName.CarsLicensePlateNumber,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Input,
        {
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeCarsLicensePlateRegionFieldSchema(items) {
  const i18nLabel = 'features.cars_license_plate_region';
  return {
    name: FieldName.CarsLicensePlateRegion,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldI18nItems(items, i18nLabel),
          alphabeticalOrder: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeCarsSpecialVehicleTypeFieldSchema(items) {
  const i18nLabel = 'features.cars_special_vehicle_type';
  return {
    name: FieldName.CarsSpecialVehicleType,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.Select,
        {
          multiple: true,
          items: computeFieldI18nItems(items, i18nLabel),
          alphabeticalOrder: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeLimitFieldSchema() {
  return {
    name: FieldName.Limit,
    control: {
      i18nLabel: 'limit',
      component: [
        FilterFormComponentName.Select,
        {
          items: [10, 20, 50, 100, 200, 500],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { identity: 'int' }
  };
}

function computeFacesLivenessFieldSchema(items) {
  return computeCheckboxGroupFieldSchema(FieldName.FacesLiveness, 'features.faces_liveness', items);
}

function computeMatchedCardFieldSchema(cardType, fieldName = FieldName.MatchedCard) {
  return {
    name: fieldName,
    control: {
      i18nLabel: 'card',
      component: [
        FilterFormComponentName.Autocomplete,
        {
          fetchAction: `cards/${cardType}s/getByNameContains`,
          i18nPlaceholder: 'card',
          fetchByIdAction: `cards/${cardType}s/getById`,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { identity: 'int_or_null' }
  };
}

export function computeMatchedHumanCardFieldSchema() {
  return computeMatchedCardFieldSchema('human');
}

export function computeMatchedCarCardFieldSchema() {
  return computeMatchedCardFieldSchema('car');
}

export function computeMatchedHumanCardAsCardFieldSchema() {
  return computeMatchedCardFieldSchema('human', 'card');
}

export function computeMatchedCarCardAsCardFieldSchema() {
  return computeMatchedCardFieldSchema('car', 'card');
}

function computeMatchedListsFieldSchema() {
  return {
    name: FieldName.MatchedLists,
    control: {
      i18nLabel: 'watch_lists',
      component: [
        FilterFormComponentName.WatchLists,
        {
          itemsPath: '$store.getters.watchlistsInFilter',
          i18nPlaceholder: 'not_selected',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true, identity: 'int' }
  };
}

function computeFacesMedmaskFieldSchema(items) {
  return computeCheckboxGroupFieldSchema(FieldName.FacesMedmask, 'features.faces_medmask', items);
}

function computeCarsModelFieldSchema(items) {
  const i18nLabel = 'features.cars_model';
  return {
    name: FieldName.CarsModel,
    control: {
      i18nLabel,
      component: [
        FilterFormComponentName.CarsModelSelect,
        {
          items: computeFieldItems(items),
          makeField: 'make',
          multiple: true,
          filterable: true,
          allowCreate: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeNoMatchFieldSchema() {
  return {
    name: FieldName.NoMatch,
    control: {
      i18nLabel: 'matches,, 1',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'True', i18nLabel: 'only_without_matches' },
            { value: 'False', i18nLabel: 'only_with_matches' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computePageFieldSchema() {
  return { name: FieldName.Page };
}

function computeRaceFieldSchema() {
  return {
    name: FieldName.Race,
    control: {
      i18nLabel: 'race',
      component: [
        FilterFormComponentName.Select,
        {
          items: [],
          multiple: true,
          filterable: true,
          allowCreate: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true }
  };
}

function computeVideoArchiveFieldSchema() {
  return {
    name: FieldName.VideoArchive,
    control: {
      i18nLabel: 'video_archive_id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeBsTypeFieldSchema() {
  return {
    name: FieldName.BsType,
    control: {
      i18nLabel: 'bs_type',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'overall', i18nLabel: 'bs_type_overall' },
            { value: 'realtime', i18nLabel: 'bs_type_realtime' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function computeConfidenceFieldSchema() {
  return {
    name: FieldName.Confidence,
    control: {
      i18nLabel: 'confidence',
      component: [
        FilterFormComponentName.Confidence,
        {
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}
function computeOrderingFieldSchema() {
  return {
    name: FieldName.Ordering,
    control: {
      i18nLabel: 'ordering',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '-id', i18nLabel: 'date' },
            { value: '-looks_like_confidence', i18nLabel: 'confidence' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}
