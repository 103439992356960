import { VuexModule } from 'vuex-class-modules';
import { Store } from 'vuex/types';

const computeDynamicStoreModuleName = (baseName: string) => `${baseName}_${Date.now()}`;

export type DynamicStoreModule<TState extends VuexModule> = {
  state: TState;
  unreg: { (): void };
};

export type DynamicStoreModuleBuilder<TState extends VuexModule> = (store: Store<any>, name: string) => TState;

export type DynamicStoreModuleFactory<TState extends VuexModule> = (store: Store<any>) => DynamicStoreModule<TState>;

export function configureDynamicStoreModuleFactory<TState extends VuexModule>(
  baseName: string,
  builder: DynamicStoreModuleBuilder<TState>
): DynamicStoreModuleFactory<TState> {
  return function (store) {
    const name = computeDynamicStoreModuleName(baseName);
    return { state: builder(store, name), unreg: () => store.unregisterModule(name) };
  };
}
