import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';
import { schema } from './filter.schema.ts';

const getFilledArray = (length) =>
  Array.from(
    { length },
    (v, k) =>
      (v = {
        value: k + 1,
        label: k + 1
      })
  );

const emptyFilter = Object.seal({
    id_in: [],
    page: '',
    name__contains: null,
    limit: 20,
    created_date_gte: null,
    created_date_lte: null,
    cameras: [],
    counter: null,
    counters: [],
    camera_groups: [],
    faces_count_gte: null,
    silhouettes_count_gte: null,
    cars_count_gte: null,
    proximity_min_gte: null,
    proximity_min_lte: null,
    proximity_avg_gte: null,
    proximity_avg_lte: null,
    proximity_max_lte: null,
    proximity_max_gte: null
  }),
  emptyItem = Object.seal({
    id: null,
    open: false,
    event_id: null,
    last_event_date: null,
    events_count: 0,
    matched_event_id: null
  }),
  Name = 'counter-records',
  Action = require('../action.names').default.CounterRecords;

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    prev_page: [],
    items: [],
    item: {
      emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter),
      schema
    },
    Action
  },
  actions: getCrud(Name),
  getters: {
    getDistanceFilterOptions: () => getFilledArray(1000)
  }
};
