export const ImageType = {
  Event: 'event',
  ClusterEvent: 'clusterEvent',
  MatchedObject: 'matchedObject',
  Source: 'source'
};

export default function showImageByType({ state, dispatch }, { type, objectsType, data, no_video }) {
  const dialog = state.dialog.image;

  dialog.src = '';
  dialog.enabled = false;
  dialog.faces_bbox = null;
  dialog.bodies_bbox = null;
  dialog.silhouettes_bbox = null;
  dialog.cars_bbox = null;
  dialog.license_plates_bbox = null;
  dialog.no_video = no_video;

  if ((type === ImageType.Event || type === ImageType.ClusterEvent) && data) {
    const licensePlateBBox = data?.features?.license_plate_number?.bbox;
    dialog.enabled = true;
    dialog.src = data.fullframe;
    dialog[`${objectsType}_bbox`] = [type === ImageType.ClusterEvent ? getClusterEventBBox(data) : getEventBBox(data)];
    dialog.license_plates_bbox = licensePlateBBox ? [getLicensePlateBBox(licensePlateBBox)] : null;
  } else if (type === ImageType.Source) {
    dialog.enabled = true;
    dialog.src = data;
  }
}

export function getEventBBox(item) {
  const result = {
    x: item.frame_coords_left,
    y: item.frame_coords_top,
    w: item.frame_coords_right - item.frame_coords_left,
    h: item.frame_coords_bottom - item.frame_coords_top
  };
  return result;
}

export function getClusterEventBBox(item) {
  const [x2, y1, x1, y2] = item.bbox;
  const result = {
    x: x1,
    y: y1,
    w: x2 - x1,
    h: y2 - y1
  };
  return result;
}

function getLicensePlateBBox({ left, top, right, bottom }) {
  const result = {
    x: left,
    y: top,
    w: right - left,
    h: bottom - top
  };
  return result;
}
