<template>
  <em class="reports-notification">
    {{ $tf('see_the_report_progress') }}
    <a
      href="#"
      v-text="$tt('reports_page')"
      class="reports-notification__link"
      @click.prevent="dispatchClickEvent"
      @auxclick.prevent="dispatchAuxClickEvent"
    ></a>
  </em>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

const ReportsClickPath = '/reports';

const ReportsAuxClickPath = '#/reports';

@Component({ name: 'ReportsNotification' })
export default class extends Vue {
  dispatchClickEvent() {
    this.$emit('click', ReportsClickPath);
  }

  dispatchAuxClickEvent() {
    this.$emit('auxclick', ReportsAuxClickPath);
  }
}
</script>

<style lang="stylus">
.reports-notification {
  font-style: normal;

  &__link {
    text-decoration: underline;
  }
}
</style>
