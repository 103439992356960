






import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.FilledComponent,
  props: {
    item: {
      type: Object,
      required: true
    }
  }
})
export default class TableGeneratorFilledComponent extends Vue {
  @Prop({ type: Object, required: true })
  item!: any;
}
