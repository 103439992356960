<template>
  <img :class="classes" v-if="record.thumbnail" :src="record.thumbnail" @click.stop="openFullframeImageViewer" width="160" />
  <span v-else>-</span>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: { record: { type: Object, default: () => ({}) } }
})
export default class CounterRecordImage extends Vue {
  get classes() {
    return !this.record.fullframe ? 'counter-record-image' : '';
  }

  openFullframeImageViewer() {
    if (this.record.fullframe) {
      this.$store.dispatch('showImage', {
        src: this.record.fullframe,
        faces_bbox: this.normalizeRecordBBoxes(this.record.faces_bbox),
        cars_bbox: this.normalizeRecordBBoxes(this.record.cars_bbox),
        silhouettes_bbox: this.normalizeRecordBBoxes(this.record.silhouettes_bbox, this.record.proximity_distances)
      });
    }
  }

  normalizeRecordBBoxes(bboxes, distances) {
    const proximityOptions = this.$store.state.config.counter_records?.proximity_display;
    const enabled = proximityOptions?.enabled && distances?.length;
    return enabled
      ? this.$filters.getBBoxDistanceCoords(bboxes, distances, proximityOptions)
      : (bboxes ?? []).map((item) => this.$filters.polyArrayToShortRect(item));
  }
}
</script>

<style lang="stylus">
.counter-record-image {
  cursor: default;
}
</style>
