










import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseFormComponentName, BaseFormComponents } from './components/index';
import { getNumberValidator, getArrayValidator, getStringValidator } from '@/apps/common/validator';
import { IFormFieldComponent, IFormFieldValidator } from '@/definitions/base.form.schema';

const FieldPosition = {
  Left: 'left',
  Right: 'right',
  Stretch: 'stretch',
  Half: 'half',
  Quarter: 'quarter'
};

@Component({
  name: 'base-form-form-item'
})
export default class BaseFormFormItem extends Vue {
  @Prop({ type: Object, required: true })
  form!: any;

  @Prop({ type: String, default: FieldPosition.Stretch })
  align!: string;

  @Prop({ type: String, default: '' })
  i18nLabel!: string;

  @Prop({ type: String, default: '' })
  i18nDescription?: string;

  @Prop({ type: Array })
  validators?: IFormFieldValidator[];

  @Prop({ type: String })
  fieldName!: string;

  @Prop({ type: Object })
  component?: IFormFieldComponent;

  getTranslatedRule(v: IFormFieldValidator) {
    const rule = { required: v.required, trigger: v.trigger, validator: null, tmessage: null, message: null };

    switch (v.name) {
      case 'number':
        rule.validator = getNumberValidator({ ...v.props, required: v.required });
        rule.tmessage = this.$tf(v.props?.i18nMessage);
        break;
      case 'array':
        rule.validator = getArrayValidator({ ...v.props, required: v.required });
        rule.tmessage = this.$tf(v.props?.i18nMessage);
        break;
      case 'string':
        rule.validator = getStringValidator({ ...v.props });
        rule.tmessage = this.$tf(v.props?.i18nMessage);
        break;
      default:
        rule.message = this.$tf(v.props?.i18nMessage);
        break;
    }

    return rule;
  }

  get translatedRules() {
    const validators: IFormFieldValidator[] = this.validators as IFormFieldValidator[];
    return validators?.map(this.getTranslatedRule);
  }

  get isClearable() {
    return this.form.clearable && !this.form.isFieldClear(this.fieldName);
  }

  clearHandler() {
    this.form.clearFields([this.fieldName]);
  }

  get label() {
    return this.i18nLabel && this.$tf(this.i18nLabel);
  }

  get description() {
    return this.i18nDescription && this.$tf(this.i18nDescription);
  }

  get componentClassInstance() {
    return BaseFormComponents[this.componentName];
  }

  get componentName() {
    return this.component?.name || BaseFormComponentName.Input;
  }

  get className() {
    switch (this.align) {
      case FieldPosition.Left:
        return 'base-form-control_left';
      case FieldPosition.Right:
        return 'base-form-control_right';
      case FieldPosition.Half:
        return 'base-form-control_half';
      case FieldPosition.Quarter:
        return 'base-form-control_quarter';
    }
    return 'base-form-control_stretch';
  }
}
