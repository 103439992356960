<template></template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    form: { type: Object, required: true },
    fieldName: { type: [String, Array], required: true },
    i18nPlaceholder: { type: [String, Object, Array], default: '' },
    clearFieldsOnChange: { type: Array, default: () => [] }
  }
})
export default class FilterFormComponent extends Vue {
  get model() {
    if (Array.isArray(this.fieldName)) {
      const [firstField, secondField] = this.fieldName;
      return {
        [firstField]: this.form.getCurrentFieldValue(firstField),
        [secondField]: this.form.getCurrentFieldValue(secondField)
      };
    }
    return this.form.getCurrentFieldValue(this.fieldName);
  }

  set model(fieldValue) {
    return this.form.setCurrentFieldValue(this.fieldName, fieldValue);
  }

  get placeholder() {
    if (Array.isArray(this.i18nPlaceholder)) {
      const [firstPlaceholder, secondPlaceholder] = this.i18nPlaceholder;
      return {
        firstPlaceholder: this.$tf(firstPlaceholder),
        secondPlaceholder: this.$tf(secondPlaceholder)
      };
    }
    return this.i18nPlaceholder && this.$tf(this.i18nPlaceholder);
  }

  clearLinkedFields() {
    this.form.clearFields(this.clearFieldsOnChange);
  }
}
</script>
