<template>
  <page-layout>
    <span slot="header-name">{{ $tf('search') }}</span>
    <div slot="content">
      <div class="page-search__content">
        <div class="cols mar-bottom--1 page-search-source">
          <div class="flex-cell--static">
            <h3>{{ $tf('search_what') }}</h3>
            <el-radio-group v-model="filter.objectsType">
              <el-radio :key="`objectsType-${item.name}`" :label="item.name" v-for="item in objectsItems">{{ $tf(item.name) }}</el-radio>
            </el-radio-group>

            <common-tabs v-model="filter.sourceType" :items="sourceItems" class="page-search__source-tabs"></common-tabs>

            <div>
              <component
                ref="sourceSelector"
                :is="sourceSelectorComponentName"
                :objects="filter.objectsType"
                :state="state"
                :filter="filter"
                @reset="resetSearchResults"
              ></component>
            </div>

            <h3 class="page-search__where-header">{{ $tf('search_where') }}</h3>

            <el-radio-group v-model="filter.targetType">
              <el-radio :key="`targetType-${item.name}`" :label="item.name" v-for="item in targetItems">{{ $tf(item.i18n) }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" name="search-form">
          <el-form-item :label="$tf('search_looks_like')" v-if="filter.looks_like">
            <span name="looks-like" style="opacity: 0.5">{{ filter.looks_like }}</span>
          </el-form-item>
        </el-form>
        <div class="mar-top--large">
          <el-alert
            v-if="isDisabledByMultifaceAndDate"
            :title="$tf('date_multiselect_warning')"
            type="warning"
            class="mar-v-1"
            show-icon
            effect="dark"
            :closable="false"
          ></el-alert>

          <el-button name="reset-btn" size="large" @click="reset">{{ $tf('reset') }}</el-button>
          <el-button name="search-btn" size="large" type="primary" @click="search" :loading="state.loading" :disabled="!canSearch"
            >{{ $tf('search') }}
          </el-button>
        </div>
        <div v-if="state.loaded" class="search-results mar-v--2" v-loading="state.loading">
          <h2>{{ $tf(['found,,2', 'items,,1']) }} - {{ state.items.length }}</h2>

          <el-row class="cols--center mar-v--1" row-key="id" v-if="filter.ordering === '-id' && filter.targetType !== TargetType.Social">
            <div class="cell"></div>
            <div name="table-pagination" class="table-pagination pull-right mar-left--1">
              <table-navigation :state="state" :change-handler="search"></table-navigation>
            </div>
          </el-row>

          <component v-if="state.loaded" :is="searchResultComponent" :objects="filter.objectsType" :state="state"></component>

          <el-row class="cols--center mar-v--1" row-key="id" v-if="filter.ordering === '-id' && filter.targetType !== TargetType.Social">
            <div class="cell"></div>
            <div name="table-pagination" class="table-pagination pull-right mar-left--1">
              <table-navigation :state="state" :change-handler="search"></table-navigation>
            </div>
          </el-row>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import qs from 'qs';
import { SearchTargetType as TargetType } from '@/store/search/objects';
import { ObjectsType } from '@/store/objects/get.module';
import TableNavigation from '@/components/tables/navigation.vue';
import ConfidenceLabel from '../../common/confidence.label';
import CardsSearchResults from './results/cards';
import EventsSearchResults from './results/events';
import SocialSearchResults from './results/social';
import ClustersSearchResults from './results/clusters';
import CaseFaceObjectsSelector from './selectors/case.face';
import CardObjectsSelector from './selectors/card';
import EventObjectsSelector from './selectors/event';
import FileObjectsSelector from './selectors/file';

import PageLayout from '@/components/page/layout';
import { ObjectsTypeSingleForm } from '../../../store/objects/get.module';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const FilterKey = 'search_objects.filter.current';
const FilterFieldsToReset = ['objectsType', 'sourceType', 'targetType'];

export const SourceType = {
    Event: 'event',
    CaseFace: 'caseface',
    CardObject: 'card',
    Upload: 'upload'
  },
  SourceSelectorComponent = {
    event: 'event-objects-selector',
    caseface: 'case-face-objects-selector',
    card: 'card-objects-selector',
    upload: 'file-objects-selector'
  },
  SearchResultsComponent = {
    events: 'events-search-results',
    cards: 'cards-search-results',
    clusters: 'clusters-search-results',
    social: 'social-search-results'
  };

export default {
  components: {
    SocialSearchResults,
    CaseFaceObjectsSelector,
    FileObjectsSelector,
    CardsSearchResults,
    CardObjectsSelector,
    EventsSearchResults,
    EventObjectsSelector,
    ClustersSearchResults,
    ConfidenceLabel,
    TableNavigation,
    PageLayout
  },
  name: 'page-search',
  data: function () {
    return {
      TargetType,
      SourceType
    };
  },
  computed: {
    searchResultComponent() {
      return SearchResultsComponent[this.filter.targetType];
    },
    sourceSelectorComponentName() {
      return SourceSelectorComponent[this.filter.sourceType];
    },
    sourceItems() {
      const items = Object.values(SourceType).map((v) => ({ name: v, i18n: `search_from_${v}` })),
        enabledCaseFacesSource = this.$store.getters.plugins.incidents && this.filter.objectsType === ObjectsType.Faces;
      if (!enabledCaseFacesSource) items.splice(1, 1);
      return items;
    },
    targetItems() {
      const items = Object.values(TargetType).map((v) => ({ name: v, i18n: `search_in_${v}` }));
      if (!this.$store.getters.features.social_search) items.splice(3, 1);
      return items;
    },
    objectsItems() {
      return this.$store.getters.enabledObjects.map((v) => ({ name: v }));
    },
    state() {
      return this.$store.state.search_objects;
    },
    filter() {
      return this.$store.state.search_objects.filter.current;
    },
    canSearch() {
      return !this.state.loading && this.filter.looks_like && !this.isDisabledByMultifaceAndDate;
    },
    isDisabledByMultifaceAndDate() {
      return (
        this.filter.ordering === '-id' &&
        this.filter.sourceType === SourceType.CardObject &&
        Array.isArray(this.filter.looks_like) &&
        this.filter.looks_like.length > 1
      );
    }
  },
  watch: {
    'filter.objectsType': function (v, p) {
      this.filter.looks_like = '';
      this.state.loaded = false;
      this.$refs?.sourceSelector?.reset();
      this.setDefaultThreshold(v);
      if (this.filter.sourceType === SourceType.CaseFace) this.filter.sourceType = SourceType.Event;
    },
    'filter.sourceType': function (v, p) {
      this.filter.looks_like = '';
      this.state.loaded = false;
    },
    '$route.params.filter': function (v, p) {
      applyQueryFilter.call(this, v);
    },
    filter: {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v, k) => !!v && k !== 'page'),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== decodeURIComponent(filterString)) {
          this.$router.push({ path: '/search/' + filterString });
          this.state.items = [];
          this.state.loaded = false;
          this.state.prev_page = [];
          this.filter.page = '';
        }
      }
    }
  },
  beforeCreate() {
    applyQueryFilter.call(this, this.$route.params.filter);
  },
  beforeMount() {
    this.setDefaultThreshold(this.filter.objectsType);
  },
  mounted() {
    this.$store.state.app.fileHandler = (files) => {
      this.filter.sourceType = SourceType.Upload;
      this.$nextTick(() => {
        const event = { target: { files } };
        this.$refs.sourceSelector.selectFile(event);
      });
    };
  },
  beforeDestroy() {
    this.$store.state.app.fileHandler = null;
  },
  async beforeRouteLeave(to, from, next) {
    await this.resetSearchResults();
    await next();
  },
  methods: {
    setDefaultThreshold(v) {
      const objectType = ObjectsTypeSingleForm[v];
      const defaultThreshold = this.$store.getters.objectConfidenceThresholds[objectType + '_confidence_threshold'];
      this.state.filter.empty.threshold = defaultThreshold;
      this.filter.threshold = defaultThreshold;
    },
    search() {
      this.displayResults = true;
      this.$store.dispatch('searchObjects').catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });
    },
    getBoxByEvent(event) {
      return {
        x: event.frame_coords_left,
        y: event.frame_coords_top,
        w: event.frame_coords_right - event.frame_coords_left,
        h: event.frame_coords_bottom - event.frame_coords_top
      };
    },
    getBoxByDetect(v) {
      let { left, top, right, bottom } = v.bbox;
      return {
        x: left,
        y: top,
        w: right - left,
        h: bottom - top
      };
    },
    reset() {
      this.$refs?.sourceSelector?.reset();
      FilterFieldsToReset.forEach((fieldToReset) => {
        this.state.filter.current[fieldToReset] = _.cloneDeep(this.state.filter.empty[fieldToReset]);
      });
    },
    async resetSearchResults() {
      this.state.loaded = false;
      this.state.items = [];

      const filter = this.$route.params.filter;
      const subStr = '&looks_like=' + this.filter.looks_like;
      const newFilter = filter?.replace(subStr, '');
      await localStorage.setItem(FilterKey, newFilter);
    }
  }
};

function applyQueryFilter(stringifiedFilter) {
  const { filter } = this.$store.state.search_objects;
  let parsed = qs.parse(stringifiedFilter, { arrayLimit: 100 }),
    objectFilter = Object.assign(_.cloneDeep(filter.empty), parsed),
    filtersEqual = false,
    cameras = objectFilter['cameras'] || [],
    cameraGroups = objectFilter['camera_groups'] || [];

  cameraGroups = cameraGroups instanceof Array ? cameraGroups : [cameraGroups];

  objectFilter['camera_groups'] = cameraGroups.map((v) => parseInt(v));
  objectFilter['cameras'] = cameras.map((v) => parseInt(v));
  objectFilter['limit'] = parseInt(objectFilter['limit']);
  objectFilter['threshold'] = parseFloat(objectFilter['threshold']);
  objectFilter['confidence'] = parseFloat(objectFilter['confidence']);
  filtersEqual = _.isEqual(filter.current, objectFilter);
  filter.current = objectFilter;
  return !filtersEqual;
}
</script>

<style lang="stylus">
.search-results .el-table__row {
  cursor: pointer;
}

.input--text-info {
  opacity: 0.5;
  margin-left: 1rem;
  margin-right: 1rem;
}

.page-search {
  padding: 0 !important;

  &__content {
    height: 100%;
    padding: 2rem;
    padding-top: 1.4rem;
    min-width: 640px;
    box-sizing: border-box;
  }

  &__source-tabs {
    margin-top: 3rem;
  }

  &__where-header {
    margin-top: 3rem !important;
  }

  &-header {
    height: 6.25rem;
    color: rgba(255, 255, 255, 0.95);

    &__content {
      display: flex;
      align-items: center;
    }

    &__title {
      margin: 0;
    }

    &__item:not(:last-child) {
      margin-right: 3rem;
    }
  }

  &-source {
    display: flex;
    align-items: center;
  }
}
</style>
