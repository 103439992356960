<template>
  <el-form-item>
    <objects-inline ref="userFace" target="user" editable show-id :id="form.item.id"></objects-inline>
  </el-form-item>
</template>

<script>
import { Component } from 'vue-property-decorator';
import BaseFormComponent from '../component.vue';
import ObjectsInline from '@/components/objects/inline.vue';

@Component({
  components: {
    ObjectsInline
  }
})
export default class BaseFormComponentUserFaces extends BaseFormComponent {}
</script>
