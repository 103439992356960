import { Action, RegisterOptions, VuexModule } from 'vuex-class-modules';
import { BaseItemsState, ItemStorage, StateFilter } from '@/definitions/base.state';
import { AnyFieldOfTypeWithId } from '@/definitions/base.actions';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import { RawLocation } from 'vue-router';

export const AutoUpdateIntervalInMs = 20000;
export const getDelay = (time = 1000) => new Promise((r, j) => setTimeout(r, time));
export const AclPrefix = 'ffsecurity';
export interface IIntervalData {
  enabled: boolean;
  intervalInMs: number;
  intervalIndex: number;
}

export type PartialWithId<T> = Partial<T> & { id: string | number };

export class BaseItemsStateModule<T, F> extends VuexModule implements BaseItemsState<T, F> {
  name = 'base';
  routeName = 'baseRouteName';
  aclModelName = 'base';

  loading = false;
  appending = false;
  playing = true;
  loaded = false;
  loadError: any | null = null;
  items: T[] = [];
  selectedItems: T[] = [];
  itemHandler?: (...args: any[]) => any;
  customRequestHandler: any | null = null;

  autoUpdate: IIntervalData = {
    enabled: false,
    intervalInMs: AutoUpdateIntervalInMs,
    intervalIndex: 0
  };

  item: ItemStorage<T> = {
    current: {} as T,
    empty: {} as T
  };

  page = '';
  next_page: string | null = null;
  prev_page: string[] = [];
  limits: number[] = [10, 20, 50, 100, 200, 500];

  filter: StateFilter<F> = {
    current: {} as F,
    empty: {} as F,
    schema: null
  };

  constructor(options: RegisterOptions) {
    super(options);
  }

  get aclViewPermissionName(): string {
    return `${AclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${AclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${AclPrefix}.change_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${AclPrefix}.delete_${this.aclModelName}`;
  }

  init(emptyItem: T, emptyFilter: F, filterSchema?: any) {
    this.filter.empty = _.cloneDeep(emptyFilter);
    this.filter.current = _.cloneDeep(emptyFilter);
    this.filter.schema = filterSchema;
    this.item.empty = _.cloneDeep(emptyItem);
  }

  resetBeforeGet(): void {
    this.selectedItems = [];
  }

  setCustomRequestHandler(v: any): void {
    this.customRequestHandler = v;
  }

  async requestImplementation(payload: any): Promise<any> {
    return this.customRequestHandler ? this.customRequestHandler(payload) : this.dispatchImplementation('requestApi', payload);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    throw new Error('Must be overrided');
  }

  @Action
  async getWithFilter(filter: F): Promise<boolean> {
    this.filter.current = filter;
    return this.get();
  }

  @Action
  async get(resetState = true): Promise<boolean> {
    if (this.loading || this.appending) return false;
    if (resetState) this.items = [];
    this.setLoading(true);

    try {
      const responseData = await this.requestImplementation({ model: this.name, method: 'GET', filter: this.filter.current });
      this.items = responseData.results;
      this.next_page = responseData.next_page;
    } catch (e) {
      this.loadError = e;
    }

    this.setLoading(false);
    this.setAutoUpdate(this.autoUpdate.enabled);
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  @Action
  async getByFilter(filter: Partial<F> | null): Promise<T[]> {
    let items = [];
    const responseData = await this.requestImplementation({ model: this.name, method: 'GET', filter });
    items = responseData.results;
    return items;
  }

  @Action
  async getById({ id }: AnyFieldOfTypeWithId<T>): Promise<T> {
    return this.requestImplementation({ model: this.name, method: 'GET', id });
  }

  @Action
  getByNameContains(name: string) {
    return this.getByFilter({ name_contains: name, limit: 10 } as any as F);
  }

  setLoading(value: boolean) {
    this.loading = value;

    if (this.loading) {
      this.loadError = null;
    } else {
      this.loaded = true;
    }
  }

  setAutoUpdate(value: boolean, intervalInMs?: number) {
    clearInterval(this.autoUpdate.intervalIndex);
    this.autoUpdate.enabled = value;
    this.autoUpdate.intervalInMs = intervalInMs || this.autoUpdate.intervalInMs;
    if (value) {
      this.autoUpdate.intervalIndex = setInterval(() => this.get(false), this.autoUpdate.intervalInMs);
    }
  }

  @Action
  async append(): Promise<boolean> {
    const nextPage = this.next_page && getPageFromNextPage(this.next_page);
    if (!nextPage) return false;
    if (this.loading || this.appending) return false;

    this.appending = true;
    this.loadError = null;

    try {
      const { results, next_page } = await this.requestImplementation({ model: this.name, method: 'GET', filter: { ...this.filter.current, page: nextPage } });
      this.items = this.items.concat(results);
      this.next_page = next_page;
    } catch (e) {
      this.loadError = e;
    }

    this.appending = false;
    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  @Action
  async create(data: Partial<T>): Promise<T> {
    return this.requestImplementation({ model: this.name, method: 'POST', data });
  }

  @Action
  async update(data: AnyFieldOfTypeWithId<T>): Promise<T> {
    const id: any = data.id;
    return this.requestImplementation({ model: this.name, id, method: 'PATCH', data });
  }

  @Action
  async delete(id: string | number): Promise<T> {
    return this.requestImplementation({ model: this.name, id, method: 'DELETE' });
  }

  togglePlaying(): void {
    this.playing = !this.playing;
  }

  getNewItemRoute() {
    return {
      name: `${this.routeName}Create`
    };
  }

  getItemRoute(item: T): RawLocation {
    const id = (item as any as PartialWithId<T>).id;
    const params = {
      id: id,
      item: item
    };
    return {
      name: `${this.routeName}Edit`,
      params: params as any
    };
  }

  getListRoute(filter?: F): RawLocation {
    return {
      name: `${this.routeName}`,
      params: {
        filter: getFilterString(filter, this.filter.empty)
      }
    };
  }

  dispose() {
    this.setAutoUpdate(false);
  }
}

export function getPageFromNextPage(value: string) {
  let filterFromPageQuery = qs.parse((value || '').split('?')[1] || '', { arrayLimit: 100 });
  return filterFromPageQuery.page;
}

export function getFilterString(filter: any, emptyFilter: any): string {
  return qs.stringify(
    _.pickBy({ ...emptyFilter, ...filter }, (v) => !!v),
    { arrayFormat: 'repeat' }
  );
}
