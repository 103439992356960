export function groupCounterRecordsByCreatedDate(records) {
  const groups = records.reduce((groups, record) => {
    const createdDate = computeRecordsGroupTimestamp(record.created_date);
    completeRecordsGroup(getOrCreateRecordsGroup(groups, createdDate), record);
    return groups;
  }, {});
  return sortGroupsByCreatedDate(sortGroupsRecordsByCreatedDate(Object.values(groups)));
}

function computeRecordsGroupTimestamp(createdDateString) {
  const createdDate = new Date(createdDateString);
  createdDate.setMilliseconds(0);
  return createdDate.valueOf();
}

function completeRecordsGroup(group, record) {
  group.records.push({ ...record, created_date: computeRecordCreatedDateTimestamp(record.created_date) });
  group.silhouettes_count += record.silhouettes_count;
  group.faces_count += record.faces_count;
  group.cars_count += record.cars_count;
}

function computeRecordCreatedDateTimestamp(createdDateString) {
  return new Date(createdDateString).valueOf();
}

function getOrCreateRecordsGroup(groups, createdDate) {
  return groups[createdDate] ?? (groups[createdDate] = createRecordsGroup(createdDate));
}

function createRecordsGroup(createdDate) {
  return { records: [], faces_count: 0, silhouettes_count: 0, cars_count: 0, created_date: createdDate };
}

function sortGroupsRecordsByCreatedDate(groups) {
  return groups.forEach(({ records }) => records.sort(compareCreatedDatesAsc)), groups;
}

function sortGroupsByCreatedDate(groups) {
  return groups.sort(compareCreatedDatesAsc);
}

function compareCreatedDatesAsc(itemA, itemB) {
  return itemA.created_date - itemB.created_date;
}
