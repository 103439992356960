<template>
  <el-form ref="userPasswords" :model="form.item" :rules="rules">
    <el-form-item prop="password" :label="$tf(`common.password`)" class="el-form-item--small">
      <el-input type="password" v-model="form.item.password" autocomplete="off" />
    </el-form-item>
    <el-form-item prop="confirm_password" :label="$tf(`common.confirm_password`)">
      <el-input type="password" v-model="form.item.confirm_password" autocomplete="off" />
    </el-form-item>
  </el-form>
</template>

<script>
import { Component, Watch } from 'vue-property-decorator';
import BaseFormComponent from '../component.vue';
import { symbolAsciiRule } from '@/apps/common/validator';

@Component
export default class BaseFormComponentUserPasswords extends BaseFormComponent {
  rules = {};

  baseRules = {
    password: [
      { required: true, message: 'error.required.password', trigger: 'change' },
      { min: 6, message: 'error.minimum.password', trigger: 'change' },
      symbolAsciiRule
    ],
    confirm_password: [{ required: true, message: 'error.required.password', trigger: 'change' }, symbolAsciiRule]
  };

  @Watch('$route', { immediate: true })
  routeChangeHandler() {
    this.userModeHandler();
  }

  get isCreateMode() {
    return !this.$route.params.id;
  }

  validateConfirmationWhenPasswordIsValid(_rule, value, callback) {
    if (this.form.item.confirm_password) {
      this.$refs.userPasswords.validateField('confirm_password');
    }
    callback();
  }

  validatePasswordsEquality(_rule, value, callback) {
    if (value !== this.form.item.password) {
      this.form.item.passwordsValid = false;
      callback(this.$t('error.confirm.password'));
    } else {
      callback();
      this.form.item.passwordsValid = true;
    }
  }

  userModeHandler() {
    if (!this.isCreateMode) {
      this.baseRules.password[0].required = false;
      this.baseRules.confirm_password[0].required = false;
    }

    this.rules = this.$applyRuleMessages(this.baseRules);
  }

  created() {
    this.baseRules.password.push({ validator: this.validateConfirmationWhenPasswordIsValid, trigger: 'change' });
    this.baseRules.confirm_password.push({ validator: this.validatePasswordsEquality, trigger: 'change' });

    this.userModeHandler();

    Object.assign(this.form.item, { password: '', confirm_password: '', passwordsValid: !this.isCreateMode });
  }
}
</script>
