



import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IAuditLog } from '@/store/audit-logs/types';

@Component({
  name: TableComponentNames.ObjectTypeLabel
})
export default class TableGeneratorObjectTypeLabel extends Vue {
  @Prop({ required: true })
  item!: IAuditLog;

  get label() {
    let objectType = this.item.object_type;
    if (objectType) {
      return this.$store.state.audit_logs.ObjectTypesI18n[objectType] ?? objectType;
    }
    return 'unknown';
  }
}
