export const GlobalBusName = 'ff-services-bus';

export const MicroserviceEventType = {
  AddService: 'add_service',
  AddMainMenu: 'add_menu',
  AddSettingsMenu: 'add_extended_menu',
  AddRoute: 'add_route',
  AddStoreModule: 'add_module',
  RemoveStoreModule: 'remove_store_module'
};

export interface IServiceData {
  name: string;
  main_menu?: IMenu;
  settings_menu?: IMenu;
  routes?: any[];
  modules?: IAddStoreModule[];
}

export interface IMenu {
  name: string;
  i18n?: string;
  classes?: string;
  path: string;
  group?: boolean;
  serverPath?: string;
  action?: string;
}

export interface IUISevice {
  name: string;
  js: string;
  css?: string;
}

export interface IAddStoreModule {
  name: string;
  module?: any;
}
