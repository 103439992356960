import Router, { RawLocation, RouterOptions } from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = Router;

export class AppRouter extends Router {
  constructor(options: RouterOptions) {
    super(options);
  }

  errorHandler(error: Error) {
    const isRedirectError = isNavigationFailure(error, NavigationFailureType.redirected);
    const isDuplicateError = isNavigationFailure(error, NavigationFailureType.duplicated);
    if (isRedirectError || isDuplicateError) {
      console.warn(error.message);
    } else {
      throw error;
    }
  }

  push(location: RawLocation): Promise<any> {
    return super.push(location).catch(this.errorHandler);
  }

  replace(location: RawLocation): Promise<any> {
    return super.replace(location).catch(this.errorHandler);
  }
}
