<template>
  <el-form-item :label="indexLabelPart + (meta.label || meta.name)" v-if="isRenderEmptyValue(item[meta.name])">
    <div v-if="meta.type === ItemTypes.List">
      <el-select
        v-if="editable(meta)"
        v-model="item[meta.name]"
        :name="meta.name"
        :multiple="meta.multiple"
        :placeholder="meta.placeholder"
        :allow-create="meta.allow_create"
        filterable
        default-first-option
      >
        <el-option
          v-for="listItem in $store.state.dicts.items[meta.items]"
          :key="listItem.id"
          :value="meta.custom_id ? listItem[meta.custom_id] : listItem.id"
          :label="$filters.shortString(listItem.label || listItem.name)"
        ></el-option>
      </el-select>
      <div v-else>
        <span v-if="meta.multiple">{{
          item[meta.name] && item[meta.name].map((metaId) => getDictItemLabel(metaId, meta.items, meta.custom_id)).join(', ')
        }}</span>
        <span v-else>{{ getDictItemLabel(item[meta.name], meta.items, meta.custom_id) }}</span>
      </div>
    </div>

    <div v-else-if="meta.type === ItemTypes.ValueList">
      <el-select
        v-if="editable(meta)"
        v-model="item[meta.name]"
        :name="meta.name"
        :multiple="meta.multiple"
        :placeholder="meta.placeholder"
        :allow-create="meta.allow_create"
        filterable
        clearable
        default-first-option
        class="metafield-select"
      >
        <el-option
          v-for="listItem in $store.state.dicts.items[meta.items || meta.name]"
          :key="listItem"
          :value="listItem"
          :label="$filters.shortString(listItem, 32)"
        ></el-option>
      </el-select>
      <div v-else>
        <span v-if="meta.multiple">{{ item[meta.name] && item[meta.name].map((v) => v).join(', ') }}</span>
        <span v-else>{{ item[meta.name] }}</span>
      </div>
    </div>

    <div v-else-if="meta.type === ItemTypes.ObjectList">
      <div v-if="meta.simple">
        <meta-field
          :edit="edit"
          :meta="Object.assign({}, meta.object, { name: metaKey })"
          :index="metaKey"
          :item="item[meta.name]"
          v-for="(metaValue, metaKey) in item[meta.name]"
          :key="'meta-field-' + metaKey"
        ></meta-field>
      </div>
      <div v-else>
        <div v-for="(metaItem, metaKey) in item[meta.name]" style="padding-left: 2rem">
          <meta-field
            :meta="metaMeta"
            :edit="edit"
            :index="metaKey"
            :item="metaItem"
            v-for="metaMeta in meta.object"
            :key="'meta-field-2-' + metaKey"
          ></meta-field>
        </div>
      </div>

      <div v-if="edit">
        <el-button
          size="mini"
          circle
          icon="el-icon-minus"
          @click="() => removeObjectFromList(meta.name)"
          v-if="item[meta.name] && item[meta.name].length"
        ></el-button>
        <el-button size="mini" circle icon="el-icon-plus" @click="() => addObjectToList(meta.name, meta.simple)"></el-button>
      </div>
    </div>

    <div v-else-if="meta.type === ItemTypes.Boolean">
      <div v-if="editable(meta)">
        <el-checkbox :name="meta.name" v-model="item[meta.name]"></el-checkbox>
      </div>
      <div v-else>
        {{ item[meta.name] ? '+' : '-' }}
      </div>
    </div>

    <div v-else-if="meta.type === ItemTypes.Datetime">
      <div v-if="editable(meta)">
        <el-date-picker
          :name="meta.name"
          :disabled="!editable(meta)"
          :editable="true"
          :placeholder="meta.placeholder"
          type="datetime"
          :value="getDate(item[meta.name])"
          @change="(v) => setDate(item, meta.name, v)"
          @input="(v) => setDate(item, meta.name, v)"
        >
        </el-date-picker>
      </div>
      <div v-else>
        <span>{{ $filters.formatDateTime(item[meta.name]) }}</span>
      </div>
    </div>

    <div v-else-if="meta.type === ItemTypes.Date">
      <div v-if="editable(meta)">
        <el-date-picker
          :name="meta.name"
          :disabled="!editable(meta)"
          :editable="true"
          :placeholder="meta.placeholder"
          type="date"
          :value="getDate(item[meta.name])"
          @change="(v) => setDate(item, meta.name, v)"
          @input="(v) => setDate(item, meta.name, v)"
        ></el-date-picker>
      </div>
      <div v-else>
        <span>{{ $filters.formatDate(item[meta.name]) }}</span>
      </div>
    </div>

    <div v-else>
      <el-input v-if="editable(meta)" :placeholder="meta.placeholder" :name="meta.name" v-model="item[meta.name]"></el-input>
      <span v-else>
        {{ item && item[meta.name] }}
      </span>
    </div>
  </el-form-item>
</template>

<script>
import Vue from 'vue';

const ItemTypes = {
  ObjectList: 'objectlist',
  List: 'list',
  ValueList: 'valuelist',
  Boolean: 'boolean',
  Datetime: 'datetime',
  Date: 'date'
};

export default {
  name: 'meta-field',
  props: {
    edit: Boolean,
    item: [Object, Array],
    meta: Object,
    index: Number
  },
  data() {
    return {
      ItemTypes
    };
  },
  computed: {
    indexLabelPart() {
      return this.index > -1 ? this.index + 1 + '. ' : '';
    }
  },
  methods: {
    getDate(value) {
      return value ? new Date(value) : value;
    },
    setDate(item, field, value) {
      Vue.set(item, field, (value && value.toISOString()) || null);
    },
    isRenderEmptyValue(v) {
      const isEmpty = v === null || v === undefined || v === '' || (v instanceof Array && v.length === 0);
      return this.edit || !isEmpty;
    },
    editable(meta) {
      return this.edit && meta.editable !== false;
    },
    removeObjectFromList(name) {
      this.item[name] && this.item[name].pop();
    },
    addObjectToList(name, simple) {
      this.item[name] ? this.item[name].push(simple ? '' : {}) : Vue.set(this.item, name, [simple ? '' : {}]);
    },
    getDictItemLabel(id, dictName, fieldName = 'id') {
      const dict = this.$store.state.dicts.items[dictName],
        item = dict && dict.find((v) => v[fieldName] === id);
      return item ? item.label || item.name : dict ? this.$tf('item | not_found') : this.$tf('dictionary | not_found');
    }
  }
};
</script>
