import createCommonObject from '../create-common-object';
export default createCommonObject(
  function (camera, timeFrom, timeTo, limit) {
    const isoTimeFrom = new Date(timeFrom * 1000).toISOString();
    const isoTimeTo = new Date(timeTo * 1000).toISOString();
    return {
      model: 'events/faces',
      filter: { cameras: camera, created_date_gte: isoTimeFrom, created_date_lte: isoTimeTo, limit }
    };
  },
  function (data, object) {
    object.isLoadedAll = data.next_page === null;
    return data.results.map((item) => {
      const from = new Date(item.created_date).getTime() / 1000;
      return {
        id: item.id,
        type: item.matched ? 2 : 1,
        timeFrom: from,
        timeTo: from,
        metadata: {
          quality: item.quality,
          thumbnail: item.thumbnail
        }
      };
    });
  }
);
