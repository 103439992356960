






import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'reports-select-types',
  props: {
    value: String
  }
})
export default class ReportsSelectTypes extends Vue {
  options = ['standard_report', 'work_time_report'];

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }

  get filteredOptions() {
    return this.options.filter((option) => !(option === 'work_time_report' && this.$route.name === 'search'));
  }
}
