<template>
  <div class="confidence-select">
    <div class="box-container">
      <div class="item-label parameter-description text-center">
        {{ infoLabel }}
      </div>

      <div class="items-container">
        <div class="items-container--bg">
          <div
            class="item"
            :key="key"
            v-for="(item, key) in items"
            :style="{
              backgroundColor: computeItemColor(item, hoveredItem),
              transitionDelay: key * 0.05 + 's',
              borderColor: computeBorderColor(item, hoveredItem)
            }"
            :class="{ 'item-selected': selectedValue === key }"
            @mouseover="setHoveredItem(item)"
            @mouseout="setHoveredItem(null)"
            @click="changeValue(item.confidence)"
          >
            <i v-if="selectedValue === key"></i>
          </div>
        </div>
      </div>

      <div class="item-input text-center">
        <el-input ref="confidenceInput" :value="stringValue" @input="changeValueByInput" @blur="valueHandler(internalValue)"></el-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Vue, Component, Watch } from 'vue-property-decorator';
import Color from 'color';
import _ from '@/apps/common/lodash';

@Component({
  name: 'confidence-select',
  props: {
    objects: {
      type: String,
      default: 'faces'
    },
    value: {
      type: [Number, String],
      required: true
    }
  }
})
export default class ConfidenceSelect extends Vue {
  internalValue = 0;
  stringValue = '';
  hoveredItem = null;

  get infoLabel() {
    return this.getItemLabel(this.hoveredItem || this.selectedItem);
  }

  get selectedItem() {
    return this.items[this.selectedValue];
  }

  get selectedValue() {
    const index = this.getItemIndexByValue(this.internalValue);
    return index;
  }

  get items() {
    return this.$store.getters.getConfidenceDisplay(this.objects);
  }

  @Watch('value')
  valueHandler(v) {
    this.internalValue = v < 0 ? 0 : v > 1 ? 1 : v;
    this.stringValue = String(this.internalValue);
  }

  created() {
    this.valueHandler(this.value);
    this.setHoveredItem = _.debounce(this.setHoveredItem, 50);
  }

  changeValueByInput(v) {
    const result = Number(v);
    this.stringValue = v;
    if (Number.isNaN(result) || !result) return;

    this.$emit('change', result);
    this.$emit('input', result);
  }

  changeValue(value) {
    const result = Number(value) || 0.01;
    this.$emit('change', result);
    this.$emit('input', result);
  }

  computeItemColor(item, hoveredItem) {
    if (hoveredItem) {
      return item.confidence < hoveredItem.confidence ? hoveredItem.color : item.color;
    } else {
      return item.color;
    }
  }

  computeBorderColor(item, hoveredItem) {
    let selectedColor;
    if (hoveredItem) {
      selectedColor = item.confidence < hoveredItem.confidence ? hoveredItem.color : item.confidence === hoveredItem.confidence ? '#fff' : item.color;
    } else {
      selectedColor = item.color;
    }
    return Color(selectedColor).lighten(0.3);
  }

  setHoveredItem(v = null) {
    this.hoveredItem = v;
  }

  getItemLabel(item) {
    if (!item) return '...';
    const { label } = item,
      locale = this.$i18n.locale,
      labelIsObject = label && typeof label === 'object';
    return labelIsObject ? label[locale] || label.en || label.ru : label;
  }

  getItemIndexByValue(v) {
    const index = this.items.length - [...this.items].reverse().findIndex((item) => v >= item.confidence) - 1;
    return Math.max(index, 0);
  }
}
</script>

<style lang="stylus">

.confidence-select {
  .box-container {
    display: inline-block;
  }

  .item-label {
    margin-top: 0.5rem;
  }

  .item-input {
    .el-input input {
      width: 4rem !important;
      text-align: center !important;
    }
  }

  .items-container {
    margin-top: 0.5rem;
    display: block;

    .items-container--bg {
      position: relative;
      display: inline-block;

      &:before {
        position: absolute;
        content: '';
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        left: 10px;
        right: 10px;
        top: 11px;
      }
    }
  }

  .item {
    position: relative;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2)
    transition: border 0.2s, background-color 0.3s;
  }

  .item:hover {
    border: 1px solid rgba(255, 255, 255, 1)
  }

  .item-selected {
    border: 1px solid rgba(255, 255, 255, 1);

    i {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 1);
    }
  }

}
</style>
