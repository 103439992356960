<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-select
      v-model="model"
      :multiple="multiple"
      :multipleLimit="multipleLimit"
      :filterable="filterable"
      :placeholder="placeholder"
      :allow-create="allowCreate"
      @change="clearLinkedFields"
    >
      <el-option v-for="item of computeItems" :class="computeColorIconClassName(item.value)" :label="item.computedLabel" :value="item.value" :key="item.value">
        <el-tooltip v-if="item.tooltip" class="tooltip-item" effect="dark" :content="item.tooltip" placement="top" :open-delay="800">
          <span>{{ item.computedLabel }}</span>
        </el-tooltip>
      </el-option>
    </el-select>
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';
import _ from '@/apps/common/lodash';

@Component({
  props: {
    items: { type: Array, required: false },
    itemsPath: { type: String, required: false },
    multiple: { type: Boolean, default: false },
    multipleLimit: { type: Number, required: false },
    filterable: { type: Boolean, default: false },
    colorIcons: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    alphabeticalOrder: { type: Boolean, default: false },
    computeItem: { type: Function, default: (v) => v }
  }
})
export default class FilterFormComponentSelect extends FilterFormClearableComponent {
  get computeItems() {
    if (this.itemsPath) {
      return _.get(this, this.itemsPath)
        .map(this.computeItem)
        .map((item) => ({ ...item, computedLabel: this.computeItemLabel(item) }));
    } else {
      return this.i18nItems;
    }
  }

  get i18nItems() {
    const items = this.items?.map(this.getItem) || [];
    if (this.alphabeticalOrder) items.sort(compareItemComputedLabelsAsc);
    return items;
  }

  getItem(item) {
    const isObject = item && typeof item === 'object';
    return isObject ? { ...item, computedLabel: this.computeItemLabel(item) } : { value: item, label: item }; // @todo check after FFSER-280
  }

  computeItemLabel({ i18nLabel, label }) {
    return (i18nLabel && this.$tf(i18nLabel)) ?? label;
  }

  computeColorIconClassName(value) {
    return {
      'color-icon': this.colorIcons,
      [`color-icon_${value}`]: this.colorIcons
    };
  }
}

function compareItemComputedLabelsAsc(itemA, itemB) {
  return itemA.computedLabel.localeCompare(itemB.computedLabel);
}
</script>

<style lang="stylus">
$dark_mark_color = #1f2125;

.color-icon {
  &::after {
    width: 1em;
    right: 20px;
    height: 100%;
    font-size: 12px;
    position: absolute;
    background: center / 1em 1em no-repeat;
  }

  &:not(.selected)::after {
    content: '';
  }

  &_beige::after {
    color: $dark_mark_color;
    background-image: linear-gradient(beige, beige);
  }

  &_black::after {
    background-image: linear-gradient(black, black);
  }

  &_blue::after {
    background-image: linear-gradient(blue, blue);
  }

  &_brown::after {
    background-image: linear-gradient(brown, brown);
  }

  &_cyan::after {
    color: $dark_mark_color;
    background-image: linear-gradient(cyan, cyan);
  }

  &_lightblue::after {
    background-image: linear-gradient(lightblue, lightblue);
  }

  &_gold::after {
    color: $dark_mark_color;
    background-image: linear-gradient(gold, gold);
  }

  &_green::after {
    background-image: linear-gradient(green, green);
  }

  &_grey::after {
    background-image: linear-gradient(grey, grey);
  }

  &_orange::after {
    background-image: linear-gradient(orange, orange);
  }

  &_pink::after {
    color: $dark_mark_color;
    background-image: linear-gradient(pink, pink);
  }

  &_purple::after {
    background-image: linear-gradient(purple, purple);
  }

  &_red::after {
    background-image: linear-gradient(red, red);
  }

  &_silver::after {
    color: $dark_mark_color;
    background-image: linear-gradient(silver, silver);
  }

  &_violet::after {
    background-image: linear-gradient(violet, violet);
  }

  &_white::after {
    color: $dark_mark_color;
    background-image: linear-gradient(white, white);
  }

  &_yellow::after {
    color: $dark_mark_color;
    background-image: linear-gradient(yellow, yellow);
  }
}
</style>
