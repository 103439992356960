import { NotificationErrorAdapter } from './error-adapter';
import { IErrorOptions, INotificationOptions } from './types';

export function showError(optionsOrError: IErrorOptions | Error) {
  try {
    if (typeof optionsOrError !== 'object') {
      throw new Error('Expect Error or IErrorOptions object.');
    } else if (optionsOrError instanceof Error) {
      optionsOrError = { error: optionsOrError };
    }
    const adapted = new NotificationErrorAdapter().adapt(optionsOrError);
    const notifyOptions = Object.assign({}, optionsOrError, {
      error: undefined,
      name: undefined,
      message: this.$createElement('error-message', { props: { error: adapted } })
    });
    this.$notify(notifyOptions);
  } catch (e) {
    console.error('[notifications]', e, optionsOrError);
  }
}

export function showSuccess(options: INotificationOptions) {
  this.$notify({ ...options, type: 'success' });
}

export function showWarning(options: INotificationOptions) {
  this.$notify({ ...options, type: 'warning' });
}
