








































import { TableComponents } from '@/components/tables/components';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Component as VueComponent } from 'vue/types/options';
import { TableContext } from './types';

export interface ITableColumn {
  component?: string | VueComponent<any, any, any, any>;
  label_i18n?: string;
  prop?: string;
  cardType?: string;
  'class-name'?: string;
  width?: string | number;
  'min-width'?: string | number;
  formatter?: (item: any, column?: any) => string;
  align?: 'center' | 'left' | 'right';
  sortable?: boolean | string;
  'sort-method'?: (item0: any, item1: any) => number;
  'render-header'?: (h: CreateElement, { column }: { column: any }) => VNode;
  /** @todo Remove this hack as soon as possible. */
  changeHandler?: (...args: any[]) => any;
}

@Component({
  name: 'table-generator'
})
export default class TableGenerator extends Vue {
  @Prop({ required: true })
  state!: BaseItemsStateModule<any, any>;

  @Prop({ required: true })
  schema!: ITableColumn[];

  @Prop({ default: false })
  selection;

  @Prop({ type: String })
  objectsType?: string;

  @Prop({ type: Boolean })
  displayErrors?: boolean;

  @Prop({ type: Object })
  propToFieldMap?: { [key: string]: string };

  getComponent(component: string | VueComponent<any, any, any, any>) {
    return typeof component === 'string' ? TableComponents[component] : component;
  }

  getColumnProps(columnSchema: ITableColumn) {
    return { ...columnSchema, label: columnSchema.label_i18n ? this.$tf(columnSchema.label_i18n) : '' };
  }

  getComponentProps(row: any, columnSchema: ITableColumn) {
    return {
      item: row,
      state: this.state,
      objectsType: this.objectsType,
      routeName: this.state.routeName,
      prop: columnSchema.prop,
      cardType: columnSchema.cardType,
      changeHandler: columnSchema.changeHandler
    };
  }

  handleSelectionChange(v: any[]) {
    this.state.selectedItems = v;
  }

  rowClick(row, column, event) {
    if (column.type === 'selection') return;
    this.$emit('rowClick', { item: row, column, event });
  }

  rowDoubleClick(row, column, event) {
    this.$emit('rowDoubleClick', { item: row, column, event });
  }

  cellClick(row, column, cell, event) {
    this.$emit('rowCellClick', { item: row, column, cell, event });
  }

  cellDoubleClick(row, column, cell, event) {
    this.$emit('rowCellDoubleClick', { item: row, column, cell, event });
  }

  sortChangeHandler({ prop, order }) {
    const field = this.propToFieldMap && this.propToFieldMap[prop] ? this.propToFieldMap[prop] : prop;
    this.state.filter.current.ordering = field ? (order !== 'ascending' ? '-' : '') + field : '';
  }

  computeSpan(context: TableContext) {
    return {
      rowspan: 1,
      colspan: this.displayErrors && context.row.error ? this.schema.length : 1
    };
  }

  computeRowClassName({ row }: { row: { error: boolean } }) {
    return row.error ? 'error-row' : '';
  }
}
