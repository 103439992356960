




import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IUser } from '@/store/users/users/interfaces';
import { IAuditLog } from '@/store/audit-logs/types';

@Component({
  name: TableComponentNames.UserProfileLink
})
export default class TableGeneratorUserProfileLink extends Vue {
  @Prop({ required: true })
  item!: IUser | IAuditLog;

  isLogItem(item: any): item is IAuditLog {
    return item.user_id !== undefined;
  }

  get id() {
    return this.isLogItem(this.item) ? this.item.user_id : this.item.id;
  }

  get login() {
    return this.isLogItem(this.item) ? this.item.user_login : this.item.name;
  }
}
