import ActionNames from '@/store/action.names';

const RemoteModels = {
  Watchlist: 'ffsecurity.watchlist',
  Dossier: 'ffsecurity.dossier',
  CameraGroup: 'ffsecurity.cameragroup',
  Camera: 'ffsecurity.camera'
};

const SyncedRoutes = ['/events/faces/filter', '/cards/filter', '/episodes/filter', '/persons/filter', '/video-wall'];

function modelEvent({ state, dispatch }, payload) {
  const { action, model_id, model } = payload,
    path = state.app.route ? state.app.route.path : '',
    sync = SyncedRoutes.reduce((m, v) => {
      return m || path.indexOf(v) === 0;
    }, false);

  if (!sync) return;

  switch (model) {
    case RemoteModels.Watchlist:
      dispatch(ActionNames.Watchlists.Get);
      break;

    case RemoteModels.CameraGroup:
      dispatch(ActionNames.CameraGroups.Get);
      break;

    case RemoteModels.Camera:
      dispatch(ActionNames.Cameras.GetAll);
      break;

    default:
      console.warn(`[model.event]: has no handler model '${model}' with action '${action}', item id is ${model_id}`);
  }
}

export default modelEvent;
