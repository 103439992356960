import {
  IBodyCluster,
  IBodyClusterFilter,
  ICarCluster,
  ICarClusterFilter,
  IFaceCluster,
  IFaceClusterEvent,
  IFaceClusterEventFilter,
  IFaceClusterFilter,
  MergeTypes,
  UpdatedBodyClusterFilter,
  UpdatedCarsClusterFilter,
  UpdatedFaceClusterFilter
} from '@/store/clusters/index';

const attributeFilters = {
  faces: {
    gender: [],
    age_gte: null,
    age_lte: null,
    emotions: [],
    glasses: [],
    beard: [],
    liveness: [],
    race: [],
    medmask: []
  },
  cars: {
    color: [],
    body: [],
    make: [],
    model: [],
    special_vehicle_type: [],
    license_plate_country: [],
    license_plate_number: undefined,
    license_plate_region: undefined
  },
  bodies: {
    top_color: [],
    bottom_color: [],
    detailed_upper_clothes: [],
    headwear: [],
    lower_clothes: [],
    upper_clothes: []
  }
};

export const EmptyFaceClusterItem: IFaceCluster = {
  id: 0,
  tnt_id: 0,
  card: 0,
  cameras: [],
  camera_groups: [],
  matched_lists: [],
  pinned: false,

  best_event: 0,
  best_event_quality: 0,

  events_count: 0,
  events_quality: 0,

  looks_like: null,
  looks_like_confidence: 0
};

export const EmptyBodyClusterItem: IBodyCluster = {
  id: 0,
  tnt_id: 0,
  card: 0,
  cameras: [],
  camera_groups: [],
  matched_lists: [],
  pinned: false,

  best_event: 0,
  best_event_quality: 0,

  events_count: 0,
  events_quality: 0,

  looks_like: null,
  looks_like_confidence: 0
};

export const EmptyCarClusterItem: ICarCluster = {
  id: 0,
  tnt_id: 0,
  card: 0,
  cameras: [],
  camera_groups: [],
  matched_lists: [],
  pinned: false,

  best_event: 0,
  best_event_quality: 0,

  events_count: 0,
  events_quality: 0,

  looks_like: null,
  looks_like_confidence: 0
};

export const EmptyFaceClusterFilter: MergeTypes<IFaceClusterFilter, UpdatedFaceClusterFilter> = {
  limit: 10,
  id_in: [],
  cameras: [],
  camera_groups: [],
  matched_lists: [],
  card: undefined,
  first_event_date_gte: undefined,
  first_event_date_lte: undefined,
  has_card: '',
  page: undefined,
  ordering: '-id',
  gender: [],
  age_gte: null,
  age_lte: null,
  emotions: [],
  glasses: [],
  beard: [],
  liveness: [],
  race: [],
  medmask: []
};

export const EmptyBodyClusterFilter: MergeTypes<IBodyClusterFilter, UpdatedBodyClusterFilter> = {
  limit: 10,
  id_in: [],
  cameras: [],
  camera_groups: [],
  matched_lists: [],
  first_event_date_gte: undefined,
  first_event_date_lte: undefined,
  has_card: '',
  page: undefined,
  ordering: '-id',
  top_color: [],
  bottom_color: [],
  detailed_upper_clothes: [],
  headwear: [],
  lower_clothes: [],
  upper_clothes: []
};

export const EmptyCarClusterFilter: MergeTypes<ICarClusterFilter, UpdatedCarsClusterFilter> = {
  limit: 10,
  id_in: [],
  cameras: [],
  camera_groups: [],
  matched_lists: [],
  first_event_date_gte: undefined,
  first_event_date_lte: undefined,
  has_card: '',
  page: undefined,
  ordering: '-id',
  color: [],
  body: [],
  make: [],
  model: [],
  special_vehicle_type: []
};

export const EmptyClusterEventItem: Partial<IFaceClusterEvent> = {
  id: 0,
  matched_card: 0,
  cluster: 0,
  camera_groups: [],
  matched_lists: []
};

export const EmptyClusterEventFilter = (objects): IFaceClusterEventFilter => ({
  limit: 10,
  cameras: [],
  cluster_in: undefined,
  camera_groups: [],
  created_date_gte: undefined,
  created_date_lte: undefined,
  page: undefined,
  ordering: '-id',
  ...attributeFilters[objects]
});
