import { AxiosError } from 'axios';
import { AppErrors, findAppError, findServerError } from './erros';
import { IBaseNotificationError, IErrorOptions, INotificationError } from './types';

class NotificationError implements INotificationError {
  code = '';
  desc = '';
  code_i18n = '';
  desc_i18n = '';
  debug = '';
  source;
}

export class NotificationErrorAdapter {
  error: INotificationError;

  constructor() {
    this.error = new NotificationError();
  }

  adapt(options: IErrorOptions): INotificationError {
    let { name, error } = options;
    if (name) {
      this.adaptByString(name, error);
    } else if (error) {
      if ('isAxiosError' in error && error.isAxiosError) {
        this.adaptByAxiosError(error);
      } else {
        this.adaptByError(error);
      }
    } else {
      throw new Error('Cant create notification adapter from passed options.');
    }

    if (this.error.code === '' && this.error.code_i18n === '') {
      throw new Error('Cant generate notification error message from passed options.');
    }

    return this.error;
  }

  protected ALLOWED_JSON_FIELDS = ['code', 'desc', 'param', 'missing_permissions'];

  protected fillByJson(target: IBaseNotificationError, jsonSource: unknown): void {
    if (jsonSource && typeof jsonSource === 'object') {
      this.ALLOWED_JSON_FIELDS.forEach((field) => {
        if (field in jsonSource) {
          target[field] = jsonSource[field];
        }
      });
    }
  }

  protected adaptByAxiosError(error: AxiosError): void {
    if (error.response) {
      if (error.response.headers['content-type'] === 'application/json') {
        this.fillByJson(this.error, error.response.data);
        if (error.response.data.original_error && Object.keys(error.response.data.original_error).length > 0) {
          this.error.original_error = { code: '', desc: '' };
          this.fillByJson(this.error.original_error, error.response.data.original_error);
        }
        this.error.debug = JSON.stringify(error.response.data.traceback);
      } else {
        let serverError = findServerError(error.response.status);
        if (serverError) {
          this.error.code_i18n = serverError.code_i18n;
          this.error.desc_i18n = serverError.desc_i18n;
        } else {
          this.error.code = `${error.response.status} ${error.response.statusText}`;
        }
        this.error.debug = error.response.data;
      }
    } else {
      let appError = findAppError(AppErrors.NoResponse);
      if (appError) {
        this.error.code_i18n = appError.code_i18n;
        this.error.desc_i18n = appError.desc_i18n;
      }
    }
    this.error.source = error;
  }

  protected adaptByError(error: Error) {
    const appError = findAppError(error.name);
    if (appError) {
      this.error.code_i18n = appError.code_i18n;
      this.error.desc_i18n = appError.desc_i18n;
    } else {
      this.error.code = error.name;
      this.error.desc = error.message;
    }
    this.error.debug = error.toString();
    this.error.source = error;
  }

  protected adaptByString(name: string, error?: Error) {
    const appError = findAppError(name);
    if (appError) {
      this.error.code_i18n = appError.code_i18n;
      this.error.desc_i18n = appError.desc_i18n;
    }
    if (error) {
      this.error.debug = error.toString();
      this.error.source = error;
    } else {
      this.error.source = name;
    }
  }
}
