



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import toHHMMSS from './utils/time-to-hhmmss';
import humanFileSize from './utils/human-file-size';
import { saveAs } from 'file-saver';
import axios from 'axios';
import VideoPlayerButton from '@/components/video-player/video-player-button.vue';

@Component({
  name: 'video-player-export',
  components: { VideoPlayerButton }
})
export default class VideoPlayerExport extends Vue {
  @Prop({ required: true })
  camera!: number;

  @Prop({ required: true })
  timeStart!: number;

  @Prop({ required: true })
  timeEnd!: number;

  isDownloading = false;
  bytesCompleted = 0;
  cancelTokenSource: any;

  get timeStartFormated() {
    return toHHMMSS(this.timeStart);
  }

  get timeEndFormated() {
    return toHHMMSS(this.timeEnd);
  }

  get totalTime() {
    return toHHMMSS(this.timeEnd - this.timeStart, true, false);
  }

  get loadedBytesFormated() {
    return humanFileSize(this.bytesCompleted);
  }

  async download() {
    const timeFrom = new Date(this.timeStart * 1000);
    const timeTo = new Date(this.timeEnd * 1000);
    try {
      this.isDownloading = true;
      this.bytesCompleted = 0;
      this.cancelTokenSource = axios.CancelToken.source();
      const data = await this.$store.dispatch('requestApi', {
        model: 'vms/download',
        headers: { accept: 'video/x-matroska' },
        responseType: 'arraybuffer',
        filter: { camera: this.camera, time_from: timeFrom.toISOString(), time_to: timeTo.toISOString() },
        onDownloadProgress: (e) => {
          this.bytesCompleted = e.loaded;
        },
        cancelToken: this.cancelTokenSource.token
      });
      saveAs(new Blob([data]), `${timeFrom.toISOString()}.mkv`);
    } catch (e) {
      if (e.toString().startsWith('Cancel')) {
        this.$notify({ type: 'success', message: this.$tf('export_canceled') });
      } else {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      }
    } finally {
      this.isDownloading = false;
    }
  }

  cancel() {
    if (this.cancelTokenSource && this.cancelTokenSource.cancel) {
      this.cancelTokenSource.cancel();
    }
  }

  beforeDestroy() {
    this.cancel();
  }
}
