import _ from '@/apps/common/lodash';
import getCrud from '../../get.crud';
import * as filters from '@/apps/common/filters';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    card: null,
    camera: null,
    limit: 100,
    created_date_gte: null,
    created_date_lte: null
  }),
  emptyItem = Object.seal({
    id: null,
    card: 0,
    external_face_id: null,
    face_object_id: null,
    puppet_id: null,
    camera: null,
    confidence: 0,
    bbox: {},
    labels: {},
    external_timestamp: null,
    created_date: null,
    fullfame: null,
    thumbnail: null
  }),
  Name = 'puppeteer/remote-monitoring/events',
  Action = require('../../action.names').default.PuppeteerRemoteMonitoringEvents;

export default {
  state: {
    playing: false,
    notify: false,
    unacknowledged: false,
    loading: false,
    page: '',
    next_page: null,
    prev_page: [],
    limits: [10, 20, 50, 100, 200, 500],
    items: [],
    item: {
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: { ...getCrud(Name), satisfy_puppeteer_remote_monitoring_event, puppeeteerEventsAcknowledgeAll, puppeeteerEventAcknowledge }
};

function satisfy_puppeteer_remote_monitoring_event({ state, rootState }, event) {
  let filter = state.filter.current,
    satisfy = true;

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.card) {
    satisfy = event.card === filter.card;
  }

  if (satisfy && filter.created_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  return satisfy;
}

function puppeeteerEventsAcknowledgeAll({ rootState, state, dispatch }) {
  return dispatch(rootState.Action.RequestApi, { model: Name + '/acknowledge_all', method: 'POST' });
}

function puppeeteerEventAcknowledge({ rootState, state, dispatch }, payload) {
  let data = _.cloneDeep(payload);
  let model = `${Name}/${data.id}/acknowledge`;
  delete data.id;
  return dispatch(rootState.Action.RequestApi, { model, method: 'POST', data });
}
