import { IFilter } from '@/definitions/filter';
import { FilterFormComponentName } from '@/components/filter-form/components';
import { shortString } from '@/apps/common/filters.js';
import ActionNames from '@/store/action.names.js';

export const schema: IFilter = {
  module: 'counters',
  action: ActionNames.Counters.Get,
  fields: [
    {
      name: 'cameras',
      control: {
        i18nLabel: ['cameras'],
        component: [
          FilterFormComponentName.Select,
          {
            itemsPath: '$store.state.cameras.items',
            computeItem: (v) => ({
              ...v,
              value: v.id,
              label: shortString(v.name),
              tooltip: v.name
            }),
            multiple: true,
            multipleLimit: 10,
            alphabeticalOrder: true,
            i18nPlaceholder: 'not_selected',
            clearFieldsOnChange: ['page', 'id']
          }
        ]
      },
      meta: {
        multiple: true,
        identity: 'int'
      }
    },
    {
      name: 'camera_groups',
      control: {
        i18nLabel: 'camera_groups',
        component: [
          'camera-groups',
          {
            itemsPath: '$store.getters.cameraGroupsWithoutSpecial',
            i18nPlaceholder: 'not_selected',
            multiple: true,
            multipleLimit: 10,
            alphabeticalOrder: true,
            clearFieldsOnChange: ['page', 'id']
          }
        ]
      },
      meta: {
        multiple: true,
        identity: 'int'
      }
    },
    {
      name: 'id_in',
      control: {
        i18nLabel: ['counter_ids'],
        component: [
          FilterFormComponentName.InputIDs,
          {
            clearFieldsOnChange: ['page']
          }
        ]
      },
      meta: {
        multiple: true,
        identity: 'int'
      }
    },
    {
      name: 'detect_proximity',
      control: {
        i18nLabel: 'distance',
        component: [
          FilterFormComponentName.Select,
          {
            items: [
              { value: '', i18nLabel: 'all' },
              { value: 'True', i18nLabel: 'with_detect_proximity' },
              { value: 'False', i18nLabel: 'without_detect_proximity' }
            ],
            clearFieldsOnChange: ['page', 'id']
          }
        ]
      }
    }
  ]
};
