// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    watch_lists: []
  },
  emptyItem = {
    dossier: '',
    name: '',
    comment: '',
    file: null
  },
  Name = 'dossier-attachments',
  Action = require('../action.names').default.DossierAttachments;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({}, getCrud(Name))
};
