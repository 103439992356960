










import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { ICarEvent, IFaceEvent } from '@/definitions/root.state';
import { ImageType } from '@/store/actions/showImageByType';
import { CardType } from '@/store/cards/cards';
import {
  ICarCluster,
  IFaceCluster,
  IBodyCluster,
  IBodyClusterEvent,
  selectClusterEventsListStoreModuleFactory,
  selectClusterListStoreModuleFactory
} from '@/store/clusters';
import { ObjectsType, ObjectsTypeSingleForm } from '@/store/objects/get.module';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.CardClusterThumbnailItem,
  components: { ObjectThumbnail }
})
export default class TableGeneratorCardObjectThumbnailItem extends Vue {
  @Prop({ required: true })
  item!: any;

  @Prop({ type: String, required: true })
  cardType!: CardType;

  @Prop({ type: String, required: true })
  objectsType!: ObjectsType;

  private readonly module = selectClusterListStoreModuleFactory(this.objectsType)(this.$store);
  private readonly eventsModule = selectClusterEventsListStoreModuleFactory(this.objectsType)(this.$store);

  get objectsByObjectType() {
    return `${ObjectsTypeSingleForm[this.objectsType]}_objects`;
  }

  get clusterByObjectType() {
    return `${ObjectsTypeSingleForm[this.objectsType]}_cluster`;
  }

  get state() {
    return this.module.state;
  }

  get eventsState() {
    return this.eventsModule.state;
  }

  created() {
    this.state.filter.current = { card: this.item.id, limit: 1 };
    this.state.get();
  }

  beforeDestroy() {
    this.module.unreg();
  }

  loadClusterEvents() {
    this.eventsState.filter.current = { cluster_in: this.cluster?.id as any, limit: 10 };
    this.eventsState.get();
  }

  thumbnailAttempts = 0;
  async handleThumbnailError() {
    if (this.thumbnailAttempts === 0) {
      await this.loadClusterEvents();
    }
    this.thumbnailAttempts++;
  }

  previewHandler() {
    this.$store.dispatch('showImageByType', {
      type: ImageType.ClusterEvent,
      objectsType: this.objectsType,
      data: this.event
    });
  }

  get cluster() {
    const [item] = this.state.items ?? [];
    return item as Required<IFaceCluster | IBodyCluster | ICarCluster> | undefined;
  }

  get clusterEvents() {
    return this.eventsState.items;
  }

  get event(): IFaceEvent | IBodyClusterEvent | ICarEvent | undefined {
    if (this.thumbnailAttempts === 0) {
      return this.cluster?.best_event as IFaceEvent | IBodyClusterEvent | ICarEvent | undefined;
    }
    return this.clusterEvents[this.thumbnailAttempts - 1] as IFaceEvent | IBodyClusterEvent | ICarEvent | undefined;
  }
}
