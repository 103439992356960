








import { TableComponentNames } from '@/components/tables/components/names';
import { CardType } from '@/store/cards/cards';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

const pass = () => {};

@Component({
  name: TableComponentNames.CardStatistic
})
export default class TableGeneratorCardStatistic extends Vue {
  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: Function, default: () => pass })
  changeHandler!: any;

  @Prop({ required: true })
  cardType!: CardType;

  get isHuman() {
    return this.cardType === CardType.Human;
  }

  getValueOrNull(v) {
    return v ?? 0;
  }
}
