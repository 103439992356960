<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-select v-model="model" multiple filterable alphabeticalOrder placeholder="">
      <el-option v-for="(item, id) in items" :key="id" :value="item" :label="item"> </el-option>
    </el-select>
  </filter-form-clear-button>
</template>

<script>
import FilterFormClearableComponent from './filter-form-component-clearable';
import { Component } from 'vue-property-decorator';

@Component
export default class FilterFormComponentModel extends FilterFormClearableComponent {
  get items() {
    return this.$store.state.config.objects.cars.features.model;
  }
}
</script>

<style scoped></style>
