<template>
  <li class="menu-item" :class="menuItemClasses">
    <a v-if="!group" :href="getPath(item)" @click.prevent="clickHandler">
      <i v-if="item.classes" class="icon-large" :class="[item.classes, largeHighlightClass]" :style="getStyleByItem(item)" />
      <i v-else-if="item.icon" class="fa" :class="[item.icon, highlightClass]"></i>
      <span class="flex-cell collapsed--hidden">{{ text }}</span>
      <i class="external-icon flex-cell--static" style="font-size: 1rem" v-if="item.external"></i>
      <div class="filter-badge collapsed--hidden" v-if="filterCount">
        <b class="fa fa-filter"></b>
        {{ filterCount }}
      </div>
    </a>
    <div v-else>{{ text }}</div>
    <i v-if="canShowEditButton" @click.prevent="deleteMenuItem()" class="delete-button" />
  </li>
</template>

<script>
import _ from '@/apps/common/lodash';

export default {
  name: 'user-menu-item',
  props: ['item', 'child', 'editMode'],

  computed: {
    largeHighlightClass() {
      if (this.item['event-highlight']) {
        return this.$store.state.faces_events.unacknowledged ? 'icon-large--highlight' : '';
      }
      if (this.item['puppeteer-event-highlight']) {
        return this.$store.state.puppeteer_remote_monitoring_events.unacknowledged ? 'icon-large--highlight' : '';
      }
      return '';
    },
    highlightClass() {
      if (this.item['event-highlight']) {
        return this.$store.state.faces_events.unacknowledged ? 'icon-highlight' : '';
      }
      if (this.item['puppeteer-event-highlight']) {
        return this.$store.state.puppeteer_remote_monitoring_events.unacknowledged ? 'icon-highlight' : '';
      }
      return '';
    },
    filterCount() {
      const DisplayFiltersSections = [];
      const name = this.item.name;
      const filter = this.$store.state[name] && this.$store.state[name].filter;
      const showFilterCount = filter && (DisplayFiltersSections.indexOf(name) > -1);

      if (!showFilterCount) return;

      const r = _.differenceOf(filter.empty, filter.current);
      return Object.keys(r).length;
    },
    group() {
      return !(this.item.path || this.item.serverPath || this.item.action);
    },
    currentUserName() {
      return this.$filters.shortString(this.$store.state.users.current.real_name || this.$store.state.users.current.login, 20);
    },
    text() {
      let result = this.item.i18n ? this.$tfo(this.item.i18n) : this.item.name;
      if (result.indexOf('{') === 0) {
        result = result.replace('{name}', this.currentUserName);
        result = result.replace('{state.config.name}', this.$store.state.config.name);
      }
      return this.group ? result.toUpperCase() : result;
    },
    active() {
      const path = this.child ? this.$route.path : this.$store.state.app.menu.parent && this.$store.state.app.menu.parent.path,
        isActivePath = this.$isActiveItem(path, this.item);
      return isActivePath;
    },
    canShowEditButton() {
      const isLauncher = this.item.name === 'launcher';
      return this.editMode && !isLauncher;
    },
    menuItemClasses() {
      const isLauncher = this.item.name === 'launcher';
      return {
        'is-active': this.active,
        'is-group': this.group,
        'menu-item--launcher': isLauncher
      };
    }
  },
  methods: {
    isEvents(item) {
      return item.name === 'events';
    },
    getStyleByItem(item) {
      let result = null;
      if (item.classes === 'menu-image--logo') {
        const url = this.$store.getters.themeLogo;
        result = { backgroundImage: `url(${url})` };
      }
      return result;
    },
    getPath(item) {
      let r = '';
      if (item.serverPath) {
        r = this.$store.state.config.server.url + item.serverPath;
      } else if (item.path) {
        r = '#' + item.path;
      }
      return r;
    },
    clickHandler(e) {
      if (this.editMode) return false;
      const { item } = this;
      item.action && this.$emit('action', item.action, item);

      if (item.serverPath) {
        window.open(this.getPath(item), '_blank');
      } else if (item.path) {
        this.$router.push({ path: item.path });
      }
    },
    deleteMenuItem() {
      this.$store.dispatch('toggleItemLauncher', this.item.name);
    }
  }
};
</script>
