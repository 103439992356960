const eventsCount = [1, 2, 3, 5, 10];

function computeFieldI18nItems(items, i18nLabel) {
  return items.map((item) => ({ value: item, i18nLabel: `${i18nLabel}__${item}` }));
}

function computeFieldItems(items) {
  return items.map((item) => ({ value: item, label: item }));
}

export { computeFieldI18nItems, computeFieldItems, eventsCount };
