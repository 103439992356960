





import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IFaceClusterEvent, IBodyClusterEvent, ICarClusterEvent } from '@/store/clusters';
import { getFilterString } from '@/definitions/base.items.state.module';

type ClusterEvent = IFaceClusterEvent | IBodyClusterEvent | ICarClusterEvent;

@Component({
  name: TableComponentNames.ClusterLink
})
export default class extends Vue {
  @Prop({ required: true })
  item!: IFaceClusterEvent;

  @Prop({ required: true })
  objectsType!: string;

  get clusterRouterLink() {
    return {
      name: 'clusterEvents',
      params: {
        filter: getFilterString({ cluster_in: this.item.cluster, limit: 10 })
      }
    };
  }
}
