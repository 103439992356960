<template>
  <div class="card-item">
    <router-link name="event-btn" :to="{ path: `/events/${objects}/${eventId}/` }">
      <span>{{ item.name }}</span> <span v-if="confidence && !hasObjectsConfidence">({{ $filters.floatToPercent(confidence) }})</span>
    </router-link>
    <br />
    <confidence-label :objects="objects" :value="confidence" v-if="confidence && hasObjectsConfidence"></confidence-label>
    <div>
      {{ item.comment }}
    </div>
  </div>
</template>

<script>
import ConfidenceLabel from '../common/confidence.label';
import { CardType, objectsTypeToCardType } from '@/store/cards/cards';
import { dataServiceFactory } from '@/services/data.services';
import { ApiUrls } from '@/services/constants';

export default {
  components: { ConfidenceLabel },
  name: 'card-item',
  props: ['id', 'eventId', 'confidence', 'objects'],
  data: function () {
    return {
      item: {
        id: null,
        name: '',
        comment: ''
      }
    };
  },
  computed: {
    hasObjectsConfidence() {
      return this.$store.getters.hasObjectsConfidence(this.objects || 'faces');
    }
  },
  methods: {
    load() {
      const cardType = objectsTypeToCardType(this.objects);
      const serviceUrl = cardType === CardType.Human ? ApiUrls.HumanCards : ApiUrls.CarCards;
      this.item = { id: null, name: '', comment: '', watch_lists: [] };

      if (this.id) {
        const name = `${serviceUrl}/${this.id}`,
          promiseName = 'promise/' + name,
          cached = this.$cache.get(name),
          promiseCached = this.$cache.get(promiseName);

        if (cached) {
          this.item = this.$cache.get(name);
        } else if (promiseCached) {
          promiseCached.then((v) => {
            this.item = v;
          });
        } else {
          const getItemPromise = dataServiceFactory.getService(serviceUrl).get(this.id);
          getItemPromise.then((v) => {
            this.$cache.set(name, v);
            this.item = v;
          });
          this.$cache.set(promiseName, getItemPromise);
        }
      }
    }
  },
  watch: {
    id() {
      this.load();
    }
  },
  mounted() {
    this.load();
  }
};
</script>
