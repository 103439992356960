<template>
  <div>
    <el-select :multiple-limit="multipleLimit" @change="clearLinkedFields" :placeholder="placeholder" v-model="model" multiple>
      <el-option v-for="item in items" :key="item.id" :value="item.id" :label="item.name">
        <base-form-component-watch-lists-item :color="item.color" :name="item.name" />
      </el-option>
    </el-select>
    <span class="compact-buttons">
      <el-button size="mini" circle icon="el-icon-refresh" @click="loadWatchlists" />
      <el-button size="mini" circle icon="el-icon-plus" @click="addWatchlist" />
    </span>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';
import BaseFormComponentWatchListsItem from './watch-lists.item';

@Component({
  props: {
    itemsPath: { type: String, required: true },
    multipleLimit: { type: Number, default: 10 },
    excludeUnmatched: { type: Boolean, default: false }
  },
  components: { BaseFormComponentWatchListsItem }
})
export default class BaseFormComponentWatchLists extends BaseFormComponent {
  get items() {
    const items = this.fetchWatchListsByPath();
    return this.excludeUnmatched ? excludeUnmatchedWatchList(items) : items;
  }

  fetchWatchListsByPath() {
    return _.get(this, this.itemsPath).map(this.normalizeWatchList);
  }

  normalizeWatchList(item) {
    return { ...item, name: this.$filters.shortString(item.name) };
  }

  addWatchlist() {
    const route = this.$router.resolve({ path: '/watch-lists/create/' });
    window.open(route.href, '_blank');
  }
  loadWatchlists() {
    this.$store.dispatch(this.$store.state.watch_lists.Action.Get);
  }
}

function excludeUnmatchedWatchList(items) {
  return items.filter((item) => item.id > 0);
}
</script>
