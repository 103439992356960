<template>
  <el-form ref="form" :model="model" :rules="rules" label-position="top" class="reports-dialog-form">
    <el-form-item :label="$tf('name')" prop="name">
      <el-input v-model="model.name"></el-input>
    </el-form-item>

    <template v-if="isTimeReport && isAreaReport">
      <el-form-item :label="$tf('id | areas')" prop="area">
        <el-input v-model="model.area" />
      </el-form-item>
    </template>

    <el-form-item v-if="isTimeReportAvailable" :label="$tf('report_type')" prop="report_type">
      <reports-select-types class="reports-dialog-form-item" v-model="model.report_type" />
    </el-form-item>

    <template v-if="isTimeReport && isFaceEventsReport">
      <el-form-item :label="$tf('camera_entry')" prop="camera_in">
        <reports-select-cameras class="reports-dialog-form-item" :items="camerasIn" v-model="model.camera_in" />
      </el-form-item>

      <el-form-item :label="$tf('camera_exit')" prop="camera_out">
        <reports-select-cameras class="reports-dialog-form-item" :items="camerasOut" v-model="model.camera_out" />
      </el-form-item>
    </template>

    <el-form-item :label="$tf('save_report_images_as')" prop="pictures" v-if="isPictureSelectAvailable">
      <reports-select-pictures class="reports-dialog-form-item" v-model="model.pictures" :options="filters.pictures" />
    </el-form-item>

    <el-form-item v-if="sourceEnabled" :label="$tf('analytic_select_data')" prop="source">
      <el-select class="reports-dialog-form-item" v-model="model.source">
        <el-option :key="item.name" :value="item.name" :label="$tf(item.i18n)" v-for="item in AnalyticSourceTypes">
          <div class="cols--center">
            <div>
              {{ $tf(item.i18n) }}
            </div>
            <div class="flex-cell reports-dialog-form__delimiter"></div>
            <el-tooltip :content="$tf(item.i18n_desc)">
              <i class="fa fa-info"></i>
            </el-tooltip>
          </div>
        </el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import ReportsSelectPictures from './reports-select-pictures';
import ReportsSelectTypes from './reports-select-types';
import ReportsSelectCameras from './reports-select-cameras';
import { ReportTypeI18n } from './reports-helpers';

const Rules = {
  pictures: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  report_type: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  camera_in: [{ required: true, type: 'number', message: 'error.required.field', trigger: 'change' }],
  camera_out: [{ required: true, type: 'number', message: 'error.required.field', trigger: 'change' }],
  source: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  area: [{ required: true, message: 'error.required.field', trigger: 'change' }]
};

const AnalyticSourceTypes = [
  {
    name: 'input',
    i18n: 'analytic_input_data',
    i18n_desc: 'analytic_input_data_desc'
  },
  {
    name: 'output',
    i18n: 'analytic_output_data',
    i18n_desc: 'analytic_output_data_desc'
  }
];

const PicturesSelectExcludedByFilter = ['areas', 'area-activations', 'audit-logs'];

@Component({
  components: { ReportsSelectPictures, ReportsSelectTypes, ReportsSelectCameras },
  props: {
    filters: {
      type: Object,
      required: true
    },
    i18nData: {
      type: Object,
      default: {}
    }
  }
})
export default class ReportsDialogForm extends Vue {
  AnalyticSourceTypes = AnalyticSourceTypes;
  model = {
    name: this.computeDefaultReportName(),
    pictures: 'link',
    report_type: '',
    source: 'output',
    camera_in: null,
    camera_out: null,
    event_type: null,
    area: ''
  };
  rules = this.$applyRuleMessages(Rules);

  @Watch('model.report_type')
  changeReportTypeHandler() {
    this.computeEventType();
    this.resetCameras();
  }

  @Watch('isTimeReport')
  changeTimeReportHandler() {
    this.model.area = this.computeDefaultArea();
    if (!this.isPictureSelectAvailable) {
      this.model.pictures = 'link';
    }
  }

  get sourceEnabled() {
    return this.filters && this.filters.type === 'kyc';
  }

  get isTimeReport() {
    return this.model.report_type === 'work_time_report';
  }

  get isAreaReport() {
    return this.filters.type.includes('area');
  }

  get isFaceEventsReport() {
    return this.filters.type === 'face-events';
  }

  get isPictureSelectAvailable() {
    let isSourceAndOutput = this.sourceEnabled && this.model.source === 'output';
    let isEventTypeAreaInOut = this.model.event_type === 'area-activations-in-out';
    return !PicturesSelectExcludedByFilter.includes(this.filters.type) && !(isSourceAndOutput || isEventTypeAreaInOut);
  }

  get isTimeReportAvailable() {
    return this.isAreaReport || this.isFaceEventsReport;
  }

  get cameras() {
    return this.$store.state.cameras.items;
  }

  get camerasIn() {
    return this.excludeCameraWithId(this.model.camera_out);
  }

  get camerasOut() {
    return this.excludeCameraWithId(this.model.camera_in);
  }

  get eventTypeByFilter() {
    return this.isAreaReport ? 'area-activations-in-out' : 'face-events-in-out';
  }

  excludeCameraWithId(targetCameraId) {
    return this.cameras.filter(({ id }) => id !== targetCameraId);
  }

  computeDefaultReportName() {
    const reportType = this.computeReportType();
    const en = `${reportType} | report`;
    const ru = `report | by,,1 | ${reportType},,2`;
    return this.$tf({ en, ru }, this.i18nData);
  }

  computeDefaultArea() {
    return this.i18nData.area && this.isAreaReport && this.isTimeReport ? String(this.i18nData.area) : '';
  }

  computeReportType() {
    const { filters } = this.filters,
      type = ReportTypeI18n[this.filters.type] || this.filters.type;
    return filters?.looks_like ? 'search' : type;
  }

  computeEventType() {
    this.model.event_type = this.isTimeReport ? this.eventTypeByFilter : this.filters.type;
  }

  resetCameras() {
    this.model.camera_in = null;
    this.model.camera_out = null;
  }

  validate() {
    return this.$refs.form.validate().then(() => ({ ...this.model }));
  }
}
</script>

<style lang="stylus">
.reports-dialog-form {
  &-item {
    width: 100%;
  }

  &__delimiter {
    min-width: 2rem;
  }
}
</style>
