






import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';
import CounterChartItem from '@/components/counters/counter.chart.item.vue';

const CounterChartClickEventType = 'counter_chart_click';
export { CounterChartClickEventType };

@Component({
  name: TableComponentNames.CounterChart,
  components: { CounterChartItem }
})
export default class TableGeneratorCounterChart extends Vue {
  @Prop({ required: true, type: Object })
  item!: any;

  clickHandler() {
    this.$emit('action', { type: CounterChartClickEventType, item: this.item });
  }
}
