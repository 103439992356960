import { FilterFormComponentName } from '@/components/filter-form/components';
import { computeMatchedCarCardFieldSchema, computeMatchedHumanCardFieldSchema, FieldName } from '@/store/events/filters/fields';
import { computeFieldI18nItems, computeFieldItems, eventsCount } from '@/store/episodes/utils';
import { objects } from '../../../public/config.sample.json';

const DefaultClearableFields = [FieldName.Page, FieldName.Id];
const { faces, bodies, cars } = objects;

const commonFiltersFields = [
  {
    name: 'matched_lists',
    control: {
      i18nLabel: 'watch_lists',
      component: [
        FilterFormComponentName.WatchLists,
        {
          itemsPath: '$store.getters.watchlistsInFilter',
          i18nPlaceholder: 'not_selected',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true, identity: 'int' }
  },
  {
    name: 'acknowledged',
    control: {
      i18nLabel: 'acknowledged',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'False', i18nLabel: 'only_unacknowledged' },
            { value: 'True', i18nLabel: 'only_acknowledged' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  },
  {
    name: 'camera_groups',
    control: {
      i18nLabel: 'camera_groups',
      component: [
        FilterFormComponentName.CameraGroups,
        {
          itemsPath: '$store.state.camera_groups.items',
          i18nPlaceholder: 'not_selected',
          clearFieldsOnChange: [FieldName.Page, FieldName.Id, FieldName.CreatedDateGte, FieldName.CreatedDateLte, FieldName.Cameras],
          multipleLimit: 0
        }
      ]
    },
    meta: { multiple: true, identity: 'int' }
  },
  {
    name: 'cameras',
    control: {
      i18nLabel: 'cameras',
      component: [
        FilterFormComponentName.Cameras,
        {
          itemsPath: '$store.state.cameras.items',
          i18nPlaceholder: 'not_selected',
          cameraGroupsFieldName: 'camera_groups',
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    },
    meta: { multiple: true, identity: 'int' }
  },
  {
    name: 'events_count_gte',
    control: {
      i18nLabel: 'count | events,,1',
      component: [
        FilterFormComponentName.Select,
        {
          items: computeFieldItems(eventsCount),
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  },
  {
    name: 'created_date_gte',
    control: {
      i18nLabel: 'start',
      component: [
        FilterFormComponentName.Datetime,
        {
          type: 'datetime',
          clearFieldsOnChange: [FieldName.Page, FieldName.Id]
        }
      ]
    }
  },
  {
    name: 'created_date_lte',
    control: {
      i18nLabel: 'end',
      component: [
        FilterFormComponentName.Datetime,
        {
          type: 'datetime',
          clearFieldsOnChange: [FieldName.Page, FieldName.Id]
        }
      ]
    }
  },
  {
    name: 'video_archive',
    control: {
      i18nLabel: 'video_archive_id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  },
  {
    name: 'id_in',
    control: {
      i18nLabel: 'episode_id',
      component: [
        FilterFormComponentName.Input,
        {
          number: true,
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  }
];

export const HumansFilterSchema = {
  module: 'episodes/humans',
  action: 'episodes/humans/get',
  fields: [
    {
      name: 'no_face_match',
      control: {
        i18nLabel: 'face_matches',
        component: [
          FilterFormComponentName.Select,
          {
            items: [
              { value: '', i18nLabel: 'all' },
              { value: 'True', i18nLabel: 'only_without_matches' },
              { value: 'False', i18nLabel: 'only_with_matches' }
            ],
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    },
    {
      name: 'no_body_match',
      control: {
        i18nLabel: 'body_matches',
        component: [
          FilterFormComponentName.Select,
          {
            items: [
              { value: '', i18nLabel: 'all' },
              { value: 'True', i18nLabel: 'only_without_matches' },
              { value: 'False', i18nLabel: 'only_with_matches' }
            ],
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    },
    computeMatchedHumanCardFieldSchema(),
    ...commonFiltersFields,
    {
      name: 'age_gte',
      control: {
        align: 'left',
        i18nLabel: 'features.faces_age',
        component: [
          FilterFormComponentName.Age,
          {
            maxAge: 100,
            i18nPlaceholder: 'from'
          }
        ]
      }
    },
    {
      name: 'age_lte',
      control: {
        align: 'right',
        component: [
          FilterFormComponentName.Age,
          {
            maxAge: 100,
            i18nPlaceholder: 'to,,1'
          }
        ]
      }
    },
    {
      name: 'beard',
      control: {
        i18nLabel: 'features.faces_beard',
        component: [
          FilterFormComponentName.CheckboxGroup,
          {
            items: computeFieldI18nItems(faces.features.beard, 'features.faces_beard'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'emotions',
      control: {
        i18nLabel: 'features.faces_emotions',
        component: [
          FilterFormComponentName.CheckboxGroup,
          {
            items: computeFieldI18nItems(faces.features.emotions, 'features.faces_emotions'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'gender',
      control: {
        i18nLabel: 'features.faces_gender',
        component: [
          FilterFormComponentName.CheckboxGroup,
          {
            items: computeFieldI18nItems(faces.features.gender, 'features.faces_gender'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'glasses',
      control: {
        i18nLabel: 'features.faces_glasses',
        component: [
          FilterFormComponentName.CheckboxGroup,
          {
            items: computeFieldI18nItems(faces.features.glasses, 'features.faces_glasses'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'liveness',
      control: {
        i18nLabel: 'features.faces_liveness',
        component: [
          FilterFormComponentName.CheckboxGroup,
          {
            items: computeFieldI18nItems(faces.features.liveness, 'features.faces_liveness'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'medmask',
      control: {
        i18nLabel: 'features.faces_medmask',
        component: [
          FilterFormComponentName.CheckboxGroup,
          {
            items: computeFieldI18nItems(faces.features.medmask, 'features.faces_medmask'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'top_color',
      control: {
        i18nLabel: 'features.bodies_top_color',
        component: [
          FilterFormComponentName.Select,
          {
            items: computeFieldI18nItems(bodies.features.top_color, 'features.bodies_top_color'),
            multiple: true,
            colorIcons: true,
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'bottom_color',
      control: {
        i18nLabel: 'features.bodies_bottom_color',
        component: [
          FilterFormComponentName.Select,
          {
            items: computeFieldI18nItems(bodies.features.bottom_color, 'features.bodies_bottom_color'),
            multiple: true,
            colorIcons: true,
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'detailed_upper_clothes',
      control: {
        i18nLabel: 'features.bodies_detailed_upper_clothes',
        component: [
          FilterFormComponentName.Select,
          {
            items: computeFieldI18nItems(bodies.features.detailed_upper_clothes, 'features.bodies_detailed_upper_clothes'),
            multiple: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'headwear',
      control: {
        i18nLabel: 'features.bodies_headwear',
        component: [
          FilterFormComponentName.Select,
          {
            items: computeFieldI18nItems(bodies.features.headwear, 'features.bodies_headwear'),
            multiple: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'lower_clothes',
      control: {
        i18nLabel: 'features.bodies_lower_clothes',
        component: [
          FilterFormComponentName.Select,
          {
            items: computeFieldI18nItems(bodies.features.lower_clothes, 'features.bodies_lower_clothes'),
            multiple: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'upper_clothes',
      control: {
        i18nLabel: 'features.bodies_upper_clothes',
        component: [
          FilterFormComponentName.Select,
          {
            items: computeFieldI18nItems(bodies.features.upper_clothes, 'features.bodies_upper_clothes'),
            multiple: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    }
  ]
};

export const CarsFilterSchema = {
  module: 'episodes/cars',
  action: 'episodes/cars/get',
  fields: [
    {
      name: 'no_car_match',
      control: {
        i18nLabel: 'matches,,1',
        component: [
          FilterFormComponentName.Select,
          {
            items: [
              { value: '', i18nLabel: 'all' },
              { value: 'True', i18nLabel: 'only_without_matches' },
              { value: 'False', i18nLabel: 'only_with_matches' }
            ],
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    },
    computeMatchedCarCardFieldSchema(),
    ...commonFiltersFields,
    {
      name: 'body',
      control: {
        i18nLabel: 'features.cars_body',
        component: [
          FilterFormComponentName.Select,
          {
            multiple: true,
            filterable: true,
            items: computeFieldI18nItems(cars.features.body, 'features.cars_body'),
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'color',
      control: {
        i18nLabel: 'features.cars_color',
        component: [
          FilterFormComponentName.Select,
          {
            multiple: true,
            filterable: true,
            items: computeFieldI18nItems(cars.features.color, 'features.cars_color'),
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'license_plate_country',
      control: {
        i18nLabel: 'features.cars_license_plate_country',
        component: [
          FilterFormComponentName.Select,
          {
            multiple: true,
            filterable: true,
            items: computeFieldI18nItems(cars.features.license_plate_country, 'features.cars_license_plate_country'),
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'license_plate_number',
      control: {
        i18nLabel: 'features.cars_license_plate_number',
        component: [
          FilterFormComponentName.Input,
          {
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    },
    {
      name: 'license_plate_region',
      control: {
        i18nLabel: 'features.cars_license_plate_region',
        component: [
          FilterFormComponentName.Select,
          {
            multiple: true,
            filterable: true,
            items: computeFieldI18nItems(cars.features.license_plate_region, 'features.cars_license_plate_region'),
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'special_vehicle_type',
      control: {
        i18nLabel: 'features.cars_special_vehicle_type',
        component: [
          FilterFormComponentName.Select,
          {
            multiple: true,
            filterable: true,
            items: computeFieldI18nItems(cars.features.special_vehicle_type, 'features.cars_special_vehicle_type'),
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true }
    },
    {
      name: 'make',
      control: {
        i18nLabel: 'features.cars_make',
        component: [
          FilterFormComponentName.Select,
          {
            multiple: true,
            filterable: true,
            items: computeFieldItems(cars.features.make),
            colorIcons: true,
            alphabeticalOrder: true,
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    },
    {
      name: 'model',
      control: {
        i18nLabel: 'features.cars_model',
        component: [FilterFormComponentName.Model]
      }
    }
  ]
};
