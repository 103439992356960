




































import { coerceAsNonNullable } from '@/apps/common/lib';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ElapsedTimer from './utils/elapsed-timer';
import AutoOffsetTimer from './utils/auto-offset-timer';
import labelsStepByScale from './utils/labels-step-by-scale';
import toHHMMSS from './utils/time-to-hhmmss';
import VideoPlayerExport from './video-player-export.vue';
import VideoPlayerThumb from './video-player-thumb.vue';
import VideoPlayerTimelineData from './video-player-timeline-data';
import VideoPlayerTimelineExport from './video-player-timeline-export';
import { formatDate, formatTime } from '@/apps/common/filters';
import VideoPlayerDatePicker from '@/components/video-player/video-player-date-picker.vue';
import VideoPlayerButton from '@/components/video-player/video-player-button.vue';

interface ITimelineChunk {
  from: Date;
  to: Date;
}

const OBJECT_TYPE_CHUNK = 0;
const OBJECT_TYPE_EXPORT = 104;

@Component({
  name: 'video-player-timeline',
  components: { VideoPlayerButton, VideoPlayerDatePicker, VideoPlayerExport, VideoPlayerThumb }
})
export default class VideoPlayerTimeline extends Vue {
  @Prop({ type: Date })
  datePickerValue!: Date;

  @Prop({ type: Array, default: [] })
  timeline!: ITimelineChunk[];

  @Prop({ required: true })
  camera!: number;

  @Prop({ type: Boolean })
  cameraRemoved!: boolean;

  isMouseEnter = false;
  isMouseMove = false;

  oldMouseX!: number;
  oldTimeOffset!: number;
  mousePosX = 0;

  canvas!: HTMLCanvasElement;
  ctx!: CanvasRenderingContext2D;

  mouseTimer = new ElapsedTimer();
  autoOffsetTimer = new AutoOffsetTimer(); // отключение авто прокрутки timeOffset при ручной прокрутке
  timelineData = new VideoPlayerTimelineData();
  timelineExport = new VideoPlayerTimelineExport();

  pixelsToTime = 1; // из пикселей в секунды
  timeOffset = 0; // сдвиг в секундах
  timePosition = 0; // позиция курсора в секундах
  mouseTime = 0; // время под курсором мыши

  forceAutoOffset = false;

  exportBorderLeft = new Image();
  exportBorderRight = new Image();

  dataLoaderStatus = '';
  minPixelsToTime = this.$store.getters.vmsTimelineMinZoom;
  maxPixelsToTime = this.$store.getters.vmsTimelineMaxZoom;

  checkLimits() {
    if (this.timeOffset < 0) {
      this.timeOffset = 0;
    }
    const maxTimeOffset = new Date().getTime() / 1000 - (this.canvas?.width * this.pixelsToTime) / 2;
    if (this.timeOffset > maxTimeOffset) {
      this.timeOffset = maxTimeOffset;
    }
    if (this.pixelsToTime < this.minPixelsToTime) {
      this.pixelsToTime = this.minPixelsToTime;
    }
    if (this.pixelsToTime > this.maxPixelsToTime) {
      this.pixelsToTime = this.maxPixelsToTime;
    }
    this.timelineExport.checkLimits();
  }

  clearCanvasArea() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  drawTimeLabels(ctx) {
    const labelStep = labelsStepByScale(this.pixelsToTime);
    const firstLabelTime = Math.floor(this.timeOffset / labelStep) * labelStep;
    const firstLabelOffsetPx = (this.timeOffset % labelStep) / this.pixelsToTime;
    const labelCount = (this.canvas.width * this.pixelsToTime) / labelStep;
    let lastDay = '';

    for (let i = 0; i < labelCount + 1; i++) {
      const labelX = -firstLabelOffsetPx + i * (labelStep / this.pixelsToTime) + 1;
      const d = new Date((firstLabelTime + i * labelStep) * 1000);
      const day = formatDate(d, 'dd.MM.yyyy');
      const time = formatTime(d, 'HH:mm');
      const mt = Math.ceil(ctx.measureText(time).width);

      ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)';
      ctx.beginPath();
      ctx.moveTo(labelX, 0);
      ctx.lineTo(labelX, this.canvas.height);
      ctx.stroke();

      ctx.font = '12px serif';
      ctx.fillStyle = 'white';
      ctx.fillText(time, labelX - mt / 2, 13);

      if (i && day !== lastDay) {
        ctx.fillText(day, labelX - mt / 2, 24);
      }
      lastDay = day;
    }
  }

  drawObject(ctx, timeStart, timeEnd, itemType) {
    let xStart = (timeStart - this.timeOffset) / this.pixelsToTime;
    let xEnd = (timeEnd - this.timeOffset) / this.pixelsToTime;
    if (xEnd < 0 || xStart >= this.canvas.width) {
      return;
    }
    if (itemType < 3) {
      const pad = itemType ? 20 : 50;
      const colors = ['rgba(255,0,255,0.2)', 'rgba(232,92,74,0.8)', 'rgba(6,193,103,0.8)'];
      const color = colors[itemType];
      ctx.fillStyle = color;
      ctx.fillRect(xStart, pad, xEnd - xStart, this.canvas.height - pad);
    }
    if (itemType === OBJECT_TYPE_EXPORT) {
      const pad = 0;
      ctx.fillStyle = 'rgba(121,204,233,0.3)';
      ctx.fillRect(xStart, pad, xEnd - xStart, this.canvas.height - pad);
    }
  }

  drawExportBorder(ctx, time, direct) {
    let x = (time - this.timeOffset) / this.pixelsToTime;
    if (x < 0 || x >= this.canvas.width) {
      return;
    }
    ctx.fillStyle = 'rgba(51, 121, 217, 0.9)';
    ctx.fillRect(x - 1, 0, 2, this.canvas.height);
    if (direct > 0) {
      ctx.drawImage(this.exportBorderLeft, x - 8, this.canvas.height / 2 - 15);
    } else {
      ctx.drawImage(this.exportBorderRight, x - 7, this.canvas.height / 2 - 15);
    }
  }

  drawTimeLabel(ctx, time, color) {
    ctx.font = '12px serif';
    const hhmmss = toHHMMSS(time);
    const bgWidth = Math.ceil(ctx.measureText(hhmmss).width) + 10;
    const bgHeight = 18;

    let x = (time - this.timeOffset) / this.pixelsToTime;
    if (x > -bgWidth && x < this.canvas.width) {
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, this.canvas.height);
      ctx.stroke();

      if (x > this.canvas.width - bgWidth * 2) {
        x -= bgWidth;
      }

      const y = 0;

      ctx.fillStyle = color;
      ctx.fillRect(x, y, bgWidth, bgHeight);

      ctx.fillStyle = '#000000';
      ctx.fillText(hhmmss, x + 4, y + 13);
    }
  }

  drawEventHelper(ctx) {
    ctx.font =
      '14px BlinkMacSystemFont, \'-apple-system\', BlinkMacSystemFont, \'Segoe UI\', ' +
      'Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', Helvetica, Arial, sans-serif';
    const text = this.$tf('video_player_zoom_helper');
    const metrics = ctx.measureText(text);
    const bgWidth = Math.ceil(metrics.width) + 8;
    const bgHeight = 24;
    const x = this.canvas.width / 2 - bgWidth / 2;
    const y = 20 + bgHeight / 2;

    ctx.fillStyle = 'rgba(0,0,0,0.5)';
    ctx.fillRect(x, y, bgWidth, bgHeight);

    ctx.fillStyle = 'rgba(255,255,255,0.5)';
    ctx.fillText(text, x + 4, y + 17);
  }

  forceRedraw() {
    this.checkLimits();
    this.clearCanvasArea();
    this.drawTimeLabels(this.ctx);

    const { objectItemsAll, isLoadedAll } = this.timelineData.findItems(this.timeOffset, this.timeOffset + this.canvas.width * this.pixelsToTime);

    objectItemsAll.forEach((item) => {
      if (isLoadedAll || item.type === OBJECT_TYPE_CHUNK) {
        let timeFrom = item.timeFrom;
        let timeTo = item.timeTo;
        if (timeFrom == timeTo) {
          timeFrom = timeFrom - 3 * this.pixelsToTime;
          timeTo = timeTo + 3 * this.pixelsToTime;
        }
        this.drawObject(this.ctx, timeFrom, timeTo, item.type);
      }
    });

    if (!isLoadedAll) {
      if (this.dataLoaderStatus !== 'wait' && this.dataLoaderStatus !== 'loading') {
        this.drawEventHelper(this.ctx);
      }
    }

    if (this.timelineExport.enabled) {
      this.drawObject(this.ctx, this.timelineExport.exportTimeStart, this.timelineExport.exportTimeEnd, OBJECT_TYPE_EXPORT);
      this.drawExportBorder(this.ctx, this.timelineExport.exportTimeStart, 1);
      this.drawExportBorder(this.ctx, this.timelineExport.exportTimeEnd, -1);
    }

    this.drawTimeLabel(this.ctx, this.timePosition, 'yellow');
    if (this.isShowCurrentPos) {
      this.drawTimeLabel(this.ctx, this.mouseTime, 'white');
    }
  }

  @Watch('isShowCurrentPos')
  @Watch('timePosition')
  redraw() {
    window.requestAnimationFrame(this.forceRedraw);
  }

  @Watch('timeOffset')
  updateTimelineData() {
    const timeTo = this.timeOffset + this.canvas.width * this.pixelsToTime;
    this.timelineData.updateDataByTimeRange(this.timeOffset, timeTo);
  }

  @Watch('cameraRemoved')
  cameraRemovedHandle() {
    if (!this.cameraRemoved) return;
    this.resetAll();
  }

  resetAll() {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
    window.removeEventListener('resize', this.resize);
    this.timelineData.clear();
  }

  toggleExportMode() {
    const canvasEndTime = this.timeOffset + this.canvas.width * this.pixelsToTime;
    this.timelineExport.toggleExportMode(this.timeOffset, canvasEndTime);
    this.redraw();
  }

  get timelineExportEnabled() {
    return this.timelineExport.enabled;
  }

  @Watch('timelineExportEnabled')
  timelineExportEnabledWatcher(value) {
    this.$emit('toggleExportMode', value);
  }

  getMousePosition(e) {
    const rect = this.canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    return { x, y, w: rect.width, h: rect.height };
  }

  updateMouseTime(e) {
    const mousePosition = this.getMousePosition(e);
    this.mousePosX = mousePosition.x;
    this.mouseTime = this.timeOffset + mousePosition.x * this.pixelsToTime;
    this.timelineExport.setMouseTime(this.mouseTime);
    this.timelineExport.setThresholdTime(this.pixelsToTime * 16);
  }

  scaleHandler(delta) {
    const oldPixelToTime = this.pixelsToTime;
    this.pixelsToTime -= (this.pixelsToTime / 5) * delta;
    this.checkLimits();
    this.timeOffset += (this.canvas.width / 2) * (oldPixelToTime - this.pixelsToTime);
    this.redraw();
  }

  areaPositionHandler(delta) {
    if (this.cameraRemoved) return;
    this.timeOffset += this.canvas.width * this.pixelsToTime * delta;
    this.redraw();
  }

  mouseenter() {
    this.isMouseEnter = true;
  }

  mouseleave() {
    this.isMouseEnter = false;
  }

  mousedown(e) {
    this.isMouseMove = true;
    this.oldMouseX = e.clientX;
    this.oldTimeOffset = this.timeOffset;
    this.mouseTimer.start();

    if (this.timelineExport.enabled) {
      this.updateMouseTime(e);
      this.timelineExport.mousedown();
    }
  }

  mousemove(e) {
    this.updateMouseTime(e);
    if (this.isMouseMove) {
      const deltaTime = (e.clientX - this.oldMouseX) * this.pixelsToTime;
      if (this.timelineExport.mousemove(deltaTime)) {
        this.redraw();
        return;
      }
      this.timeOffset = this.oldTimeOffset - (e.clientX - this.oldMouseX) * this.pixelsToTime;
    }
    this.autoOffsetTimer.stopForTimeout();
    this.redraw();
  }

  mouseup(e) {
    this.isMouseMove = false;
    this.timelineExport.mouseup();
    // проверка длительности нажатия, можно проверить что мышь не была сдвинута
    if (this.mouseTimer.elapsed() < 200) {
      this.updateMouseTime(e);
      this.$emit('positionChange', this.mouseTime);
    }
  }

  mousewheel(e) {
    const oldPixelToTime = this.pixelsToTime;
    this.pixelsToTime += (this.pixelsToTime * e.deltaY) / 500;
    this.checkLimits();
    const mousePosition = this.getMousePosition(e);
    this.timeOffset += mousePosition.x * oldPixelToTime - mousePosition.x * this.pixelsToTime;
    this.redraw();
  }

  resize() {
    const rect = (this.$refs.canvasWrapper as HTMLDivElement).getBoundingClientRect();
    const canvasWidth = rect.width;
    const canvasHeight = rect.height;
    this.canvas = this.$refs.canvas as HTMLCanvasElement;

    this.canvas.style.width = canvasWidth + 'px';
    this.canvas.style.height = canvasHeight + 'px';

    const scaleFactor = 1;
    this.canvas.width = Math.ceil(canvasWidth * scaleFactor);
    this.canvas.height = Math.ceil(canvasHeight * scaleFactor);

    this.ctx = coerceAsNonNullable(this.canvas.getContext('2d'));
    this.redraw();
  }

  created() {
    this.timelineData.setCamera(this.camera);
    this.timelineData.setObjects(this.$store.getters.vmsTimelineObjects);
    this.timelineData.setDataLoader(
      (options) => {
        return this.$store.dispatch('requestApi', options);
      },
      (status) => {
        this.dataLoaderStatus = status;
        let translatedStatus = '';
        if (status) {
          translatedStatus = this.$tf('player_helper_' + status);
        }
        this.$emit('helperChange', translatedStatus);
      },
      (e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      }
    );
    this.timelineData.setDataRender(this.redraw.bind(this));
    this.timelineData.setAutoUpdate(60 * 1000);
    this.autoOffsetForceNext();
  }

  dayChange(time) {
    this.$emit('positionChange', time);
    this.autoOffsetForceNext();
  }

  autoOffsetForceNext() {
    this.forceAutoOffset = true;
  }

  autoOffset(time) {
    if (this.forceAutoOffset || (this.autoOffsetTimer.isAutoOffset && !this.timelineExport.enabled)) {
      const canvasTimeAll = this.canvas.width * this.pixelsToTime;
      const canvasTimePad = canvasTimeAll / 10;
      if (time > this.timeOffset + canvasTimeAll - canvasTimePad || time < this.timeOffset) {
        this.timeOffset = time - canvasTimePad;
      }
    }
    this.forceAutoOffset = false;
  }

  setPosition(time) {
    this.timePosition = time;
    this.redraw();
  }

  mounted() {
    this.resize();
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mouseup', this.mouseup);
    window.addEventListener('resize', this.resize);

    this.exportBorderLeft.src = require('../../assets/icons/player/export-border-left.svg');
    this.exportBorderRight.src = require('../../assets/icons/player/export-border-right.svg');
  }

  beforeDestroy() {
    this.resetAll();
  }

  get isShowCurrentPos() {
    const sideButtonsWidth = 40; // кнопки на таймлайне
    if (this.mousePosX < sideButtonsWidth || this.mousePosX > this.canvas.width - sideButtonsWidth) {
      return false;
    }
    return this.isMouseEnter && !this.timelineExport.enabled && !this.isMouseMove;
  }

  get thumbSrc() {
    if (this.isShowCurrentPos) {
      const { objectItemsAll, isLoadedAll } = this.timelineData.findItems(this.timeOffset, this.timeOffset + this.canvas.width * this.pixelsToTime);
      for (let i = 0; i < objectItemsAll.length; i++) {
        if (!objectItemsAll[i].metadata?.thumbnail) {
          continue;
        }
        let timeFrom = objectItemsAll[i].timeFrom;
        let timeTo = objectItemsAll[i].timeTo;
        if (timeFrom === timeTo) {
          timeFrom = timeFrom - 4 * this.pixelsToTime;
          timeTo = timeTo + 4 * this.pixelsToTime;
        }
        if (this.mouseTime >= timeFrom && this.mouseTime <= timeTo && isLoadedAll) {
          return objectItemsAll[i].metadata?.thumbnail;
        }
      }
    }
    return false;
  }

  get cursorStyle() {
    let cursor = this.timelineExport.cursor || 'crosshair';
    if (this.isMouseMove) {
      cursor = this.timelineExport.cursor || 'move';
    }
    return { cursor };
  }

  get thumbStyles() {
    return {
      left: this.mousePosX - 25 + 'px'
    };
  }
}
