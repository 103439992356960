













import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { CardType, ICarCard, IHumanCard } from '@/store/cards/cards';
import CardMetafieldListItem from '@/components/cards/metafield.list.item.vue';
import WatchListsInline from '@/components/watch-lists/inline.vue';
import CardRelations from '@/components/cards/card-relations.vue';

@Component({
  name: TableComponentNames.CardDescription,
  components: { CardRelations, WatchListsInline, CardMetafieldListItem }
})
export default class TableGeneratorCardDescription extends Vue {
  @Prop({ required: true })
  routeName!: string;

  @Prop({ required: true })
  item!: ICarCard | IHumanCard;

  @Prop({ required: true })
  cardType!: CardType;

  get isCarCard() {
    return this.cardType === CardType.Car;
  }

  get nameClass() {
    return this.item.filled ? 'card-table-item__text-accent' : '';
  }
}
