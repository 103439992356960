import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class ObjectPreview extends Vue {
  private _item: any;

  previewHandler(item) {
    this._item = item;
    const data = { src: this.fullImage, box: this.bbox, silhouettes_bbox: [] };
    if (this._item.body_bboxes) data.silhouettes_bbox = this.$filters.polyArraysToRects(this._item.body_bboxes);
    this.$store.dispatch('showImage', data);
  }

  get fullImage() {
    return this._item.fullframe || this._item.source_photo; // fullframe -> events; source_photo -> case faces
  }

  get hasBBox() {
    return this._item.frame_coords_top > 0 || this._item.frame_coords_bottom > 0;
  }

  get bbox() {
    return this.hasBBox
      ? {
          x: this._item.frame_coords_left,
          y: this._item.frame_coords_top,
          w: this._item.frame_coords_right - this._item.frame_coords_left,
          h: this._item.frame_coords_bottom - this._item.frame_coords_top
        }
      : null;
  }
}
