






import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { IFilter } from '@/definitions/filter';

@Component({
  name: 'counter-records-filter',
  components: {
    FilterForm
  }
})
export default class CounterRecordsFilter extends Vue {
  get schema(): IFilter {
    return this.$store.state.counter_records.filter.schema;
  }
}
