




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';

@Component({ components: { FilterForm } })
export default class EpisodesFilter extends Vue {
  get state() {
    return this.$store.state[`episodes/${this.objectsType}`];
  }

  get objectsType() {
    return this.$route.params.objects ?? 'humans';
  }
}
