<template>
  <filter-form v-bind="schema" />
</template>
<script>
import FilterForm from '@/components/filter-form/filter-form';
import { SourceType } from './index.vue';
import { FieldName } from '@/store/events/filters/fields';
import { SearchTargetType } from '@/store/search/objects';
import qs from 'qs';
import _ from '../../../apps/common/lodash';

const unusedFieldsForTargetTypeEqualToCards = [
  FieldName.CameraGroups,
  FieldName.Cameras,
  FieldName.CreatedDateGte,
  FieldName.CreatedDateLte,
  FieldName.Ordering
];

export default {
  name: 'search-filter',
  components: { FilterForm },
  computed: {
    state: function () {
      return this.$store.state.search_objects;
    },
    schema() {
      const schema = this.state.filter.schema;
      let modifiedSchema = this.isSourceTypeEqualToCardObject ? this.modifySchemaBySourceType(schema) : schema;
      modifiedSchema = this.isTargetTypeEqualToCards ? this.modifySchemaByTargetType(modifiedSchema) : modifiedSchema;
      modifiedSchema = this.isTargetTypeEqualToClusters ? this.modifySchemaByClustersTargetType(modifiedSchema) : modifiedSchema;
      return modifiedSchema;
    },
    queryFilter() {
      const filter = this.$route.params.filter;
      return qs.parse(filter);
    },
    isSourceTypeEqualToCardObject() {
      return this.queryFilter.sourceType === SourceType.CardObject;
    },
    isTargetTypeEqualToCards() {
      return this.queryFilter.targetType === SearchTargetType.Cards;
    },
    isTargetTypeEqualToClusters() {
      return this.queryFilter.targetType === SearchTargetType.Clusters;
    }
  },
  methods: {
    modifySchemaBySourceType(schema) {
      const modifiedSchema = _.cloneDeep(schema);
      const field = modifiedSchema.fields.find(({ name }) => name === FieldName.Ordering);
      const item = field.control.component[1].items.find(({ value }) => value === '-id');
      item.i18nLabel = 'date_multiselect_not_supported';
      return modifiedSchema;
    },
    modifySchemaByTargetType(schema) {
      const modifiedSchema = _.cloneDeep(schema);
      const filteredFields = schema.fields.filter(({ name }) => !unusedFieldsForTargetTypeEqualToCards.includes(name));
      modifiedSchema.fields = filteredFields;
      return modifiedSchema;
    },
    modifySchemaByClustersTargetType(schema) {
      const modifiedSchema = _.cloneDeep(schema);
      const field = modifiedSchema.fields.find(({ name }) => name === FieldName.Ordering);
      const item = field.control.component[1].items.find(({ value }) => value === '-looks_like_confidence');
      item.value = '-created_date';
      item.i18nLabel = 'cluster_created_date';
      const idItem = field.control.component[1].items.find(({ value }) => value === '-id');
      idItem.i18nLabel = 'id';
      return modifiedSchema;
    }
  }
};
</script>
