



import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';
import CounterRecordImage from '@/components/counters/counter-record-image.vue';

@Component({
  name: TableComponentNames.CounterRecordImage,
  components: { CounterRecordImage }
})
export default class TableGeneratorCounterRecordImage extends Vue {
  @Prop({ required: true, type: Object })
  item!: any;

  getLastCounterRecordsGroupItem(group) {
    if (Array.isArray(group?.records)) {
      return group.records[group.records.length - 1] ?? {};
    }
    return {};
  }
}
