



import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IAuditLog } from '@/store/audit-logs/types';

@Component({
  name: TableComponentNames.ObjectItemLink
})
export default class TableGeneratorObjectItemLink extends Vue {
  @Prop({ required: true })
  item!: IAuditLog;

  get state() {
    return this.$store.state.audit_logs;
  }

  get path() {
    if (this.item.object_id && this.item.object_type) {
      const resolver = this.state.ObjectPages[this.item.object_type];
      return resolver ? resolver(this.item) : '';
    }
    return '';
  }
}
