<template>
  <div>
    <div slot="content" class="cols flex-wrap clusters">
      <template v-if="state.items.length">
        <cluster-item
            :key="'item-' + item.id"
            :item="item"
            :selected="false"
            :objects="objects"
            :any-selected="false"
            :selectable="false"
            v-for="item in state.items"
        ></cluster-item>
      </template>
      <div v-else class="state__items-empty">
        {{ $tf('no_data') }}
      </div>
    </div>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { ObjectsType } from '@/store/objects/get.module';
import ClusterItem from '@/components/clusters/item';

@Component({
  name: 'ClustersSearchResults',
  components: { ClusterItem },
  props: {
    state: {
      type: Object,
      required: true
    },
    objects: {
      type: String,
      default: ObjectsType.Faces
    }
  }
})
export default class SocialSearchResults extends Component {
  socialClickHandler(v) {
    window.open(v.profile, '_blank');
  }
}
</script>

<style lang="stylus"></style>
