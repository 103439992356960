import { Module, VuexModule } from 'vuex-class-modules';
import { IMenu } from '../../definitions/microservices'; //@dont-touch

export const MicroserviceModuleName = 'microservices';

@Module({ generateMutationSetters: true })
export class MicroservicesModule extends VuexModule {
  main_menu: IMenu[] = [];
  settings_menu: IMenu[] = [];
  store_modules: string[] = [];
}

console.log('Microservices', MicroserviceModuleName);
