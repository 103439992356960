import { TableComponentNames } from '@/components/tables/components/names';
import TableGeneratorEventDossier from '@/components/tables/components/generator.event.dossier.vue';
import TableGeneratorEventFeatures from '@/components/tables/components/generator.event.features.vue';
import TableGeneratorEventTimeCamera from '@/components/tables/components/generator.event.time.camera.vue';
import TableGeneratorPolygonThumbnailObjectPreview from '@/components/tables/components/generator.polygon.thumbnail.object.preview.vue';
import TableGeneratorDateTime from '@/components/tables/components/generator.date.time.vue';
import TableGeneratorAreaNameDescription from '@/components/tables/components/generator.area.name.description.vue';
import TableGeneratorCameraScreenshot from '@/components/tables/components/generator.camera.screenshot.vue';
import TableGeneratorAreaTriggerDescription from '@/components/tables/components/generator.area-trigger.description.vue';
import TableGeneratorAreaRecordDescription from '@/components/tables/components/generator.area-record.description.vue';
import TableGeneratorAreaTriggerPolygon from '@/components/tables/components/generator.area-trigger.polygon.vue';
import TableGeneratorPolygonThumbnailPreview from '@/components/tables/components/generator.polygon.thumbnail.preview.vue';
import TableGeneratorAuthSessionButtons from '@/components/tables/components/generator.auth-sessions.buttons.vue';
import TableGeneratorCardDescription from '@/components/tables/components/generator.card.description.vue';
import TableGeneratorCardObjectThumbnail from '@/components/tables/components/generator.card.object.thumbnail.vue';
import TableGeneratorCardClusterThumbnail from '@/components/tables/components/generator.card.cluster.thumbnail.vue';
import TableGeneratorCardClusterThumbnailItem from '@/components/tables/components/generator.card.cluster.thumbnail.item.vue';
import TableGeneratorCounterStatus from '@/components/tables/components/generator.counter.status.vue';
import TableGeneratorCounterChart from '@/components/tables/components/generator.counter.chart.vue';
import TableGeneratorCounterCheckbox from '@/components/tables/components/generator.counter.checkbox.vue';
import TableGeneratorCounterRecordImage from '@/components/tables/components/generator.counter.record-image.vue';
import TableGeneratorActiveComponent from '@/components/tables/components/generator.active.component.vue';
import TableGeneratorFilledComponent from '@/components/tables/components/generator.filled.component.vue';
import TableGeneratorCardStatistic from '@/components/tables/components/generator.card.statistic.vue';
import TableGeneratorObjectTypeLabel from '@/components/tables/components/generator.object.type.label.vue';
import TableGeneratorActionTypeLabel from '@/components/tables/components/generator.action.type.label.vue';
import TableGeneratorUserProfileLink from '@/components/tables/components/generator.user.profile.link.vue';
import TableGeneratorObjectItemLink from '@/components/tables/components/generator.object.item.link.vue';
import ClusterColumn from './cluster/cluster.column.vue';
import EpisodeColumn from './cluster/episode.column.vue';
import ClusterEventObjectThumbnail from './cluster/object.thumbnail.column';
import FeaturesColumn from './cluster/attributes.column.vue';

export const TableComponents = {
  [TableComponentNames.ActionTypeLabel]: TableGeneratorActionTypeLabel,
  [TableComponentNames.AreaNameAndDescription]: TableGeneratorAreaNameDescription,
  [TableComponentNames.AreaTriggerDescription]: TableGeneratorAreaTriggerDescription,
  [TableComponentNames.AreaTriggerPolygon]: TableGeneratorAreaTriggerPolygon,
  [TableComponentNames.AreaRecordDescription]: TableGeneratorAreaRecordDescription,
  [TableComponentNames.AuthSessionButtons]: TableGeneratorAuthSessionButtons,
  [TableComponentNames.CameraScreenshot]: TableGeneratorCameraScreenshot,
  [TableComponentNames.CardDescription]: TableGeneratorCardDescription,
  [TableComponentNames.CardObjectThumbnail]: TableGeneratorCardObjectThumbnail,
  [TableComponentNames.CardClusterThumbnail]: TableGeneratorCardClusterThumbnail,
  [TableComponentNames.CardClusterThumbnailItem]: TableGeneratorCardClusterThumbnailItem,
  [TableComponentNames.CardStatistic]: TableGeneratorCardStatistic,
  [TableComponentNames.CounterStatus]: TableGeneratorCounterStatus,
  [TableComponentNames.CounterChart]: TableGeneratorCounterChart,
  [TableComponentNames.CounterCheckbox]: TableGeneratorCounterCheckbox,
  [TableComponentNames.CounterRecordImage]: TableGeneratorCounterRecordImage,
  [TableComponentNames.ActiveComponent]: TableGeneratorActiveComponent,
  [TableComponentNames.FilledComponent]: TableGeneratorFilledComponent,
  [TableComponentNames.CounterRecordImage]: TableGeneratorCounterRecordImage,
  [TableComponentNames.DateTime]: TableGeneratorDateTime,
  [TableComponentNames.EventDossier]: TableGeneratorEventDossier,
  [TableComponentNames.EventFeatures]: TableGeneratorEventFeatures,
  [TableComponentNames.EventTimeCamera]: TableGeneratorEventTimeCamera,
  [TableComponentNames.ObjectTypeLabel]: TableGeneratorObjectTypeLabel,
  [TableComponentNames.ObjectItemLink]: TableGeneratorObjectItemLink,
  [TableComponentNames.PolygonThumbnailPreview]: TableGeneratorPolygonThumbnailPreview,
  [TableComponentNames.PolygonThumbnailObjectPreview]: TableGeneratorPolygonThumbnailObjectPreview,
  [TableComponentNames.UserProfileLink]: TableGeneratorUserProfileLink,
  [TableComponentNames.ClusterLink]: ClusterColumn,
  [TableComponentNames.EpisodeLink]: EpisodeColumn,
  [TableComponentNames.ClusterEventObjectThumbnail]: ClusterEventObjectThumbnail,
  [TableComponentNames.FeaturesContent]: FeaturesColumn
};
