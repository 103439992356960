






import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'reports-select-cameras',
  props: {
    value: { type: Number },
    items: { type: Array, required: true }
  }
})
export default class ReportsSelectCameras extends Vue {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }
}
