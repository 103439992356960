import '@/css/dark/element-ui.css';
import {
  Alert,
  Autocomplete,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  ColorPicker,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Icon,
  InfiniteScroll,
  Input,
  InputNumber,
  Loading,
  Menu,
  MenuItem,
  Message,
  MessageBox,
  Notification,
  Option,
  Pagination,
  Popover,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Row,
  Select,
  Slider,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  TimePicker,
  TimeSelect,
  Tooltip,
  Tree
} from 'element-ui';
import ElementLocale from 'element-ui/lib/locale';
import { showError, showSuccess, showWarning } from '../components/notification';

export function setupElementUi(Vue) {
  [
    Alert,
    Autocomplete,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Col,
    Collapse,
    CollapseItem,
    ColorPicker,
    DatePicker,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Form,
    FormItem,
    Icon,
    InfiniteScroll,
    Input,
    InputNumber,
    Loading.directive,
    Menu,
    MenuItem,
    Option,
    Pagination,
    Popover,
    Progress,
    Radio,
    RadioButton,
    RadioGroup,
    Row,
    Select,
    Slider,
    Switch,
    Table,
    TableColumn,
    TabPane,
    Tabs,
    Tag,
    TimePicker,
    TimeSelect,
    Tooltip,
    Tree
  ].forEach((component) => Vue.use(component));
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$notify = Notification;
  Vue.prototype.$message = Message;
  Vue.prototype.$showError = showError;
  Vue.prototype.$showWarning = showWarning;
  Vue.prototype.$showSuccess = showSuccess;
  preventDialogClosingWhenMouseLeavesIt();
}

export function setupElementUiI18n(i18n) {
  ElementLocale.i18n((key, value) => i18n.instance.t(key, value));
}

function preventDialogClosingWhenMouseLeavesIt() {
  let mousedown = false;
  let mouseup = false;

  window.addEventListener('mousedown', handleMousedownEvent, true);
  window.addEventListener('mouseup', handleMouseupEvent, true);
  window.addEventListener('click', handleClickEvent, true);

  function handleMousedownEvent(event) {
    mousedown = isDialogBackdropElement(event.target);
  }

  function handleMouseupEvent(event) {
    mouseup = isDialogBackdropElement(event.target);
  }

  function handleClickEvent(event) {
    if (isDialogBackdropElement(event.target)) {
      if (!(mousedown && mouseup)) {
        event.stopPropagation();
      }
      mousedown = mouseup = false;
    }
  }
}

function isDialogBackdropElement(element) {
  return element.classList.contains('el-dialog__wrapper');
}
