



































import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import PolygonView from '@/components/polygon-draw/view.vue';
import FailedScreenshot from '@/components/common/failedScreenshot.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { IArea } from '@/store/areas/areas/index';
import axios from 'axios';

interface IDimension {
  width: number;
  height: number;
}

@Component({
  name: TableComponentNames.PolygonThumbnailPreview,
  components: { PolygonView, FailedScreenshot }
})
export default class TableGeneratorPolygonThumbnailPreview extends Vue {
  @Prop({ required: true, type: Object })
  item!: IArea;

  thumbnailWidth = 160;
  thumbnailHeight = 90;

  defaultWidth = 1024;
  defaultHeight = 768;

  thumbnailImageData = '';
  previewImageData = '';

  thumbnailLoading = false;
  previewLoading = false;

  dimensions: IDimension | null = null;

  previewEnabled = false;

  previewHandler() {
    this.loadPreviewScreenshot();
    this.previewEnabled = true;
  }

  get previewStyle() {
    return { width: `${this.previewSize.width}px`, height: `${this.previewSize.height}px` };
  }

  get previewSize() {
    let { clientWidth, clientHeight } = document.documentElement;
    let height = Math.min(clientHeight * 0.8, this.originalHeight);
    let width = this.dimensions ? (this.dimensions.width * height) / this.dimensions.height : this.defaultWidth;
    if (width > clientWidth * 0.8) {
      width = clientWidth * 0.8;
      height = this.dimensions ? (this.dimensions.height * width) / this.dimensions.width : this.defaultHeight;
    }

    return { height: Math.round(height), width: Math.round(width) };
  }

  get thumbnailStyle() {
    return { width: `${this.thumbnailWidth}px`, height: `${this.thumbnailRelativeHeight}px` };
  }

  get thumbnailRelativeHeight() {
    return this.dimensions ? Math.round((this.dimensions.height * this.thumbnailWidth) / this.dimensions.width) : this.thumbnailHeight;
  }

  get defaultCameraRoi(): any {
    return this.item.camera_roi ? this.item.camera_roi[0] : null;
  }

  get defaultPoints(): number[][] {
    return [
      [0, 0],
      [this.originalWidth, 0],
      [this.originalWidth, this.originalHeight],
      [0, this.originalHeight]
    ];
  }

  async loadDimensions() {
    this.dimensions = await this.$store.dispatch('getCameraDimensions', this.cameraId);
  }

  get originalWidth(): number {
    return this.dimensions?.width || this.defaultWidth;
  }

  get originalHeight(): number {
    return this.dimensions?.height || this.defaultHeight;
  }

  get cameraId(): number {
    return this.defaultCameraRoi?.camera;
  }

  get points(): number[][] {
    return this.defaultCameraRoi?.roi ? this.defaultCameraRoi.roi : this.defaultPoints;
  }

  getScreenshotUrl(params = ''): string {
    return this.$store.state.config.server.url + `cameras/${this.cameraId}/screenshot/${params}`;
  }

  get previewUrl(): string {
    return this.getScreenshotUrl(this.previewSize.width ? `?width=${this.previewSize.width}` : '');
  }

  get thumbnailUrl(): string {
    return this.getScreenshotUrl(this.thumbnailWidth ? `?width=${this.thumbnailWidth}` : '');
  }

  mounted() {
    if (this.cameraId) {
      this.loadThumbnailScreenshot();
      this.loadDimensions();
    }
  }

  @Watch('cameraId')
  cameraIdHandler() {
    this.clearImages();
    if (this.cameraId) {
      this.loadThumbnailScreenshot();
      this.loadDimensions();
    }
  }

  loadScreenshot(url: string) {
    return axios({
      url,
      method: 'post',
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
      }
    })
      .then((v) => {
        if (v.data && v.data.size) {
          return URL.createObjectURL(v.data);
        } else {
          throw new Error('[image:loaded] empty data error');
        }
      })
      .catch((e) => {
        console.warn('[poly-view-error] ', e);
        return '';
      });
  }

  async loadThumbnailScreenshot() {
    if (this.thumbnailImageData) return;
    try {
      this.thumbnailLoading = true;
      this.thumbnailImageData = await this.loadScreenshot(this.thumbnailUrl);
    } finally {
      this.thumbnailLoading = false;
    }
  }

  async loadPreviewScreenshot() {
    if (this.previewImageData) return;
    try {
      this.previewLoading = true;
      this.previewImageData = await this.loadScreenshot(this.previewUrl);
    } finally {
      this.previewLoading = false;
    }
  }

  clearImages() {
    if (this.previewImageData) {
      URL.revokeObjectURL(this.previewImageData);
      this.previewImageData = '';
    }
    if (this.thumbnailImageData) {
      URL.revokeObjectURL(this.thumbnailImageData);
      this.thumbnailImageData = '';
    }
  }

  beforeDestroy() {
    this.clearImages();
  }
}
