






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { INotificationError } from './types';

@Component({
  name: 'error-message'
})
export default class ErrorMessage extends Vue {
  @Prop({ type: Object, required: true })
  error!: Error | INotificationError;

  get errorCode(): string {
    if (this.error instanceof Error) {
      return this.error.name;
    }
    return this.error.code_i18n ? this.$tt(this.error.code_i18n) : this.error.code;
  }

  get errorDescription(): string {
    if (this.error instanceof Error) {
      return this.error.message;
    }
    return this.error.desc_i18n ? this.$tt(this.error.desc_i18n) : this.error.desc;
  }

  get errorDebug(): string {
    if (this.error instanceof Error) {
      return this.error.stack || '';
    }
    return this.error.debug || '';
  }

  get originalErrorNotEmpty(): boolean {
    if (!(this.error instanceof Error) && this.error.original_error) {
      return Boolean(Object.keys(this.error.original_error).length);
    }
    return false;
  }
}
