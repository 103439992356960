/* eslint-disable quotes */
export default {
  // verb
  activate: 'activate',
  add: 'add',
  cancel_v: 'cancel',
  create: 'create',
  change: 'change',
  clear: 'clear',
  date: 'Date',
  delete: 'delete',
  deduplicate: 'deduplicate',
  duplicate: 'duplicate',
  duplicate_card: 'Seems like the card already exists',
  download: 'download',
  edit: 'edit',
  enable: 'enable',
  enable_recorder: 'enable recorder',
  forget: 'forget',
  save: 'save',
  select: 'select',
  unselect: 'unselect',
  take: 'take',
  remember: 'remember',
  restore: 'restore',
  require: 'require',
  update: 'update',
  use: 'use',
  verify_v: 'verify',
  want: 'want',
  restart: 'restart',
  acknowledge_v: 'acknowledge',
  show: 'show',
  upload_v: 'upload',
  register: 'register',
  purge: 'purge',
  list: 'list',
  // adjective
  alert: 'alert',
  acknowledge: 'acknowledge',
  acknowledged: 'acknowledged',
  average_age: 'average age',
  not_acknowledged: 'not acknowledged',
  only_acknowledged: 'only acknowledged',
  only_unacknowledged: 'only unacknowledged',
  only_active: 'only active',
  only_not_active: 'only not active',
  active: 'active',
  added: 'added',
  advanced: 'advanced',
  as: 'as',
  created: 'created',
  completed: 'completed',
  scheduled: 'scheduled',
  detected: 'detected',
  enabled: 'enabled',
  failed: 'failed',
  features: 'features',
  finished: 'finished',
  found: 'found',
  group_ajv: 'group',
  not_found: 'not found',
  has_no_match: 'Has no match',
  no_match: 'no match',
  no_matches: 'no matches',
  has_match: 'Has match',
  no_faces_on_photo: 'no faces on photo',
  only_with_matches: 'only with matches',
  only_without_matches: 'only without matches',
  only_with_faces: 'only with faces',
  only_without_faces: 'only without faces',
  only_with_cars: 'only with cars',
  only_without_cars: 'only without cars',
  only_with_bodies: 'only with bodies',
  only_without_bodies: 'only without bodies',
  important: 'important',
  info: 'information',
  local: 'local',
  matched_to: 'matched to',
  modified: 'modified',
  new: 'new',
  next: 'next',
  or: 'or',
  previous: 'previous',
  parallel: 'parallel',
  success: 'success',
  successfully: 'successfully',
  selected: 'selected',
  not_selected: 'not selected',
  taked: 'accepted',
  not_taked: 'not accepted',
  was_updated: 'updated',
  was_created: 'created',
  with: 'with',
  uploaded: 'uploaded',
  deleted: 'deleted',
  posted: 'posted',
  not_posted: 'not posted',
  full: 'full',
  short: 'short',
  male: 'male',
  female: 'female',
  eye: 'eyeglasses',
  sun: 'sunglasses',
  // noun
  acknowledgement: 'acknowledgment',
  azimuth: 'azimuth',
  event_acknowledgement: 'event acknowledgment',
  action: 'action',
  admin: 'administrator',
  administrator: 'administrator',
  all: 'all',
  back: 'back',
  batch: 'batch',
  batch_size: 'number of notifications in webhook batch',
  batch_upload_leave_confirm: 'The batch upload will be canceled if you leave the page. Continue?',
  buttons: 'buttons',
  camera: 'camera',
  cameras: 'cameras',
  camera_entry: 'camera entry',
  camera_exit: 'camera exit',
  camera_type: 'camera type',
  camera_group: 'camera group',
  camera_groups: 'camera groups',
  camera_info: 'camera info',
  no_camera: 'no cameras',
  cancel: 'cancel',
  comment: 'comment',
  code: 'code',
  csv: 'CSV',
  confirm_password: 'confirm password',
  confidence: 'confidence',
  confidence_threshold: 'confidence threshold',
  dashboard: 'dashboard',
  datetime: 'time',
  detect: 'detect',
  detection_error: 'detection error',
  detector: 'detector',
  deduplicate_delay: 'deduplication interval',
  description: 'description',
  dialogs: 'dialogs',
  documentation: 'documentation',
  attributes: 'attributes',
  watch_lists: 'watch lists',
  watch_list: 'watch list',
  dossier_list: 'watch list',
  end: 'end',
  email: 'email',
  error: 'error',
  original_error: 'Original error',
  errors: 'errors',
  event: 'event',
  events: 'events',
  face: 'face',
  faces: 'faces',
  car: 'car',
  body: 'body',
  filename: 'filename',
  file: 'file',
  files: 'files',
  filter: 'filter',
  filters: 'filters',
  filled: 'filled',
  folder: 'folder',
  forms: 'forms',
  galleries: 'galleries',
  gallery: 'gallery',
  group: 'group',
  guide: 'guide',
  header: 'header',
  height: 'height',
  icons: 'icons',
  id: 'ID',
  image: 'image',
  in_progress: 'in progress',
  in_queue: 'in queue',
  items: 'items',
  label: 'label',
  labels: 'labels',
  language: 'language',
  latitude: 'latitude',
  license: 'license',
  loading: 'loading',
  log: 'log',
  logs: 'logs',
  login: 'username | login',
  logout: 'logout',
  longitude: 'longitude',
  manager: 'manager',
  match: 'match | matched',
  matches: 'matches',
  menu: 'menu',
  meta: 'meta',
  name: 'name',
  no: 'no',
  notify: 'notify',
  page: 'page',
  parameters: 'parameters',
  parameter: 'parameter',
  password: 'password',
  phone: 'phone',
  photo: 'photo',
  photo_delete_confirm: 'are you sure you want to delete the photo?',
  photos: 'photos',
  prefix: 'prefix',
  preferences: 'preferences',
  profile: 'profile',
  postfix: 'postfix',
  ok: 'ok',
  operator: 'operator',
  reason: 'reason',
  report: 'report',
  reports: 'reports',
  report_type: 'report type',
  audit_logs: 'audit Log',
  audit_logs_details: 'details',
  reset: 'reset',
  roi: 'Regions of Interest',
  role: 'role',
  roles: 'roles',
  rot: 'Region of Tracking',
  upload: 'upload',
  upload_faces_failed: 'upload failed',
  upload_bodies_failed: 'upload failed',
  upload_cars_failed: 'upload failed',
  has_no_good_quality_objects: 'has no good quality objects',
  has_no_objects_detected: 'has no objects detected',
  url: 'url',
  stream_url: 'streaming protocol URL',
  user: 'user',
  users: 'users',
  screenshot: 'screenshot',
  security: 'security',
  send_attempts: 'number of attempts to send (0 - unlimited)',
  send_attempts_comment:
    'If the connection is established after a loss and the value is set to 0, you will receive all messages since the loss of the connection.',
  sign_in: 'sign in',
  size: 'size',
  sound: 'sound',
  sound_aj: 'sound',
  source: 'source',
  start: 'start',
  status: 'status',
  search: 'search',
  search_by: 'search by',
  search_location: 'search in',
  search_looks_like: 'search looks like',
  settings: 'settings',
  social: 'social networks',
  social_nets: 'social networks',
  standard_report: 'standard report',
  stop: 'stop',
  styles: 'styles',
  tables: 'tables',
  type: 'type',
  title: 'title',
  work_time_report: 'work time report',
  typography: 'typography',
  threshold: 'threshold',
  validate: 'validate',
  verify: 'verify',
  warning: 'warning',
  width: 'width',
  you: 'you',
  yes: 'yes',
  host: 'host',
  port: 'port',
  base_uri: 'base uri',
  server: 'server',
  media: 'media',
  ids: 'ids',
  application: 'application',
  alarm: 'alarm',
  hook: 'hook',
  hooks: 'hooks',
  webhook: 'webhook',
  webhooks: 'webhooks',
  genetec: 'genetec',
  general: 'general',
  isnot: 'isnot',
  is: 'is',
  set: 'set',
  check: 'check',
  config: 'config',
  configured: 'configured',
  import: 'import',
  state: 'state',
  biggest: 'biggest',
  reject: 'reject',
  total: 'total',
  red: 'red',
  green: 'green',
  yellow: 'yellow',
  gray: 'gray',
  result: 'result',
  results: 'results',
  count: 'count',
  number: 'number',
  of: 'of',
  not: 'not',
  process: 'process',
  progress: 'progress',
  permission: 'permission',
  permissions: 'permissions',
  missing_permissions: 'missing permissions',
  roles_and_permissions: 'roles & permissions',
  duration: 'duration',
  ready: 'ready',
  the: 'the',
  video: 'video',
  videos: 'videos',
  video_wall: 'video Wall',
  waiting: 'waiting',
  was: 'was',
  were: 'were',
  video_stream: 'video stream',
  gender: 'gender',
  age: 'age',
  emotions: 'emotions',
  glasses: 'glasses',
  beard: 'beard',
  angry: 'angry',
  disgust: 'disgust',
  fear: 'fear',
  happy: 'happy',
  sad: 'sad',
  surprise: 'surprise',
  neutral: 'neutral',
  real: 'real',
  fake: 'fake',
  layout: 'layout',
  live: 'live',
  liveness: 'liveness',
  common: 'common',
  services: 'services',
  generated: 'generated',
  limits: 'limits',
  limit: 'limit',
  buy: 'buy',
  valid: 'valid',
  main: 'main',
  second_ago_pf: 'second ago | seconds ago',
  attempts: 'attempts',
  remote: 'remote',
  appearance: 'appearance',
  processing: 'processing',
  request_ip: 'IP',
  device_uid: 'device ID',
  action_type: 'action',
  object_type: 'object',
  object_id: 'object ID',
  created_date: 'time',
  cluster_created_date: 'creation date',
  // phrases
  save_screenshot: 'save the screenshot',
  make_screenshot: 'make a screenshot',
  redo_screenshot: 'make another screenshot',
  add_to_monitoring: 'add to monitoring',
  added_to_monitoring: 'added to monitoring',
  remove_from_monitoring: 'remove from monitoring',
  last_updated: 'last updated',
  updated_at: 'updated at',
  set_all_acknowledged_confirm: 'Do you want to acknowledge all events?',
  license_agreement: 'license agreement',
  license_not_found: 'License not found',
  contact_admin_imv: 'Please contact your administrator.',
  accept_license_agreement: 'I accept the License Agreement',
  events_cleanup: 'Events cleanup',
  events_cleanup_matched: 'Delete matched events older than',
  events_cleanup_unmatched: 'Delete unmatched events older than',
  events_fullframe_cleanup_matched: 'Delete full frames of matched events older than',
  events_fullframe_cleanup_unmatched: 'Delete full frames of unmatched events older than',
  older_n_days: 'day | days | day | days',
  proceed: 'Proceed',
  ordering: 'Ordering',
  input_label_names: 'Input or select labels',
  ignore_this_warning: 'Ignore this warning',
  to: 'to',
  in: 'in',
  by: 'by',
  and: 'and',
  none: 'off',
  primary: 'primary',
  from: 'from',
  enter_fullscreen: 'Enter Fullscreen',
  exit_fullscreen: 'Exit Fullscreen',
  side_by_side: 'Side by Side',
  fixed_aspect_ratio: 'Fixed Aspect Ratio',
  debug_info: 'Debug information',
  tools: 'tools',
  extensions: 'extensions',
  developer: 'developer',
  do_you_have_many_cards: 'Too many cards?',
  try: 'try',
  batch_cards_upload__after_try__: 'batch cards upload',
  human__after_batch_upload__: 'of humans',
  car__after_batch_upload__: 'of cars',
  batch_cards_upload: 'batch cards upload',
  default: 'default',
  disabled: 'disabled',
  api: 'API',
  race: 'ethnicity',
  black: 'african | black',
  white: 'european | white',
  asian: 'asian',
  indian: 'indian',
  episode: 'episode',
  episodes: 'episodes',
  this: 'this',
  not_set: 'not set',
  item: 'item',
  dictionary: 'dictionary',
  has: 'has',
  certificate: 'certificate',
  certificates: 'certificates',
  cryptopro: 'CryptoPro',
  time_action: 'time of action',
  issuer: 'issuer',
  continue: 'continue',
  key: 'key',
  browser_plugin: 'browser plugin',
  browser_extension: 'browser extension',
  synchronization: 'synchronization',
  attachment: 'attachment',
  attachments: 'attachments',
  person: 'person',
  persons: 'persons',
  persons_pf: 'person | persons',
  null: 'null',
  card_list_sync_warning: 'Attention. All local cards of this watch list will be deleted.',
  thumbnail: 'thumbnail',
  thumbnails: 'thumbnails',
  jpeg: 'jpeg',
  quality: 'quality',
  counter: 'counter',
  counters: 'counters',
  date_multiselect_not_supported: 'date, object multi-selection is not supported',
  date_multiselect_warning: 'Ordering by date is not supported when selected if multiple objects are selected.',
  threshold_warning: 'Changing this parameter will affect the system functioning. Do not touch if you do not know exactly what you are doing.',
  ignore_events: 'Do not create events',
  video_url: 'Video URL',
  add_photo_to_existing_card: 'add photo to existing card',
  silhouettes: 'silhouettes',
  medmask: 'face mask',
  correct: 'on',
  incorrect: 'improperly worn',
  records: 'records',
  now: 'now',
  chart: 'chart',
  month: 'month',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  week: 'week',
  interval: 'interval',
  counter_interval: 'count within interval (seconds)',
  delete_all_cards_confirm: 'Are you sure you want to delete all cards? ',
  delete_all_watch_lists_confirm: 'Are you sure you want to delete all the cards from the watch lists? ',
  delete_confirm: 'Are you sure you want to delete',
  confirm_video_processing:
    'Reprocessing of this video will delete all events and episodes associated with this video. Are you sure you want to process this video again?',
  it_may_take_some_time: 'It may take some time. ',
  delete_video_confirm:
    'Are you sure you want to delete the video(s)? Attention: the video(s) will be deleted only after deleting all the events related to it, this may take a long time to complete.',
  frames: 'frames',
  fps: 'FPS',
  dropped: 'dropped',
  processed: 'processed',
  imotion: 'imotion',
  skipped: 'skipped',
  decoding: 'decoding',
  soft: 'soft',
  job: 'job',
  starts: 'starts',
  more_than_one_object: 'more than one object',
  external: 'external',
  temperature: 'temperature',
  systems: 'systems',
  is_deleted: 'is deleted',
  save_results: 'save results',
  location: 'location',
  monitoring: 'monitoring',
  precision: 'scale interval',
  return_interval: 'time between visits (minimal)',
  returns_number: 'number of returns',
  visitors: 'visitors',
  visits: 'visits',
  lookback_interval: 'lookback interval',
  know_your_customer: 'analytics',
  kyc: 'analytics',
  year: 'year',
  returning: 'returning',
  intervals: 'interval',
  current: 'current',
  unique: 'unique',
  relations: 'relations',
  analytics: 'analytics',
  second: 'second',
  no_data: 'no data',
  is_not_changed: 'is not changed',
  name_required: 'name required',
  counter_cameras_or_groups: 'fill cameras or camera groups',
  unknown: 'unknown',
  circle: 'circle',
  last: 'last',
  clusters_disabled_warn:
    'Enable the Clusters functionality to display data on charts. To do this, please refer to the documentation ("Enable and Configure Clusterization" section), or contact the administrator of your FindFace Multi system.',
  case: 'case',
  cases: 'cases',
  incident: 'incident',
  claim: 'claim',
  uploads: 'uploads',
  looks_like: 'looks like',
  fast: 'fast',
  video_archive: 'video archive',
  till: 'to',
  kusp: 'KUSP',
  id_ru: 'id',
  user_is_not_logged: 'user is not logged in',
  men: 'men',
  women: 'women',
  visitors_pf: 'visitor | visitors',
  age_pf: 'age | ages',
  visits_pf: 'visit | visits',
  new_visits_pf: 'new visit | new visits',
  returning_visits_pf: 'returning visit | returning visits',
  processing_s: 'processing',
  speed: 'speed',
  max: 'max',
  browse: 'browse',
  drop_file_here: '*You can drag and drop file here',
  supported_media_formats: '*Supported video formats: h264, h265, mjpeg, vp9 and others (see docs)',
  time: 'time',
  exact_time: 'time',
  exact_time_comma_camera: 'Time, Camera',
  custom_parameters: 'set custom parameters ',
  stop_video_processing_warning: 'If you press ‘stop’, you will have to start processing video all over again',
  delete_video_tooltip: 'You can not delete videos which are currently being processed',
  multi_edit: 'multi-editing',
  multi_edit_warning: '*Multi-editing does not support the following parameter settings: roi / rot / start_stream_timestamp',
  see_the_report_progress: 'a Report is being generated. Go to the ',
  reports_page: 'reports page',
  here: 'here',
  save_images_as: 'save images as',
  save_report_images_as: 'save report images as',
  fullframe: 'full frame',
  link: 'link',
  analytic_select_data: 'select the data to include in the report',
  analytic_output_data: 'outcome data',
  analytic_input_data: 'input data used for graphs',
  face_recognition_in_progress: 'trying to recognize your face',
  additional_security_measure: 'as an additional security measure please enter password',
  sign_in_as_another_user: 'sign in as another user',
  activate_webcam_for_face_recognition: 'activate webcam to login using face recognition',
  activate_webcam: 'Activate Webcam',
  or_enter_login_password: 'or enter login / password to sign in',
  video_recording_in_progress: 'video recording in progress',
  hi: 'hi',
  analytic_output_data_desc: 'outcome data is the data that is displayed in the chart(s) according to the applied filters.',
  analytic_input_data_desc: 'input data is the complete set of data (according to the applied filters) from which the outcome data is derived.',
  caseface: 'case face',
  edit_in_menu: 'edit in left menu',
  finish_editing_menu: 'finish editing',
  reset_to_default_menu: 'reset to default',
  launcher: 'launcher',
  linked_data: 'linked data',
  translations: 'translations',
  license_id: 'license ID',
  license_file_uploaded: 'license file uploaded',
  license_download_c2v: 'Download C2V for activation',
  processing_few: 'processing',
  not_processing_few: 'not processing',
  not_working: 'failed',
  process_duration: 'process duration',
  faces_posted: 'posted faces',
  faces_not_posted: 'not posted faces',
  faces_failed: 'failed faces',
  bodies_posted: 'posted bodies',
  bodies_failed: 'failed bodies',
  bodies_not_posted: 'not posted bodies',
  cars_posted: 'posted cars',
  cars_failed: 'failed cars',
  cars_not_posted: 'not posted cars',
  frames_dropped: 'frames dropped',
  job_starts: 'job starts',
  fast_case_search: 'fast case search',
  cases_search: 'cases search',
  card_search: 'card search',
  kusp_id: 'KUSP id',
  kusp_date: 'KUSP date',
  incident_date: 'incident date',
  video_archive_id: 'video archive ID',
  counter_id: 'counter ID',
  counter_ids: 'counters ID',
  counter_record_id: 'counter record ID',
  counter_record_ids: 'counter records ID',
  counter_records: 'counter records',
  batch_upload_log: 'batch upload log',
  batch_upload_logs: 'batch upload logs',
  success_count: 'success count',
  failed_count: 'failed count',
  name_prefix: 'name prefix',
  name_postfix: 'name postfix',
  comment_prefix: 'comment prefix',
  comment_postfix: 'comment postfix',
  card_id: 'card ID',
  number_of_results: 'number of results',
  episode_id: 'episode ID',
  event_id: 'event ID',
  acknowledged_datetime: 'acknowledged datetime',
  acknowledged_by: 'acknowledged by',
  genetec_settings: 'genetec settings',
  config_error: 'config error',
  webhook_id: 'webhook ID',
  webhook_title: 'webhook title',
  person_id: 'person ID',
  search_id: 'search ID',
  search_results: 'search results',
  case_face_id: 'case face ID',
  progress_upload: 'progress upload',
  thumbnail_jpeg_quality: 'thumbnail jpeg quality',
  delete_file: 'delete file',
  delete_file_and_linked_data: 'delete file and linked data',
  processing_speed: 'processing speed',
  stream: 'stream',
  onvif: 'ONVIF',
  load_from_device: 'discover devices',
  onvif_brand: 'brand',
  onvif_model: 'model',
  onvif_ip_address: 'IP address',
  onvif_port: 'port',
  onvif_add_button: 'add',
  onvif_added: 'added',
  onvif_username: 'username',
  onvif_password: 'password',
  onvif_auth_camera: 'login to camera',
  onvif_dialog_title: 'onvif cameras',
  onvif_select_stream: 'select stream',
  basic_auth: 'authorization',
  video_auth: 'face authorization',
  video_auth_renew: 'face check',
  video_recording_is_not_supported: "user agent doesn't support the media recording feature",
  cars: 'cars',
  bodies: 'bodies',
  select_file: 'select file',
  color: 'car colour',
  body_type: 'car body style',
  make: 'make',
  model: 'model',
  license_plate_number: 'license plate',
  license_plate_country: 'country of the license plate',
  license_plate_region: 'region of the license plate',
  top_color: 'upper wear colour',
  bottom_color: 'lower wear colour',
  suv: 'SUV',
  sedan: 'sedan',
  crossover: 'crossover',
  convertible: 'convertible',
  coupe: 'coupe',
  wagon: 'shooting brake',
  cab: 'pickup',
  minibus: 'van',
  minivan: 'minivan',
  limousine: 'limousine',
  beige: 'beige',
  blue: 'blue',
  brown: 'brown',
  cyan: 'cyan',
  gold: 'gold',
  grey: 'gray',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  silver: 'silver',
  violet: 'violet',
  lightblue: 'light blue',
  faces_confidence_threshold: 'faces: generic confidence threshold',
  faces_events_cleanup_matched: 'faces: delete matched events older than',
  faces_events_cleanup_unmatched: 'faces: delete unmatched events older than',
  faces_events_fullframe_cleanup_matched: 'faces: delete full-frames of matched events older than',
  faces_events_fullframe_cleanup_unmatched: 'faces: delete full-frames of unmatched events older than',
  cars_confidence_threshold: 'cars: generic confidence threshold',
  cars_events_cleanup_matched: 'cars: delete matched events older than',
  cars_events_cleanup_unmatched: 'cars: delete unmatched events older than',
  cars_events_fullframe_cleanup_matched: 'cars: delete full-frames of matched events older than',
  cars_events_fullframe_cleanup_unmatched: 'cars: delete full-frames of unmatched events older than',
  bodies_confidence_threshold: 'bodies: generic confidence threshold',
  bodies_events_cleanup_matched: 'bodies: delete matched events older than',
  bodies_events_cleanup_unmatched: 'bodies: delete unmatched events older than',
  bodies_events_fullframe_cleanup_matched: 'bodies: delete full-frames of matched events older than',
  bodies_events_fullframe_cleanup_unmatched: 'bodies: delete full-frames of unmatched events older than',
  counter_type: 'type of the counter',
  single_camera: 'single camera',
  multi_camera: 'multi camera',
  multi_camera_counter_roi:
    'in case you are using more than one camera in a counter, please configure Region of Interest (for faces and bodies) for each camera separately. To do so go to camera settings and configure it via camera’s Region of Interest for faces:',
  configure_region_of_interest_for_camera: 'configure region of interest for camera',
  select_object_type: 'select object type',
  select_faces_object: 'select face',
  select_cars_object: 'select car',
  select_silhouette_object: 'select silhouette',
  general_settings: 'general settings',
  common_settings: 'common setting',
  geoposition: 'GEO',
  connection_type: 'connection type',
  starts_of_job: 'job starts',
  decoding_errors: 'decoding errors',
  posted_objects: 'posted objects',
  failed_objects: 'failed objects',
  not_posted_objects: 'not posted objects',
  processed_frames: 'processed frames',
  dropped_frames: 'dropped frames',
  skipped_imotion_frames: 'skipped imotion frames',
  transformation: 'transformation',
  stream_rotation: 'stream rotation',
  transformation_tooltip:
    'Stream rotation is executed on the FindFace Multi server side by post-processing tools. This can negatively affect performance. Hence it is recommended to rotate the stream on the camera side, if possible.',
  posting_objects: 'posting objects',
  timestamp: 'timestamp',
  other: 'other',
  none_transformation: 'none',
  flip_horizontally: 'flip horizontally',
  rotate_180: 'rotate 180°',
  flip_vertically: 'flip vertically',
  transpose: 'transpose',
  rotate_90: 'rotate 90°',
  transverse: 'transverse',
  rotate_270: 'rotate 270°',
  apply_changes: 'apply changes',
  discard_changes: 'discard changes',
  detectors: 'detectors',
  cant_load_image: 'Can not load an image.',
  objects: 'objects',
  add_object_to_card: 'Add object to card',
  object_was_added: 'Object was added',
  search_what: 'What are we looking for?',
  search_where: 'Where are we looking for?',
  search_from_event: 'From event',
  search_from_card: 'From card',
  search_from_caseface: 'From caseface',
  search_from_upload: 'From file',
  search_in_events: 'In events',
  search_in_cards: 'In cards',
  search_in_social: 'In social networks',
  search_in_clusters: 'In clusters',
  'face-events': 'face events',
  'face-events-in-out': 'face events in out',
  'body-events': 'silhouette events',
  'car-events': 'car events',
  'audit-logs': 'audit logs',
  'human-cards': 'human cards',
  'car-cards': 'car cards',
  'human-episodes': 'human episodes',
  'car-episodes': 'car episodes',
  faceobject: 'face from the card',
  bodyobject: 'body from the card',
  carobject: 'car from the card',
  faceevent: 'face event',
  bodyevent: 'body event',
  carevent: 'car event',
  log_in_with_password: 'log in with password',
  object: 'object',
  objects_posted: 'objects posted',
  advanced_parameters: 'advanced parameters',
  debug_parameters: 'debug parameters',
  objects_not_posted: 'objects not posted',
  view: 'view',
  information: 'information',
  mf_example: 'You have {count, plural, =0{no messages} one{# message} other{# messages}}.',
  face_and_face: 'face verification',
  car_and_car: 'car verification',
  body_and_body: 'body verification',
  upload_progress: 'upload progress',
  area: 'area',
  areas: 'areas',
  'area-activations-in-out': 'area activations in out',
  area_trigger_activators: 'area {area} activations',
  areas_records: 'area {area} activation {trigger} records',
  area_records_body_count: 'number of people',
  area_records_created_date: 'creation date',
  area_duration: 'duration (seconds)',
  more_than_duration: 'more than',
  max_people_count: 'maximum number of people',
  edit_counter: 'edit counter',
  create_counter: 'create counter',
  edit_area: 'edit area',
  create_area: 'create area',
  area_records: 'records',
  area_trigger: 'trigger',
  area_trigger_type: 'type',
  people_count: 'number of people',
  area_calendar: 'schedule',
  area_calendar_weekdays: 'days of week',
  area_delete_confirm: 'are you sure you want to delete the area?',
  start_at: 'from',
  end_at: 'till',
  more_then: 'more than',
  less_then: 'less than',
  people_mf: '{ count, plural, =0 {0 people} one {# people} other {# people} }',
  duration_mf: '{ duration, plural, =0 {0 seconds} one {# seconds} other {# seconds} }',
  update_image: 'update the image',
  sessions: 'sessions',
  block: 'block',
  uuid: 'UUID',
  uuids: 'UUIDs',
  mobile: 'mobile',
  device_info: 'device info',
  last_ping_date: 'last ping',
  blocklist_records: 'blocklist records',
  expire: 'expires',
  create_blocklist_record: 'create blocklist record',
  edit_blocklist_record: 'edit blocklist record',
  ip: 'IP',
  status_none: 'none',
  status_online: 'online',
  status_offline: 'offline',
  status_blocked: 'blocked',
  only_mobile: 'only mobile',
  only_web: 'only web',
  uuid_contains: 'UUID contains',
  web: 'web',
  device: 'device',
  bs_type: 'event’s best shot',
  bs_type_overall: 'the best shot after the track',
  bs_type_realtime: 'real-time shot',
  detect_proximity: 'distance measurement',
  detect_bodies: 'detect bodies',
  detect_cars: 'detect cars',
  detect_faces: 'detect faces',
  calibration_time: 'calibration time',
  calibrate_counter: 'calibrate counter',
  proximity_not_enabled: 'distance detection is off',
  proximity_enabled: 'distance detection is on',
  distance: 'distance',
  distance_measurement: 'distance measurement',
  distance_is_not_detected: 'not detected',
  min_distance: 'minimum distance',
  max_distance: 'maximum distance',
  avg_distance: 'average distance',
  calibration_status__not_calibrated: 'not calibrated',
  calibration_status__awaiting_sync: 'awaiting sync',
  calibration_status__collecting_calibration_data: 'Сollecting calibration data',
  calibration_status__awaiting_proximity_model: 'awaiting distance model',
  calibration_status__calibrated: 'calibrated',
  calibration_status__calibration_failed: 'calibration failed',
  stop_calibration: 'delete calibration',
  collect_track_data_seconds: 'set calibration timeframe (seconds).',
  with_detect_proximity: 'with detect proximity',
  without_detect_proximity: 'without detect proximity',
  minimum: 'minimum',
  maximum: 'maximum',
  average: 'average',
  with_external_detector: 'with detector',
  external_detector_token: 'edge device token',
  external_detector: 'edge device',
  copy_to_clipboard: 'copy to clipboard',
  clipboard_success: 'Copied to clipboard',
  clipboard_failed: 'Copy to clipboard failed',
  reason_contains: 'reason contains',
  close_session: 'close',
  at_least_one_detector: 'Please turn on at least one detector',
  unknown_camera: 'Unknown camera',
  scale: 'Scale',
  moving: 'Moving',
  player_sec: 'sec',
  player_helper_wait: 'Wait',
  player_helper_loading: 'Loading...',
  player_helper_error: 'Error',
  enter_export_mode: 'Export',
  leave_export_mode: 'Cancel',
  export_cancel: 'Cancel',
  export_canceled: 'Video download canceled',
  export_downloaded: 'Downloaded',
  selected_area: 'Area',
  download_selected_area: 'Download',
  video_not_available: 'Video ended or not available',
  no_video_camera_removed: 'There is no video recording because the camera is removed',
  no_webcams_available: 'No webcams available',
  deviceblacklistrecord: 'Blocklist record',
  ffsecauthsession: 'Session',
  close_multiple_sessions: 'Close multiple sessions',
  cards: 'cards',
  card: 'card',
  create_card: 'create card',
  edit_card: 'edit card',
  human_card: 'human card',
  humancard: 'human card',
  car_card: 'car card',
  carcard: 'car card',
  human_cards: 'humans',
  car_cards: 'cars',
  cluster_images: 'cluster images',
  cluster_image: 'cluster image',
  active_counters: 'Counters for the camera are always active if enabled',
  active_counters_description: 'Counters for the camera are always active if enabled.',
  cluster: 'cluster',
  human_cards_list: 'human cards',
  car_cards_list: 'car cards',
  human_episodes: 'humans',
  car_episodes: 'cars',
  connections: 'relations',
  card_type: 'card type',
  card_name: 'card name',
  filled_card: 'filled',
  only_filled_card: 'only filled',
  only_not_filled_card: 'only not filled',
  statistic: 'statistic',
  successful_action: 'successful action',
  human_cards__by: 'human cards',
  car_cards__by: 'car cards',
  create_connection: 'create connection',
  edit_connection: 'edit connection',
  delete_selected_connections: 'delete selected relations',
  relation_name: 'relation name',
  relation_link: 'link name',
  connect_with_another_card: 'connect with another card',
  cards_human: 'person',
  cards_car: 'car',
  clusters: 'clusters',
  'face-clusters': 'face clusters',
  'body-clusters': 'body clusters',
  'car-clusters': 'car clusters',
  'area-activations': 'area activations',
  'area-records': 'area records',
  set_all_menu_items: 'select all',
  add_event_to_card: 'add event to card',
  event_has_been_added_to_card: 'event has been added to card',
  cluster_events: 'cluster events',
  cluster_id: 'cluster ID',
  similar_cluster: 'similar cluster',
  unknown_cluster: 'unknown cluster',
  merge: 'merge',
  interaction_analysis: 'interaction analysis',
  face_matches: 'matches: faces',
  body_matches: 'matches: bodies',
  video_player_zoom_helper: 'zoom in timeline to see events',
  video_player_video_not_found: 'no video for this event',
  has_card: 'has card',
  with_dossiers: 'with dossiers',
  without_dossier: 'without dossier'
};
