<template>
  <el-select v-model="model">
    <el-option v-for="option of options" :key="option" :label="$tf(option)" :value="option" />
  </el-select>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    value: String,
    options: { type: Array, required: true }
  }
})
export default class ReportsSelectPictures extends Vue {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }
}
</script>
