import { dataServiceFactory, DataServiceFactory } from '@/services/data.services';
import Vue from 'vue';
import { ListViewModel } from '@/definitions/view-models/list.view.model';
import { ItemViewModel } from '@/definitions/view-models/item.view.model';
import { Store } from 'vuex';
import { RootState } from '@/definitions/root.state';

export interface ItemModelOptions<T> {
  url: string;
  routeName: string;
  aclName?: string;
  emptyItem: T;
  dispatch?: (action: string, payload: any) => Promise<any>;
}

export interface ListModelOptions<T, F> extends ItemModelOptions<T> {
  emptyFilter: F;
  filterSchema?: any;
}

class ViewModelManager {
  constructor(protected dataServiceFactory: DataServiceFactory) {}

  getListViewModel<T, F>(options: ListModelOptions<T, F>): ListViewModel<T, F> {
    const result = new ListViewModel();
    result.name = options.url;
    result.routeName = options.routeName;
    result.aclModelName = options.aclName;
    result.dataService = this.dataServiceFactory.getService(options.url);
    result.init(options.emptyItem, options.emptyFilter, options.filterSchema);
    if (options.dispatch) result.dispatch = options.dispatch;
    const observableResult = Vue.observable(result) as ListViewModel<T, F>;
    return observableResult;
  }

  getItemViewModel<T>(options: ItemModelOptions<T>): ItemViewModel<T> {
    const result = new ItemViewModel();
    result.name = options.url;
    result.routeName = options.routeName;
    result.aclModelName = options.aclName;
    result.dataService = this.dataServiceFactory.getService(options.url);
    result.init(options.emptyItem);
    if (options.dispatch) result.dispatch = options.dispatch;
    const observableResult = Vue.observable(result) as ItemViewModel<T>;
    return observableResult;
  }

  registerListViewModel(value: ListViewModel<any, any>, moduleName: string, store: Store<RootState>) {
    store.registerModule(moduleName, { state: value, actions: value.getModuleActions(), namespaced: true });
  }

  unregisterModel(moduleName: string, store: Store<RootState>) {
    store.unregisterModule(moduleName);
  }
}

export const viewModelFactory = new ViewModelManager(dataServiceFactory);
export const getListViewModel = viewModelFactory.getListViewModel.bind(viewModelFactory);
export const getItemViewModel = viewModelFactory.getItemViewModel.bind(viewModelFactory);
