<template>
  <el-form ref="form" v-bind="$form.getFormProps($options, {}, $data)" :rules="rules">
    <el-form-item :label="$tf('event_id')" prop="id">
      <el-input :placeholder="$tf('id')" v-model="id" style="width: 16rem; margin-top: 1rem" clearable></el-input>
    </el-form-item>
    <el-form-item :label="$tf('photo')" v-if="event">
      <object-thumbnail :url="event.thumbnail" :frame="event.fullframe" @preview="handleThumbnailPreviewEvent" />
    </el-form-item>
  </el-form>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ObjectsType, ObjectsTypeSingleForm } from '@/store/objects/get.module';
import ObjectThumbnail from '../../../objects/thumbnail';

@Component({
  components: { ObjectThumbnail },
  props: {
    objects: {
      type: String,
      default: ObjectsType.Faces
    },
    filter: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  }
})
export default class EventObjectsSelector extends Vue {
  id = '';
  event = null;

  created() {
    this.validateId = _.debounce(this.validateId, 1000);
  }

  mounted() {
    this.applyLooksLike();
  }

  @Watch('id')
  idHandler(v, p) {
    if (!v && p) {
      this.clear();
    }
  }

  get looksLikeName() {
    return ObjectsTypeSingleForm[this.objects] + 'event';
  }

  get rules() {
    return { id: [{ trigger: 'change', validator: this.validateId }]};
  }

  applyLooksLike() {
    if (!this.filter.looks_like) return;
    [, this.id] = this.filter.looks_like.split(':');
    this.load();
  }

  get eventsState() {
    return this.$store.state[`${this.objects}_events`];
  }

  handleThumbnailPreviewEvent() {
    this.$store.dispatch('showImage', {
      src: this.event.fullframe,
      box: this.getBoxByObject(this.event)
    });
  }

  resetEvent() {
    this.event = null;
    this.filter.looks_like = '';
  }

  reset() {
    this.resetEvent();
    this.$refs.form?.resetFields();
  }

  clear() {
    this.resetEvent();
    this.$emit('reset');
  }

  load() {
    this.resetEvent();
    if (!this.id) return;
    this.state.loading = true;
    return this.$store
      .dispatch(this.eventsState.Action.Get, { id: this.id })
      .then((v) => {
        this.filter.looks_like = `${this.looksLikeName}:${this.id}`;
        this.event = v;
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        throw e;
      })
      .finally(() => {
        this.state.loading = false;
      });
  }

  validateId(_rule, value, cb) {
    if (!value || value === this.event?.id) return cb();
    this.load()
      .then(() => {
        cb();
      })
      .catch(() => {
        cb(this.$tfo('event | {value} | not | found,,2', { value }));
      });
  }

  getBoxByObject(v) {
    return {
      x: v.frame_coords_left,
      y: v.frame_coords_top,
      w: v.frame_coords_right - v.frame_coords_left,
      h: v.frame_coords_bottom - v.frame_coords_top
    };
  }
}
</script>
