import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';
import { getBatch } from '@/apps/common/interval_downloader';
import { firstCase } from '@/apps/common/filters';

export const ObjectsType = {
  Faces: 'faces',
  Cars: 'cars',
  Bodies: 'bodies'
};

export const ObjectsTypeSingleForm = {
  faces: 'face',
  cars: 'car',
  bodies: 'body'
};

export const ObjectsTypePluralForm = {
  face: 'faces',
  car: 'cars',
  body: 'bodies'
};

export const ObjectsTypeItems = [ObjectsType.Faces, ObjectsType.Bodies, ObjectsType.Cars];

export function getObjectModule(type) {
  if (!type) throw '[getEventModule:error] You need specify module type';

  const emptyFilter = {
      watch_lists: []
    },
    emptyItem = {
      card: '',
      source_photo: ''
    },
    Name = `objects/${type}`,
    typeFirstUpper = firstCase(type),
    Action = require('../action.names').default[`${typeFirstUpper}Objects`];

  const module = {
    state: {
      page: 0,
      next_page: null,
      prev_page: null,
      items: [],
      item: {
        current: _.cloneDeep(emptyItem),
        empty: emptyItem
      },
      filter: {
        empty: emptyFilter,
        current: _.cloneDeep(emptyFilter)
      },
      Action
    },
    actions: Object.assign({}, getCrud(Name), { [`getBatchObject${typeFirstUpper}`]: getBatchObject })
  };

  function getBatchObject(context, payload) {
    return getBatch(context, { model: Name, payload });
  }

  return module;
}
