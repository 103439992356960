import { IAppError, IServerError } from './types';

export const AppErrors = {
  NoResponse: 'no_response',
  WebcamNotAllowed: 'webcam_not_allowed',
  WebcamNotAvailable: 'webcam_not_available',
  WebcamNotReadable: 'webcam_not_readable'
};

export const AppErrorsStore: IAppError[] = [
  {
    name: AppErrors.NoResponse,
    code_i18n: 'error.server.no_response',
    desc_i18n: 'error.server.no_response_desc'
  },
  {
    name: AppErrors.WebcamNotAllowed,
    code_i18n: 'error.webcam.not_allowed',
    desc_i18n: 'error.webcam.not_allowed_desc'
  },
  {
    name: AppErrors.WebcamNotAvailable,
    code_i18n: 'error.webcam.not_available',
    desc_i18n: 'error.webcam.not_available_desc'
  },
  {
    name: AppErrors.WebcamNotReadable,
    code_i18n: 'error.webcam.not_readable',
    desc_i18n: 'error.webcam.not_readable_desc'
  }
];

export const ServerErrorsStore: IServerError[] = [
  {
    status: 404,
    code_i18n: 'error.server.notfound',
    desc_i18n: 'error.server.notfound_desc'
  },
  {
    status: 403,
    code_i18n: 'error.server.forbidden',
    desc_i18n: 'error.server.forbidden_desc'
  },
  {
    status: /^4\d{2}$/,
    code_i18n: 'error.server.bad_request',
    desc_i18n: 'error.server.bad_request_desc'
  },
  {
    status: /503/,
    code_i18n: 'error.server.unavailable',
    desc_i18n: 'error.server.unavailable_desc'
  },
  {
    status: /^5\d{2}$/,
    code_i18n: 'error.server.internal',
    desc_i18n: 'error.server.internal_desc'
  }
];

export const findAppError = (name: string): IAppError | undefined => {
  return AppErrorsStore.find((appError) => appError.name === name);
};

export const findServerError = (status: number): IServerError | undefined => {
  return ServerErrorsStore.find((serverError) => {
    if (typeof serverError.status === 'object') {
      return serverError.status.test(status.toString());
    }
    return serverError.status === status;
  });
};
