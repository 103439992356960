<template>
  <filter-form-layout>
    <template v-for="{ name, control } of fields">
      <filter-form-control v-if="control" :key="Array.isArray(name) ? name.join('') : name" :form="form" :field-name="name" v-bind="control" />
    </template>

    <slot name="meta-filters" />

    <filter-form-clear-button slot="clear-button" :disabled="areFieldsClear" @click="clear" />
  </filter-form-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getLocalStorageStringifiedFilter,
  normalizeParsedFilter,
  parseStringifiedFilter,
  setLocalStorageStringifiedFilter,
  stringifyFilter
} from './common/index';
import FilterFormClearButton from './filter-form-clear-button';
import FilterFormControl from './filter-form-control';
import FilterFormLayout from './filter-form-layout';
import { Debounce } from '@/apps/common/decorators/lodash';

const Meta = Object.freeze({
  Querystr: ['querystr', true],
  Multiple: ['multiple', false],
  Identity: ['identity', 'indefinite']
});

const Filter = Object.freeze({
  Initial: 'filter.empty',
  Current: 'filter.current'
});

@Component({
  props: {
    fields: { type: Array, required: true },
    module: { type: String, required: false },
    local_state: { type: Object, required: false },
    action: { type: String, required: false },
    paging: { type: Boolean, default: true },
    extraFieldsToReset: { type: Array, required: false }
  },
  components: {
    FilterFormClearButton,
    FilterFormControl,
    FilterFormLayout
  }
})
export default class FilterForm extends Vue {
  get form() {
    return this;
  }

  get state() {
    return this.local_state || _.get(this.$store.state, this.module);
  }

  get fieldsMap() {
    return this.fields.reduce(setFieldsMapItem, {});
  }

  get fieldsNames() {
    const names = [];
    this.fields.forEach(({ name }) => {
      if (name instanceof Array) {
        names.push(...name);
      } else {
        names.push(name);
      }
    });
    return names;
  }

  get initialFilter() {
    return _.get(this.state, Filter.Initial);
  }

  get currentFilter() {
    return _.get(this.state, Filter.Current);
  }

  get commonLsKey() {
    return this.state.commonLsKey;
  }

  get areExtraFieldsClear() {
    return this.extraFieldsToReset ? this.extraFieldsToReset.every(this.isFieldClear) : true;
  }

  get areFieldsClear() {
    return this.fieldsNames.every(this.isFieldClear) && this.areExtraFieldsClear;
  }

  get stringifiedRouteFilter() {
    const filter = this.parseStringifiedFilter(this.$route.params.filter);
    return this.stringifyFilter(this.normalizeParsedFilter(filter));
  }

  get stringifiedCurrentFilter() {
    return this.stringifyFilter(this.currentFilter);
  }

  created() {
    let localStorageFilterString = getLocalStorageStringifiedFilter(this.module);
    if (this.commonLsKey) {
      localStorageFilterString = this.synchronizeCommonFields(localStorageFilterString);
    }

    const emptyFilterString = this.stringifyFilter(this.initialFilter);
    const defaultFilterString = localStorageFilterString || this.stringifiedCurrentFilter || emptyFilterString;
    const isRouterFilterEmpty = this.stringifiedRouteFilter === emptyFilterString;
    const computedFilterString = isRouterFilterEmpty ? defaultFilterString : this.stringifiedRouteFilter;
    this.debouncedSyncStateItems = _.debounce(this.syncStateItems, 600);

    this.resetStateItems();
    this.handleStringifiedFilterChange(computedFilterString);
  }

  @Watch('stringifiedRouteFilter')
  @Watch('stringifiedCurrentFilter')
  handleStringifiedFilterChange(stringifiedFilter, prevStringifiedFilter) {
    const isRouteFilterNotEqualComputed = this.state.syncFiltersToRoute ?? this.stringifiedRouteFilter !== stringifiedFilter;
    const isCurrentFilterNotEqualComputed = this.stringifiedCurrentFilter !== stringifiedFilter;

    if (isRouteFilterNotEqualComputed) {
      this.synchronizeRoute(stringifiedFilter, prevStringifiedFilter);
    } else if (isCurrentFilterNotEqualComputed) {
      this.synchronizeState(stringifiedFilter);
    } else {
      this.synchronizeLocalStorage(this.getLocalStorageStringifiedFilter(stringifiedFilter));

      if (this.currentFilter.page) {
        this.setPagingState(this.currentFilter.page);
      } else {
        this.clearPagingState();
      }

      const immediateSync = !prevStringifiedFilter || stringifiedFilter.indexOf('page=') > -1;
      if (immediateSync) {
        this.syncStateItems();
      } else {
        this.debouncedSyncStateItems();
      }
    }
  }

  resetStateItems() {
    this.state.items = [];
  }
  getLocalStorageStringifiedFilter(stringifiedFilter) {
    const localStorageFilter = this.parseStringifiedFilter(stringifiedFilter);
    delete localStorageFilter.page;
    return this.stringifyFilter(localStorageFilter);
  }

  synchronizeRoute(filter, previous) {
    const method = previous ? 'push' : 'replace';
    this.$router[method]({ name: this.$route.name, params: { filter } });
  }

  synchronizeState(stringifiedFilter) {
    const filter = this.parseStringifiedFilter(stringifiedFilter);
    Object.assign(this.currentFilter, this.normalizeParsedFilter(filter));
  }

  synchronizeLocalStorage(stringifiedFilter) {
    setLocalStorageStringifiedFilter(this.module, stringifiedFilter);
    if (this.commonLsKey) {
      setLocalStorageStringifiedFilter(this.commonLsKey, stringifiedFilter);
    }
  }

  synchronizeCommonFields(filterString) {
    const filter = this.parseStringifiedFilter(filterString);
    const commonStringifiedFilter = getLocalStorageStringifiedFilter(this.commonLsKey);
    const commonFilter = this.parseStringifiedFilter(commonStringifiedFilter);
    const commonFields = this.state.commonFields;
    const commonKeys = Object.keys(commonFilter);
    commonKeys.forEach((key) => {
      if (commonFields.includes(key)) {
        filter[key] = commonFilter[key];
      }
    });
    const filterKeys = Object.keys(filter);
    filterKeys.forEach((key) => {
      if (commonFields.includes(key) && !(key in commonFilter)) {
        delete filter[key];
      }
    });
    return this.stringifyFilter(filter);
  }

  parseStringifiedFilter(stringifiedFilter) {
    return parseStringifiedFilter(stringifiedFilter, this.getFieldMetaData);
  }

  normalizeParsedFilter(parsedFilter) {
    return normalizeParsedFilter(parsedFilter, this.initialFilter);
  }

  stringifyFilter(filter) {
    return stringifyFilter(filter, this.getFieldMetaData);
  }

  @Debounce(600)
  debouncedSyncStateItems() {
    this.syncStateItems();
  }

  syncStateItems() {
    const syncItemsPromise = this.state.version > 1 ? this.state.get() : this.$store.dispatch(this.action);
    syncItemsPromise.catch(this.handleError);
  }

  clear() {
    this.clearFields(this.fieldsNames);
    this.clearExtraFields();
  }

  clearFields(fieldsNames) {
    fieldsNames.forEach(this.setInitialFieldValue);
    this.clearPagingState();
  }
  clearExtraFields() {
    this.extraFieldsToReset?.forEach(this.setInitialFieldValue);
  }

  isFieldClear(fieldName) {
    const currentValue = this.getCurrentFieldValue(fieldName);
    const initialValue = this.getInitialFieldValue(fieldName);
    return (isFalsyValueExceptNumber(currentValue) && isFalsyValueExceptNumber(initialValue)) || _.isEqual(currentValue, initialValue);
  }

  getInitialFieldValue(fieldName) {
    return _.get(this.initialFilter, fieldName);
  }

  setInitialFieldValue(fieldName) {
    const initialValue = this.getInitialFieldValue(fieldName);
    const initialValueCopy = _.cloneDeep(initialValue);
    this.setCurrentFieldValue(fieldName, initialValueCopy);
  }

  getCurrentFieldValue(fieldName) {
    return _.get(this.currentFilter, fieldName);
  }

  setCurrentFieldValue(fieldName, fieldValue) {
    _.set(this.currentFilter, fieldName, fieldValue);
  }

  getFieldMetaData(fieldName) {
    const meta = _.get(this.fieldsMap, `${fieldName}.meta`, {});
    const querystr = _.get(meta, ...Meta.Querystr);
    const multiple = _.get(meta, ...Meta.Multiple);
    const identity = _.get(meta, ...Meta.Identity);
    return { querystr, multiple, identity };
  }

  handleError(e) {
    this.$notify({ message: this.$createElement('message-box', { props: { e } }) });
  }

  clearPagingState() {
    if (this.paging) {
      this.currentFilter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    }
  }

  setPagingState(page) {
    if (this.paging) {
      if (!this.state.prev_page.length) {
        this.state.page = `?page=${page}`;
        this.state.prev_page = [''];
      }
    }
  }
}

function setFieldsMapItem(fieldsMap, field) {
  return _.set(fieldsMap, field.name, field);
}

function isFalsyValueExceptNumber(value) {
  return value == null || value === '';
}
</script>
