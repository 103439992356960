import { formatTime } from '@/apps/common/filters';

export default function toHHMMSS(value, isShowSeconds = true, isShowHours = true) {
  let format = 'mm';
  if (isShowHours) {
    format = 'HH:' + format;
  }
  if (isShowSeconds) {
    format += ':ss';
  }
  return formatTime(new Date(value * 1000), format);
}
