





















import CardClusterThumbnailItem from '@/components/tables/components/generator.card.cluster.thumbnail.item.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { CardType } from '@/store/cards/cards';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.CardClusterThumbnail,
  components: { CardClusterThumbnailItem }
})
export default class TableGeneratorCardObjectThumbnail extends Vue {
  @Prop({ required: true })
  item!: any;

  @Prop({ type: String, required: true })
  cardType!: CardType;

  get isHumanCard(): boolean {
    return this.cardType === CardType.Human;
  }
}
