import _ from '@/apps/common/lodash';
import qs from 'qs';

function cleanId(value) {
  return value || '';
}

function getPageFromNextPage(value) {
  let filterFromPageQuery = qs.parse((value || '').split('?')[1] || '', { arrayLimit: 100 });
  return filterFromPageQuery.page;
}

function getCrudObject(name) {
  let result,
    actionHandlers = {};

  actionHandlers['get'] = function getAction({ state, rootState, dispatch }, payload) {
    let itemHandler =
        (state && state.itemHandler) ||
        function (v) {
          return v;
        },
      resultsHandler = (v) => {
        let items = v;
        Array.isArray(items) && (items = items.map(itemHandler));
        return items;
      };

    if (payload && payload.id) {
      let id = cleanId(payload.id);
      return dispatch(rootState.Action.RequestApi, { model: name, id: id }).then(itemHandler);
    } else if (payload && payload.filter) {
      return dispatch(rootState.Action.RequestApi, { model: name, filter: payload.filter }).then((v) => {
        v && v.results && (v.results = resultsHandler(v.results));
        return v;
      });
    } else {
      state.loading = true;
      return dispatch(rootState.Action.RequestApi, { model: name, filter: state.filter.current, page: state.page })
        .then((v) => {
          let items = resultsHandler(v.results) || v;
          state.loading = false;
          state.loaded_date = Date.now();
          state.next_page = v.next_page;
          state.total = v.total || 0;
          state.items = items;
          return v;
        })
        .catch((e) => {
          state.loading = false;
          return Promise.reject(e);
        });
    }
  };

  actionHandlers['get_next'] = function getNextAction({ state, rootState, dispatch }, payload) {
    let itemHandler =
        (state && state.itemHandler) ||
        function (v) {
          return v;
        },
      resultsHandler = (v) => {
        let items = v;
        Array.isArray(items) && (items = items.map(itemHandler));
        return items;
      },
      nextPage = getPageFromNextPage(state.next_page);

    if (!nextPage) return;

    if (payload && payload.append) {
      state.appending = true;
    } else {
      state.loading = true;
    }

    state.prev_page.push(state.filter.current.page);
    state.filter.current.page = nextPage;
    return dispatch(rootState.Action.RequestApi, { model: name, filter: state.filter.current, page: state.page })
      .then((v) => {
        let items = resultsHandler(v.results) || v;
        state.loaded_date = Date.now();
        state.next_page = v.next_page;
        state.total = v.total || 0;

        if (payload && payload.append) {
          state.items = state.items.concat(items);
        } else {
          state.items = items;
        }
        return v;
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => {
        if (payload && payload.append) {
          state.appending = false;
        } else {
          state.loading = false;
        }
      });
  };

  actionHandlers['create'] = function createAction({ state, rootState, dispatch }, payload) {
    return dispatch(rootState.Action.RequestApi, { model: name, method: 'POST', data: payload });
  };

  actionHandlers['update'] = function updateAction({ state, rootState, dispatch }, payload) {
    let data = _.cloneDeep(payload);
    let id = cleanId(data.id);
    delete data.id;
    return dispatch(rootState.Action.RequestApi, { model: name, id, method: 'PATCH', data });
  };

  actionHandlers['acknowledge'] = function updateAction({ state, rootState, dispatch }, payload) {
    let data = _.cloneDeep(payload);
    let id = cleanId(data.id);
    delete data.id;
    return dispatch(rootState.Action.RequestApi, { model: name, id, method: 'PATCH', data });
  };

  actionHandlers['put'] = function updateAction({ state, rootState, dispatch }, payload) {
    let data = _.cloneDeep(payload);
    let id = cleanId(data.id);
    delete data.id;
    return dispatch(rootState.Action.RequestApi, { model: name, id, method: 'PUT', data });
  };

  actionHandlers['delete'] = function deleteAction({ state, rootState, dispatch }, payload) {
    let id = cleanId(payload.id);
    return dispatch(rootState.Action.RequestApi, { model: name, id, method: 'DELETE' });
  };

  result = Object.keys(actionHandlers).reduce((m, v) => {
    let actionName = v + '_' + name.replace(/\//g, '_');
    m[actionName] = actionHandlers[v];
    return m;
  }, {});

  return result;
}

export default getCrudObject;
