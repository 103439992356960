import { FilterFormComponentName } from '@/components/filter-form/components';
import { computeIdInAsIdFieldSchema, FieldName } from '@/store/events/filters/fields';
import { ObjectsTypePluralForm } from '@/store/objects/get.module';

const DefaultClearableFields = [FieldName.Page, FieldName.Id, FieldName.IdInAsId];

export const HumansFilterSchema = {
  module: 'cards/humans',
  action: 'cards/humans/get',
  fields: [
    getCardNameFilter(),
    {
      name: 'watch_lists',
      control: {
        i18nLabel: 'watch_lists',
        component: [
          FilterFormComponentName.WatchLists,
          {
            itemsPath: '$store.getters.watchlistsInFilter',
            i18nPlaceholder: 'not_selected',
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true, identity: 'int' }
    },
    getHasObjectFilter('face'),
    getHasObjectFilter('body'),
    getFilledFilter(),
    computeIdInAsIdFieldSchema()
  ]
};

export const CarsFilterSchema = {
  module: 'cards/cars',
  action: 'cards/cars/get',
  fields: [
    getCardNameFilter(),
    getLicensePlateNumberFilter(),
    {
      name: 'watch_lists',
      control: {
        i18nLabel: 'watch_lists',
        component: [
          FilterFormComponentName.WatchLists,
          {
            itemsPath: '$store.getters.watchlistsInFilter',
            i18nPlaceholder: 'not_selected',
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true, identity: 'int' }
    },
    getHasObjectFilter('car'),
    getFilledFilter(),
    computeIdInAsIdFieldSchema()
  ]
};

function getHasObjectFilter(type) {
  const objectsType = ObjectsTypePluralForm[type];

  return {
    name: `has_${type}_objects`,
    control: {
      i18nLabel: `${objectsType}`,
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'True', i18nLabel: `only_with_${objectsType}` },
            { value: 'False', i18nLabel: `only_without_${objectsType}` }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function getFilledFilter() {
  return {
    name: 'filled',
    control: {
      i18nLabel: 'filled_card',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'True', i18nLabel: 'only_filled_card' },
            { value: 'False', i18nLabel: 'only_not_filled_card' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function getCardNameFilter() {
  return {
    name: 'name_contains',
    control: {
      i18nLabel: 'card_name',
      component: [
        FilterFormComponentName.Input,
        {
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function getLicensePlateNumberFilter() {
  return {
    name: 'license_plate_number_contains',
    control: {
      i18nLabel: 'license_plate_number',
      component: [
        FilterFormComponentName.Input,
        {
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}
