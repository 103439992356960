<template>
  <el-dialog ref="dialog" name="deduplication-dialog" class="deduplication-dialog" :title="$tf('duplicate') + '?'" :visible="true" @close="closeHandler">
    <div class="deduplication-dialog__info">
      <h3>{{ $tf('duplicate_card') }}</h3>
    </div>

    <div class="cols">
      <object-thumbnail :objects="state.objects" :url="state.current.detect.thumbnail" />
      <object-thumbnail :url="state.current.duplicate.thumbnail"></object-thumbnail>
      <div v-if="card" class="deduplication-dialog__dossier">
        <div class="deduplication-dialog__dossier-name">{{ card.name }}</div>
        <watchlists-inline :ids="card.watch_lists"></watchlists-inline>
      </div>
    </div>

    <el-button @click="ignoreHandler">{{ $tf('ignore_this_warning') }}</el-button>
    <el-button @click="acceptHandler">{{ $tf('add_photo_to_existing_card') }}</el-button>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ObjectThumbnail from '../thumbnail';
import WatchlistsInline from '../../watch-lists/inline';

@Component({
  name: 'deduplication-dialog',
  components: {
    WatchlistsInline,
    ObjectThumbnail
  }
})
export default class DuplicationDialog extends Vue {
  card = null;

  mounted() {
    this.load();
  }

  async load() {
    const id = this.state.current.duplicate?.card;
    if (!id) return;
    this.card = await this.$store.dispatch('requestApi', {
      model: `cards/${this.cardType}/${id}`,
      method: 'get'
    });
  }

  get cardType() {
    return this.state.objects === 'cars' ? 'cars' : 'humans';
  }

  get state() {
    return this.$store.state.detect;
  }

  get dialog() {
    return this.$store.state.detect.deduplication_dialog;
  }

  ignoreHandler() {
    this.dialog?.resolveHandler({ action: 'ignore' });
  }

  acceptHandler() {
    const id = this.state.current.duplicate?.card;
    this.dialog?.resolveHandler({ action: 'add_photo_to_card', id });
  }

  selectHandler(e) {
    this.$refs.dialog.hide();
  }

  closeHandler() {
    this.dialog.enabled = false;
    this.dialog?.rejectHandler('canceled_by_user');
  }
}
</script>

<style lang="stylus">
.deduplication-dialog {
  .el-button:first-of-type {
    margin-right: 10px;
  }

  .el-button + .el-button {
    margin-top: 10px;
    margin-left: 0;
  }
}
</style>
