<template>
  <div>
    <el-table
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      row-key="id"
      @row-click="(v) => $router.push({ path: getItemLink(v) })"
      class="events-table"
    >
      <el-table-column prop="id" :label="$tf('id')" width="200"></el-table-column>

      <el-table-column prop="" :label="$tf('detected')" width="200">
        <template slot-scope="{ row }">
          <object-thumbnail :url="row.thumbnail" :frame="row.fullfame" @preview="() => showVideoPlayerOrFullFrame(row)"></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="name">
        <template slot-scope="{ row }">
          <router-link :to="{ path: getItemLink(row) }">
            {{ $tf('event') }} {{ row.id }}
            <span v-if="!hasObjectsConfidence">({{ row.looks_like_confidence | floatToPercent }})</span>
          </router-link>

          <confidence-label :v-if="hasObjectsConfidence" :objects="objects" :value="row.looks_like_confidence"></confidence-label>

          <div v-if="row.matched_card">
            <card-item :id="row.matched_card" :eventId="row.id" :objects="objects"></card-item>
          </div>
          <div v-else>
            <router-link :to="{ path: getItemLink(row) }">{{ $tf('no_match') }}</router-link>
          </div>

          <watch-lists-inline links :objects-type="objects" :ids="row.matched_lists" />

          <div v-if="$store.getters.hasMatchClusters && row.matched_cluster" class="mar-top--0-5">
            <router-link @click.stop :to="getClusterEventsUrl(row.matched_cluster)">{{ $tf('similar_cluster') }}: {{ row.matched_cluster }}</router-link>
          </div>

          <br />

          <div v-if="state.filter.current.sourceType === 'card'">
            <a
              v-if="!addedObjects[row.id]"
              v-loading="uploading"
              id="upload-event-face-card-btn"
              @click.prevent.stop="() => addObjectToCard(row.id)"
              class="link"
            >
              <i class="mar-left--1 el-icon-plus"></i> {{ $tfo(`add_event_to_card`) }}
            </a>
            <div v-else>
              {{ $tf('event_has_been_added_to_card') }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="role" width="200">
        <template slot-scope="{ row }">
          {{ row.created_date | isoStringToDate | formatDate }}
          <br />
          {{ row.created_date | isoStringToDate | formatTime }}
          <br /><br />
          <div>
            <el-tooltip
              class="tooltip-item"
              effect="dark"
              :content="getCameraGroup(row).name + ' (' + getCamera(row).name + ')'"
              placement="top"
              :open-delay="800"
            >
              <span>
                {{ $filters.shortString(getCameraGroup(row).name) }}
                ({{ $filters.shortString(getCamera(row).name) }})
              </span>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { ObjectsType, ObjectsTypeSingleForm } from '@/store/objects/get.module';
import Component from 'vue-class-component';
import ConfidenceLabel from '../../../common/confidence.label';
import CardItem from '../../../events/card-item';
import ObjectThumbnail from '../../../objects/thumbnail';
import WatchListsInline from '../../../watch-lists/inline';

@Component({
  name: 'EventsSearchResults',
  components: {
    CardItem,
    WatchListsInline,
    ObjectThumbnail,
    ConfidenceLabel
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    objects: {
      type: String,
      default: ObjectsType.Faces
    }
  }
})
export default class EventsSearchResults extends Component {
  addedObjects = [];
  uploading = false;

  addObjectToCard(eventId) {
    const cardId = this.state.filter.current.sourceCardId;
    if (!cardId) return;
    this.uploading = true;
    this.$store
      .dispatch('uploadObject', {
        create_from: `${ObjectsTypeSingleForm[this.objects]}event:${eventId}`,
        card: cardId,
        objects: this.objects
      })
      .then(() => {
        this.addedObjects[eventId] = true;
        this.$notify({ type: 'success', message: this.$tf(['action', 'success']) });
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.uploading = false;
      });
  }

  hasObjectsConfidence() {
    return this.$store.getters.hasObjectsConfidence(this.objects || 'faces');
  }

  getCamera(item) {
    return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tfo('not_found,,1') };
  }

  getCameraGroup(item) {
    let camera = this.getCamera(item);
    return this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || { name: this.$tfo('not_found,,1') };
  }

  getItemLink(item) {
    return `/events/${this.objects}/${encodeURIComponent(item.id)}/`;
  }

  getClusterEventsUrl(id) {
    return `/cluster-events/${this.objects}/filter/cluster_in=${id}&limit=10`;
  }

  showVideoPlayerOrFullFrame(row) {
    this.$store.dispatch('showVideoPlayerOrFullFrame', { item: row, objectsType: this.objects, orFullFrame: true });
  }
}
</script>

<style lang="stylus"></style>
