










import Component, { mixins } from 'vue-class-component';
import { ObjectPreview } from '@/components/objects/preview';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';
import PolygonView from '@/components/polygon-draw/view.vue';

@Component({
  name: TableComponentNames.PolygonThumbnailObjectPreview,
  components: { PolygonView }
})
export default class TableGeneratorPolygonThumbnailObjectPreview extends mixins(ObjectPreview) {
  @Prop({ required: true })
  item!: any;

  public dimensions = { width: 0, height: 0 };

  get points() {
    return this.item.roi || [];
  }

  async loadDimensions() {
    this.dimensions = (await this.$store.dispatch('getCameraDimensions', this.item.camera)) ?? { width: 0, height: 0 };
  }

  mounted() {
    this.loadDimensions();
  }
}
