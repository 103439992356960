<template>
  <app-layout>
    <menu-side slot="menu" v-if="!($route.path === '/')" :menu="$store.state.app.menu" :user="$store.state.users.current" class="flex-cell--static"></menu-side>
    <menu-extension slot="menu" class="flex-cell--static"></menu-extension>
    <router-view slot="content" class="page-content cell h--100" style="overflow: auto"></router-view>
    <right-container slot="filter" class="page-filters flex-cell--static h-100" style="overflow: auto" v-if="hasFilters">
      <component :is="activeFilter.component.default" />
      <reports-dialog-activator v-if="activeFilter.report && !$hasNoPermission('ffsecurity.add_report')" v-bind="activeFilter.report" />
    </right-container>

    <div
      slot="addons"
      class="container-language"
      v-if="$route.path === '/'"
      :class="{ 'container-language--over': containerOver }"
      @mouseover="containerOverHandler"
      @mouseout="containerOutHandler"
    >
      <el-select v-model="$i18n.locale" size="mini" @change="localeHandler">
        <el-option :key="item.name" :value="item.name" :label="item.label" v-for="item in $store.getters.languages"></el-option>
      </el-select>
    </div>

    <session-monitor slot="addons" />
    <app-notifications slot="addons" />

    <app-dialogs slot="dialogs"></app-dialogs>

    <div slot="info" class="build">{{ $store.state.config.build.version }} {{ $store.state.config.build.date | isoStringToDate | formatDate }}</div>
  </app-layout>
</template>

<script>
import MenuExtension from '@/components/menu/extension.vue';
import MenuSide from '@/components/menu/index.vue';
import RightContainer from '@/components/menu/right.container';
import SessionMonitor from '@/components/users/session-monitor';
import { getActiveFilterDescriptor } from './filters';
import AppLayout from '@/components/app/layout';
import AppDialogs from '@/components/app/dialogs';
import ReportsDialogActivator from '@/components/reports/reports-dialog-activator';
import AppNotifications from '@/components/app/notifications';

export default {
  components: {
    AppNotifications,
    AppDialogs,
    AppLayout,
    RightContainer,
    MenuSide,
    MenuExtension,
    SessionMonitor,
    ReportsDialogActivator
  },
  name: 'Application',
  data: () => ({
    soundInterval: 0,
    containerOver: false,
    containerOutTimeout: 0,
    dragover: false
  }),
  created() {
    this.$store.dispatch('loadLanguages');
    this.$store.dispatch('syncFromLocalStorage');
    this.$store.state.app.acceptLanguage = this.$t('accept_language');
    this.tryLogin();
  },
  computed: {
    hasFilters() {
      const path = this.$route.path;
      return (
        !path.includes('/batch-upload/filter/') &&
        path.indexOf('/puppeteer/search/filter/') !== 0 &&
        (path.indexOf('/filter/') > 0 || path.indexOf('/search/') === 0) &&
        this.activeFilter != null
      );
    },
    activeFilter() {
      return getActiveFilterDescriptor(this);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('resize', this.resizeHandler);
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
    window.addEventListener('storage', this.storageHandler);
    this.resizeHandler();
    this.setFavicon();
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
    window.removeEventListener('storage', this.storageHandler);
  },
  watch: {
    '$store.state.app.permissions.sound.enabled': function watchSoundEnabled(v, p) {
      if (!v) {
        this.$notify({
          title: this.$tf('error.play_sound'),
          duration: 0,
          message: this.$createElement('message-box', { props: { e: this.$store.state.app.permissions.sound.error } })
        });
      }
    },
    '$i18n.locale': function watchI18nLocale(v, p) {
      document.title = this.$tf(this.$route.meta.i18n);
    },
    '$store.state.users.current': function watchUserCurrent(v, p) {
      const equalId = v?.id && v?.id === p?.id;
      if (equalId) return; // https://github.com/vuejs/vuex/issues/524 dynamic registration module issue

      clearInterval(this.soundInterval);
      if (v && v.id) {
        this.$store.dispatch(this.$store.state.ActionNames.FacesEvents.ConnectToWebSocket);
        if (this.$store.getters.puppeteer) this.$store.dispatch(this.$store.state.ActionNames.Puppeteer.ConnectToWebSocket);
        this.soundInterval = setInterval(this.tryPlaySound, 100);

        this.$store.dispatch('get_settings').then((v) => {
          if (v && !v.license_accepted) {
            this.$store.state.dialog.license_agreement.enabled = true;
          }
          this.$store.dispatch('setDefaultConfidenceThreshold', v);
        });

        this.setLanguage(v.language);
        this.validateRoutePermission();
      } else {
        this.$router.push({ path: '/' });
        this.$store.dispatch(this.$store.state.ActionNames.FacesEvents.DisconnectWebSocket);
        if (this.$store.getters.puppeteer) this.$store.dispatch(this.$store.state.ActionNames.Puppeteer.DisconnectWebSocket);
      }
    },
    '$store.state.app.menu': {
      handler(value, prev) {
        this.validateRoutePermission();
      },
      deep: true
    }
  },
  methods: {
    setFavicon() {
      if (this.$store.state.app.configured) {
        const element = document.getElementById('favicon');
        if (element) {
          element.href = this.$store.getters.themeFavicon;
        }
      }
    },
    tryPlaySound() {
      if (this.$store.state.faces_events.notify) {
        this.$store.dispatch('playSound');
      }
    },
    tryLogin() {
      return this.$store
        .dispatch('tryLogin')
        .then((v) => {})
        .catch((e) => {
          this.$router.push({ path: '/' });
        });
    },
    scrollHandler(e) {
      this.$store.state.window.scrollTop = window.pageYOffset;
      this.$store.state.window.scrollLeft = window.pageXOffset;
    },
    resizeHandler(e) {
      this.$store.state.window.width = window.innerWidth;
      this.$store.state.window.height = window.innerHeight;
    },
    localeHandler(v) {
      window.localStorage.locale = v;
      this.$i18n.locale = v;
      this.$store.state.app.acceptLanguage = this.$t('accept_language');
    },
    setLanguage(language) {
      const locale = language.replace(/-\w+$/, '');
      if (this.$store.getters.languages.map(item => item.name).includes(locale)) {
        this.localeHandler(locale);
      }
    },
    containerOverHandler(e) {
      clearTimeout(this.containerOutTimeout);
      this.containerOver = true;
    },
    containerOutHandler() {
      clearTimeout(this.containerOutTimeout);
      this.containerOutTimeout = setTimeout(() => (this.containerOver = false), 3500);
    },
    keyDownHandler({ ctrlKey, keyCode, altKey }) {
      this.$store.state.app.key.ctrl = ctrlKey && keyCode === 17;
      this.$store.state.app.key.alt = altKey && keyCode === 18;
    },
    keyUpHandler(e) {
      const isTargetInput = e.target && e.target.nodeName === 'INPUT';

      if (e.keyCode === 17) {
        this.$store.state.app.key.ctrl = false;
      } else if (e.keyCode === 18) {
        this.$store.state.app.key.alt = false;
      } else if (e.keyCode === 68 && !isTargetInput) {
        e.preventDefault();
        this.$store.state.app.debug = !this.$store.state.app.debug;
      } else if (e.ctrlKey && e.altKey && e.keyCode === 76 && !isTargetInput) {
        e.preventDefault();
        this.switchToNextLanguage();
      }
    },
    switchToNextLanguage() {
      const languages = Object.keys(this.$i18n.messages),
        currentLanguage = this.$i18n.locale,
        nextLanguageIndex = languages.indexOf(currentLanguage) + 1,
        nextLanguage = languages[nextLanguageIndex] || languages[0];
      this.localeHandler(nextLanguage);
    },
    validateRoutePermission() {
      let user = this.$store.state.users.current,
        { parent, child } = this.$store.state.app.menu,
        permissions = (child && child.meta && child.meta.permissions) || (parent && parent.meta && parent.meta.permissions);

      if (user.id && permissions && permissions.length && this.$hasNoPermission(permissions)) {
        console.warn('[app:route:permission] has no permission to route: ', child || parent, permissions, user);
        this.$router.push({ path: this.$store.getters.defaultRoutePath });
      }
    },
    storageHandler(e) {
      if (e && e.key === 'updateStateItems') this.$store.dispatch('updateStateItems', e.newValue);
    }
  }
};
</script>

<style lang="stylus">
.black {
  background-color: #000;
}

.el-table td {
  vertical-align: top !important;
}

.container-language {
  opacity: 0.3;
  position: absolute;
  width: 8rem;
  right: 2rem;
  top: 2rem;
  transition: all 0.1s ease-in;
}

.state-image--security {
  img {
    object-fit: contain !important;
  }

  .content--state-image {
    background-size: contain !important;
  }
}

.container-language--out {
  opacity: 0;
}

.container-language--over {
  width: 8em;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.link {
  cursor: pointer;
  font-weight: bold;
  word-break: normal;
}

.link:hover {
  opacity: 0.8;
}

.cards-table, .watch-lists-table, .cameras-table, .camera-groups-table, .users-table, .events-table, .counters-table, .videos-table {
  .el-table__row {
    cursor: pointer;
  }
}

.cards-table, .watch-lists-table, .cameras-table, .camera-groups-table, .users-table, .events-table, .counters-table, .counter-records-table {
  .image-thumbnail {
    cursor: pointer;
  }
}

.build {
  position: absolute;
  font-size: 0.8rem;
  opacity: 0.3;
  bottom: 0;
  right: 0;
  line-height: 1.2rem;
}

.build:hover {
  opacity: 1;
}

.dragover:after {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  border: 4px solid rgba(255, 255, 255, 0.5);
}
</style>
