const BboxColors = {
  faces: '#00ff00',
  silhouettes: '#ff0000',
  cars: '#ffe8a2',
  license_plates: '#00ffff',
  distance: '#B745E5FF'
};

const BboxTypes = {
  faces: 'faces',
  silhouettes: 'silhouettes',
  cars: 'cars',
  license_plates: 'license_plates'
};

export { BboxColors, BboxTypes };
